<template>
    <Breadcrumbs main="rydpay APIs" title="Troubleshooting & Support" />

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <b>Work in Progress:</b> Troubleshooting & Support - coming soon
                <br><br>
                <div class="card">
                    <div class="card-header bg-primary">
                        <h5>Support processes</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>B2C support:</h5>You redirect the
                        customer to the ryd applications for checkout the pump to our webapplication
                        <ol>
                            <li><b>Level I</b> - Customer reach out to your or our support team</li>
                            <li><b>Level II</b> - Your support refers the customer to the ryd support website where the
                                cusotmer can submit a support request and receive an email with his corresponding support
                                ticket.
                                <ol type="a">
                                    <li>ryd support successfully address the customers needs</li>
                                    <li>ryd support is required to forward the inquiry for further investigation to Level
                                        III</li>
                                </ol>
                            </li>
                            <li><b>Level III</b> - Our quality department is analysing the issue</li>
                            <ol type="a">
                                <li>A missconfiguration was found and resolved by the ryd support team. The customer is
                                    informed about the ticket resolution.</li>
                                <li>A software bug was found and developer ressoures are required. The customer is informed
                                    about the bug fix requirement and a rough ETA is shared.
                                </li>
                            </ol>
                            <li><b>Solution</b> - After the new release was published the customer support inform the
                                customer and close the support ticket.</li>
                        </ol>

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>B2B support:</h5>The partner owns
                        the
                        customer relationship
                        <ol>
                            <li><b>Level I</b> - Customer reach out to your or our support team</li>
                            <li><b>Level II</b> - Your support resolve the issue or escalate to your Level II support team.
                                Usually that is your integration team.
                                <ol type="a">
                                    <li>The issue could be resolved internaly so no relation to ryd atm</li>
                                    <li>Your team requires further assistance from ryd and open a support request which will
                                        be directed directly to the ryd Level III quality team.</li>
                                </ol>
                            </li>
                            <li><b>Level III</b> - Our quality department is analysing the issue</li>
                            <ol type="a">
                                <li>A missconfiguration was found and resolved by the ryd support team. Your level II will
                                    be
                                    informed about the ticket resolution.</li>
                                <li>A software bug was found and developer ressoures are required. Your level II team is
                                    informed
                                    about the bug fix requirement and a rough ETA is shared.
                                </li>
                            </ol>
                            <li><b>Solution</b> - After the new release was published the customer support closes the issue
                                and your Level II will be informed.</li>
                            <li>Your Level II will inform Level I.</li>
                            <li>Your Level I will inform the customer.</li>
                        </ol>
                    </div>
                    <div class="card">
                        <div class="card-header bg-primary">
                            <h5>Possible customer fueling flows</h5>
                        </div>
                        <div class="card-body">
                            <div class="row my-gallery gallery" id="aniimated-thumbnials-1" itemscope="" data-pswp-uid="1">
                                <!-- Display only the first image in the gallery1 array -->
                                <figure class="col-md-3 col-6 img-hover hover-15" @click="() => showImg(0)"
                                    itemprop="associatedMedia" itemscope="">
                                    <div>
                                        <img :src="require('../../../../assets/images/ryd/PartnerCustomerFuelingFlows.png')"
                                            itemprop="thumbnail1" alt="Image description" class="img-fluid">
                                    </div>
                                </figure>
                            </div>
                            <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages" @hide="handleHide">
                            </vue-easy-lightbox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      lightBoxImages: [],
      visible: false,
      gallery1: [
        {
          image: 'PartnerCustomerFuelingFlows.png'
        },
        {
          image: 'PartnerCustomerFuelingFlows.png'
        },
      ],
    }
  },
  watch: {
    '$route.hash': function () {
      this.scrollToCard();
    }
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false;
      this.$nextTick(() => {
        document.body.style.overflow = '';
      });
    },
    scrollToCard() {
      const hash = this.$route.hash;
      const offset = 75; // Height of your fixed header

      if (hash) {
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
      }
    },
  },
  mounted() {
    this.scrollToCard();
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });

  }
}
</script>