<template>
  <Breadcrumbs main="Troubleshooting" title="Order Data By Location"/>

  <div class="container-fluid search-page">
    <div class="row">

      <div class="col-sm-12">
        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group mb-3">
                <div class="row g-3 align-items-center">
                  <div class="col-md-2 text-center fw-bold label-margin-adjust">
                    ZIP Code
                  </div>
                  <div class="col-md-3">
                    <input v-model="poiSearchData.address_zip"
                           type="text"
                           class="form-control"
                           placeholder="Enter ZIP Code"
                           ref="zipCode"
                           :class="{ 'is-invalid': zipCodeError }"
                           @input="onZipCodeInput"
                           @change="validateZipCode">
                  </div>
                  <div class="col-md-2 text-center fw-bold">
                    Petrol Station
                  </div>
                  <div class="col-md-5 position-relative">
                    <select class="form-control border border-primary min-width-max-content"
                            :disabled="selectDisabled"
                            ref="petrolStationSelect"
                            :class="{ 'is-invalid': petrolStationError }"
                            @change="validatePetroStation"
                            v-model="selectedPoiId">
                      <option :value="null" disabled v-html="selectMessage"></option>
                      <option v-for="poi in poisData" :key="poi.poi_id" :value="poi.poi_id" v-html="padString(poi.address_country_code + ' - ' + poi.address_street + ' ' + poi.address_house_number + ', ' + poi.address_zip + ' ' + poi.address_city + ' - ' + poi.brand)">
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 END -->

              <!-- INPUT GROUP 1 ERROR START -->
              <div v-if="zipCodeError || petrolStationError">
                <div class="row g-3 align-items-center m-b-20 m-l-15">
                  <div class="col-md-4">
                    <div class="invalid-feedback" v-if="zipCodeError">
                      Please enter a postal code, regardless of the country, at least 4 digits.
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="invalid-feedback" v-if="petrolStationError">
                      Please select a petro station for the select
                    </div>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 ERROR END -->

              <!-- INPUT GROUP 2 START -->
              <div class="input-group mb-3">
                <div class="row g-3 align-items-center">
                  <div class="col-md-2 text-center fw-bold label-margin-adjust">
                    Search Date Range
                  </div>
                  <div class="col-md-3">
                    <input v-model="fromTimestamp"
                           type="datetime-local"
                           class="form-control"
                           id="searchStart"
                           ref="searchStart"
                           :class="{ 'is-invalid': searchStartError }"
                           @change="validateTimestamps"
                           placeholder="From Timestamp">
                  </div>
                  <div class="col-md-1 text-center fw-bold">
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                  <div class="col-md-3">
                    <input v-model="toTimestamp"
                           id="searchEnd"
                           type="datetime-local"
                           class="form-control"
                           ref="searchEnd"
                           :class="{ 'is-invalid': searchEndError }"
                           @change="validateTimestamps"
                           placeholder="To Timestamp">
                  </div>
                  <div class="col-md-3">
                    <div>You are using timezone: <b>{{ userTimezone }}</b></div>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 2 END -->

              <!-- INPUT GROUP 2 ERROR START -->
              <div v-if="showAlertTimestamps">
                <div class="row g-3 align-items-center m-b-20 m-l-15">
                  <div class="col-md-9">
                    <div class="invalid-feedback">
                      Please Enter a Valid Search Date Range: the time span between the <code>From</code> and <code>To</code> dates must <b>not exceed 7 days</b>.
                    </div>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 2 ERROR END -->

              <!-- INPUT GROUP 3 START -->
              <div class="input-group mb-3">
                <div class="row g-3 align-items-center">
                  <div class="col-md-2">
                    <button @click="refreshFindOrders"
                            class="btn btn-pill btn-success btn-air-success active input-group-text">
                      <i class="fa fa-send m-r-10"></i> Find Orders
                    </button>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 3 END -->

            </div>
          </div>
        </div>
        <!-- CARD 1 END -->

        <!-- CARD 2 START -->
        <div class="card">
          <div class="card-body">

            <!-- Loading Indicator START -->
            <div v-if="isLoadingFoundOrdersByPoiId" class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Loading... Orders for the specified search criteria </h6>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>

            <!-- INPUT GROUP 1a START -->
            <div v-if="isPoiAutocompleteActive" class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Search for petrol stations with the given zip code...</h6>
              <div class="loader-box">
                <div class="loader-19"></div>
              </div>
            </div>
            <!-- INPUT GROUP 1a END -->

            <div class="input-group">
              <div v-if="!isPoiAutocompleteActive && isPoiAutocompleteFinished && !selectedPoiId && poisData && poisData.length === 0"
                   class="alert alert-danger outline-2x mb-2" role="alert">
                <i data-feather="thumbs-up">
                  <vue-feather type="alert-triangle"></vue-feather>
                </i>
                &nbsp;&nbsp;
                  No gas station could be found with the provided zip code, please check your input.

              </div>
            </div>


            <div class="table-responsive product-table"
                 v-if="!isLoadingFoundOrdersByPoiId && findOrdersResponse && findOrdersResponse.numberOfResults > 0">
              <table class="table table-border table-striped display" id="orderReportsByPoiTable">
                <thead class="bg-primary text-white">
                <tr>
                  <th class="bg-primary" scope="col">Creation Date Time</th>
                  <th class="bg-primary" scope="col">Order State</th>
                  <th class="bg-primary" scope="col">Is Successful?</th>
                  <th class="bg-primary" scope="col">Order Uuid at Ryd</th>
                  <th class="bg-primary" scope="col">Pump Number</th>
                  <th class="bg-primary" scope="col">Number of Liters</th>
                  <th class="bg-primary" scope="col">Fuel Type/Product</th>
                  <th class="bg-primary" scope="col">Product Unit Price</th>
                  <th class="bg-primary" scope="col">Product Net Price</th>
                  <th class="bg-primary" scope="col">Product Total Price</th>
                  <th class="bg-primary" scope="col">Product Currency Code</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="orderDetail in findOrdersResponse.data" :key="orderDetail">
                 <td class="border border-dark">{{ localTimeString(orderDetail.order_created_at) }}</td>
                 <td class="border border-dark">{{ orderDetail.order_state }}</td>
                 <td class="border border-dark">{{ orderStatusDescription(orderDetail.order_state) }}</td>
                 <td class="border border-dark">{{ orderDetail.order_uuid }}</td>
                 <td class="border border-dark">{{ orderDetail.pump_number }}</td>
                 <td class="border border-dark">{{ orderDetail.product_quantity }}</td>
                 <td class="border border-dark">{{ orderDetail.fuel_type_standard }}</td>
                 <td class="border border-dark">{{ orderDetail.product_unit_price_eur_amount }}</td>
                 <td class="border border-dark">{{ orderDetail.product_net_price_eur_amount }}</td>
                 <td class="border border-dark">{{ orderDetail.product_total_price_eur_amount }}</td>
                 <td class="border border-dark">{{ orderDetail.product_currency_code }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div v-if="findOrdersResponse && findOrdersResponse.infoMessage">
              <div class="text-center fw-bold fs-6 font-success" v-html="findOrdersResponse.infoMessage"></div>
            </div>

            <div v-if="findOrdersResponse && findOrdersResponse.errorMessage">
              <div class="text-center fw-bold fs-6 font-danger">{{ findOrdersResponse.errorMessage }}</div>
            </div>
          </div>
        </div>
        <!-- CARD 2 END -->

        <!-- card 3 start -->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-info">
            <h5 class="text-white">Important Notes</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="icofont icofont-hand-right font-primary"></i>
                <b>Please follow these steps for your search:</b>
                <ul class="list-group">
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    1. <b>Enter the ZIP code</b> of the desired petrol station.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    2. <b>Select a petrol station</b> from the list that appears.
                  </li>
                  <li class="list-group-item"><i class="fa fa-arrow-circle-right font-primary"></i>
                    3. <b>Specify the start and end times</b> for your search.
                  </li>
                </ul>
              </li>
              <li class="list-group-item"><i class="icofont icofont-hand-right font-primary"></i>
                Ensure you specify a valid search date range where the interval between the 'From' and 'To' dates does not exceed 7 days, to maintain optimal search performance.
              </li>
              <li class="list-group-item"><i class="icofont icofont-hand-right font-primary"></i>
                A maximum of <b>200</b> orders will be returned for each search.
              </li>
              <li class="list-group-item"><i class="icofont icofont-hand-right font-primary"></i>
                All times are based on the <b>{{ userTimezone }}</b> time zone, as determined by your web browser settings.
              </li>
              <li class="list-group-item"><i class="icofont icofont-hand-right font-primary"></i>
                Currently, you can only search for <b>Miles</b> orders.
              </li>
            </ul>
          </div>
        </div>
        <!-- card 3 end -->

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getUserAddDataFromLocalStorage } from "@/utils/userUtils";
import { PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY, PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL } from "@/constants/constants";
import { convertDateTimeToReadFormat } from "@/utils/dateUtils";

let primary = localStorage.getItem('primary_color') || '#24695c';
let secondary = localStorage.getItem('secondary_color') || '#ba895d';

export default {
  keywords: ['Order By Location', 'Order Data by Location', 'Order by POI'],
  data() {
    return {
      showAlertTimestamps: false,
      showAlertPetrolStation: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      isLoadingFoundOrdersByPoiId: false,
      minDate: '2023-11-10',
      missingInvoiceApiCallsResponse: {
        numberOfResults: 0,
        sortedResult: [],
        errorMessage: null,
        infoMessage: null
      },
      findOrdersResponse: {
        orders: [],
        errorMessage: null,
        infoMessage: null
      },
      userTimezone: '',
      poiData: {
        poi_id: null,
        brand: null,
        address_street: null,
        address_house_number: null,
        address_zip: null,
        address_city: null,
        address_country_code: null,
        address_latitude: null,
        address_longitude: null,
      },
      selectedPoiId: null,  // Initially no petrol station is selected
      poisData: [],
      poiSearchData: {
        address_zip: '',
        address_street: '',
      },
      isPoiAutocompleteActive: false,
      isPoiAutocompleteFinished: false,
      onePoiHasBeenSelected: false,
      maxLength: 70,
      debounceTimeout: null,
      zipCodeError: false,
      petrolStationError: false,
      searchStartError: false,
      searchEndError: false,
      showDebugMessage: false
    }
  },
  mounted() {
    const { uid, userGroup, isUserGroupRyd } = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.partnerMetricsFor = userGroup;
    this.userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.initializeParameters();
    this.checkAndTriggerSearch();
    // this.findOrders(this.fromTimestamp, this.toTimestamp);
  },
  watch: {
    poiSearchData: {
      handler(newVal) {
        if (newVal.address_zip && newVal.address_zip.length >= 4) {
          this.debouncedfindPoisByAutoComplete();
        }
      },
      deep: true
    },
    poisData(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.selectedPoiId = null;
        this.$forceUpdate(); // Ensure the select element updates correctly
      }
    }
  },
  computed: {
    showNoGasStationFoundMessage() {
      return this.searched && !this.isPoiAutocompleteActive && !this.selectedPoi && this.poisData.length === 0;
    },
    isValidZip() {
      // Assume ZIP code must be at least 3 characters long to be considered valid
      if (!this.poiSearchData) {
        return false;
      }
      if (!this.poiSearchData.address_zip) {
        return false;
      }

      return this.poiSearchData.address_zip.length >= 3;
    },
    selectDisabled() {
      return (!this.isValidZip || this.poisData.length === 0);
    },
    selectMessage() {

      if(this.showDebugMessage){
        console.log("##################");
        console.log("this.isPoiAutocompleteActive", this.isPoiAutocompleteActive);
        console.log("this.isPoiAutocompleteFinished", this.isPoiAutocompleteFinished);
        console.log("this.isValidZip", this.isValidZip);
        console.log("this.poisData.length", this.poisData.length);
        console.log("this.poiSearchData.address_zip.length", this.poiSearchData && this.poiSearchData.address_zip ? this.poiSearchData.address_zip.length : 0);
        console.log("-------------------");
      }


      let message = '';

      if (this.poiSearchData && !this.poiSearchData.address_zip) {
        message = 'Enter a valid zip code to activate options';
        return this.padString(message);
      }

      if (this.isPoiAutocompleteFinished && this.poisData.length > 0) {
        message = 'Please select a petrol station';
      } else if (this.isPoiAutocompleteFinished && this.poisData.length === 0) {
        if (!this.isPoiAutocompleteActive && this.isValidZip && this.poisData.length === 0) {
          message = 'No supported petrol station found given zip code';
        } else {
          message = 'Please select a petrol station';
        }
      } else {

        if (!this.poiSearchData.address_zip) {
          message = 'Enter a valid zip code to activate options';
        } else if (this.poiSearchData && this.poiSearchData.address_zip && this.poiSearchData.address_zip.length < 4) {
          message = 'Enter a valid zip code to activate options';
        } else if (this.isPoiAutocompleteActive && this.isValidZip) {
          message = 'Search in Progress..';
        }
      }

      if(this.showDebugMessage){
        console.log("default message", message);
      }

      return this.padString(message);

    }
  },
  methods: {
    validateZipCode() {
      this.zipCodeError = !this.poiSearchData ||  (this.poiSearchData && !this.poiSearchData.address_zip) || this.poiSearchData.address_zip.length < 4;
    },
    validatePetroStation() {
      this.petrolStationError = !this.selectedPoiId;
    },
    convertDateTimeToReadFormat,
    padString(str) {
      const padLength = Math.max(0, this.maxLength - str.length);
      const pad = '&nbsp;'.repeat(padLength);
      return `${pad}${str}${pad}`;
    },
    orderStatusDescription(orderState) {
      switch (orderState) {
        case 'PROBLEM':
          return 'UNKNOWN, manual check is needed';
        case 'COMPLETED':
        case 'COMPLETED_MANUALLY':
          return 'YES';
        case 'CANCELLED':
        case 'FAILED':
          return 'NO';
        default:
          return 'Final State not reached';
      }
    },
    async refreshFindOrders() {

      // Perform validations
      this.validateZipCode();
      this.validatePetroStation();
      this.validateTimestamps();

      // Check if there are any errors
      const errors = [];
      if (this.zipCodeError) {
        errors.push('zipCode');
      }
      if (this.petrolStationError) {
        errors.push('petrolStationSelect');
        this.showAlertPetrolStation = true;
      }
      if (this.searchStartError) {
        errors.push('searchStart');
        this.showAlertTimestamps = true;
      }
      if (this.searchEndError) {
        errors.push('searchEnd');
        this.showAlertTimestamps = true;
      }

      console.log("errors", errors);

      // If there are errors, scroll into view and focus on the first error
      if (errors.length > 0) {
        this.$nextTick(() => {
          const firstError = errors[0];
          const refName = `${firstError}`;
          this.$refs[refName].scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.$refs[refName].focus();
        });
        return;
      }

      let validateTimestamps = !this.validateTimestamps();
      let validatePetrolStationSelection = !this.selectedPoiId || this.selectedPoiId === 'null';
      if (validateTimestamps || validatePetrolStationSelection) {
        if (validateTimestamps) {
          this.showAlertTimestamps = true;
        }
        if (validatePetrolStationSelection) {
          this.showAlertPetrolStation = true;
        }
        return;
      }

      this.showAlertTimestamps = false;
      this.showAlertPetrolStation = false;
      this.isLoadingFoundOrdersByPoiId = true;
      await this.findOrders(this.fromTimestamp, this.toTimestamp);
    },

    validateTimestamps() {
      if(this.showDebugMessage) {
        console.log("validateTimestamps started");
        console.log("this.fromTimestamp", this.fromTimestamp);
        console.log("this.toTimestamp", this.toTimestamp);
      }

      if (!this.fromTimestamp) {
        this.showAlertTimestamps = true;
        this.searchStartError = true;
        this.searchEndError = false;
      }
      if (!this.toTimestamp) {
        this.showAlertTimestamps = true;
        this.searchStartError = false;
        this.searchEndError = true;
      }

      console.log("searchStartError", this.searchStartError);
      console.log("searchEndError", this.searchEndError);
      console.log("showAlertTimestamps", this.showAlertTimestamps);

      if(!this.fromTimestamp && !this.toTimestamp){
        return false;
      }


      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        this.searchStartError = true;
        this.searchEndError = true;
        return false;
      }

      console.log("showAlertTimestamps", this.showAlertTimestamps);

      const sevenDays = 7 * 24 * 60 * 60 * 1000;
      if (to - from > sevenDays) {
        this.showAlertTimestamps = true;
        this.searchStartError = true;
        this.searchEndError = true;
        this.missingInvoiceApiCallsResponse.infoMessage = null;
        this.missingInvoiceApiCallsResponse.errorMessage = "Ensure you specify a valid search date range where the interval between the 'From' and 'To' dates does not exceed 7 days, to maintain optimal search performance.";
        this.missingInvoiceApiCallsResponse.numberOfResults = 0;
        this.missingInvoiceApiCallsResponse.sortedResult = [];
        return false;
      }

      this.showAlertTimestamps = false;
      this.searchStartError = false;
      this.searchEndError = false;
      return true;
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 14);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate());
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    initializeParameters() {
      const queryParams = this.$route.query;
      this.fromTimestamp = queryParams.from || this.getDefaultFromDate();
      this.toTimestamp = queryParams.to || this.getDefaultToDate();

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to) {
        this.refreshFindOrders();
      }
    },

    async findPoisByAutoComplete() {
      this.isPoiAutocompleteActive = true;
      this.poisData = [];
      this.selectedPoiId = null;
      this.onePoiHasBeenSelected = false;
      this.findOrdersResponse = { orders: [] };

      if (!this.poiSearchData.address_zip || this.poiSearchData.address_zip.length < 4) {
        this.isValidZip = false;
        setTimeout(() => {
          this.isPoiAutocompleteActive = false;
          this.isPoiAutocompleteFinished = false;
        }, 500); // Delay turning off the loading state
        return;
      }

      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/gas-stations/address-auto-completion`, {
          params: {
            zip: this.poiSearchData.address_zip
          },
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId
          }
        });
        this.poisData = response.data.data;
        this.isValidZip = this.poisData.length > 0;
        if (this.poisData.length === 0) {
          this.selectedPoiId = null;
        }
        this.isPoiAutocompleteFinished = true;
      } catch (error) {
        console.error('Error fetching autocomplete data:', error);
        this.poisData = [];
        this.isValidZip = false;
        this.isPoiAutocompleteFinished = true;
      } finally {
        // Delay turning off the loading state
        setTimeout(() => {
          this.isPoiAutocompleteActive = false;
        }, 500); // 500ms delay, adjust as needed
      }
    },

    debouncedfindPoisByAutoComplete() {
      if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.findPoisByAutoComplete();
      }, 300); // 300ms debounce
    },

    async findOrders(fromTimestamp, toTimestamp) {
      this.isLoadingFoundOrdersByPoiId = true;
      this.findOrdersResponse.errorMessage = null;
      this.findOrdersResponse.infoMessage = null;
      this.showAlertPetrolStation = false;
      this.zipCodeError = false;
      this.petrolStationError = false;
      this.searchStartError = false;
      this.searchEndError = false;

      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/orders`, {
          params: {
            poiId: this.selectedPoiId,
            startTime: fromTimestamp,
            endTime: toTimestamp,
          },
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.findOrdersResponse = {};
          this.findOrdersResponse.errorMessage = "A technical error occurred.";
        } else {
          this.findOrdersResponse = response.data;
          if (response.data.numberOfResults === 0) {
            this.findOrdersResponse = {};
            this.findOrdersResponse.infoMessage = "No order could be found for the given petrol station in the selected date ranges.";
          }
        }
      } catch (error) {
        this.findOrdersResponse.errorMessage = "A technical error occurred.";
        console.error('Error findOrders:', error);
      } finally {
        this.isLoadingFoundOrdersByPoiId = false;
      }
    },
    localTimeString(utcDateString) {
      const date = new Date(utcDateString);
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,  // Enforce 24-hour format
        timeZone: this.userTimezone
      };
      const formatter = new Intl.DateTimeFormat('en-CA', options); // Using Canadian English to ensure YYYY-MM-DD format
      const parts = formatter.formatToParts(date);
      let year, month, day, hour, minute;

      parts.forEach(part => {
        if (part.type === 'year') {
          year = part.value;
        } else if (part.type === 'month') {
          month = part.value;
        } else if (part.type === 'day') {
          day = part.value;
        } else if (part.type === 'hour') {
          hour = part.value;
        } else if (part.type === 'minute') {
          minute = part.value;
        }
      });

      return `${year}-${month}-${day} ${hour}:${minute}`; // Constructing the date in the desired format
    },
    onZipCodeInput() {
      this.debouncedfindPoisByAutoComplete();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>

<style scoped>
@import "@/assets/css/productlist.css";

select.form-control {
  width: 100%;
  white-space: nowrap;
}

select.form-control option {
  display: block;
  padding: 8px;
  text-align: left; /* Left align text for better readability */
  width: 100%;
  white-space: pre;
}


#searchStart.is-invalid,
#searchEnd.is-invalid {
  background-image: none
}

</style>
