<template>
  <Breadcrumbs main="Guides: EV Charging" title="EV Charging Simulated Stations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Purpose</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">Ryd provides virtual gas stations in a STAGING environment, specifically designed for testing purposes.</li>
              <li class="list-group-item">These simulated petro stations are intended to replicate various scenarios for API integration and testing.</li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->

        <br/>

        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-success">
            <h5 class="text-white">Simulated EV Charging Station 1</h5>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Main Purpose:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Test successful transactions
              </li>
              <li class="list-group-item">
                Test complex pricing models (multiple pricing elements and restrictions)
              </li>
            </ul>
            <br />


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>POI Id:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>b989ce59-d976-411d-ba30-0d2e1f90baac</b>
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>POI Address:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Landsberger Str. 35, 80339 München
              </li>
            </ul>
            <br />


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Get /v4/pois/:id Response:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#examplePoiResponseModal1">
                  Click to see pois-by-id API response
                </button>
              </li>
            </ul>
            <br />


            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-success text-white">
                <tr>
                  <th class="font-success f-w-800 border border-dark">EVSE id</th>
                  <th class="font-success f-w-800 border border-dark">Connector Type</th>
                  <th class="font-success f-w-800 border border-dark">EVSE Status</th>
                  <th class="font-success f-w-800 border border-dark">Power</th>
                  <th class="font-success f-w-800 border border-dark">Price Elements</th>
                  <th class="font-success f-w-800 border border-dark">Transaction Status</th>
                  <th class="font-success f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark">TYPE_2:22:AC:EV_CHARGE</td>
                  <td class="border border-dark">TYPE_2</td>
                  <td class="border border-dark">AVAILABLE</td>
                  <td class="border border-dark">22</td>
                  <td class="border border-dark">ENERGY, TIME restriction
                  </td>
                  <td class="border border-dark">successful</td>
                  <td class="border border-dark"></td>
                </tr>

                <tr>
                  <td class="border border-dark">SCHUKO:4:AC:EV_CHARGE</td>
                  <td class="border border-dark">SCHUKO</td>
                  <td class="border border-dark">AVAILABLE</td>
                  <td class="border border-dark">4</td>
                  <td class="border border-dark">ENERGY without restriction</td>
                  <td class="border border-dark">successful</td>
                  <td class="border border-dark"></td>
                </tr>

                <tr>
                  <td class="border border-dark">TYPE_2:50:AC:EV_CHARGE</td>
                  <td class="border border-dark">TYPE_2</td>
                  <td class="border border-dark">AVAILABLE</td>
                  <td class="border border-dark">50</td>
                  <td class="border border-dark">ENERGY, TIME without restriction</td>
                  <td class="border border-dark">successful</td>
                  <td class="border border-dark"></td>
                </tr>


                <tr>
                  <td class="border border-dark">CHADEMO:175:DC:EV_CHARGE</td>
                  <td class="border border-dark">CHADEMO</td>
                  <td class="border border-dark">AVAILABLE</td>
                  <td class="border border-dark">175</td>
                  <td class="border border-dark">ENERGY, TIME with restriction</td>
                  <td class="border border-dark">Overshoot</td>
                  <td class="border border-dark">the final price of 250 EURO is higher than the authorised amount of 40 EURO</td>
                </tr>

                <tr>
                  <td class="border border-dark">SCHUKO:4:AC:EV_CHARGE</td>
                  <td class="border border-dark">SCHUKO</td>
                  <td class="border border-dark">UNAVAILABLE / OCCUPIED</td>
                  <td class="border border-dark">4</td>
                  <td class="border border-dark">FLAT</td>
                  <td class="border border-dark">Can not be used</td>
                  <td class="border border-dark"></td>
                </tr>


                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->


        <JsonModal
            modalId="examplePoiResponseModal1"
            modalTitle="pois-by-id Response for b989ce59-d976-411d-ba30-0d2e1f90baac"
            :jsonData="json1"
        />

        <br/>

        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="text-white">Simulated EV Charging Station 2</h5>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Main Purpose:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                 unsuccessful transactions
              </li>
            </ul>
            <br />


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>POI Id:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>f1157103-63b5-4412-b7b0-4737dac45470</b>
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>POI Address:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Landsberger Str. 33, 80339 München
              </li>
            </ul>
            <br />


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Get /v4/pois/:id Response:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#examplePoiResponseModal2">
                  Click to see pois-by-id API response
                </button>
              </li>
            </ul>
            <br />


            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-primary f-w-800 border border-dark">EVSE id</th>
                  <th class="font-primary f-w-800 border border-dark">Connector Type</th>
                  <th class="font-primary f-w-800 border border-dark">EVSE Status</th>
                  <th class="font-primary f-w-800 border border-dark">Power</th>
                  <th class="font-primary f-w-800 border border-dark">Price Elements</th>
                  <th class="font-primary f-w-800 border border-dark">Transaction Status</th>
                  <th class="font-primary f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark">OTHER:120:DC:EV_CHARGE</td>
                  <td class="border border-dark">OTHER</td>
                  <td class="border border-dark">AVAILABLE</td>
                  <td class="border border-dark">120</td>
                  <td class="border border-dark">ENERGY, TIME without restriction
                  </td>
                  <td class="border border-dark">unsuccessful</td>
                  <td class="border border-dark"></td>
                </tr>

                <tr>
                  <td class="border border-dark">CHADEMO:175:DC:EV_CHARGE</td>
                  <td class="border border-dark">CHADEMO</td>
                  <td class="border border-dark">AVAILABLE</td>
                  <td class="border border-dark">175</td>
                  <td class="border border-dark">ENERGY, TIME without restriction</td>
                  <td class="border border-dark">unsuccessful</td>
                  <td class="border border-dark"></td>
                </tr>


                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->
        <JsonModal
            modalId="examplePoiResponseModal2"
            modalTitle="Another Response Title"
            :jsonData="json2"
        />


      </div>
    </div>
  </div>
</template>
<script>
import JsonModal from './../components/JsonModal.vue';

import json1 from './../data/poi-id-api-response-b989ce59.json';
import json2 from './../data/poi-id-api-response-f1157103.json';
export default {
  name: 'EvChargingSimulatedStations',
  keywords: [
    'Simulated EV Charging Stations', 'EV Charging Simulation', 'Charging Port Testing', 'Simulated Charging Ports',
    'API Testing', 'Test Scenarios', 'EV Station Simulation', 'EV Charging API', 'Mock EV Charging Stations',
    'Virtual EV Charging', 'Charging Station Behavior', 'Charging Port Errors', 'API Simulation', 'Test Environment',
    'Test Stations', 'Testing Tools', 'API Debugging', 'Simulation Setup', 'Simulated Environments',
    'EV Charging Scenarios', 'Test Charging Stations', 'Charging Station Performance', 'Simulated EV Charging',
    'Behavior Testing', 'Test Cases', 'API Validation', 'Station Configuration', 'Charging Port Configuration',
    'API Testing Guide', 'Test Data', 'Test Setup', 'Mock API', 'Simulated Stations', 'Charging Error Handling',
    'Charging Testing Examples', 'API Test Scripts', 'Charging Simulation', 'Charging Station Interaction', 'Station Simulation',
    'Test Charging', 'Test Simulation', 'API Integration Testing', 'Charging Testing Tools', 'Scenario Testing',
    'API Test Environment', 'EV Charging Simulation', 'Testing Scenarios', 'API Simulation Guide', 'Virtual Charging Setup',
    'EV Charging API', 'Simulated Station Guide', 'Testing Best Practices', 'API Test Scenarios', 'Simulation Tools',
    'API Test Data', 'API Mocking', 'Charging Process Scenarios', 'Simulated Test Environment', 'Station and Charging Testing',
    'Test Execution', 'Charging Test Examples', 'API Test Cases', 'Testing Framework', 'API Mock Setup',
    'Electric Vehicle Charging', 'EV Charger Simulation', 'EV Charging Process', 'Electric Car Charging', 'EV Charging Points'
  ],
  components: {
    JsonModal
  },
  data() {
    return {
      json1: JSON.stringify(json1, null, 2),
      json2: JSON.stringify(json2, null, 2)
    };
  },
  props: {
  },
  methods: {

  },
}
</script>


<style scoped>
pre {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>