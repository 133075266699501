<template>
  <Breadcrumbs main="rydpay APIs" title="Account Settings"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div class="card">
          <div class="card-header bg-primary">
            <h5>API Key / Token</h5>
          </div>
          <div class="card-body" v-if="!isLoadingPartnerConfigs">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Ryd's <b>Staging/Test</b>
              environment</h5>
            <h6>Token:</h6>
            <div v-if="tt4ApiTokensData && tt4ApiTokensData.tt4ApiTokenStaging">
              <ul class="list-group alternating-list">
                <li class="list-group-item col-sm-10 col-md-8 col-lg-6 border border-primary"
                    v-for="tokenItem in tt4ApiTokensData.tt4ApiTokenStaging" :key="tokenItem.id">
                      <span :class="{'blur-token': !tokenItem.visible}">
                        {{ tokenItem.token }}
                      </span>
                  <button class="m-l-50 btn btn-primary" @click="toggleVisibility(tokenItem)"><i class="fa fa-eye"></i>View
                  </button>
                  <button class="m-l-50 btn btn-primary" @click="copyToken(tokenItem.token)"><i class="fa fa-copy"></i>Copy
                  </button>
                </li>
              </ul>
            </div>
            <div v-else>
              <p>NOT Available (may be due to contractual agreement and no API key should be created)</p>
            </div>
            <br/>
            <h6>Server URL:</h6>
            <ul class="list-group alternating-list">
              <li class="list-group-item col-sm-10 col-md-8 col-lg-6 border border-primary">
                      <span>
                        https://tt4.thinxcloud-staging.de
                      </span>
                <button class="m-l-50 btn btn-primary" @click="copyToken('https://tt4.thinxcloud-staging.de')"><i
                    class="fa fa-copy"></i>Copy
                </button>
              </li>
            </ul>
            <br/>
            <br/>
            <hr/>
            <h5><i class="icofont icofont-hand-right font-info m-t-50">&nbsp;</i>Ryd's <b>Production</b>
              environment</h5>
            <h6>Token:</h6>
            <div v-if="tt4ApiTokensData && tt4ApiTokensData.tt4ApiTokenProd">
              <ul class="list-group alternating-list">
                <li class="list-group-item col-sm-10 col-md-8 col-lg-6 border border-info"
                    v-for="tokenItem in tt4ApiTokensData.tt4ApiTokenProd" :key="tokenItem">
                  <span :class="{'blur-token': !tokenItem.visible}">
                        {{ tokenItem.token }}
                  </span>
                  <button class="m-l-50 btn btn-info" @click="toggleVisibility(tokenItem)"><i class="fa fa-eye"></i>View
                  </button>
                  <button class="m-l-50 btn btn-info" @click="copyToken(tokenItem.token)"><i class="fa fa-copy"></i>Copy
                  </button>
                </li>
              </ul>
            </div>
            <div v-else>
              <p>NOT Available. (may be due to contractual agreement and no API key should be created)</p>
            </div>
            <br/>
            <h6>Server URL:</h6>
            <ul class="list-group alternating-list">
              <li class="list-group-item col-sm-10 col-md-8 col-lg-6 border border-info">
                      <span>
                        https://tt4.thinxcloud.de
                      </span>
                <button class="m-l-50 btn btn-info" @click="copyToken('https://tt4.thinxcloud.de')"><i
                    class="fa fa-copy"></i>Copy
                </button>
              </li>
            </ul>
            <br/>
            <br/>
            <hr/>
            <br/>
          </div>

          <!-- Loading Indicator START -->
          <div v-if="isLoadingPartnerConfigs" class="loading-indicator">
            <br/>
            <h6 class="sub-title mb-0 text-center">Loading ... Your Account Settings</h6>
            <div class="loader-box">
              <div class="loader-17"></div>
            </div>
          </div>
          <!-- Loading Indicator END -->

        </div>

        <div class="card">
          <div class="card-header bg-success">
            <h5>Webhooks</h5>
          </div>
          <div class="card-body">
            <p>Available webhooks in rydpay APIs can be found at: </p><a href="/integration-guide/rydpay-apis/webhooks">rydpay API Webhooks</a>
          </div>
        </div>


        <!-- toast-container start -->
        <!-- Success Toast -->
        <div v-show="showSuccessToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-body bg-info">
            Token copied to clipboard!
          </div>
        </div>

        <!-- Error Toast -->
        <div v-show="showErrorToast" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-body bg-error">
            Failed to copy token.
          </div>
        </div>
        <!-- toast-container END -->

      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {RPP_API_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {auth} from "@/firebase/firebaseInit";


export default {
  name: 'AccountSettings',
  keywords: [
    'API Key', 'Token', 'Authentication', 'Account', 'Staging', 'Production', 'Server Url',
    'Access Key', 'Access Token', 'API Access', 'Generate Key', 'Retrieve Token', 'API Authentication Key',
    'Environment Keys', 'Environment Tokens', 'API Management', 'Key Management', 'Token Management',
    'Test Environment', 'Live Environment', 'Sandbox Environment', 'Production Access', 'API Credentials',
    'Security Key', 'Key Generation', 'Token Generation', 'API Authorization', 'API Security',
    'Generate API Key', 'Create API Key', 'API Key Creation', 'Key Retrieval', 'Token Retrieval',
    'API Key Setup', 'API Token Setup', 'Account Security', 'API Access Control', 'Key Storage',
    'Token Storage', 'API Integration', 'API Access Management', 'Credential Management', 'Environment Configuration',
    'Staging Key', 'Production Key', 'Staging Token', 'Production Token', 'Fetch API Key', 'Fetch API Token',
    'Download API Key', 'Download API Token', 'API Key Activation', 'API Token Activation', 'Service Credentials',
    'API Setup', 'Key Configuration', 'Token Configuration', 'Secure API Key', 'Secure API Token'
  ],
  data() {
    return {
      tt4ApiTokensData: {},
      isLoadingPartnerConfigs: false,
      showSuccessToast: false,
      showErrorToast: false,
    };
  },
  mounted() {
    this.fetchPartnerConfigs();
  },
  methods: {
    async fetchPartnerConfigs() {

      const {uid} = getUserAddDataFromLocalStorage();

      try {
        this.isLoadingPartnerConfigs = true;
        const response = await axios.get(`${RPP_API_SERVER_URL}/partner-configs?singleUserId=${uid}`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY
          }
        });
        this.tt4ApiTokensData = response.data;
      } catch (error) {
        console.error('API fetch error:', error);
        this.tt4ApiTokensData.errorMessage = "NOT available.";
      } finally {
        this.isLoadingPartnerConfigs = false;
      }
    },
    toggleVisibility(tokenItem) {
      tokenItem.visible = !tokenItem.visible;
    },
    async copyToken(token) {
      try {
        await navigator.clipboard.writeText(token);
        this.showSuccessToast = true;
        setTimeout(() => this.showSuccessToast = false, 3000); // Hide toast after 3 seconds
      } catch (err) {
        this.showErrorToast = true;
        setTimeout(() => this.showErrorToast = false, 3000); // Hide toast after 3 seconds
      }
    },
    async fetchUsers() {

      this.isUsersLoading = true;

      const user = auth.currentUser;
      //console.log("userListItemUser", user);
      const uid = user ? user.uid : null;
      this.currentUserUid = uid;

      try {
        const response = await axios.get(`${RPP_API_SERVER_URL}`, {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            searchKey: this.searchKey,
            sortBy: this.sortColumn,
            order: this.sortOrder,
          },
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
            'uid': uid
          }
        });

        this.users = response.data.users;
        this.totalUsers = response.data.total;
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error
      } finally {
        this.isUsersLoading = false;
      }
    },
    showToast(toastId) {
      const toastEl = document.getElementById(toastId);
      const toast = new bootstrap.Toast(toastEl);
      toast.show();
    },
  }
};

</script>

<style>
.blur-token {
  filter: blur(4px);
}
</style>