import { parse } from '@vue/compiler-sfc';

export function extractTextContent(component) {
    const componentString = component.toString();

    // Ensure the component string contains a <template>
    if (!componentString.includes('<template>')) {
        return '';
    }

    const parsedComponent = parse(componentString);

    let textContent = '';
    if (parsedComponent.descriptor && parsedComponent.descriptor.template) {
        const templateContent = parsedComponent.descriptor.template.content;
        textContent = templateContent.replace(/<[^>]*>/g, ' ').trim();
    }

    return textContent;
}
