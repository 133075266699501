<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Cashier System Configurations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div v-if="originFromPage === 'edit'">
          <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"></i>
            <p>Please select an existing configuration from the list below to edit it.</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        <div v-if="originFromPage === 'view'">
          <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"></i>
            <p>Please select an existing configuration from the list below to view it.</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>

        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="m-t-20 mb-0 text-center f-w-900">Loading All Available Configurations for <span class="font-primary">Cashier Systems</span> ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main form content -->
        <div v-if="!isLoading && cashierSystemConfigurations.results && cashierSystemConfigurations.results.length > 0">
          <!-- second card: found users -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive product-table">
                    <table class="display table-striped" id="basic-1">
                      <thead class="bg-primary text-white">
                      <tr>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Cashier System
                        </th>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Country
                        </th>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Email Language
                        </th>
                        <th class="bg-primary" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                            aria-label="" style="width: 25%;">
                          Actions
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="cashierSystemConfiguration in cashierSystemConfigurations.results" :key="cashierSystemConfiguration">
                        <td>{{ cashierSystemConfiguration.id }}</td>
                        <td>
                          {{ getCountryName(cashierSystemConfiguration.country) }}
                        </td>
                        <td>
                          {{ getLanguageName(cashierSystemConfiguration.emailLanguage) }}
                        </td>


                        <td>
                          <button class="btn btn-success me-3 m-t-10" type="button" title="View Alert Configuration for A Cashier System"
                                  @click="viewCashierSystemConfiguration(cashierSystemConfiguration.id )">
                            View
                          </button>

                          <button class="btn btn-warning me-3 m-t-10" type="button" title="Edit Alert Configuration for A Cashier System"
                                  @click="editCashierSystemConfiguration(cashierSystemConfiguration.id )">
                            Edit
                          </button>


                          <button class="btn btn-danger me-3 m-t-10" type="button" data-bs-toggle="modal"
                                  @click="selectCashierSystemConfigForDeletion(cashierSystemConfiguration.id, cashierSystemConfiguration.country, cashierSystemConfiguration.cashierSystemName)"
                                  data-bs-target="#cashierSystemConfigurationDeletionModalCenter">
                            Delete
                          </button>

                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

              <br/><br/>
            </div>
          </div>
        </div>


        <!-- Model Start -->
        <div class="modal fade" id="cashierSystemConfigurationDeletionModalCenter" tabindex="-1" role="dialog"
             ref="deletionModal"
             aria-labelledby="cashierSystemConfigurationDeletionModalCenter">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Delete Alert Configuration for Cashier System</h5>
              </div>
              <div class="modal-body">
                <p>
                  The alert configuration for the Cashier System <br/>
                  <b>{{ this.cashierSystemConfigurationNameToDelete }}</b>
                  <br/>
                  in the country <br/>
                  <b>{{ getCountryName(this.cashierSystemConfigurationCountryToDelete) }}</b>
                  <br/>
                  will be deleted.
                </p>
                <p>Please confirm the deletion by entering the Cashier System Name below, noting spaces and capitalization:</p>
                <input
                    type="text"
                    class="form-control border border-primary"
                    v-model="confirmationInput"
                    @input="validateConfirmationInput"
                    placeholder="Enter MOC Partner Name"
                />
                <div v-if="confirmationError" class="text-danger">
                  The entered name does not match the Cashier System Name.
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-secondary" type="button" @click="confirmDelete" :disabled="!isConfirmationValid">Confirm Deletion</button>
              </div>
            </div>
          </div>
        </div>
        <!-- Model END -->

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL,
  RPP_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {sharedConstantsMixin} from "@/mixins/sharedConstantsMixin";
import {Modal} from "bootstrap";

export default {
  name: 'CashierSystemConfigurationsList',
  keywords: ['List Alert Configurations', 'Cashier System Alert Config List', 'All Alert Configurations', 'System Alert Configurations', 'Alert Configurations Overview', 'Alert Settings List', 'Cashier System Alerts Directory'],
  mixins: [sharedConstantsMixin],
  data() {
    return {
      newConfiguration: {
        cashierSystemName: '',
        additionalConfigurations: ''
      },
      isLoading: false,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      cashierSystemConfigurations: {},
      originFromPage: null,
      cashierSystemConfigurationIdToDelete: '',
      cashierSystemConfigurationNameToDelete: '',
      cashierSystemConfigurationCountryToDelete: '',
      isModalVisible: false,
      theModal: null,
      confirmationInput: '',
      confirmationError: false,
      isConfirmationValid: false,
    };
  },
  mounted() {
    this.theModal = new Modal('#cashierSystemConfigurationDeletionModalCenter');
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
      this.loadCashierSystemConfigurations();
    } else {
      this.partnerMetricsFor = userGroup;
    }
    this.originFromPage = this.$route.query.originFromPage;

  },
  methods: {
    validateConfirmationInput() {
      this.isConfirmationValid = this.confirmationInput === this.cashierSystemConfigurationNameToDelete;
      this.confirmationError = !this.isConfirmationValid;
    },
    selectCashierSystemConfigForDeletion(cashierSystemConfigurationId, cashierSystemConfigurationCountryToDelete, cashierSystemConfigurationNameToDelete) {
      this.cashierSystemConfigurationIdToDelete = cashierSystemConfigurationId;
      this.cashierSystemConfigurationCountryToDelete = cashierSystemConfigurationCountryToDelete;
      this.cashierSystemConfigurationNameToDelete = cashierSystemConfigurationNameToDelete;
      this.isModalVisible = true;
    },
    editCashierSystemConfiguration(cashierSystemConfigurationId) {
      this.$router.push({
        path: '/guardian-system-and-alerts/cashier-system-configuration/edit',
        query: {
          cashierSystemConfigurationId: cashierSystemConfigurationId
        }
      });
    },
    viewCashierSystemConfiguration(cashierSystemConfigurationId) {
      this.$router.push({
        path: '/guardian-system-and-alerts/cashier-system-configuration/view',
        query: {
          cashierSystemConfigurationId: cashierSystemConfigurationId
        }
      });
    },
    async loadCashierSystemConfigurations() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations?pageSize=100`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        this.cashierSystemConfigurations =  response.data;
      } catch (error) {
        console.log("error", error);
      }finally {
        this.isLoading = false;
      }
    },
    confirmDelete() {
      if (this.isConfirmationValid && this.cashierSystemConfigurationIdToDelete) {

        axios.delete(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations/${this.cashierSystemConfigurationIdToDelete}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        })
            .then(response => {
              // Handle the successful deletion
              console.log('Cashier System Alert configuration deleted', response);

              window.location.href = '/guardian-system-and-alerts/cashier-system-configuration/list';
            })
            .catch(error => {
              console.error('Error deleting Cashier System Alert configuration:', error);
              window.location.href = '/guardian-system-and-alerts/cashier-system-configuration/list';
            });
      }
    },
  }
};
</script>

<style scoped>
/* Add any specific styles for your component here */
</style>