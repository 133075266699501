<template>
  <div>
    <Breadcrumbs main="Pages" title="Portal Changelog"/>

    <div class="container-fluid">
      <div class="faq-wrap">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-body">
                <p class="mb-0">Welcome to our update center. This page is dedicated to providing you with
                  the
                  latest updates and changes made to our portal. Stay informed about new features,
                  enhancements,
                  and any modifications to ensure you are always up to date with the latest developments.
                </p>
              </div>
            </div>
          </div>

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.13</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: New EV Pricing Exploration Feature
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    New feature allowing users to select a country or choose a cross-country view to explore all available combinations of price components and restrictions for charging stations. This feature is based on the real prices of charging stations as of July 12, 2024.
                  </li>
                  <li class="list-group-item">
                    More details can be found at <a href="/integration-guide/rydpay-apis/guides-ev-charging/ev-pricing#available-price-component-combinations">EV Pricing: Available Price Component Combinations</a>.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-07-12</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.12</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Enhanced Search Functionality</p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    <strong>Search in Navigation Menus</strong>
                    <ul>
                      <li><strong>Find What You Need Fast:</strong> You can now search through our navigation menus with ease. Simply type in a keyword, and relevant menu items will appear, clearly showing their location within the menu hierarchy.</li>
                      <li><strong>Improved Navigation:</strong> Easily locate and navigate to different sections of the portal using the new search feature.</li>
                    </ul>
                  </li>
                  <li class="list-group-item">
                    <strong>Search on Pages</strong>
                    <ul>
                      <li><strong>Keyword-Based Search:</strong> Pages now include predefined keywords that make it easier to find the information you're looking for. Just enter a keyword related to your topic of interest.</li>
                      <li><strong>Content Snippets:</strong> When you search for content, the results will show a snippet of the text with your search term highlighted, allowing you to quickly see the context and relevance.</li>
                    </ul>
                  </li>
                </ul>
                <br/>
                <div>
                  <strong>How to Use:</strong>
                  <p>Click on the search icon located at the top right corner of the portal:</p>
                  <br/>
                  <div class="text-center">
                    <img src="@/assets/images/ryd/search_functionality.png" alt="Search Icon Location" class="img-fluid" style="width: 60%;">
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-07-08</h6>
              </div>
            </div>
          </div>

          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.11</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Updated rydpay API documentation
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Extended APIs for EV Charging, more details can be found at <a href="/integration-guide/rydpay-apis/release-notes-and-updates">Release Notes and Updates</a>.
                  </li>
                  <li class="list-group-item">
                    Updated menu structure for the rydpay API integration guide.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-06-28</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.9</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added "View Activity" for Specific User
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    This feature is accessible to users with the <code>userManagementAdmin</code> role: <a href="/users/list">User List</a>.
                  </li>
                  <li class="list-group-item">
                    Administrators can now view detailed activity information for individual users within their company.
                  </li>
                  <li class="list-group-item">
                    What activity information is available is documented in the <code>Important Notes</code> section of this page:  <a href="/users/list">User List</a>.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-04-11</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.8</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added 'Change Password' Feature for Users
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Users can now securely update their passwords within the portal, enhancing account management and security: <a href="/users/edit">User Edit</a>.
                  </li>
                </ul>
                <br/>

                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Enhanced 'Password-Set' and 'Password-Reset' Email Communications
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Ryd has upgraded the email experience by implementing customized email templates and processes, moving away from the default Google Firebase emails. This update provides a more branded and user-friendly interaction for password setting and resetting.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-26</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.7</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Swagger Document for Staging and Production
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Swagger document added for the staging environment including upcoming changes and for the production environment including the stable version. <a href="/integration-guide/rydpay-apis/api-reference#/production">ryd.pay API Reference.</a>.
                  </li>
                </ul>
                <br/>
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Release Notes & Updates for rydpay APIs
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    API release note and update added due to the
                    Introduction of two new transitive order states: PAYMENT_AUTHORIZATION_STARTED and PRODUCT_ACQUISITION_STARTED.
                    <a href="/integration-guide/rydpay-apis/release-notes-and-updates">Release Notes & Updates</a>.
                  </li>
                </ul>
              </div>

              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-22</h6>
              </div>
            </div>
          </div>
          <!-- end -->


          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.6</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Section Webapp Integration
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Added top-up flow steps to <a href="/integration-guide/webapp-integration/topup-flow-steps">Webapp Integration</a>.
                  </li>
                </ul>
                <br/>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-16</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.5</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Updated User Management Section
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Added a search function to the  <a href="/users/list">user list page</a>, which is only available to users with the userManagementAdmin role.
                  </li>
                </ul>
                <br/>
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: New Dashboard: Order Insights & Analytics
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item"><a href="/dashboard/order-insights-analytics">Order Insights & Analytics Dashboard</a> provides a wealth of information about orders and their states, presented through interactive and informative tabs..
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-02</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.4</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Platform Status Section
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added a <a href="/platform-status/current-status">Platform Status</a> section
                    to check the latest operational status of ryd's platform, infrastructure, network, and partner gas stations. (still in progress.)
                  </li>
                </ul>
                <br/>
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: New Feature: HTTP Request Error Rate Histogram
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item"><a href="/dashboard/dashboard-request-stats">Platform Status</a> visualizes the error trends with our newly added histogram charting the error rates of HTTP requests.
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-17</h6>
              </div>
            </div>
          </div>
          <!-- end -->


          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.3</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Updated troubleshooting section:
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added <a href="/troubleshooting/order-details">troubleshooting section</a>
                    to simplify its usage by employing only the date instead of date/time for the search time range.
                    Additionally, added support for the 'from', 'to', and 'orderUuid' parameters.
                  </li>
                  <li class="list-group-item">e.g.
                  <pre><code>https://partner-portal.rydcloud.de/troubleshooting/order-details?orderUuid=AddTheOrderUuIdCreatedByRyd&from=2023-12-07&to=2023-12-15</code></pre>
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-14</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.2</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added dashboard section
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added a <a href="/dashboard/general-dashboard">dashboard section</a> so
                    you can find some performance metrics for your account.
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-12</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.1</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added troubleshooting section:
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added a <a href="/troubleshooting/order-details">troubleshooting
                    section</a> so you can find the most important logs for a given order uuid.
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-08</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.0</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Initial release of ryd partner
                  portal:
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added use management: add a new user, edit an existing
                    user,
                    view a
                    list of users if the logged in user has the userManagementAdmin role.
                  </li>
                  <li class="list-group-item">Added documentation for ryd's topup APIs</li>
                  <li class="list-group-item">Added documentation for the integration approach:
                    deeplinking
                    registration
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-01</h6>
              </div>
            </div>
          </div>
          <!-- end -->

        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "PartnerPortalChangelog",
  keywords: [
    'Changelog', 'Portal Updates', 'Release Notes', 'Version History', 'Feature Updates',
    'Bug Fixes', 'Enhancements', 'Improvements', 'Change Log', 'Update History', 'Patch Notes',
    'System Updates', 'Portal Changes', 'Update Summary', 'New Features', 'Modification Log',
    'Release History', 'Version Updates', 'API Changes', 'Documentation Updates', 'Change History',
    'Platform Updates', 'Site Updates', 'Release Documentation', 'Update Notes', 'Version Notes',
    'Feature Additions', 'Feature Enhancements', 'Issue Resolutions', 'Error Corrections',
    'Problem Fixes', 'Feature Enhancements', 'System Enhancements', 'Modification Record',
    'Update Record', 'Change Record', 'Patch Updates', 'Patch History', 'Patch Records',
    'Platform Changes', 'System Modifications', 'System Enhancements', 'Portal Modifications',
    'Portal Feature Changes', 'Release Summary', 'Change Summary', 'Update Overview', 'Feature Releases',
    'Feature Rollout', 'Feature Deployment', 'Change Documentation', 'Modification Record',
    'Update Documentation', 'Update Timeline', 'Release Timeline', 'Version Release History',
    'Version Changes', 'Version Release Notes', 'Version Modifications', 'API Updates',
    'API Modifications', 'API Enhancements', 'Docs Changes', 'Documentation Modifications', 'Docs Enhancements'
  ]
}
</script>