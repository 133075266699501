
export function scrollToCard(cardId) {
    history.pushState(null, null, `#${cardId}`);
    const element = document.getElementById(cardId);
    const offset = 70; // Adjust this to the height of your fixed header

    if (element) {
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
    }
}


export function scrollToRootHash(offset = 75) {
    const hash = this.$route.hash;

    if (hash) {
        const element = document.querySelector(hash);

        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
    }
}

export function scrollToWithOffet(offset) {
    const hash = this.$route.hash;

    if (hash) {
        const element = document.querySelector(hash);

        if (element) {
            const elementPosition = element.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
    }
}

export function  scrollToTop() {
    history.pushState(null, null, window.location.pathname);
    window.scrollTo({ top: 0, behavior: 'smooth' });
}