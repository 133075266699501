<template>
  <div class="search-links tab-pane fade" :class="{'show active': showtab == 'evCharges' }"
       id="all-links"
       role="tabpanel" aria-labelledby="evCharges">




    <div class="col-sm-12" v-if="!isLoading">

      <!-- CARD 1...  START... -->
      <div class="card">
        <div class="card-header b-l-primary font-primary">
          <h5>EV Session at Plugsurfing</h5>
        </div>
        <div class="card-body">
          <div class="form theme-form">
            <div class="mb-3">
              <label class="fw-bold f-14">An EV session was created at Plugsurfing?</label>
              <div v-if="isLoadingEvSessions" class="loading-indicator">
                <h6 class="sub-title mb-0 text-center">Searching ... EV Session Id</h6>
                <div class="loader-box">
                  <div class="loader-4"></div>
                </div>
              </div>
              <div v-if="!isLoadingEvSessions && apiResponseEvSessions && apiResponseEvSessions.totalNumberOfResults === 0">
                <p class="font-primary fw-bold">No ev session id has been created at pluginsurfing.</p>
              </div>
              <div v-if="!isLoadingEvSessions && apiResponseEvSessions && apiResponseEvSessions.totalNumberOfResults > 0">
                <p class="font-primary fw-bold">YES</p>
              </div>
            </div>
            <div class="mb-3">
              <label class="fw-bold f-14">Created Session Id at Plugsurfing</label>
              <div v-if="!isLoadingEvSessions && apiResponseEvSessions && apiResponseEvSessions.totalNumberOfResults > 0">
                <p class="font-primary fw-bold">{{apiResponseEvSessions.createdSessionId}}</p>
              </div>
              <div v-if="!isLoadingEvSessions && apiResponseEvSessions && apiResponseEvSessions.totalNumberOfResults === 0">
                <p class="font-primary fw-bold">not available.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CARD 1...  END... -->

      <!-- CARD 2...  START... -->
      <div class="card">
        <div class="card-header b-l-success font-success">
          <h5>Received Events from Plugsurfing</h5>
        </div>
        <div class="card-body">
          <div class="form theme-form">
            <div class="mb-3">
              <label class="fw-bold f-14">Has Ryd received any events from Plugsurfing?</label>
              <div v-if="isLoadingEvSessionsEvents || isLoadingEvSessionsEvents" class="loading-indicator">
                <h6 class="sub-title mb-0 text-center">Searching ... Session Events from Plugsurfing</h6>
                <div class="loader-box">
                  <div class="loader-11"></div>
                </div>
              </div>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsEvents && apiResponseEvSessionsEvents && apiResponseEvSessionsEvents.totalNumberOfResults === 0">
                <p class="font-success fw-bold">ryd has NOT received any session event from Plugsurfing.</p>
                <p class="fw-bold">NOTE: In the search time range</p>
                <p>Search Start Date Time: {{apiResponseEvSessionsEvents.from}}</p>
                <p>Search End Date Time: {{apiResponseEvSessionsEvents.to}}</p>
              </div>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsEvents && apiResponseEvSessionsEvents && apiResponseEvSessionsEvents.totalNumberOfResults > 0">
                <p class="font-success fw-bold">YES</p>
                <p class="fw-bold">NOTE: In the search time range</p>
                <p>Search Start Date Time: {{apiResponseEvSessionsEvents.from}}</p>
                <p>Search End Date Time: {{apiResponseEvSessionsEvents.to}}</p>
              </div>

              <br/>
              <label class="fw-bold  f-14">Received events</label>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsEvents && apiResponseEvSessionsEvents && apiResponseEvSessionsEvents.totalNumberOfResults > 0">
                <div class="order-history table-responsive">
                  <table class="table table-bordernone table-striped display" id="basic-4">
                    <thead class="bg-success text-white">
                    <tr>
                      <th class="bg-success" scope="col">Timestamp</th>
                      <th class="bg-success" scope="col">Event Name</th>
                      <th class="bg-success" scope="col">User ID at Plugsurfing</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="eventItem in apiResponseEvSessionsEvents.extractedData" :key="eventItem.timestamp">
                      <td>{{ eventItem.timestamp }}</td>
                      <td>{{ eventItem.event }}</td>
                      <td>{{ eventItem.userId }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsEvents && apiResponseEvSessionsEvents && apiResponseEvSessionsEvents.totalNumberOfResults === 0">
                <p class="font-success fw-bold">not available.</p>
              </div>
              </div>
          </div>
        </div>
      </div>
      <!-- CARD 2...  END... -->


      <!-- CARD 3...  START... -->
      <div class="card">
        <div class="card-header b-l-primary font-primary">
          <h5>CDR from Plugsurfing</h5>
        </div>
        <div class="card-body">
          <div class="form theme-form">
            <div class="mb-3">
              <label class="fw-bold f-14">Has Ryd received a CDR Record from Plugsurfing?</label>
              <div v-if="isLoadingEvSessionsEvents || isLoadingEvSessionsCdr" class="loading-indicator">
                <h6 class="sub-title mb-0 text-center">Searching ... CDR Record from Plugsurfing</h6>
                <div class="loader-box">
                  <div class="loader-11"></div>
                </div>
              </div>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsCdr && apiResponseEvSessionsCdr && apiResponseEvSessionsCdr.totalNumberOfResults === 0">
                <p class="font-primary  fw-bold">ryd has NOT received any CDR record from Plugsurfing.</p>
                <p class="fw-bold">NOTE: In the search time range</p>
                <p>Search Start Date Time: {{apiResponseEvSessionsCdr.from}}</p>
                <p>Search End Date Time: {{apiResponseEvSessionsCdr.to}}</p>
              </div>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsCdr && apiResponseEvSessionsCdr && apiResponseEvSessionsCdr.totalNumberOfResults > 0">
                <p class="font-primary fw-bold">YES</p>
                <p class="fw-bold">NOTE: In the search time range</p>
                <p>Search Start Date Time: {{apiResponseEvSessionsEvents.from}}</p>
                <p>Search End Date Time: {{apiResponseEvSessionsEvents.to}}</p>
              </div>
              <br/>
            </div>
            <div class="mb-3">
              <label class="fw-bold f-14">Received CDR Recoard</label>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsCdr && apiResponseEvSessionsCdr && apiResponseEvSessionsCdr.totalNumberOfResults > 0">

                    <button class="btn font-primary btn-pill btn-lg" type="button" data-bs-toggle="modal"
                            data-bs-target="#foundCdrRecordModalCenter"> Click to see the found CDR Record
                    </button>
                <br/>
                <p class="fw-bold">Ryd has received the CDR record at:</p>
                <p class="fw-bold font-primary">{{apiResponseEvSessionsCdr.extractedData[0].timestamp}}</p>
              </div>
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsCdr && apiResponseEvSessionsCdr && apiResponseEvSessionsCdr.totalNumberOfResults === 0">
                <p class="font-primary fw-bold">not available.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CARD 3...  END... -->

      <!-- foundCdrRecordModal -->
      <div class="modal fade" id="foundCdrRecordModalCenter" tabindex="-1" role="dialog" ref="foundCdrRecordModal"
           aria-labelledby="foundCdrRecordModalCenter">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Found CDR Record</h5>
            </div>
            <div class="modal-body">
              <div v-if="!isLoadingEvSessionsEvents && !isLoadingEvSessionsCdr && apiResponseEvSessionsCdr && apiResponseEvSessionsCdr.totalNumberOfResults > 0">
                <pre ref="cdrContent">{{ formatJson(apiResponseEvSessionsCdr.extractedData[0].cdr) }}</pre>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" type="button" @click="copyToClipboard">Copy</button>
              <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <!-- CARD 4...  START... -->
      <div class="card">
        <div class="card-header b-l-primary font-success">
          <h5>Charging Station Information</h5>
        </div>
        <div class="card-body">
          <div class="form theme-form">
            <div class="mb-3">
              <label class="fw-bold f-14">Prices displayed to the user during the order process?</label>
              <p class="font-success">At the moment we don't have the data yet.</p>
            </div>

          </div>
        </div>
      </div>
      <!-- CARD 4...  END... -->





    </div>

  </div>

</template>
<script>
export default {
  name: 'TabEvCharges',
  data() {
  },
  props: {
    apiResponseEvSessions: {},
    apiResponseEvSessionsEvents: {},
    apiResponseEvSessionsCdr: {},
    showtab: {},
    isLoadingEvSessions: false,
    isLoadingEvSessionsEvents: false,
    isLoadingEvSessionsCdr: false,
    loggedInUserIsRyd: {}
  },
  methods: {
    formatJson(jsonString) {
      try {
        const jsonObj = JSON.parse(jsonString);
        return JSON.stringify(jsonObj, null, 2);
      } catch (e) {
        console.error('Invalid JSON string:', e);
        return jsonString;
      }
    },
    copyToClipboard() {
      const cdrContent = this.$refs.cdrContent.innerText;
      navigator.clipboard.writeText(cdrContent).then(() => {
        alert('CDR data copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    }
  },
}
</script>


<style scoped>
pre {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>