<template>
  <Breadcrumbs title="User Profile" main="Users"/>


  <div class="container-fluid ">
    <div class="user-profile">
      <div class="row">
        <userDetails/>
      </div>
    </div>
  </div>
</template>

<script>
import userDetails from "./userDetails.vue";

export default {
  name: 'userProfile',
  keywords: ['User View', 'User Profile', 'Profile View', 'User Details', 'Account Information', 'View Profile', 'User Information', 'Profile Page'],
  components: {
    userDetails,
  },

}
</script>
