import axios from 'axios';
import { getUserAddDataFromLocalStorage } from '@/utils/userUtils';
import loadJson from '@/utils/importJsonFile';
import {RPP_API_SERVER_URL} from "@/constants/constants";

export default {
    data() {
        return {
            isLoadingPartnerConfigs: false,
            tt4ApiTokensData: {},
            userGroup: '',
            productName: ''
        };
    },
    methods: {
        async fetchPartnerConfigs() {
            try {
                const { uid, userGroup } = getUserAddDataFromLocalStorage();
                this.userGroup = userGroup;
                const response = await axios.get(`${RPP_API_SERVER_URL}/partner-configs?singleUserId=${uid}`, {
                    headers: {
                        'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY
                    }
                });
                this.tt4ApiTokensData = response.data;
            } catch (error) {
                console.error('API fetch error:', error);
                this.tt4ApiTokensData.errorMessage = "NOT available.";
            } finally {
                this.isLoadingPartnerConfigs = false;
            }
        },
        replaceVariablesInJson(json) {
            json.variable.forEach(variable => {
                if (variable.key === 'xTxnAuthToken') {
                    if (this.tt4ApiTokensData && this.tt4ApiTokensData.tt4ApiTokenStaging && this.tt4ApiTokensData.tt4ApiTokenStaging[0]) {
                        variable.value = this.tt4ApiTokensData.tt4ApiTokenStaging[0].token || variable.value;
                    }
                } else if (variable.key === 'xTxnInitiatedByPartner') {
                    variable.value = this.userGroup || variable.value;
                }
            });

            // Set the info.name
            if (json.info) {
                json.info.name = `rydpay-apis-${this.productName}-for-partner-${this.userGroup}`;
            }

            return json;
        },
        async downloadJson(filePath, productName) {
            try {
                this.isLoadingPartnerConfigs = true;
                this.productName = productName;

                // Load your JSON file using the utility function
                const data = await loadJson(filePath);
                let jsonFile = data.default || data;

                // Replace values in the JSON file
                jsonFile = this.replaceVariablesInJson(jsonFile);

                // Download the modified JSON file
                const jsonStr = JSON.stringify(jsonFile, null, 2); // Stringify the JSON data
                const blob = new Blob([jsonStr], { type: 'application/json' });
                const url = URL.createObjectURL(blob);

                // Create a link and trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = `rydpay-api-${productName}-for-partner-${this.userGroup}.postman_collection.json`;

                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(url);

                setTimeout(() => {
                    this.isLoadingPartnerConfigs = false;
                }, 500);

                this.$toast.show('The Postman Collection JSON file has been successfully downloaded!', { theme: 'outline', position: 'bottom', type: 'success', duration: 5000 });

            } catch (error) {
                console.error('JSON download error:', error);
                this.isLoadingPartnerConfigs = false;
            }
        }
    }
};
