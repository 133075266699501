<template>
  <Breadcrumbs main="rydpay APIs" title="Testing & Simulation"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- card start-->
        <div class="card">
          <div class="card-header bg-primary">
            <h5>Overview</h5>
          </div>
          <div class="card-body">

            <ul class="list-group">
              <li class="list-group-item">
                <b>Testing & Simulation</b> is designed for ryd partners to effectively test and simulate various
                scenarios for API integration and service provision, including fuelling, EV charging, and car wash
                experiences.
              </li>
              <li class="list-group-item">
                The goal is to ensure seamless integration and functionality of Ryd's platform with partner systems,
                thereby providing robust and reliable services to end-users.
              </li>
              <li class="list-group-item">
                To provide a clear overview, there are <b>separate documentation sections for each service</b>:
                refuelling, EV charging and car wash. Within these sections below you will find links to the simulated
                stations and test scenarios specific to each service.
              </li>
            </ul>
            <br/>


          </div>
        </div>
        <!-- card end-->


        <!-- card start-->
        <div class="card">
          <div class="card-header bg-success">
            <h5>Test Scenarios</h5>
          </div>
          <div class="card-body">


            <ul class="list-group">
              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Overview</h5>
                <span>
                 This subsection showcases how to validate and test different happy paths and error cases. It provides detailed scenarios to guide partners through the testing of their integrations with Ryd’s platform.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Postman Collection</h5>
                <span>
                A Postman collection is provided to demonstrate all test cases, making it easier for partners to execute and verify each scenario.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Details</h5>
                <span>
                Each test scenario includes step-by-step instructions and expected outcomes, helping partners identify and resolve potential issues in their integration processes.

                </span>
              </li>

              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Links</h5>
                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/integration-guide/rydpay-apis/testing-and-simulation/fuelling-test-scenarios"
                       class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Fuelling Test Scenarios</a>
                  </li>
                  <li class="list-group-item">
                    <a href="/integration-guide/rydpay-apis/testing-and-simulation/car-wash-test-scenarios"
                       class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Car Wash Test Scenarios</a>
                  </li>
                  <li class="list-group-item">
                    <a href="/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-test-scenarios"
                       class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> EV Charging Test Scenarios</a>
                  </li>
                </ul>
              </li>

            </ul>
            <br/>


          </div>
        </div>
        <!-- card end-->

        <!-- card start-->
        <div class="card">
          <div class="card-header bg-primary">
            <h5>Simulated Stations</h5>
          </div>
          <div class="card-body">

            <ul class="list-group">
              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Overview</h5>
                <span>
                 This subsection contains information about simulated stations for fueling, EV charging, and car wash
        services. These simulations are designed to replicate various real-world conditions, allowing partners to test
        their integrations comprehensively.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Features</h5>
                <span>
Diverse Configurations: Simulated stations can mimic different currencies, countries, fuel products, tax rates,
        and other variables. This ensures partners can test their integrations under a wide range of conditions.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Purpose</h5>
                <span>
The focus here is on the environment setup, not the individual transaction flows. Simulated stations
        help ensure that the technical integration can handle various configurations and operational scenarios.
                </span>
              </li>

              <li class="list-group-item p-t-15">
                <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Links</h5>

                <ul class="list-group">
                  <li class="list-group-item">
                    <a href="/integration-guide/rydpay-apis/testing-and-simulation/fuelling-simulated-stations" class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Simulated
                      Fuelling Stations</a>
                  </li>
                  <li class="list-group-item">
                    <a href="/integration-guide/rydpay-apis/testing-and-simulation/car-wash-simulated-stations" class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Simulated
                      EV Charging Stations</a>
                  </li>
                  <li class="list-group-item">
                    <a href="/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-simulated-stations" class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Simulated
                      Car Wash Stations</a>
                  </li>
                </ul>
              </li>


            </ul>
            <br/>

          </div>
        </div>
        <!-- card end-->


      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TestingAndSimulation",
  keywords: ['Postman', 'Test Scenarios', 'Simulated Stations'],
}
</script>