import {menuItems} from '../../data/menu.js';
import BonusUI from '../../data/bonusui';

const state = {
  data: menuItems.data,
  megamenu: BonusUI.data,
  searchData: [],
  togglesidebar: true,
  activeoverlay : false,
  searchOpen : false,
  customizer: '',
  hideRightArrowRTL: false,
  hideLeftArrowRTL: true,
  hideRightArrow: true,
  hideLeftArrow: true,
  width: 0,
  height: 0,
  margin: 0,
  menuWidth: 0,
};


const getters = {

};


const mutations = {
  opensidebar: (state) => {
    state.togglesidebar = !state.togglesidebar;
    if (window.innerWidth < 991) {
      state.activeoverlay = true;
    } else {
      state.activeoverlay = false;
    }
  },
  resizetoggle: (state) => {
    if (window.innerWidth < 1007) {
      state.togglesidebar = false;
    } else {
      state.togglesidebar = true;
    }
  },
  setSearchData: (state, items) => {
    state.searchData = items;
  },
  searchTerm: (state, term) => {
    let items = [];
    var searchval = term.toLowerCase();
    state.data.filter(menuItems => {
      
      if (menuItems.title) {
        if (menuItems.title.toLowerCase().includes(searchval) && menuItems.type === 'link') {
          items.push(menuItems);
        }
        if (!menuItems.children) return false;
        menuItems.children.filter(subItems => {
          if (subItems.title.toLowerCase().includes(searchval) && subItems.type === 'link') {
            subItems.icon = menuItems.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter(suSubItems => {
            if (suSubItems.title.toLowerCase().includes(searchval)) {
              suSubItems.icon = menuItems.icon;
              items.push(suSubItems);
            }
          });
        });
        state.searchData = items;
      }
    });
  },
  setBonusNavActive: (state, item) => {
    if (!item.active) {
      state.megamenu.forEach(a => {
        if (state.megamenu.includes(item))
          a.active = false;
        if (!a.children) return false;
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  },
  setNavActive: (state, item) => {
    function resetActiveStates(items) {
      items.forEach(menuItem => {
        menuItem.active = false;
        if (menuItem.children) {
          resetActiveStates(menuItem.children);
        }
      });
    }

    function deactivateSiblings(items, targetItem) {
      items.forEach(menuItem => {
        if (menuItem !== targetItem) {
          menuItem.active = false;
          if (menuItem.children) {
            resetActiveStates(menuItem.children);
          }
        }
      });
    }

    function toggleItem(items, targetItem) {
      items.forEach(menuItem => {
        if (menuItem === targetItem) {
          menuItem.active = !menuItem.active;
          if (menuItem.children && menuItem.active) {
            // If the item is now active and has children, reset all its children's active state
            resetActiveStates(menuItem.children);
          }
        } else {
          menuItem.active = false;
        }
        if (menuItem.children) {
          toggleItem(menuItem.children, targetItem);
        }
      });
    }

    function activateItem(items, targetItem) {
      items.forEach(menuItem => {
        if (menuItem === targetItem) {
          deactivateSiblings(items, targetItem);
          menuItem.active = !menuItem.active;
          if (menuItem.children && menuItem.active) {
            resetActiveStates(menuItem.children);
          }
        }
        if (menuItem.children) {
          activateItem(menuItem.children, targetItem);
        }
      });
    }

    activateItem(state.data, item);
  },

  setActiveRoute: (state, item) => {
    state.data.filter(menuItem => {
      if (menuItem !== item)
        menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item)){
        item.active = true;
        menuItem.active = true;
      }
      if (menuItem.children) {
        menuItem.children.filter(submenuItems => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            item.active = true;
            menuItem.active = true;
            submenuItems.active = true;
          }
        });
      }
    });
  },

  SET_SEARCH_OPEN(state, isOpen) {
    state.searchOpen = isOpen;
  },

};

const actions = {
  opensidebar: (context, term) => {
    context.commit('opensidebar', term);
  },
  resizetoggle: (context, term) => {
    context.commit('resizetoggle', term);
  },
  setBonusNavActive: (context, term) => {
    context.commit('setBonusNavActive', term);
  },
  searchTerm: (context, term) => {
    context.commit('searchTerm', term);
  },
  setNavActive: (context, item) => {
    context.commit('setNavActive', item);
  },
  setActiveRoute: (context, item) => {
    context.commit('setActiveRoute', item);
  },
  setSearchData: (context, items) => {
    context.commit('setSearchData', items);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};