<template>
  <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" ref="modalRef"
       :aria-labelledby="modalId + 'Label'">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
        </div>
        <div class="modal-body">
          <div>
            <pre ref="apiResponse">{{ jsonData }}</pre>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" @click="copyToClipboard">Copy</button>
          <button class="btn btn-primary" type="button" @click="downloadJson">Download</button>
          <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      required: true
    },
    jsonData: {
      type: String,
      required: true
    }
  },
  methods: {
    copyToClipboard() {
      const el = this.$refs.apiResponse;
      const range = document.createRange();
      range.selectNodeContents(el);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
      selection.removeAllRanges();
      this.$toast.show('JSON copied to clipboard!', { theme: 'outline',position: 'bottom-left', type: 'success', duration: 5000 });
    },
    downloadJson() {
      const jsonBlob = new Blob([this.jsonData], { type: 'application/json' });
      const url = URL.createObjectURL(jsonBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'response.json';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  }
};
</script>

<style>
/* Add any necessary styling here */
</style>
