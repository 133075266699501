import { EUROPEAN_COUNTRIES, SUPPORTED_EMAIL_LANGUAGES } from '@/constants/constants';

export const sharedConstantsMixin = {
    methods: {
        getCountryName(countryCode) {
            const country = EUROPEAN_COUNTRIES.find(c => c.value === countryCode);
            return country ? country.text : countryCode;
        },
        getLanguageName(languageCode) {
            const language = SUPPORTED_EMAIL_LANGUAGES.find(c => c.value === languageCode);
            return language ? language.text : languageCode;
        }
    }
};
