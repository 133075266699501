<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Guardian Check Map View"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">General Information</div>
            <div v-show="guardianCheckId">
              The following charts are based on the checks performed at the predefined time point:
              <strong>{{ formattedGuardianCheckId }}</strong> (UTC Time).
              <br/>
              Click <a target="_blank"
                       :href="`/guardian-system-and-alerts/guardian-checks?guardianCheckId=${guardianCheckId}`">here</a>
              to find out the details of the offline stations.
              <br/>
            </div>
          </div>
        </div>
        <!-- CARD 1 END -->

        <div v-if="!isLoadingPoiMapData">
          <div class="card">
            <div class="card-body">
              <h6 class="m-b-20">Total Offline Stations: <span class="font-primary">{{ totalOfflineStations }}</span></h6>
              <GMapMap
                  :center="{ lat: 52.5200, lng: 13.4050 }"
                  :zoom="4"
                  style="width: 100%; height: 600px"
              >
                <GMapMarker
                    v-for="station in offlineStations"
                    :key="station.poi_id"
                    :position="{ lat: station.address_latitude, lng: station.address_longitude }"
                    @mouseover="showInfoWindow(station)"
                    @mouseout="hideInfoWindow"
                />
                <GMapInfoWindow
                    :options="infoWindowOptions"
                    :position="infoWindowPosition"
                    :opened="infoWindowOpened"
                >
                  <div v-html="infoWindowContent"></div>
                </GMapInfoWindow>
              </GMapMap>
            </div>
          </div>
        </div>

        <!-- Loading Indicator -->
        <div v-if="isLoadingPoiMapData" class="card">
          <div class="card-body">
            <div class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Loading ... Offline stations and drawing on a map</h6>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { GMapMap, GMapMarker, GMapInfoWindow } from '@fawmi/vue-google-maps';
import axios from 'axios';
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import { getUserAddDataFromLocalStorage } from "@/utils/userUtils";
import { useRoute } from 'vue-router';

export default {
  name: 'GuardianCheckMapView',
  components: {
    GMapMap,
    GMapMarker,
    GMapInfoWindow,
  },
  data() {
    const route = useRoute();
    return {
      isLoadingPoiMapData: false,
      offlineStations: [],
      infoWindowOpened: false,
      infoWindowPosition: null,
      infoWindowContent: '',
      infoWindowOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      guardianCheckId: route.query.guardianCheckId || '',
      loggedInUserId: getUserAddDataFromLocalStorage().uid,
    };
  },
  computed: {
    formattedGuardianCheckId() {
      if (this.guardianCheckId) {
        const raw = this.guardianCheckId;
        const formatted = `${raw.substring(0, 4)}-${raw.substring(4, 6)}-${raw.substring(6, 8)} ${raw.substring(8, 10)}:${raw.substring(10, 12)}:${raw.substring(12, 14)}.${raw.substring(14)}`;
        return formatted;
      }
      return '';
    },
    totalOfflineStations() {
      return this.offlineStations.length;
    },
  },
  methods: {
    async fetchMaxGuardianCheckId() {
      this.isLoadingPoiMapData = true;
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/guardian-checks/aggregations/max-id`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });
        return response.data.maxId;
      } catch (error) {
        console.error('Failed to fetch max guardian check id:', error);
        throw error;
      }finally {
        this.isLoadingPoiMapData = false;
      }
    },
    async fetchData() {
      this.isLoadingPoiMapData = true;
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/guardian-checks/${this.guardianCheckId}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });
        this.offlineStations = response.data;
        this.isLoadingPoiMapData = false;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }finally {
        this.isLoadingPoiMapData = false;
      }
    },
    showInfoWindow(station) {
      this.infoWindowPosition = {
        lat: parseFloat(station.address_latitude),
        lng: parseFloat(station.address_longitude),
      };
      this.infoWindowContent = `
            <div>
              <p><strong style="color: #000000;">Brand:</strong> <span style="color: #7366ff;">${station.brand}</span></p>
              <p><strong style="color: #000000;">Partner Name:</strong> <span style="color: #7366ff;">${station.partner_name}</span></p>
              <p><strong style="color: #000000;">Cashier System:</strong> <span style="color: #7366ff;">${station.cashier_system}</span></p>
              <p><strong style="color: #000000;">Street:</strong> <span style="color: #7366ff;">${station.address_street}</span></p>
              <p><strong style="color: #000000;">House Nr:</strong> <span style="color: #7366ff;">${station.address_house_number}</span></p>
              <p><strong style="color: #000000;">Zip:</strong> <span style="color: #7366ff;">${station.address_zip}</span></p>
              <p><strong style="color: #000000;">City:</strong> <span style="color: #7366ff;">${station.address_city}</span></p>
              <p><strong style="color: #000000;">Country:</strong> <span style="color: #7366ff;">${station.country_code}</span></p>
            </div>
      `;
      this.infoWindowOpened = true;
    },
    hideInfoWindow() {
      this.infoWindowOpened = false;
    },
    async initializeData() {
      if (!this.guardianCheckId) {
        try {
          this.guardianCheckId = await this.fetchMaxGuardianCheckId();
        } catch (error) {
          console.error('Failed to fetch max guardian check id:', error);
        }
      }
      await this.fetchData();
    }
  },
  async mounted() {
    await this.initializeData();
  },
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
