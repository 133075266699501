<template>
  <Breadcrumbs main="rydpay APIs" title="API Fundamentals"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <p v-html="highlightedContent" ref="content"></p>

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <p>
              On this page, you will find essential information about the API fundamentals and how the Rydpay API handles them. The topics covered include:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('versioning')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Versioning</a>
                Learn about the versioning approach used in Rydpay APIs.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('authentication')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Authentication
                </a>
                Understand the authentication mechanism, including the use of API keys.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('error-handling')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Error handling
                </a>
                Discover how Rydpay uses HTTP response codes and specific error codes to indicate success or failure.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('api-headers')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> API Headers
                </a>
                Get detailed information about the necessary and optional headers used in API requests.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('idempotency')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Idempotency
                </a>
                Learn about idempotent API methods and how Rydpay ensures reliable integration.
              </li>

            </ul>

          </div>
        </div>


        <div class="card" id="versioning">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Versioning</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Versioning Approach</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Although the rydpay APIs currently support version 4, indicated by <code>/v4/</code> in the API
                endpoints, they also offer detailed version management.
                This is achieved through an <b>optional</b> HTTP request header field, <code>x-txn-api-version</code>,
                which allows you to specify a more granular version number, such as 4.1.1.
              </li>
              <li class="list-group-item">
                If the <code>x-txn-api-version</code> header is not specified, the latest version of the API will be
                used by default, which is also recommended by ryd.
              </li>

            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Note</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd is committed to maintaining backward compatibility. No breaking changes will be introduced without
                updating the API version number and informing all Ryd partners in advance.
              </li>
            </ul>
            <br/>
          </div>
        </div>

        <div class="card" id="authentication">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Authentication</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>General Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                For all API requests, a static API key is required and must be included in the HTTP header field <code>x-txn-auth-token</code>.
                This key authenticates your integration with the Ryd platform.
              </li>
              <li class="list-group-item">
                The API key can be renewed at predefined intervals upon request.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>API Keys</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd provides different API keys for different environments: Staging/Test and Production.
              </li>
              <li class="list-group-item">
                Once Ryd has created API keys for your staging and/or production systems, you can view the API token on
                the <a href="/pages/account-settings">Account Settings</a> page.
              </li>
            </ul>
            <br/>


          </div>
        </div>

        <div class="card" id="error-handling">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Error handling</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Error Handling: HTTP Response Codes</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd uses conventional <b>HTTP response codes</b> to indicate the success or failure of an API request.
              </li>
              <li class="list-group-item">
                The specific HTTP response status codes available for each API are documented in the <a
                  href="/integration-guide/rydpay-apis/api-reference#/production">API reference/documentation</a>.
              </li>
              <li class="list-group-item">
                Here’s a summary of the code ranges and their meanings:
              </li>
            </ul>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped  border border-dark">
                <thead class="bg-primary text-white">
                <tr>
                  <th class="font-primary f-w-800 border border-dark">Range</th>
                  <th class="font-primary f-w-800 border border-dark">Meaning</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark">2xx Range</td>
                  <td class="border border-dark">Indicates success</td>
                </tr>

                <tr>
                  <td class="border border-dark">4xx Range</td>
                  <td class="border border-dark">Indicates an error due to the information provided (e.g., a required parameter was omitted, a
                    charge failed, etc.).
                  </td>
                </tr>

                <tr>
                  <td class="border border-dark">5xx Range</td>
                  <td class="border border-dark">Indicates an error with Ryd’s servers.</td>
                </tr>
                </tbody>
              </table>
            </div>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>HTTP Status Code Summary</h5>
            <ul class="list-group">

              <div class="table-responsive product-table">
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark">Code Number</th>
                    <th class="font-primary f-w-800 border border-dark">Meaning</th>
                    <th class="font-primary f-w-800 border border-dark">Comment</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="border border-dark">200</td>
                    <td class="border border-dark">OK</td>
                    <td class="border border-dark">Everything worked as expected.</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">400</td>
                    <td class="border border-dark">Bad Request</td>
                    <td class="border border-dark">The request was unacceptable, often due to missing a required parameter.</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">401</td>
                    <td class="border border-dark">Unauthorized</td>
                    <td class="border border-dark">No valid API key provided.</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">402</td>
                    <td class="border border-dark">Request Failed</td>
                    <td class="border border-dark">The parameters were valid but the request failed.</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">403</td>
                    <td class="border border-dark">Forbidden</td>
                    <td class="border border-dark">The API key doesn’t have permissions to perform the request.</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">404</td>
                    <td class="border border-dark">Not Found</td>
                    <td class="border border-dark">The requested resource doesn’t exist.</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">409</td>
                    <td class="border border-dark">Conflict</td>
                    <td class="border border-dark">The request conflicts with another request (perhaps due to using the same idempotent key).</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">429</td>
                    <td class="border border-dark">Too Many Requests</td>
                    <td class="border border-dark">Too many requests hit the API too quickly. We recommend an exponential backoff of your
                      requests.
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark">500</td>
                    <td class="border border-dark">Server Error</td>
                    <td class="border border-dark">Something went wrong on Ryd’s end. (These are rare.)</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">502</td>
                    <td class="border border-dark">Bad Gateway</td>
                    <td class="border border-dark">Something went wrong on Ryd’s end. (These are rare.)</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">503</td>
                    <td class="border border-dark">Service Unavailable</td>
                    <td class="border border-dark">Something went wrong on Ryd’s end. (These are rare.)</td>
                  </tr>
                  <tr>
                    <td class="border border-dark">504</td>
                    <td class="border border-dark">Gateway Timeout</td>
                    <td class="border border-dark">Something went wrong on Ryd’s end. (These are rare.)</td>
                  </tr>
                  </tbody>
                </table>
              </div>

            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Error Handling: Ryd Specific Error Codes
            </h5>
            <ul class="list-group">
              <li class="list-group-item">
                In addition to standard HTTP response status codes, Ryd has introduced business and process-related
                error codes to indicate the root cause of errors and provide more detailed information. For example,
                these codes can explain specific errors in the payment process or issues in communication with the
                cashier system of a petrol station.
              </li>
              <li class="list-group-item">
                Ryd includes an error object in the API responses to convey this detailed information. Here is an
                example:
              </li>
              <li class="list-group-item">
                      <pre><code class="font-primary">
                  {
                    "error": {
                      "errorCode": 4100,
                      "message": "The settled transaction amount is less than the pre-authorized order amount."
                    }
                  }
                      </code></pre>
              </li>
            </ul>
            <br/>
          </div>
        </div>

        <div class="card" id="api-headers">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>API Headers</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>General Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd APIs use several HTTP headers to manage and trace API calls. Below is a list of the key headers:
              </li>
              <li class="list-group-item">
                The API key can be renewed at predefined intervals upon request.
              </li>
            </ul>
            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-success f-w-800 border border-dark">Header Name</th>
                  <th class="font-success f-w-800 border border-dark">Mandatory</th>
                  <th class="font-success f-w-800 border border-dark">Meaning</th>
                  <th class="font-success f-w-800 border border-dark">Example</th>
                </tr>
                </thead>
                <tbody class="border border-dark">
                <tr>
                  <td class="border border-dark">x-txn-auth-token</td>
                  <td class="border border-dark">Yes</td>
                  <td class="border border-dark">The API token generated for you by ryd, which can be viewed on the
                    the <a href="/pages/account-settings">account settings</a> page.
                  </td>
                  <td class="border border-dark">fbf1bac0-dfee-4a85-bfc5-c15d76ca6d25</td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-initiated-by-partner</td>
                  <td class="border border-dark">Yes</td>
                  <td class="border border-dark">your identifier at ryd and generated by ryd, which is important for tracking and rate limit.</td>
                  <td class="border border-dark">your identifier at ryd is: <b>{{loggedInUserGroup}}</b></td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-locale</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">Locale for localization, if needed</td>
                  <td class="border border-dark">de-DE</td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-api-version</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">Specify the API version on the ryd side that should be used. NOT specified means that the latest version will always be used.</td>
                  <td class="border border-dark">4.4.11</td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-app-version</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">App version</td>
                  <td class="border border-dark">4.11.1</td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-correlationid</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">Correlation ID, optional unique UUID to track multiple calls for the same flow/user</td>
                  <td class="border border-dark">a0ed1118-dae0-4b2b-bc0c-7d78ebd6de81</td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-transactionid</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">Transaction ID, used as additional information</td>
                  <td class="border border-dark">1927df1e-6c79-46cf-ad8c-44305bfce900</td>
                </tr>
                <tr>
                  <td class="border border-dark">x-txn-request-id</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">Request ID, used as additional information</td>
                  <td class="border border-dark">341ec35b-8b70-46eb-8089-ee864d7082f1</td>
                </tr>
                <tr>
                  <td class="border border-dark">user-agent</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">User agent</td>
                  <td class="border border-dark">Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/51.0.2704.103
                    Safari/537.36
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <br/>


          </div>
        </div>

        <div class="card" id="idempotency">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Idempotency</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Definition</h5>
            <ul class="list-group">
              <li class="list-group-item">
                An idempotent operation is one that can be applied multiple times without changing the result beyond the
                initial application.
              </li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Restful API Principles</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Rydpay APIs adhere to the general Restful API principles, utilizing the defined HTTP methods provided by
                these principles.
              </li>
              <li class="list-group-item">
                <b>HTTP Methods</b>: Rydpay APIs use standard HTTP methods such as GET, POST, PUT, DELETE, etc., to
                perform operations on resources.
              </li>
              <li class="list-group-item">
                <b>Stateless</b>: Each API request from a client contains all the information the server needs to
                fulfill that request, maintaining a stateless communication between client and server.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Idempotent APIs</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>GET Requests</b>: All GET APIs are idempotent. Repeated requests will return the same result and have
                no side effects.
              </li>
              <li class="list-group-item">
                <b>HEAD Requests</b>: All HEAD APIs are idempotent. Repeated requests will return the same result and
                have no side effects.
              </li>
              <li class="list-group-item">
                <b>OPTIONS Requests</b>: All HEAD APIs are idempotent. Repeated requests will return the same result and
                have no side effects.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Conditionally Idempotent APIs</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>PUT Requests</b>: While PUT requests are typically idempotent in Restful API principles, at Ryd, they
                are NOT idempotent by default.
              </li>
              <li class="list-group-item">
                If a PUT API is idempotent, it will be indicated in the <a
                  href="/integration-guide/rydpay-apis/api-reference#/production">API reference/documentation</a>.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Non-Idempotent APIs</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>POST Requests</b>: NOT idempotent. Multiple identical POST requests create multiple resources or have
                different effects.
              </li>
              <li class="list-group-item">
                <b>DELETE Requests</b>: NOT idempotent. Multiple identical DELETE requests may result in errors if the
                resource has already been deleted.
              </li>
            </ul>
            <br/>


          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";
import highlightMixin from '@/mixins/highlightMixin';

export default {
  name: "RydpayApisApiFundamentals",
  keywords: ['Versioning', 'authentication', 'Error handling', 'API Headers', 'Idempotency', 'Idempotent'],
  data() {
    return {
      loggedInUserGroup: '',
    }
  },
  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.scrollToRootHash();
  },
  methods: {
    scrollToCard,
    scrollToRootHash,
    scrollToTop
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
}
</script>