<template>
  <form class="form-inline search-full col" action="#" method="get" @submit.prevent="" :class="{ 'open': searchOpen, 'has-results': hasResults }">
    <div class="form-group w-100">
      <div class="Typeahead Typeahead--twitterUsers">
        <div class="u-posRelative">
          <input
              ref="searchInput"
              class="demo-input Typeahead-input form-control-plaintext w-100"
              type="text"
              v-model="terms"
              placeholder="Search ryd Partner Portal ..."
              name="q"
              title=""
              autofocus
              autocomplete="off"
          />
          <div class="spinner-border Typeahead-spinner" role="status" v-if="isLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <vue-feather class="close-search" type="x" @click="search_close()" />
        </div>

        <div v-if="hasResults" class="Typeahead-menu-container">
          <div id="foundResultMenu" class="Typeahead-menu is-open">
            <div v-if="filteredMenuItems.length > 0">
              <h5>Search in Navigation Menu</h5>
              <div class="ProfileCard u-cf" v-for="(menuItem, index) in filteredMenuItems" :key="index">
                <div class="ProfileCard-avatar header-search">
                  <svg class="stroke-icon">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg')+`#${menuItem.icon}`"></use>
                  </svg>
                </div>
                <div class="ProfileCard-details found-item">
                  <div class="ProfileCard-realName">
                    <span @click="setActiveLink(menuItem)" class="realname font-primary">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> {{ menuItem.displayPath }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="filteredPageContents.length > 0">
              <h5>Search In Page Keywords</h5>
              <div class="ProfileCard u-cf" v-for="(pageContent, index) in filteredPageContents.slice(0, 8)" :key="index">
                <div class="ProfileCard-details found-item">
                  <div class="ProfileCard-realName">
                    <span @click="setActiveLink(pageContent)" class="realname font-primary">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> {{ pageContent.displayPath }}
                    </span>
                  </div>
                  <div>
                    <p v-html="pageContent.contentSnippet"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="searchResultEmpty && terms.trim()" class="Typeahead-menu is-open">
          <div class="tt-dataset tt-dataset-0">
            <div class="EmptyMessage">
              Oops, there are no results found.
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { menuItems } from '@/data/menu';
import { extractTextContent } from '@/utils/textExtractor';

export default {
  name: 'SearchBar',
  data() {
    return {
      terms: '',
      searchResult: false,
      searchResultEmpty: false,
      pageContents: [],
      results: [],
      userData: JSON.parse(localStorage.getItem('userAddData')) || {},
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      searchOpen: (state) => state.menu.searchOpen,
      searchResults: (state) => state.menu.searchData,
    }),
    filteredMenuItems() {
      return this.results.filter((result) => result.type === 'menu');
    },
    filteredPageContents() {
      return this.results.filter((result) => result.type === 'page');
    },
    hasResults() {
      return this.results.length > 0;
    },
  },
  watch: {
    terms: {
      handler(value) {
        if (this.searchOpen) {
          this.debouncedSearch(value);
        }
      },
    },
    searchOpen: {
      handler(newVal) {
        if (newVal) {
          this.addFix();
          this.$nextTick(() => {
            this.$refs.searchInput.focus();
          });
        } else {
          this.removeFix();
        }
      },
      immediate: true,
    },
    hasResults: {
      handler(newVal) {
        if (newVal) {
          document.body.classList.add('no-scroll');
        } else {
          document.body.classList.remove('no-scroll');
        }
      },
      immediate: true,
    },
  },
  created() {
    this.indexPageContents();
    this.debouncedSearch = this.debounce(this.performSearch, 300);
  },
  beforeDestroy() {
    document.body.classList.remove('no-scroll');
  },
  methods: {
    debounce(func, wait) {
      let timeout;
      return function executedFunction(...args) {
        const later = () => {
          clearTimeout(timeout);
          func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
      };
    },
    search_open() {
      this.$store.commit('menu/SET_SEARCH_OPEN', true);
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    search_close() {
      this.$store.commit('menu/SET_SEARCH_OPEN', false);
      this.removeFix();
      this.terms = '';
      this.results = [];
      this.searchResultEmpty = false;
    },
    indexPageContents() {
      const traverseMenu = (items, parentTitles = []) => {
        for (const item of items) {
          if (item.component) {
            const textContent = extractTextContent(item.component);
            const keywords = item.component.keywords ? item.component.keywords.join(' | ') : '';
            const combinedContent = [keywords, textContent].join(' ');

            this.pageContents.push({
              id: item.path,
              title: item.title,
              content: combinedContent,
              requiredRoles: item.requiredRoles || [],
              requiredUserGroup: item.requiredUserGroup || [],
              displayPath: [...parentTitles, item.title].join(' -> '),
              path: item.path,
              type: 'page',
            });
          }
          if (item.children) {
            traverseMenu(item.children, [...parentTitles, item.title]);
          }
        }
      };
      traverseMenu(menuItems.data);
    },
    performSearch(value) {
      this.isLoading = true;
      const userAddData = JSON.parse(localStorage.getItem('userAddData')) || {};
      const userRoles = userAddData.roles || [];
      const userGroup = userAddData.userGroup || '';
      const searchval = value.toLowerCase();
      let items = [];

      const hasRequiredRolesAndGroup = (itemRoles, itemGroup) => {
        const hasRoles = !itemRoles || itemRoles.length === 0 || itemRoles.some((role) => userRoles.includes(role));
        const hasGroup = !itemGroup || itemGroup.length === 0 || itemGroup.includes(userGroup);
        return hasRoles && hasGroup;
      };

      const searchItems = (menuItems, parentTitles = []) => {
        menuItems.forEach((menuItem) => {
          if (menuItem.title && hasRequiredRolesAndGroup(menuItem.requiredRoles, menuItem.requiredUserGroup)) {
            const displayPath = [...parentTitles, menuItem.title].join(' -> ');
            if (displayPath.toLocaleLowerCase().includes(searchval) && menuItem.type === 'link') {
              items.push({ ...menuItem, displayPath, type: 'menu' });
            }
            if (menuItem.children) {
              searchItems(menuItem.children, [...parentTitles, menuItem.title]);
            }
          }
        });
      };

      searchItems(this.menuItems);

      const filteredPageContents = this.pageContents.filter((content) =>
          hasRequiredRolesAndGroup(content.requiredRoles, content.requiredUserGroup)
      );

      const pageResults = filteredPageContents.filter(content =>
          content.title.toLowerCase().includes(searchval) || content.content.toLowerCase().includes(searchval)
      ).map(content => ({
        ...content,
        contentSnippet: this.extractContext(content.content, searchval),
      }));

      this.results = [...items, ...pageResults];
      this.$store.dispatch('menu/setSearchData', items);
      this.searchResultEmpty = this.results.length === 0;
      this.isLoading = false;
    },
    extractContext(content, searchTerm) {
      const startIdx = content.toLowerCase().indexOf(searchTerm.toLowerCase());
      if (startIdx === -1) return content.substring(0, 100);
      const endIdx = startIdx + searchTerm.length;
      return '...' + content.substring(Math.max(0, startIdx - 20), Math.min(content.length, endIdx + 20)) + '...';
    },
    addFix() {
      document.body.classList.add('offcanvas');
      this.searchResult = true;
    },
    setActiveLink(item) {
      this.results = []; // Clear results but keep search open
      this.$store.dispatch('menu/setNavActive', item);
      this.$router.replace({
        path: item.path,
        query: { search: this.terms },
      });
      this.search_close();
    },
    removeFix() {
      document.body.classList.remove('offcanvas');
      document.body.classList.remove('no-scroll');
      this.searchResult = false;
    },
  },
};
</script>

<style>
.found-item {
  cursor: pointer;
}

#foundResultMenu.Typeahead-menu {
  position: relative;
}

.Typeahead-menu-container {
  max-height: 85vh;
  overflow-y: auto;
}

.highlight {
  font-weight: bold;
  color: #007bff;
}

.no-scroll {
  overflow: hidden;
}

.form-inline.search-full.open:not(.has-results) {
  background: transparent;
}

.form-inline.search-full.open.has-results {
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}
</style>
