
// Returns the date part in 'YYYY-MM-DD' format from a date string in ISO format
export function parseDateStringYyyyMmDd(dateString) {
    return dateString.split('T')[0];
}

export function convertDateToISOString(date) {
    return new Date(date).toISOString();
}

export function parseDateStringYyyyMmDdNoBinder(dateString) {
    const yyyyMmDd = dateString.split('T')[0];
    return yyyyMmDd.replace(/-/g, ''); // Remove hyphens
}

export function convertDateTimeToReadFormat(datetimeStr) {
    // Split the string by the 'T' to separate date and time
    const [date, time] = datetimeStr.split('T');

    // Split the time by the ':' and take only the first two segments (hours and minutes)
    const [hours, minutes] = time.split(':');

    // Concatenate the date and time with a space and return it
    return `${date} ${hours}:${minutes}`;
}