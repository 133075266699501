<template>
  <Breadcrumbs main="rydpay APIs" title="Getting Started"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <p>
              On this page, you will find essential information about getting started with Rydpay APIs. The topics covered include:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('introduction')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Introduction to Rydpay APIs
                </a>
                Learn about the basics of Rydpay APIs and how they can benefit your integration.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('features')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Comprehensive Features and Capabilities
                </a>
                Discover the powerful features and capabilities that Rydpay APIs offer.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('onboarding')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> New Partner Onboarding Guide
                </a>
                Follow the steps to get started as a new partner with Rydpay.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('structure')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> How the Integration Guides are Structured
                </a>
                Understand the structure of our integration guides and how to navigate them effectively.
              </li>
            </ul>

          </div>
        </div>


        <div class="card" id="introduction">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Introduction to rydpay APIs</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Rydpay API: Bridging Customers And Services</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Our APIs are the core of Ryd's platform, acting as a gateway between our partners and a network of petrol stations, EV charging stations, and car wash facilities. These APIs facilitate a wide range of services, from fuel purchases and EV charging sessions to car wash bookings, all through a few simple API calls.
              </li>
              <li class="list-group-item">
                By integrating these capabilities, our platform ensures that partners can offer a comprehensive and seamless experience to their customers, meeting diverse needs across various services.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Unified Yet Flexible Integration</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd's platform leverages a set of common concepts, standardized APIs, and a unified order state machine across all services.
              </li>
              <li class="list-group-item">
                Each product—fuelling, EV charging, and car wash—includes specific fields and, optionally, additional APIs to cater to unique requirements.
              </li>
              <li class="list-group-item">
                This approach ensures that partners can provide a comprehensive and seamless experience tailored to the specific needs of their customers, whether they are fuelling their vehicles, charging electric cars, or using car wash services.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Product-Specific Details</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Each product—fuelling, EV charging, and car wash—utilizes the same core APIs and order state machine, but also includes:
              </li>
              <li class="list-group-item">
                <b>Specialized Fields</b>: Optional APIs to handle product-specific operations and scenarios.
              </li>
              <li class="list-group-item">
                <b>Additional APIs</b>: Unique fields tailored to the specific needs of each service.
              </li>
              <li class="list-group-item">
                <b>Flow Variations</b>: Differences in operational flow to meet the unique requirements of each service, ensuring a seamless and intuitive user experience.
              </li>
            </ul>
            <br/>

          </div>
        </div>

        <div class="card" id="features">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Comprehensive Features and Capabilities</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item font-success"><b>Fuelling Services</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>Prepay (Automatic):</b> Customers pre-authorize payment to
                  unlock
                  the pump and begin fuelling. The pump
                  automatically stops at the pre-authorized amount, ensuring a seamless transaction.</li>
                <li class="list-group-item"><b>Postpay (Manual):</b> Customers fuel first and pay later,
                  offering
                  flexibility and convenience.
                </li>
              </ul>
              <li class="list-group-item font-success"><b>Car Wash Services</b></li>
              <ul class="list-group">
                <li class="list-group-item">Integration with car wash stations to purchase digital codes for
                  services, enhancing the customer experience.</li>
              </ul>
              <li class="list-group-item font-success"><b>EV Charging Services</b></li>
              <ul class="list-group">
                <li class="list-group-item">Integration with EV charging stations to facilitate the reservation and payment of charging sessions.</li>
              </ul>
              <li class="list-group-item font-success"><b>Real-Time Data</b></li>
              <ul class="list-group">
                <li class="list-group-item">Access to real-time data including fuel prices and station
                  information, ensuring transparency and compliance with regional regulations.</li>
              </ul>
              <li class="list-group-item font-success"><b>Cross-Country and Cross-Currency Compatibility</b></li>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Regional Adaptation</b>: Our APIs are designed to work across multiple countries, accommodating various forecourt systems and regional differences.
                </li>
                <li class="list-group-item">
                  <b>Currency Handling</b>: Seamless handling of transactions in different currencies, ensuring compatibility and convenience for international users.
                </li>
              </ul>
              <li class="list-group-item font-success"><b>Monitoring and Alerts</b></li>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Log Monitoring</b>: Comprehensive log monitoring to track system performance and user activity.
                </li>
                <li class="list-group-item">
                  <b>Alert Service</b>: Real-time alerts to notify partners of important events and potential issues, ensuring prompt action and resolution.
                </li>
              </ul>
            </ul>
          </div>
        </div>


        <div class="card" id="onboarding">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>New Partner Onboarding Guide</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 1: Contact Ryd for Account Creation</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Initial Contact:</b> Reach out to Ryd to express your interest in becoming a partner. You can do this via email or phone.</li>
              <li class="list-group-item"><b>Account Creation:</b> A member of the Ryd team will invite the first team member of your organization via their email. The first team member will receive an invitation email and will need to specify a password. This first team member can then invite additional team members without further involvement from Ryd.</li>
              <li class="list-group-item"><b>Partner payment method setup:</b> Together with the account creation ryd will set up and configure a specific partner payment method linked to the partner API account. This payment method can be configured with additional velocity checks but usually allows the partner to authorize every transaction without limitation. For a normal integration no specific parameters or reference will be required to process the payment.<br/><b>Once a partner calls the /authorize endpoint for a specific order the liability is shifted towards the partner resulting in the order amount being added to the next billing cycle.</b></li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 2: Accessing API Keys/Tokens
            </h5>
            <ul class="list-group">
              <li class="list-group-item"><b>API Keys/Tokens:</b>
                Once logged in and your API key has been created for the staging or production system, you can view the API token on the <a href="/pages/account-settings">Account Settings</a> page.
                It's important to note that Ryd provides separate API keys/Tokens for the
                staging and production environments.
              </li>
              <li class="list-group-item"><b>Staging vs. Production:</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>Staging Environment:</b> Use the staging API keys for testing
                  and development purposes. This environment allows you to simulate API interactions
                  without affecting live data.
                </li>
                <li class="list-group-item"><b>Production Environment:</b> The production API keys are for
                  your live environment. Switch to these keys once you have completed testing and are
                  ready to go live.
                </li>
              </ul>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 3: Understanding the API
              Documentation</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Documentation Access: </b> Access our comprehensive API
                documentation through the Partner Portal.
              </li>
              <li class="list-group-item"><b>Familiarization:</b>
                Explore the subsections in the <a href="/integration-guide/rydpay-apis/getting-started">Rydpay APIs section</a>.
              </li>
              <li class="list-group-item"><b>Interactive Exploration:</b> Utilize <a
                  href="/integration-guide/rydpay-apis/api-reference">Swagger UI</a> provided by ryd for
                interactive exploration of the API.
              </li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 4: Setting Up Your Development Environment</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Environment Setup:</b> Configure a development environment that closely mirrors your production setup.</li>
              <li class="list-group-item"><b>Software Installation:</b> Install necessary software and tools, such as Postman, for testing API requests.</li>
              <li class="list-group-item"><b>Postman Collection:</b> Import the Ryd Postman collections for pre-configured API request templates and examples. This collections contain different scenarios documented on <a href="/integration-guide/rydpay-apis/testing-and-simulation/section-overview">this page</a>, which also provides the downloads. Separate Postman collections are available for each service (Fuelling, EV Charging, Car Wash).</li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 5: Testing and Simulation</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Staging Environment:</b> Start by testing in the Ryd staging environment to simulate real-world scenarios without affecting live data. <a href="/integration-guide/rydpay-apis/testing-and-simulation/section-overview">This page</a> provides access to simulated fuelling, EV charging, and car wash stations to replicate real behaviors, e.g. different currencies.</li>
              <li class="list-group-item"><b>Test Scenarios:</b> Run through <a href="/integration-guide/rydpay-apis/testing-and-simulation/section-overview">common test scenarios</a>, including both successful and error responses, to ensure your integration handles all cases.</li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 6: Going Live</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Final Checks:</b> Before going live, ensure that all functionalities for Fuelling, Car Wash, and EV Charging are working as expected in the staging environment. Ryd will assist you with acceptance and field testing, including testing at real petrol stations, car wash facilities, and EV charging stations. Ryd will also help analyze all requests and responses to ensure a smooth integration.
              </li>
              <li class="list-group-item"><b>Production Keys: </b> Your production API key will be generated
                and available on the <a href="/pages/account-settings">Account Settings</a> page once the contract details have been finalised between you and
                ryd.
              </li>
              <li class="list-group-item"><b>Deployment: </b> Transition your integration from the staging to
                the production environment using the production API keys and server.
              </li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 7: Post-Deployment</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Monitoring:</b> Continuously monitor the integration for any issues or irregularities. Ryd offers an extensive monitoring solution that covers all your requests, responses, and performance metrics, ensuring comprehensive oversight and analysis.</li>
              <li class="list-group-item"><b>Updates and Maintenance:</b> Regularly check for <a href="/integration-guide/rydpay-apis/release-notes-and-updates">release notes and updates</a> in the APIs and make necessary adjustments to your integration. Ryd will inform you about API changes and will not introduce any breaking changes without your approval.</li>
            </ul>
            <br/>


            <div class="alert alert-primary outline alert-dismissible fade show d-flex" role="alert"><i
                data-feather="help-circle">
              <vue-feather type="help-circle"></vue-feather>
            </i>
              <p class="mx-2"><b>Support and Assistance</b> If you encounter any issues or have any questions
                during the setup process and your implementation, please reach out to our support team.</p>
            </div>


          </div>
        </div>


        <div class="card" id="structure">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>How the Integration Guides are Structured</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                Rydpay APIs use a unified set of API Fundamentals, including versioning, authentication, and idempotency. They share common APIs and order state management across all supported products/services: Fuelling, Car Wash, and EV Charging.
              </li>
              <li class="list-group-item">
                Each product has unique requirements, workflows, and business differences. As a result, there are specific product APIs and distinct fields within the shared APIs.
              </li>
              <li class="list-group-item">
                The best way to begin is by selecting the product you want to integrate. Visit the <a href="/integration-guide/rydpay-apis/integration-pathways">Integration Pathways page</a> to find a comprehensive guide for each product, detailing the recommended reading sections and the order in which to follow them.
              </li>

            </ul>
            <br/>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";

export default {
  name: "RydpayApiGettingStarted",
  keywords: ['Onboard', 'Onboarding', 'Capabilities', 'Introduction'],

  data() {
    return {
      loggedInUserGroup: '',
    }
  },
  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.scrollToRootHash();
  },
  methods: {
    scrollToCard,
    scrollToRootHash,
    scrollToTop
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
}
</script>