<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Station Offline Status Dashboard"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">General Information</div>
            <div v-show="guardianCheckId">
              The following charts are based on the checks performed at the predefined time point:
              <strong>{{ formattedGuardianCheckId }}</strong> (UTC Time).
              <br/>
              Click <a target="_blank"
                       :href="`/guardian-system-and-alerts/guardian-checks?guardianCheckId=${guardianCheckId}`">here</a>
              to find out the details of the offline stations.
              <br/>
              <div>
                <h6>Total Offline Stations: <span class="font-primary">{{ totalOfflineStations }}</span></h6>
              </div>
            </div>
          </div>
        </div>

        <!-- Loading Indicator -->
        <div v-if="isLoading" class="card">
          <div class="card-body">
            <div class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Loading ... Offline Stations Status Dashboard</h6>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cashier System Across Countries Chart -->
        <div v-if="!isLoading && offlineStations.length > 0" class="card">
          <div class="card-body">
            <h5 class="card-title font-primary">
              <i class="icofont icofont-dashboard-web"></i>
              D1: Offline Rate of Cashier Systems Across Countries</h5>
            <p class="card-text">
              This chart displays the offline rate for each cashier system across all supported countries. The offline
              rate is calculated as the number of offline stations divided by the total number of stations for each
              cashier system.
            </p>
            <div id="offline-rate-cashier">
              <apexchart
                  v-if="seriesCashier.length > 0"
                  :key="chartKey"
                  type="bar"
                  :options="chartOptionsCashier"
                  :series="seriesCashier"
                  height="350"
              ></apexchart>
            </div>
            <div id="pie-chart" class="m-t-30">
              <apexchart type="pie" height="350" :options="chartOptionsPieCashierSystem" :series="seriesPieCashierSystem"></apexchart>
            </div>
          </div>
        </div>

        <!-- MOC Partner Across Countries Chart -->
        <div v-if="!isLoading && offlineStations.length > 0" class="card">
          <div class="card-body">
            <h5 class="card-title font-success">
              <i class="icofont icofont-dashboard-web"></i>
              D2: Offline Rate of MOC Partners Across Countries</h5>
            <p class="card-text">
              This chart displays the offline rate for each MOC partner across all supported countries. The offline rate
              is calculated as the number of offline stations divided by the total number of stations for each MOC
              partner.
            </p>
            <div id="offline-rate-moc-partner">
              <apexchart
                  v-if="seriesMocPartnersAcrossCountries.length > 0"
                  :key="chartKeyMocPartnersAcrossCountries"
                  type="bar"
                  :options="chartOptionsMocPartnersAcrossCountries"
                  :series="seriesMocPartnersAcrossCountries"
                  height="350"
              ></apexchart>
            </div>
            <div id="pie-chart-2" class="m-t-30">
              <apexchart type="pie" height="350" :options="chartOptionsPieMocPartnersAcrossCountries" :series="seriesPieMocPartnersAcrossCountries"></apexchart>
            </div>
          </div>
        </div>


        <!-- BRAND Across Countries Chart -->
        <div v-if="!isLoading && offlineStations.length > 0" class="card">
          <div class="card-body">
            <h5 class="card-title font-primary">
              <i class="icofont icofont-dashboard-web"></i>
              D3: Offline Rate of Brands Across Countries</h5>
            <p class="card-text">
              This chart displays the offline rate for each brand across all supported countries. The offline rate is
              calculated as the number of offline stations divided by the total number of stations for each brand.
            </p>
            <div id="offline-rate-brands-across-country">
              <apexchart
                  v-if="seriesBrandsAcrossCountries.length > 0"
                  :key="chartKeyBrandAcrossCountries"
                  type="bar"
                  :options="chartOptionsBrandsAcrossCountries"
                  :series="seriesBrandsAcrossCountries"
                  height="350"
              ></apexchart>
            </div>
            <div id="pie-chart-2" class="m-t-30">
              <apexchart type="pie" height="350" :options="chartOptionsPieBrandsAcrossCountries" :series="seriesPieBrandsAcrossCountries"></apexchart>
            </div>
          </div>
        </div>

        <!-- Cashier System And country Chart -->
        <div v-if="!isLoading && offlineStations.length > 0" class="card">
          <div class="card-body">
            <h5 class="card-title font-success">
              <i class="icofont icofont-dashboard-web"></i>
              D4: Offline Rate of Cashier Systems and Country</h5>
            <p class="card-text">
              This chart displays the offline rate for each cashier system and country. The offline rate is calculated
              as the number of offline stations divided by the total number of stations for each cashier system and
              country.
            </p>
            <div id="offline-rate-cashier-system-and-country">
              <apexchart
                  v-if="seriesCashierSystemAndCountry.length > 0"
                  :key="chartKeyCashierSystemAndCountry"
                  type="bar"
                  :options="chartOptionsCashierSystemAndCountry"
                  :series="seriesCashierSystemAndCountry"
                  height="350"
              ></apexchart>
            </div>
            <div id="pie-chart-2" class="m-t-30">
              <apexchart type="pie" height="350" :options="chartOptionsPieCashierSystemAndCountry" :series="seriesPieCashierSystemAndCountry"></apexchart>
            </div>
          </div>
        </div>

        <!-- chart start -->
        <div v-if="!isLoading" class="card">
          <div class="card-body">
            <h5 class="card-title font-primary">
              <i class="icofont icofont-dashboard-web"></i>
              D5: Offline Status of a Petrol Station Over Time</h5>
            <p class="card-text">
              This chart shows the offline status of a specific petrol station over a period of time, highlighting the
              frequency and duration of offline events.
            </p>
            <div>TODO</div>
          </div>
        </div>
        <!-- chart end -->

        <!-- chart start -->
        <div v-if="!isLoading" class="card">
          <div class="card-body">
            <h5 class="card-title font-success">
              <i class="icofont icofont-dashboard-web"></i>
              D6: Offline Rate of a Specific Cashier System Over Time</h5>
            <p class="card-text">
              This chart shows the offline rate of a particular cashier system over a defined period, allowing for analysis of its reliability and performance trends.
            </p>
            <div>TODO</div>
          </div>
        </div>
        <!-- chart end -->


        <!-- chart start -->
        <div v-if="!isLoading" class="card">
          <div class="card-body">
            <h5 class="card-title font-primary">
              <i class="icofont icofont-dashboard-web"></i>
              D7: Offline Rate of a Specific MOC Partner Over Time</h5>
            <p class="card-text">
              This chart shows the offline rate of a particular MOC partner over time, providing insights into the partner's service consistency and uptime.
            </p>
            <div>TODO</div>
          </div>
        </div>
        <!-- chart end -->


      </div>
    </div>
  </div>
</template>

<script>
import {nextTick} from 'vue';
import axios from 'axios';
import VueApexCharts from 'vue3-apexcharts';
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  components: {
    apexchart: VueApexCharts
  },
  keywords: [
    'Dashboard Overview',
    'Station Performance Dashboard',
    'Comprehensive Station Metrics',
    'Station Operational Dashboard',
    'Aggregated Performance Charts',
    'Multi-Metric Station Analysis',
    'Station Status Dashboard',
    'Comprehensive Station Overview',
    'Performance Metrics Dashboard',
    'Station Performance Insights',
    'Station Analytics Dashboard',
    'Real-Time Station Dashboard',
    'Multi-Dimensional Station Metrics',
    'Station Performance Monitoring',
    'Integrated Station Performance'
  ],
  data() {
    return {
      offlineStations: [],
      isLoading: false,
      loggedInUserId: '',
      guardianCheckId: this.$route.query.guardianCheckId || '',
      showDebugMessage: false,
      chartKey: 0,
      chartKeyMocPartnersAcrossCountries: 0,
      chartKeyBrandAcrossCountries: 0,
      chartKeyCashierSystemAndCountry: 0,

      // cashier system across countries
      chartOptionsCashier: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
        },
        colors: ['#7366ff'],
        dataLabels: {enabled: false},
        stroke: {width: [1, 1, 4]},
        xaxis: {categories: []},
        yaxis: {
          labels: {
            formatter: (value) => `${value} %`
          }
        },
        tooltip: {
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const offlineRate = series[seriesIndex][dataPointIndex];
            const cashierSystem = w.config.customTooltipData[dataPointIndex].key;
            const offlineCount = w.config.customTooltipData[dataPointIndex].offlineCount;
            const totalCount = w.config.customTooltipData[dataPointIndex].totalCount;

            // Calculate alert based on rules
            let alert = false;

            if (totalCount <= 50 && offlineRate >= 60) {
              alert = true;
            } else if (totalCount <= 200 && offlineRate >= 40) {
              alert = true;
            } else if (totalCount > 200 && offlineRate >= 20) {
              alert = true;
            }

            const highlightColor = alert ? 'red' : 'blue';

            return `
                    <div>
                      <div><strong>Cashier System:</strong> ${cashierSystem}</div>
                      <div style="color:${highlightColor};"><strong>Offline Rate:</strong> ${offlineRate.toFixed(2)}%</div>
                      <div><strong>Offline Stations:</strong> ${offlineCount}</div>
                      <div><strong>Total Stations:</strong> ${totalCount}</div>
                      <div style="color:${highlightColor};"><strong>Alert:</strong> ${alert ? 'True' : 'False'}</div>
                      <div><strong>Alert Rules:</strong></div>
                      <ul>
                        <li${totalCount <= 50 ? ` style="color:${highlightColor};"` : ''}>Stations 1 - 50, Offline Rate >= 60%</li>
                        <li${totalCount <= 200 ? ` style="color:${highlightColor};"` : ''}>Stations 51 - 200, Offline Rate >= 40%</li>
                        <li${totalCount > 200 ? ` style="color:${highlightColor};"` : ''}>Stations 201+, Offline Rate >= 20%</li>
                      </ul>
                    </div>
                  `;
          }
        }

      },
      seriesCashier: [{name: 'Offline Rate', data: []}],
      customTooltipData: [],

      chartOptionsPieCashierSystem: {
        chart: {
          type: 'pie',
          height: 350,
        },
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesPieCashierSystem: [],


      // moc partner across countries
      chartOptionsMocPartnersAcrossCountries: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
        },
        colors: ['#54ba4a'],
        dataLabels: {enabled: false},
        stroke: {width: [1, 1, 4]},
        xaxis: {categories: []},
        yaxis: {
          labels: {
            formatter: (value) => `${value} %`
          }
        },
        tooltip: {
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const offlineRate = series[seriesIndex][dataPointIndex];
            const tooltipData = w.config.customTooltipDataMocPartnersAcrossCountries;
            const partnerName = tooltipData[dataPointIndex].partnerName;
            const offlineCount = tooltipData[dataPointIndex].offlineCount;
            const totalCount = tooltipData[dataPointIndex].totalCount;

            // Calculate alert based on rules
            let alert = false;

            if (totalCount <= 10) {
              alert = false;
            } else if (totalCount <= 40 && totalCount > 10 && offlineRate > 35) {
              alert = true;
            } else if (totalCount <= 80 && totalCount > 40 && offlineRate > 25) {
              alert = true;
            } else if (totalCount > 80 && offlineRate > 20) {
              alert = true;
            }

            const highlightColor = alert ? 'red' : 'blue';

            return `
                    <div>
                      <div><strong>Partner Name:</strong> ${partnerName}</div>
                      <div style="color:${highlightColor};"><strong>Offline Rate:</strong> ${offlineRate.toFixed(2)}%</div>
                      <div><strong>Offline Stations:</strong> ${offlineCount}</div>
                      <div><strong>Total Stations:</strong> ${totalCount}</div>
                      <div><strong>Alert Rules:</strong></div>
                      <ul>
                        <li${totalCount <= 10 ? ` style="color:${highlightColor};"` : ''}>Stations <= 10, manually solving </li>
                        <li${totalCount > 10 && totalCount <= 40 ? ` style="color:${highlightColor};"` : ''}>Stations 11 - 40, Offline Rate >= 35%</li>
                        <li${totalCount > 40 && totalCount <= 80 ? ` style="color:${highlightColor};"` : ''}>Stations 41 - 80, Offline Rate >= 25%</li>
                        <li${totalCount > 80 ? ` style="color:${highlightColor};"` : ''}>Stations 81+, Offline Rate >= 20%</li>

                      </ul>
                    </div>
                  `;
          }
        }
      },
      seriesMocPartnersAcrossCountries: [{name: 'Offline Rate', data: []}],
      customTooltipDataMocPartnersAcrossCountries: [],


      chartOptionsPieMocPartnersAcrossCountries: {
        chart: {
          type: 'pie',
          height: 350,
        },
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesPieMocPartnersAcrossCountries: [],

      // brand across countries
      chartOptionsBrandsAcrossCountries: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
        },
        colors: ['#7366ff'],
        dataLabels: {enabled: false},
        stroke: {width: [1, 1, 4]},
        xaxis: {categories: []},
        yaxis: {
          labels: {
            formatter: (value) => `${value} %`
          }
        },
        tooltip: {
          custom: function ({series, seriesIndex, dataPointIndex, w}) {
            const offlineRate = series[seriesIndex][dataPointIndex];
            const tooltipData = w.config.customTooltipDataBrandsAcrossCountries;
            const offlineCount = tooltipData[dataPointIndex].offlineCount;
            const totalCount = tooltipData[dataPointIndex].totalCount;

            return `
                <div>
                  <div><strong>Offline Rate:</strong> ${offlineRate.toFixed(2)}%</div>
                  <div><strong>Offline Stations:</strong> ${offlineCount}</div>
                  <div><strong>Total Stations:</strong> ${totalCount}</div>
                  <div><strong>Alert Rules:</strong> No Alert is configured for brands</div>
                </div>
              `;
          }
        }
      },
      seriesBrandsAcrossCountries: [{name: 'Offline Rate', data: []}],
      customTooltipDataBrandsAcrossCountries: [],

      chartOptionsPieBrandsAcrossCountries: {
        chart: {
          type: 'pie',
          height: 350,
        },
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesPieBrandsAcrossCountries: [],

      // cashier system and country
      chartOptionsCashierSystemAndCountry: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: false,
        },
        colors: ['#54ba4a'],
        dataLabels: {enabled: false},
        stroke: {width: [1, 1, 4]},
        xaxis: {categories: []},
        yaxis: {
          labels: {
            formatter: (value) => `${value} %`
          }
        },
        tooltip: {}
      },
      seriesCashierSystemAndCountry: [{name: 'Offline Rate', data: []}],
      customTooltipDataCashierSystemAndCountry: [],

      chartOptionsPieCashierSystemAndCountry: {
        chart: {
          type: 'pie',
          height: 350,
        },
        labels: [],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      seriesPieCashierSystemAndCountry: [],


    }
  },
  methods: {
    async fetchOfflineStations() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/offline-gas-stations`, {
          params: {
            guardianCheckId: this.guardianCheckId,
            useTheLatestCheckId: !this.guardianCheckId,
            page: 1,
            pageSize: 1000,
          },
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId
          }
        });
        this.offlineStations = response.data.data;

        if (response.data.data && response.data.data[0] && response.data.data[0].guardian_check_id) {
          this.guardianCheckId = response.data.data[0].guardian_check_id;
          await this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
        this.updateChartCashier();
        this.updateChartMocPartnersAcrossCountries();
        this.updateChartBrandsAcrossCountries();
        this.updateChartCashierSystemAndCountry();
      } catch (error) {
        console.error('Error fetching offline stations:', error);
      } finally {
        this.isLoading = false;
      }
    },

    updateChartCashier() {
      if (!this.offlineStations || this.offlineStations.length === 0) {
        console.warn('No offline stations data available');
        return;
      }

      const cashierGroups = this.groupBy(this.offlineStations, 'cashier_system');
      const categories = [];
      const offlineRates = [];
      const offlineCounts = [];
      const totalCounts = [];
      const tooltipData = [];
      const pieData = [];

      // Sort the entries of cashierGroups by keys
      const sortedEntries = Object.entries(cashierGroups).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

      for (const [key, value] of sortedEntries) {
        if (key && key.trim() !== '') {
          const offlineCount = value.length;
          const totalCount = value[0].cashier_system_count;
          const offlineRate = (offlineCount / totalCount) * 100;

          categories.push(key);
          offlineRates.push(parseFloat(offlineRate.toFixed(2)));
          offlineCounts.push(offlineCount);
          totalCounts.push(totalCount);
          tooltipData.push({key, offlineCount, totalCount});
          pieData.push({name: key, y: offlineCount});
        }
      }

      // Sort pieData by offlineCount in descending order
      pieData.sort((a, b) => a.y - b.y);

      // Extract sorted categories and data for the pie chart
      const sortedCategories = pieData.map(data => data.name);
      const sortedPieData = pieData.map(data => data.y);

      this.chartOptionsCashier.xaxis.categories = categories;
      this.seriesCashier = [{name: 'Offline Rate', data: offlineRates}];
      this.chartOptionsCashier.customTooltipData = tooltipData;

      // Set sorted data for pie chart
      this.chartOptionsPieCashierSystem.labels = sortedCategories;
      this.seriesPieCashierSystem = sortedPieData;

      nextTick(() => {
        this.chartKey += 1;
      });
    },



    updateChartMocPartnersAcrossCountries() {

      if (!this.offlineStations || this.offlineStations.length === 0) {
        console.warn('No offline stations data available');
        return;
      }

      const partnerNameGroups = this.groupBy(this.offlineStations, 'partner_name');
      const categories = [];
      const offlineRates = [];
      const offlineCounts = [];
      const totalCounts = [];
      const tooltipData = [];
      const pieData = [];

      const sortedEntries = Object.entries(partnerNameGroups).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

      for (const [key, value] of sortedEntries) {
        if (key && key.trim() !== '') {
          const offlineCount = value.length;
          const totalCount = value[0].partner_name_count;
          const offlineRate = (offlineCount / totalCount) * 100;

          if (this.showDebugMessage) {
            console.log("updateChartMocPartnersAcrossCountries key", key);
            console.log("updateChartMocPartnersAcrossCountries offlineCount", offlineCount);
            console.log("updateChartMocPartnersAcrossCountries totalStations", totalCount);
            console.log("updateChartMocPartnersAcrossCountries offlineRate", offlineRate);
          }

          categories.push(key);
          offlineRates.push(parseFloat(offlineRate.toFixed(2)));
          offlineCounts.push(offlineCount);
          totalCounts.push(totalCount);
          tooltipData.push({partnerName: key, offlineCount, totalCount});
          pieData.push({name: key, y: offlineCount});
        }
      }

      // Sort pieData by offlineCount in descending order
      pieData.sort((a, b) => a.y - b.y);

      const sortedCategories = pieData.map(data => data.name);
      const sortedPieData = pieData.map(data => data.y);
      // Set sorted data for pie chart
      this.chartOptionsPieMocPartnersAcrossCountries.labels = sortedCategories;
      this.seriesPieMocPartnersAcrossCountries = sortedPieData;

      if (this.showDebugMessage) {
        console.log("updateChartMocPartnersAcrossCountries categories", categories);
        console.log("updateChartMocPartnersAcrossCountries offlineRates", offlineRates);
        console.log("updateChartMocPartnersAcrossCountries offlineCounts", offlineCounts);
        console.log("updateChartMocPartnersAcrossCountries totalCounts", totalCounts);
        console.log("updateChartMocPartnersAcrossCountries tooltipData", tooltipData);
      }

      this.chartOptionsMocPartnersAcrossCountries.xaxis.categories = categories;
      this.chartOptionsMocPartnersAcrossCountries.xaxis.labels = {
        rotate: -45, // Rotate labels for better fit
        style: {
          fontSize: '10px', // Adjust font size for better fit
          whiteSpace: 'normal' // Allow labels to wrap
        },
        formatter: function (value) {
          // Optional: Trim the label if too long
          return value.length > 20 ? value.substring(0, 19) + '...' : value;
        }
      };
      this.seriesMocPartnersAcrossCountries = [{name: 'Offline Rate', data: offlineRates}];
      this.chartOptionsMocPartnersAcrossCountries.customTooltipDataMocPartnersAcrossCountries = tooltipData;

      nextTick(() => {
        this.chartKeyMocPartnersAcrossCountries += 1;
      });
    },


    updateChartBrandsAcrossCountries() {

      if (!this.offlineStations || this.offlineStations.length === 0) {
        console.warn('No offline stations data available');
        return;
      }

      const brandGroups = this.groupBy(this.offlineStations, 'brand');
      const categories = [];
      const offlineRates = [];
      const offlineCounts = [];
      const totalCounts = [];
      const tooltipData = [];
      const pieData = [];

      const sortedEntries = Object.entries(brandGroups).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

      for (const [key, value] of sortedEntries) {
        if (key && key.trim() !== '') {
          const offlineCount = value.length;
          const totalCount = value[0].brand_count;
          const offlineRate = (offlineCount / totalCount) * 100;

          if (this.showDebugMessage) {
            console.log("updateChartBrandsAcrossCountries key", key);
            console.log("updateChartBrandsAcrossCountries offlineCount", offlineCount);
            console.log("updateChartBrandsAcrossCountries totalStations", totalCount);
            console.log("updateChartBrandsAcrossCountries offlineRate", offlineRate);
          }

          categories.push(key);
          offlineRates.push(parseFloat(offlineRate.toFixed(2)));
          offlineCounts.push(offlineCount);
          totalCounts.push(totalCount);
          tooltipData.push({offlineCount, totalCount});
          pieData.push({name: key, y: offlineCount});
        }
      }

      // Sort pieData by offlineCount in descending order
      pieData.sort((a, b) => a.y - b.y);

      const sortedCategories = pieData.map(data => data.name);
      const sortedPieData = pieData.map(data => data.y);
      // Set sorted data for pie chart
      this.chartOptionsPieBrandsAcrossCountries.labels = sortedCategories;
      this.seriesPieBrandsAcrossCountries = sortedPieData;

      if (this.showDebugMessage) {
        console.log("updateChartBrandsAcrossCountries categories", categories);
        console.log("updateChartBrandsAcrossCountries offlineRates", offlineRates);
        console.log("updateChartBrandsAcrossCountries offlineCounts", offlineCounts);
        console.log("updateChartBrandsAcrossCountries totalCounts", totalCounts);
        console.log("updateChartBrandsAcrossCountries tooltipData", tooltipData);
      }

      this.chartOptionsBrandsAcrossCountries.xaxis.categories = categories;
      this.seriesBrandsAcrossCountries = [{name: 'Offline Rate', data: offlineRates}];
      this.chartOptionsBrandsAcrossCountries.customTooltipDataBrandsAcrossCountries = tooltipData;

      nextTick(() => {
        this.chartKeyBrandsAcrossCountries += 1;
      });
    },


    updateChartCashierSystemAndCountry() {
      if (!this.offlineStations || this.offlineStations.length === 0) {
        console.warn('No offline stations data available');
        return;
      }

      const cashierGroups = this.groupBy(this.offlineStations, 'cashier_system', 'country_code');
      const categories = [];
      const offlineRates = [];
      const offlineCounts = [];
      const totalCounts = [];
      const tooltipData = [];
      const pieData = [];

      // Sort the keys of cashierGroups
      const sortedKeys = Object.keys(cashierGroups).sort();

      for (const compositeKey of sortedKeys) {
        const value = cashierGroups[compositeKey];
        const [cashierSystem, countryCode] = compositeKey.split('|');
        if (cashierSystem && cashierSystem.trim() !== '' && countryCode && countryCode.trim() !== '') {
          const offlineCount = value.length;
          const totalCount = value[0].cashier_system_count_country;
          const offlineRate = (offlineCount / totalCount) * 100;

          const categoryLabel = `${cashierSystem} (${countryCode})`;
          categories.push(categoryLabel);
          offlineRates.push(parseFloat(offlineRate.toFixed(2)));
          offlineCounts.push(offlineCount);
          totalCounts.push(totalCount);
          tooltipData.push({cashierSystem, countryCode, offlineCount, totalCount});
          pieData.push({name: categoryLabel, y: offlineCount});
        }
      }

      // Sort pieData by offlineCount in descending order
      pieData.sort((a, b) => a.y - b.y);

      const sortedCategories = pieData.map(data => data.name);
      const sortedPieData = pieData.map(data => data.y);
      // Set sorted data for pie chart
      this.chartOptionsPieCashierSystemAndCountry.labels = sortedCategories;
      this.seriesPieCashierSystemAndCountry = sortedPieData;

      this.chartOptionsCashierSystemAndCountry.xaxis.categories = categories;
      this.seriesCashierSystemAndCountry = [{name: 'Offline Rate', data: offlineRates}];
      this.chartOptionsCashierSystemAndCountry.customTooltipDataCashierSystemAndCountry = tooltipData;

      // Update the tooltip to include cashier system and country code
      this.chartOptionsCashierSystemAndCountry.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
        const offlineRate = series[seriesIndex][dataPointIndex];
        const {
          cashierSystem,
          countryCode,
          offlineCount,
          totalCount
        } = w.config.customTooltipDataCashierSystemAndCountry[dataPointIndex];

        // Calculate alert based on rules
        let alert = false;
        let appliedRule = '';

        if (totalCount <= 50 && offlineRate >= 60) {
          alert = true;
        } else if (totalCount <= 200 && offlineRate >= 40) {
          alert = true;
        } else if (totalCount > 200 && offlineRate >= 20) {
          alert = true;
        }

        const highlightColor = alert ? 'red' : 'blue';

        return `
                <div>
                  <div><strong>Cashier System:</strong> ${cashierSystem}</div>
                  <div><strong>Country:</strong> ${countryCode}</div>
                  <div style="color:${highlightColor};"><strong>Offline Rate:</strong> ${offlineRate.toFixed(2)}%</div>
                  <div><strong>Offline Stations:</strong> ${offlineCount}</div>
                  <div><strong>Total Stations:</strong> ${totalCount}</div>
                  <div style="color:${highlightColor};"><strong>Alert:</strong> ${alert ? 'True' : 'False'}</div>
                  <div><strong>Alert Rules:</strong></div>
                  <ul>
                    <li${totalCount <= 50 ? ` style="color:${highlightColor};"` : ''}>Stations 1 - 50, Offline Rate >= 60%</li>
                    <li${totalCount <= 200 ? ` style="color:${highlightColor};"` : ''}>Stations 51 - 200, Offline Rate >= 40%</li>
                    <li${totalCount > 200 ? ` style="color:${highlightColor};"` : ''}>Stations 201+, Offline Rate >= 20%</li>
                  </ul>
                </div>
              `;
      };

      nextTick(() => {
        this.chartKeyCashierSystemAndCountry += 1;
      });
    },

    groupBy(array, ...keys) {
      return array.reduce((result, currentValue) => {
        const compositeKey = keys.map(key => currentValue[key]).join('|');
        if (compositeKey && compositeKey.trim() !== '') {
          (result[compositeKey] = result[compositeKey] || []).push(currentValue);
        }
        return result;
      }, {});
    },

  },
  mounted() {
    const {uid} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.fetchOfflineStations();
  },
  watch: {
    guardianCheckId(newVal) {
      if (newVal) {
        this.$forceUpdate();
      }
    }
  },
  computed: {
    formattedGuardianCheckId() {
      if (this.guardianCheckId) {
        const raw = this.guardianCheckId;
        const formatted = `${raw.substring(0, 4)}-${raw.substring(4, 6)}-${raw.substring(6, 8)} ${raw.substring(8, 10)}:${raw.substring(10, 12)}:${raw.substring(12, 14)}.${raw.substring(14)}`;
        return formatted;
      }
      return '';
    },
    hasGuardianCheckId() {
      return !!this.guardianCheckId;
    },
    totalOfflineStations() {
      return this.offlineStations.length;
    }
  },
};
</script>

<style scoped>
/* Add any styles you need here */
</style>
