<template>
  <div class="row">

    <!-- card start-->
    <div class="card">
      <div class="card-body">

        <div class="row">
          <div class="col-md-8">
            This tab offers a quick snapshot of recent order activities, helping you gauge short-term changes and patterns in order volume.
          </div>
          <div class="col-md-2">
            <button @click="copyShareableLink" class="btn btn-info rounded-pill m-l-5"
                    data-bs-toggle="popover"
                    data-bs-placement="left"
                    data-bs-title="Information"
                    title="Click this button to generate a full URL, including your input fields, making it easy to share with others."
                    data-bs-content="Click this button to generate a full URL, including your input fields, making it easy to share with others.">
              <i class="fa fa-share"></i> Share
            </button>
          </div>
        </div>

        <div v-if="this.loggedInUserGroup === 'ryd' ">
          <div class="row">
            <div class="col-md-4 pe-1">
              <span>Select the partner whose metrics you want to view: </span>
            </div>
            <div class="col-md-3">
              <select class="form-control border border-primary min-width-max-content" id="partnerMetricsFor" v-model="partnerMetricsFor"
                      required>
                <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                  {{ partnerName }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="this.loggedInUserGroup === 'ryd' ">
          <i class="icofont icofont-hand-right font-success">&nbsp;</i>Selecting <b>ryd</b> includes orders created by all partners and clients.
        </div>
        <div v-if="this.loggedInUserGroup === 'parkopedia' ">
          <i class="icofont icofont-hand-right font-success">&nbsp;</i>Selecting <b>parkopedia</b> includes orders created by
          parkopedia_bmw and parkopedia_skoda.
        </div>

      </div>
    </div>
    <!-- card end-->

    <!-- Loading Indicator START -->
    <div v-if="isLoadingOrderCreationStatisticsLast2Hours" class="loading-indicator">
      <h6 class="sub-title mb-0 text-center">Loading ... Recent 2-Hour Order Creation Snapshot</h6>
      <div class="loader-box">
        <div class="loader-17"></div>
      </div>
    </div>
    <!-- Loading Indicator END -->

    <!--  CARD 1 START -->
    <div class="col-sm-12 col-md-4" v-if="!isLoadingOrderCreationStatisticsLast2Hours">
      <div class="card card-absolute widget-1">
        <div class="card-header bg-primary">
          <h6 class="text-white">Recent 2-Hour Order Creation Snapshot</h6>
        </div>
        <div class="card-body m-t-35">
          <div class="widget-content">
            <div class="widget-round secondary">
              <div class="bg-round">
                <svg class="svg-fill">
                  <use href="@/assets/svg/icon-sprite.svg#cart"></use>
                </svg>
                <svg class="half-circle svg-fill">
                  <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
                </svg>
              </div>
            </div>
            <div>
              <h4>{{ OrderCreationStatisticsLast2Hours.totalNumberOfResults }}</h4><span class="f-light">Orders</span>
            </div>
          </div>
        </div>


        <!-- BUTTON START-->
        <br/>
        <div class="row" v-if="loggedInUserIsRyd">
          <div class="col">
            <div
                v-if="OrderCreationStatisticsLast2Hours && OrderCreationStatisticsLast2Hours.graylogQueryUrl">
              <a :href="OrderCreationStatisticsLast2Hours.graylogQueryUrl" target="_blank"
                 class="btn btn-pill btn-primary btn-lg" title="Go To Graylog Query">
                Go To Graylog Query to See More Details
              </a>
            </div>
          </div>
        </div>
        <!-- BUTTON END-->

      </div>
    </div>

    <!--  CARD 1 END -->

    <!-- Loading Indicator START -->
    <div v-if="isLoadingOrderCreationStatisticsLast2Days" class="loading-indicator">
      <h6 class="sub-title mb-0 text-center">Loading ... Last 48-Hour Order Creation Summary</h6>
      <div class="loader-box">
        <div class="loader-18"></div>
      </div>
    </div>

    <!-- Loading Indicator END -->
    <!--  CARD START -->
    <div class="col-sm-12 col-md-4" v-if="!isLoadingOrderCreationStatisticsLast2Days">
      <div class="card card-absolute widget-1">
        <div class="card-header bg-success">
          <h6 class="text-white">Last 48-Hour Order Creation Summary</h6>
        </div>
        <div class="card-body m-t-35">
          <div class="widget-content">
            <div class="widget-round secondary">
              <div class="bg-round">
                <svg class="svg-fill">
                  <use href="@/assets/svg/icon-sprite.svg#cart"></use>
                </svg>
                <svg class="half-circle svg-fill">
                  <use href="@/assets/svg/icon-sprite.svg#halfcircle"></use>
                </svg>
              </div>
            </div>
            <div>
              <h4>{{ OrderCreationStatisticsLast2Days.totalNumberOfResults }}</h4><span class="f-light">Orders</span>
            </div>
          </div>
        </div>


        <!-- BUTTON START-->
        <br/>
        <div class="row" v-if="loggedInUserIsRyd">
          <div class="col">
            <div
                v-if="OrderCreationStatisticsLast2Days && OrderCreationStatisticsLast2Days.graylogQueryUrl">
              <a :href="OrderCreationStatisticsLast2Days.graylogQueryUrl" target="_blank"
                 class="btn btn-pill btn-success btn-lg" title="Go To Graylog Query">
                Go To Graylog Query to See More Details
              </a>
            </div>
          </div>
        </div>
        <!-- BUTTON END-->

      </div>

    </div>
    <!--  CARD END -->


  </div>
</template>
<script>
import axios from "axios";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import getParamsFromHash from "@/utils/urlUtils";
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";

export default {
  name: 'DashboardRequestStatsTabRequests',
  props: {
    partnerNames: []
  },
  data() {
    return {
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      loggedInUserIsRyd: false,
      OrderCreationStatisticsLast2Hours: {},
      OrderCreationStatisticsLast2Days: {},
      isLoadingOrderCreationStatisticsLast2Hours: false,
      isLoadingOrderCreationStatisticsLast2Days: false,
    }
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    this.loggedInUserIsRyd = isUserGroupRyd;

    let paramsFromHash = getParamsFromHash(window.location.hash);
    this.partnerMetricsFor = paramsFromHash.partnerMetricsFor || this.loggedInUserGroup;

    this.initDataFetch();
  },
  watch: {
    partnerMetricsFor(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchOrderCreationStatisticsLast2Hours();
        this.fetchOrderCreationStatisticsLast2Days();
      }
    },
  },
  methods: {
    async fetchOrderCreationStatisticsLast2Hours(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingOrderCreationStatisticsLast2Hours = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/metrics-nr-of-created-orders`, {
          params: {
            to: new Date().toISOString(),
            from: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId
          }
        });

        this.OrderCreationStatisticsLast2Hours = response.data;
        //console.log(response.data);

      } catch (error) {
        console.error('Error fetching API Gateway HTTP Requests:', error);
        // Handle error appropriately
      } finally {
        this.isLoadingOrderCreationStatisticsLast2Hours = false;
      }
    },
    async fetchOrderCreationStatisticsLast2Days(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingOrderCreationStatisticsLast2Days = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/metrics-nr-of-created-orders`, {
          params: {
            to: new Date().toISOString(),
            from: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).toISOString(),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId
          }
        });

        this.OrderCreationStatisticsLast2Days = response.data;
        //console.log(response.data);

      } catch (error) {
        console.error('Error fetching API Gateway HTTP Requests:', error);
        // Handle error appropriately
      } finally {
        this.isLoadingOrderCreationStatisticsLast2Days = false;
      }
    },
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    async initDataFetch() {
      try {
        await Promise.all([
          this.fetchOrderCreationStatisticsLast2Hours(),
          this.fetchOrderCreationStatisticsLast2Days()
        ]);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // Handle the error appropriately
      }
    },

    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname + window.location.hash;
      const queryParams = new URLSearchParams({
        partnerMetricsFor: this.partnerMetricsFor?.trim() || 'ryd',
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('It has been successfully copied to the clipboard as a complete link and can now be components.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },

  }
}
</script>
<style scoped>
.border-right-column {
  border-right: 1px solid #dee2e6; /* Adjust color as needed */
}

.widget-1 {
  background-image: url("~@/assets/images/dashboard/widget-background-white-m.png");
  background-size: cover;
  margin-bottom: 25px;
}
</style>
