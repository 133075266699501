<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Edit Cashier System Configuration"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="m-t-20 mb-0 text-center">Loading Cashier System Configuration for {{configurationId}} ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main form content -->
        <div class="col-sm-12" v-if="!isLoading">
          <div class="card">
            <div class="card-header bg-primary">
              <h5>Edit Cashier System Configuration</h5>
            </div>
            <div class="card-body">
              <div class="form theme-form">
                <form @submit.prevent="updateConfiguration" ref="form">

                  <div class="row fw-bolder f-w-900">
                    <p v-if="error" class="error-message m-b-25">{{ error }}</p>
                  </div>

                  <!-- Cashier System Name Input (non-editable) -->
                  <div class="row border-bottom m-b-20">
                    <div class="col">
                      <div class="mb-3">
                        <label for="cashierSystemName" class="fw-bold">Cashier System Name</label>
                        <input type="text" class="form-control" v-model="existingConfiguration.cashierSystemName" disabled>
                      </div>
                    </div>
                  </div>

                  <!-- country -->
                  <div class="row border-bottom m-b-20">
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="country" class="fw-bold">Country</label>
                        <select v-model="existingConfiguration.country"
                                class="form-control border border-primary"
                                :class="{ 'is-invalid': countryError }"
                                id="country"
                                ref="countrySelect"
                                @change="validateCountry">
                          <option value="">Select a country</option>
                          <option v-for="country in europeanCountries"
                                  :key="country.value"
                                  :value="country.value">
                            {{ country.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback" v-if="countryError">
                          Please select a country.
                        </div>
                      </div>
                    </div>
                  </div>


                  <!-- Email Configurations Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Email Configurations</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addEmailConfiguration">Add Email</button>
                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Department</th>
                            <th>Note</th>
                            <th>Alert Type</th>
                            <th>Active</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(email, index) in existingConfiguration.emailConfigurations" :key="index">
                            <td><input type="text" v-model="email.email" class="form-control" placeholder="Email"></td>
                            <td><input type="text" v-model="email.firstName" class="form-control" placeholder="First Name"></td>
                            <td><input type="text" v-model="email.lastName" class="form-control" placeholder="Last Name"></td>
                            <td><input type="text" v-model="email.department" class="form-control" placeholder="Department (optional)"></td>
                            <td><textarea v-model="email.note" class="form-control" placeholder="Note"></textarea></td>
                            <td>
                              <select v-model="email.alertType" class="form-control">
                                <option value="EscalationLevel1">Escalation Level 1</option>
                                <option value="EscalationLevel2">Escalation Level 2</option>
                                <option value="EscalationLevel3">Escalation Level 3</option>
                              </select>
                            </td>
                            <td>
                              <input type="checkbox" v-model="email.isActive">
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeEmailConfiguration(index)">Remove</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- Links Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Links</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addLink">Add Link</button>
                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Description</th>
                            <th>Link Type</th>
                            <th>Link URL</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(link, index) in existingConfiguration.links" :key="index">
                            <td><input type="text" v-model="link.description" class="form-control" placeholder="Description"></td>
                            <td>
                              <select class="form-control" v-model="link.linkType">
                                <option value="STATUS_PAGE">STATUS_PAGE</option>
                                <option value="HOMEPAGE">HOMEPAGE</option>
                                <option value="OTHERS">OTHERS</option>
                              </select>
                            </td>
                            <td><input type="text" v-model="link.link" class="form-control" placeholder="Link URL"></td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeLink(index)">Remove</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- Downtimes Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Downtimes</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addDowntime">Add Downtime</button>
                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Note</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(downtime, index) in existingConfiguration.downtimes" :key="index">
                            <td><input type="datetime-local" v-model="downtime.startDate" class="form-control"></td>
                            <td><input type="datetime-local" v-model="downtime.endDate" class="form-control"></td>
                            <td><textarea v-model="downtime.note" class="form-control" placeholder="Note"></textarea></td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeDowntime(index)">Remove</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- Alert Disabled Time Ranges -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Alert Disabled Time Ranges</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addAlertDisabledTimeRanges">Add Alert Disabled Time Ranges</button>
                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Note</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(alertDisabledTimeRange, index) in existingConfiguration.alertDisabledTimeRanges" :key="index">
                            <td><input type="datetime-local" v-model="alertDisabledTimeRange.startDate" class="form-control"></td>
                            <td><input type="datetime-local" v-model="alertDisabledTimeRange.endDate" class="form-control"></td>
                            <td><textarea v-model="alertDisabledTimeRange.note" class="form-control" placeholder="Note"></textarea></td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeAlertDisabledTimeRange(index)">Remove</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <!-- Email Language -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="emailLanguage" class="fw-bold">Email Language</label>
                        <select class="form-control border border-primary"
                                id="emailLanguage"
                                v-model="existingConfiguration.emailLanguage"
                                :class="{ 'is-invalid': emailLanguageError }"
                                ref="emailLanguageSelect"
                                @change="validateEmailLanguage"
                        >
                          <option value="">Select a language...</option>
                          <option value="EN">English</option>
                          <option value="DE">German</option>
                          <option value="PT">Portuguese</option>
                        </select>
                        <div class="invalid-feedback" v-if="emailLanguageError">
                          Please select an email language.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Submit Button -->
                  <div class="row">
                    <div class="col">
                      <button class="btn btn-pill btn-success btn-air-success active btn-lg" type="submit"
                              title="Update configuration">
                        <i class="fa fa-save m-r-15"></i>Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  PARTNER_ANALYTICS_API_KEY,
  PARTNER_ANALYTICS_SERVER_URL,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL,
  RPP_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import { EUROPEAN_COUNTRIES } from '@/constants/constants';
import {sharedConstantsMixin} from "@/mixins/sharedConstantsMixin";


export default {
  name: 'EditCashierSystemConfiguration',
  keywords: ['Edit Alert Configuration', 'Modify Alert Settings', 'Update Cashier System Alerts', 'Edit System Alert Config', 'Change Alert Configuration', 'Adjust Alert Settings', 'Edit Alert Configurations'],
  mixins: [sharedConstantsMixin],
  data() {
    return {
      existingConfiguration: {
        cashierSystemName: '',
        country: '',
        emailConfigurations: [],
        links: [],
        downtimes: [],
        alertDisabledTimeRanges: [],
        emailLanguage: '',
      },
      isLoading: false,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      configurationId: null,
      europeanCountries: EUROPEAN_COUNTRIES,
      formIsValid: true,
      countryError: false,
      emailLanguageError: false,
    };
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    this.configurationId = this.$route.query.cashierSystemConfigurationId;
    if (!this.configurationId) {
      // Redirect to the list page if the ID is not provided
      this.$router.push('/guardian-system-and-alerts/cashier-system-configuration/list?originFromPage=edit');
    } else {
      this.fetchConfiguration();
    }
  },
  methods: {
    validateCountry() {
      this.countryError = !this.existingConfiguration.country;
    },
    validateEmailLanguage() {
      this.emailLanguageError = !this.existingConfiguration.emailLanguage;
    },
    fetchConfiguration() {
      this.isLoading = true;
      axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations/${this.configurationId}`, {
        headers: {
          'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
          'uid': this.loggedInUserId
        }
      }).then(response => {
        this.existingConfiguration = response.data.data;
        this.isLoading = false;
      }).catch(error => {
        this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to fetch configuration';
        this.isLoading = false;
      });
    },
    updateConfiguration() {

      // Perform validations
      this.validateCountry();
      this.validateEmailLanguage();

      // Check if there are any errors
      const errors = [];
      if (this.countryError) errors.push('country');
      if (this.emailLanguageError) errors.push('emailLanguage');

      // If there are errors, scroll into view and focus on the first error
      if (errors.length > 0) {
        this.$nextTick(() => {
          const firstError = errors[0];
          const refName = `${firstError}Select`;
          this.$refs[refName].scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.$refs[refName].focus();
        });
        return;
      }

      this.isLoading = true;
      axios.put(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations/${this.configurationId}?country=${this.existingConfiguration.country}`, this.existingConfiguration, {
        headers: {
          'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
          'uid': this.loggedInUserId
        }
      }).then(() => {
        this.$router.push('/guardian-system-and-alerts/cashier-system-configuration/list');
      }).catch(error => {
        this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to update configuration';
      }).finally(() => {
        this.isLoading = false;
      });
    },
    addEmailConfiguration() {
      this.existingConfiguration.emailConfigurations.push({
        email: '',
        firstName: '',
        lastName: '',
        department: '',
        note: '',
        alertType: 'EscalationLevel1',
        isActive: false
      });
    },
    removeEmailConfiguration(index) {
      this.existingConfiguration.emailConfigurations.splice(index, 1);
    },
    addLink() {
      this.existingConfiguration.links.push({
        description: '',
        linkType: '',
        link: ''
      });
    },
    removeLink(index) {
      this.existingConfiguration.links.splice(index, 1);
    },
    addDowntime() {
      this.existingConfiguration.downtimes.push({
        startDate: '',
        endDate: '',
        note: ''
      });
    },
    removeDowntime(index) {
      this.existingConfiguration.downtimes.splice(index, 1);
    },
    addAlertDisabledTimeRanges() {
      this.existingConfiguration.alertDisabledTimeRanges.push({
        startDate: '',
        endDate: '',
        note: ''
      });
    },
    removeAlertDisabledTimeRange(index) {
      this.existingConfiguration.alertDisabledTimeRanges.splice(index, 1);
    },
  }
};
</script>


<style scoped>

</style>