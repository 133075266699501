<template>
  <Breadcrumbs main="Troubleshooting" title="Missing Successful invoice API Call"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-12">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Search Date Range:
                  </div>
                  <div class="col d-flex align-items-center">
                    <label class="me-2" for="fromTimestamp">From:</label>
                    <input v-model="fromTimestamp" type="date" class="form-control"
                           id="fromTimestamp" placeholder="From Timestamp" :min="minDate">
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">To:</label>
                    <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                  </div>

                  <div class="col">
                    <button @click="refreshMissingInvoiceApiCallsTable"
                            class="btn btn-pill btn-success btn-air-success active input-group-text">
                      <i class="fa fa-send m-r-10"></i> Refresh Missing Invoice API Calls Table
                    </button>
                  </div>

                  <div class="col"
                       v-if="!isLoadingMissingIncoiceApiCallsResponse && missingInvoiceApiCallsResponse && missingInvoiceApiCallsResponse.numberOfResults > 0">
                    <b>{{ missingInvoiceApiCallsResponse.numberOfResults }} rows found.</b>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 END -->
            </div>
          </div>
        </div>
        <!-- CARD 1 END -->


        <!-- Loading Indicator START -->
        <div v-if="isLoadingMissingIncoiceApiCallsResponse" class="loading-indicator">
          <h6 class="sub-title mb-0 text-center">Loading... Missing Invoice API Calls</h6>
          <div class="loader-box">
            <div class="loader-17"></div>
          </div>
        </div>
        <!-- Loading Indicator END -->


        <!-- CARD 2 START -->
        <div class="card"
             v-if="!isLoadingMissingIncoiceApiCallsResponse && missingInvoiceApiCallsResponse && missingInvoiceApiCallsResponse.numberOfResults > 0">
          <div class="card-body">
            <div class="table-responsive product-table">
              <table class="table table-bordernone table-striped display" id="basic-1">
                <thead class="bg-primary text-white">
                <tr>
                  <th class="bg-primary" scope="col">
                    Error Type
                  </th>
                  <th class="bg-primary" scope="col">
                    Order Completion Timestamp (UTC)
                  </th>
                  <th class="bg-primary" scope="col">
                    Order Uuid at Ryd
                  </th>
                  <th class="bg-primary" scope="col">
                    Order Completion Type
                  </th>
                  <th class="bg-primary" scope="col">
                    HTTP Response Status Codes
                  </th>
                  <th class="bg-primary" scope="col">
                    Owner of Order
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="missingInvoiceApiCallItem in missingInvoiceApiCallsResponse.items"
                    :key="missingInvoiceApiCallItem">
                  <td>{{ missingInvoiceApiCallItem.issueType }}</td>
                  <td>
                    {{ missingInvoiceApiCallItem.timestamp }}
                  </td>
                  <td>{{ missingInvoiceApiCallItem.orderUuid }}</td>
                  <td>{{ missingInvoiceApiCallItem.eventType }}</td>
                  <td>{{ missingInvoiceApiCallItem.responseStatuses.join(', ') }}</td>
                  <td>{{ missingInvoiceApiCallItem.userGroup }}</td>

                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- CARD 2 END -->
        <div class="card" v-if="missingInvoiceApiCallsResponse && missingInvoiceApiCallsResponse.infoMessage">
          <div class="card-body">
            <div class="text-center fw-bold fs-6 font-success" v-html="missingInvoiceApiCallsResponse.infoMessage">

            </div>
          </div>
        </div>

        <div class="card" v-if="missingInvoiceApiCallsResponse && missingInvoiceApiCallsResponse.errorMessage">
          <div class="card-body">
            <div class="text-center fw-bold fs-6 font-danger">
              {{ this.missingInvoiceApiCallsResponse.errorMessage }}
            </div>
          </div>
        </div>

        <!-- card 3 start-->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-info">
            <h5 class="text-white">Important Notes</h5>
          </div>

          <div class="card-body">
            <ul class="list-group">

              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Valid Search Date Range:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">Ensure you specify a valid search date range where
                  the interval between the 'From' and 'To' dates <b>does not exceed 30 days</b>, to maintain optimal
                  search performance.
                </li>
              </ul>

              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Search From Date Range:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">Please note that Ryd retains logs for the last six months only. Keep in mind
                  that logging of HTTP requests started on November 11, 2023. Therefore, ensure that your search dates
                  fall within this accessible range for accurate results
                </li>
              </ul>

              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Process for Identifying Missing Invoice API
                Calls:</h5>
              <ul class="list-group">
                <li class="list-group-item">We first identify orders created by your account that have been successfully
                  completed, as
                  indicated by the final order states: <code>COMPLETED</code> or <code>COMPLETED_MANUALLY</code>. We
                  then try to search
                  all <code>GET /v4/invoices/:uuid</code> API calls corresponding to each specific order UUID. These
                  searches are
                  performed within an extended date range for thoroughness: The 'from' date is your selected start date
                  minus 1 day, and the 'to' date is your selected end date
                  plus 1 day.
                </li>

                <br/>
                <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Error Types Explanation:
                </h5>
                <ul class="list-group">
                  <li class="list-group-item">There are two error types to be aware of:
                    <code>NO_INVOICE_CALL_FOUND</code> and <code>ONLY_FAILED_INVOICE_CALLS</code>.
                  </li>
                  <li class="list-group-item">
                    <b>NO_INVOICE_CALL_FOUND</b>: Indicates that no <code>GET /v4/invoices/:uuid</code> API call was
                    triggered for the specific order UUID within the extended date range.
                  </li>
                  <li class="list-group-item">
                    <b>ONLY_FAILED_INVOICE_CALLS</b>: This means that while there were <code>GET
                    /v4/invoices/:uuid</code>
                    API calls for the specific order UUID within the extended date range.
                    But all such calls failed. Note that if even one of these API calls is successful, the order is
                    considered
                    successful and will not be listed on this page.
                  </li>
                </ul>

                <br/>
                <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Understanding the 'HTTP Response Status
                  Codes' Column:
                </h5>
                <ul class="list-group">
                  <li class="list-group-item">In the "HTTP Response Status Codes" column, you'll find a comprehensive
                    list of all HTTP response codes from failed <code>GET /v4/invoices/:uuid</code> API calls for the specific order UUID.
                  </li>
                  <li class="list-group-item">
                    <b>404</b>: This code indicates that no invoice could be found for the specified order UUID at the time the <code>GET /v4/invoices/:uuid</code> API was executed.
                  </li>
                  <li class="list-group-item">
                    In the case of a 404, please check <a href="/platform-status/past-incidents">the past incidents page</a> to see if there was an invoice service disruption/incident at a particular time.
                  </li>



                </ul>


              </ul>


            </ul>
          </div>
        </div>
        <!-- card 3 end-->


      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";

let primary = localStorage.getItem('primary_color') || '#24695c';
let secondary = localStorage.getItem('secondary_color') || '#ba895d';

export default {
  keywords: ['Invoice', 'Invoice API', 'Invoice API Calls', 'Missing Invoice'],
  data() {
    return {
      showAlertTimestamps: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      isLoadingMissingIncoiceApiCallsResponse: false,
      minDate: '2023-11-10',
      missingInvoiceApiCallsResponse: {
        numberOfResults: 0,
        sortedResult: [],
        errorMessage: null,
        infoMessage: null
      },
    }
  },
  mounted() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.partnerMetricsFor = userGroup;
    this.initializeParameters();
    this.checkAndTriggerSearch();
    this.fetchMissingInvoiceApiCalls(this.fromTimestamp, this.toTimestamp);
  },
  methods: {
    async refreshMissingInvoiceApiCallsTable() {
      if (!this.validateTimestamps()) {
        this.showAlertTimestamps = true;
        return;
      }

      this.showAlertTimestamps = false;
      this.isLoadingMissingIncoiceApiCallsResponse = true;
      await this.fetchMissingInvoiceApiCalls(this.fromTimestamp, this.toTimestamp);
    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sevenDays = 30 * 24 * 60 * 60 * 1000;
      if (to - from > sevenDays) {
        this.showAlertTimestamps = true;
        this.missingInvoiceApiCallsResponse.infoMessage = null;
        this.missingInvoiceApiCallsResponse.errorMessage = "Ensure you specify a valid search date range where the interval between the 'From' and 'To' dates does not exceed 30 days, to maintain optimal search performance.";
        this.missingInvoiceApiCallsResponse.numberOfResults = 0;
        this.missingInvoiceApiCallsResponse.sortedResult = [];
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    addXDayAndConvertDateToISOString(date, days) {
      if (date) {
        let dateInputValue = new Date(date);
        dateInputValue.setDate(dateInputValue.getDate() + days);
        return dateInputValue.toISOString();
      }
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 14);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate());
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    initializeParameters() {
      const queryParams = this.$route.query;
      this.fromTimestamp = queryParams.from || this.getDefaultFromDate();
      this.toTimestamp = queryParams.to || this.getDefaultToDate();

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to) {
        this.refreshMissingInvoiceApiCallsTable();
      }
    },
    async fetchMissingInvoiceApiCalls(fromTimestamp, toTimestamp) {
      this.isLoadingMissingIncoiceApiCallsResponse = true;
      this.missingInvoiceApiCallsResponse.errorMessage = null;
      this.missingInvoiceApiCallsResponse.infoMessage = null;

      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/missing-invoice-api-calls`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.addXDayAndConvertDateToISOString(toTimestamp, 1),
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.missingInvoiceApiCallsResponse = {};
          this.missingInvoiceApiCallsResponse.errorMessage = "A technical error occurred.";
        } else {
          this.missingInvoiceApiCallsResponse = response.data;
        }

        if (this.missingInvoiceApiCallsResponse && this.missingInvoiceApiCallsResponse.numberOfResults === 0) {
          this.missingInvoiceApiCallsResponse.infoMessage = "<h4>Success Confirmed!</h4> <br/>" +
              "We've thoroughly reviewed the selected date range and are pleased to report that all completed orders have successfully triggered Invoice API calls.";
        }

      } catch (error) {
        this.missingInvoiceApiCallsResponse.errorMessage = "A technical error occurred.";
        console.error('Error fetchMissingInvoiceApiCalls:', error);
      } finally {
        this.isLoadingMissingIncoiceApiCallsResponse = false;
      }
    },
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>
<style scoped>
@import "@/assets/css/productlist.css";
</style>
<style>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}
</style>
