<template>
  <Breadcrumbs main="Integration Guide" title="Integration Overview"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Integration Approaches</h5>
          </div>
          <div class="card-body">
            <p>Welcome to our Integration Overview! We provide a range of integration approaches suitable for partners
              with or without native apps. <br/> Below is an overview.</p>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>API Integration</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd's REST APIs support top-up and car wash processes, offering a streamlined way to integrate these
                services into your systems.
              </li>
              <li class="list-group-item">
                See <a href="/integration-guide/rydpay-apis/introduction">this section</a> for more details.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Webapp Integration</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd's web application, built with Vue.js, allows customers to register, top-up, and access car wash
                services. Partners can integrate this web app via Iframes or WebViews, with tracking functionalities for
                partner activities.
              </li>
              <li class="list-group-item">
                See <a href="/integration-guide/webapp-integration/introduction">this section</a> for more details.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Registration Deeplink</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Partners can generate a customizable deeplink (and QR code) for user registrations. This supports
                tracking across platforms like desktop, iOS, and Android, facilitating user onboarding.
              </li>

              <li class="list-group-item">
                Once a user completes registration via this deeplink, Ryd captures and saves the user's tracking and
                origin details. This automatic data capture enables Ryd to immediately activate specific campaigns,
                vouchers, or discounts associated with the partner.
              </li>
              <li class="list-group-item">
                See <a href="/integration-guide/deeplinking-registration/getting-started">this section</a> for more
                details.
              </li>
            </ul>
            <br/>



            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Voucher Code</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd provides voucher codes for partners to assign to customers. These codes can be used in Ryd's web or
                mobile apps for registration, linking to the partner, and availing discounts.
              </li>
              <li class="list-group-item">
                Further details will be provided shortly.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>SDK</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd's SDK simplifies the use of APIs for top-ups and car wash services. It handles API usage, retries,
                error and edge case management, and timeouts, providing a more robust solution compared to direct API
                integration.
              </li>
              <li class="list-group-item">
                Further details will be provided shortly.
              </li>
            </ul>
            <br/>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Integration Comparison</h5>
          </div>
          <div class="card-body">
            <br/>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-success text-white">
                <tr>
                  <th class="bg-success">Integration Approach</th>
                  <th class="bg-success">Ease of Implementation</th>
                  <th class="bg-success">Customization Level</th>
                  <th class="bg-success">Technical Requirement</th>
                  <th class="bg-success">Testability</th>
                  <th class="bg-success">Ideal for</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>REST API</td>
                  <td>Moderate</td>
                  <td>High</td>
                  <td>High</td>
                  <td>High (Extensive testing possible)</td>
                  <td>Partners with technical expertise, requiring deep integration</td>
                </tr>
                <tr>
                  <td>Webapp Integration (IFrame/WebView)</td>
                  <td>Easy</td>
                  <td>Moderate</td>
                  <td>Low</td>
                  <td>High (Extensive testing possible)</td>
                  <td>Quick integrations</td>
                </tr>
                <tr>
                  <td>Registration Deeplink</td>
                  <td>Easy</td>
                  <td>Low</td>
                  <td>Moderate</td>
                  <td>Low (Native apps and app stores on staging not (publicly) available)</td>
                  <td>Direct user actions, both for partners with and without native apps</td>
                </tr>
                <tr>
                  <td>Voucher Code</td>
                  <td>Very Easy</td>
                  <td>Low</td>
                  <td>Very Low</td>
                  <td>Moderate (Native apps on staging not (publicly) available)</td>
                  <td>Promotional activities, simple transactional functionalities</td>
                </tr>
                <tr>
                  <td>SDK</td>
                  <td>Moderate</td>
                  <td>High</td>
                  <td>High</td>
                  <td>Moderate</td>
                  <td>Advanced features, partners with development capabilities</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "IntegrationOverview",
  keywords: ['API Integration', 'Webapp Integration', 'Registration Deeplink', 'Voucher Code', 'SDK', 'Integration Comparison', 'Integration Approaches', 'Approaches']
}
</script>