<template>
  <Breadcrumbs main="Dashboard" title="API Requests Analytics"/>

  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="card-header">
          <h5 class="pull-left">API Requests Analytics</h5>
        </div>
        <div class="card-body">
          <div class="tabbed-card">
            <ul class="pull-right nav nav-pills nav-primary" id="pills-clrtab" role="tablist">
              <li class="nav-item" v-on:click="changetab('apiRequestsSummary')"><a class="nav-link"
                                                                                   :class="{ 'show active': showtab === 'apiRequestsSummary' }"
                                                                                   id="api-requests-summary-tab"
                                                                                   data-bs-toggle="pill"
                                                                                   data-bs-placement="left"
                                                                                   data-bs-title="What is provided in this tab?"
                                                                                   data-bs-content="Offers error rate statistics for HTTP requests made directly by the account to the ryd API gateway at https://tt4.thinxcloud.de over the last 2 and 48 hours."
                                                                                   href="#api-requests-summary"
                                                                                   role="tab"
                                                                                   aria-controls="api-requests-summary"
                                                                                   aria-selected="true"><i
                  class="fa icofont icofont-chart-bar-graph"></i>API Requests Summary</a>
              </li>
              <li class="nav-item" v-on:click="changetab('apiRequestsFailureAnalysis')"><a class="nav-link"
                                                                                           :class="{ 'show active': showtab === 'apiRequestsFailureAnalysis' }"
                                                                                           id="api-requests-failure-analysis-tab"
                                                                                           data-bs-toggle="pill"
                                                                                           data-bs-placement="left"
                                                                                           data-bs-title="What is provided in this tab?"
                                                                                           data-bs-content="Offers a comprehensive analysis of error rates for HTTP requests with a status code greater than or equal to 400 compared to total requests. Gain insights into the frequency and details of failed HTTP requests, allowing for a deeper understanding of system performance and potential issues."
                                                                                           href="#api-requests-failure-analysis"
                                                                                           role="tab"
                                                                                           aria-controls="api-requests-failure-analysis"
                                                                                           aria-selected="false"><i
                  class="fa icofont icofont-chart-histogram"></i>API Requests Failure Analysis</a>
              </li>

            </ul>
          </div>

          <!-- TAB CONTENT BEGIN -->
          <div class="tab-content" id="pills-clrtabContent">
            <div class="tab-pane fade " :class="{'show active': showtab === 'apiRequestsSummary' }"
                 id="api-requests-summary"
                 role="tabpanel" aria-labelledby="api-requests-summary-tab">
              <DashboardRequestStatsTabRequests :partnerNames="partnerNames"/>
            </div>
            <div class="tab-pane fade" :class="{'show active': showtab === 'apiRequestsFailureAnalysis' }"
                 id="api-requests-failure-analysis"
                 role="tabpanel" aria-labelledby="api-requests-failure-analysis-tab">
              <div class="container-fluid">
                <DashboardRequestStatsTabDynamicDetails :partnerNames="partnerNames"/>
              </div>
            </div>
          </div>
          <!-- TAB CONTENT END -->

        </div>
      </div>
    </div>

  </div>


</template>
<script>
import DashboardRequestStatsTabRequests
  from "@/pages/dashboard/apiRequestsAnalytics/AraApiRequestsFailureAnalytics.vue";
import DashboardRequestStatsTabDynamicDetails from "@/pages/dashboard/apiRequestsAnalytics/AraApiRequesSummary.vue";

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";
import {hideAllPopovers, initializePopovers} from "@/utils/popoverUtils";
import getParamsFromHash from "@/utils/urlUtils";

export default {
  keywords: ['Monitoring', 'API Request', 'Failure', 'Analysis', 'Error Analysis'],
  components: {
    DashboardRequestStatsTabRequests,
    DashboardRequestStatsTabDynamicDetails
  },
  data() {
    return {
      showtab: 'apiRequestsSummary',
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      partnerNames: [],
    }
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
      this.loadPartnerNames(this.loggedInUserId);
    } else {
      this.partnerMetricsFor = userGroup;
    }
    this.checkUrlFragment();

    initializePopovers();

    window.addEventListener('beforeunload', hideAllPopovers);

  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', hideAllPopovers);
  },
  methods: {
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    checkUrlFragment() {
      let hash = window.location.hash;
      if (!hash) {
        // If there is no hash, set it to '#api-requests-summary'
        hash = '#api-requests-summary';
        window.location.hash = hash; // This will add the hash to the URL without reloading the page
      }

      if (hash) {
        // Extract tab id from URL fragment
        const [path, queryParams] = hash.slice(1).split('?'); // Remove '#' and split
        //console.log("tabId", path);
        const tabId = path;
        const tabMap = {
          'api-requests-summary': 'apiRequestsSummary',
          'api-requests-failure-analysis': 'apiRequestsFailureAnalysis'
        };
        // Change tab if mapped value exists
        if (tabMap[tabId]) {
          this.showtab = tabMap[tabId];
        }
      }
    },
    changetab(tab) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      this.showtab = tab;
    },
    async loadPartnerNames() {
      try {
        this.partnerNames = await fetchPartnerNames(this.loggedInUserId);
      } catch (error) {
        console.log("error", error);
      }
    },
  }
}

</script>


<style>
.tab-content-wrapper {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}
</style>