<template>
  <Breadcrumbs main="rydpay APIs" title="Terms & Definitions"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12" id="rydPayApisCards">
              <div class="col-sm-12 col-md-12" v-for="(card, index) in cards" :key="card.id">
                <a :href="card.link">
                  <div class="card card-absolute" :id="card.id">
                    <div :class="['card-header', getHeaderClass(index)]">
                      <h5 class="text-white">{{ card.title }}</h5>
                    </div>
                    <div class="card-body">
                      <ul class="list-group">
                        <li v-for="desc in card.description" :key="desc" class="list-group-item" v-html="desc"></li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {scrollToRootHash, scrollToWithOffet} from "@/utils/anchorUtils";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  keywords: ['Overshoot', 'PSP', 'Staging', 'POS','POI', 'Overshoot', 'Acquirer', 'PrePay','Automatic', 'PostPay', 'Manual', 'Fastlane','MTSK', 'MPGS',
    'NoOp', 'PoP','SCA', 'DGEG','CPO', 'Charge Point Operator','OCPI'],
  data() {
    return {
      cards: [
        {
          id: 'overshoot',
          title: 'Overshoot',
          description: [
            'In the context of <b>fuelling</b>, if a pump has been authorized with a certain amount the expected behavior from the pump controller is that the fuel dispenser stops automatically after a certain amount of fuel has been dispensed that is worth this specific amount. if the pump controller fails to stop the pump in time and more fuel has been dispensed ryd calls this scenario an overshoot. ',
            'In the context of <b>EV charging</b>, an overshoot occurs when the amount of energy delivered exceeds the initially authorized amount. Unlike fueling, EV charging stations do not have a mechanism to stop charging automatically at a pre-set monetary value. This can lead to more energy being delivered than what was originally authorized.',
            'In the <b>Car Wash</b>, there is no overshoot.',
            'This scenario must not affect the customer and he must be allowed to leave the petro or charging station. The respective order will transfer into the manual resolution state <code>PROBLEM</code> and has to be manually completed by the ryd\'s payment operations services personnel. ',
            'If the overshoot is within a certain limit ryd will cover the differential amount. If the amount is significant higher ryd or its partner will reach out to the customer for an additional payment.',

          ],
          link: '#overshoot' // This could be a URL or an anchor
        },
        {
          id: 'poi',
          title: 'POI or POS',
          description: [
            '"POI" stands for "Point of Interest" or "POS" for "Point of Service" and both refer to a energy or auxiliary service station, car wash, or EV charging point.',
            'There are two APIs for interacting with POIs: <br/> <strong>List API:</strong> Retrieves general information about POIs within a given geographic radius. <br/> <strong>Details API:</strong> Provides comprehensive details about a specific POI. <br/> For further details on these APIs, visit <a href="https://partner-portal.rydcloud.deu/integration-guide/rydpay-apis/api-reference#/production">API Reference</a>.'
          ],
          link: '#poi'
        },
        {
          id: 'staging-environment',
          title: 'Staging Environment',
          description:
              [
                'The Staging environment, also called sandbox env is set up to closely resemble the real production environment, enabling comprehensive testing of code, builds, and updates prior to their deployment in the live application. In this simulated setting, all petrol and wash stations are replicated, allowing for testing without generating any actual transactions, payments, or invoices. This ensures a safe and controlled environment for validation and quality assurance.',
              ],
          link: '#staging-environment'
        },
        {
          id: 'PSP',
          title: 'Payment Service Provider (PSP)',
          description:
              [
                'The ryd ecosystem hosts its own payment orchestration layer and therefore supports multiple Payment Service Provider (PSP) integrations. <br>Each of them is used to process payment transactions for one or multiple use cases. Usually the PSP provides an unified interface and exposes multiple different payment methods in a unified way towards ryd. PSPs usually take care about the following services: Payment method tokenization, customer authentication, authorization of a specific amount on the payment method and the settlement via the corresponding acquirer. It is quite common that PSPs provide additional services on top like, reporting and reconciliation features, smart routing of transactions or individual identity detection and fraud prevention services.',
              ],
          link: '#psp'
        },
        {
          id: 'acquirer',
          title: 'Acquirer',
          description:
              [
                'Usually the final step of a payment transaction is the settlement where funds are collected from the buyer and aggregated on the acquiring bank in order to get payed out to a merchant like ryd. So the acquirer is usually integrated in the PSP via an acquiring link configured on a specific merchant configuration. This configuration will determine what currencies are supported and to which bank account the funds are directed towards.',
              ],
          link: '#acquirer'
        },
        {
          id: 'prepay',
          title: 'PrePay (Automatic) Flow',
          description:
              [
                'A Prepaid order/flow, also known as the "automatic" order/flow. <br>Please have a look into the guides section for more details.',
              ],
          link: '#prepay'
        },
        {
          id: 'postpay',
          title: 'PostPay (Manual) Flow',
          description:
              [
                'Postpaid transactions, or the "manual flow," offer a more traditional approach to purchasing. <br>Please have a look into the guides section for more details.',
              ],
          link: '#postpay'
        },
        {
          id: 'fastlane',
          title: 'Fastlane',
          description:
              [
                'An indicator within the ryd pay APIs to identify whether a returned POI (POS) supports ryd pay checkout. You can apply this filter to your API call in order to retrieve only mobile checkout capable stations.',
              ],
          link: '#fastlane'
        },
        {
          id: 'mtsk',
          title: 'MTSK (MTS-K)',
          description:
              [
                'The "Markttransparenzstelle für Kraftstoffe" or in  English the "Market Transparency Centre for Fuels" (MTS-K) at the Federal Cartel Office enables consumers to find out the current fuel prices at all petrol stations in deu at any time.',
              ],
          link: '#mtsk'
        },
        {
          id: 'MPGS',
          title: 'Mastercard Payment Gateway Services (MPGS)',
          description:
              [
                'The Mastercard Payment Gateway Services is a PSP connected to ryd\'s payment ecosystem and can process all varieties of payment methods, mainly credit cards.',
              ],
          link: '#mpgs'
        },
        {
          id: 'noop',
          title: 'NoOp Station(s)',
          description:
              [
                'NoOp is an abbreviation ryd use to express a station configuration that is connected to an energy product simulator instead of the real supplier service. The simulator exposes a variety of products with pre-configured behavior that can be used to test happy or unhappy paths easily without the requirement to obtain energy onsite a POI.',
              ],
          link: '#noop'
        },
        {
          id: 'popadatperandservice',
          title: 'PoP service and adapter',
          description:
              [
                'In the ryd micro service ecosystem we have the point of purchase (PoP) service which maintains several connected services to each individual supplier interface. This connection services ryd calls PoP adapters. ',
              ],
          link: '#popadatperandservice'
        },
        {
          id: 'SCA',
          title: 'Strong customer authentication (SCA)',
          description:
              [
                'The current legislative enforces all merchants processing e-commerce transaction where the customer is present to offer his issuing bank the option to strongly authenticate the purchase. Present means the customer is in front of the checkout screen and has the ability to authenticate himself with a "frictionless" flow where just device fingerprint data is obtained and passed to the Access Control Server (ACS) of the issuer (customers bank). If the first check fails the issuer can challenge the transaction leading to the know 2factor authentication step where customers are asked depending on their bank to enter additional data obtained via a different channel in order to proof they are really the person they state to be.<br>In ryd we only process full SCA e-commerce traffic to minimize the risks of potential future fraud and the resulting chargebacks. Our main objective is to establish SCA within the most convenient payment flows leveraging delegated authentication for the ease of use similar to Google and Apple Pay.',
              ],
          link: '#sca'
        },
        {
          id: 'DGEG',
          title: 'Direção-Geral deu Energia e Geologia (DGEG)',
          description:
              [
                'Similar to the German MTS-K the Portuguese regulatory authority to provides the DGEG to publish consumer fuel prices.',
              ],
          link: '#dgeg'
        },
        {
          id: 'cpo',
          title: 'CPO (Charge Point Operator)',
          description:
              [
                'In the context of EV charging, CPO stands for “Charge Point Operator.”',
                'A Charge Point Operator is an entity responsible for building, operating, and maintaining electric vehicle charging infrastructure. A CPO may operate their own EV charging stations or install and maintain them for a third party. '
              ],
          link: '#cpo'
        },
        {
          id: 'ocpi',
          title: 'OCPI (Open Charge Point Interface)',
          description:
              [
                'OCPI is an open protocol that enables seamless communication and interoperability between electric vehicle (EV) charging networks, charge point operators, and service providers.',
                'It standardizes data exchange for real-time information on charge points, pricing, and availability, facilitating transparent billing and payment processes. OCPI enhances the user experience by allowing EV drivers to access multiple charging networks with a single subscription.'
              ],
          link: '#ocpi'
        },
      ]
    };
  },
  created() {
    this.cards.sort((a, b) => a.title.localeCompare(b.title));
  },
  mounted() {
    this.scrollToWithOffet(100);
  },
  methods: {
    scrollToWithOffet,
    getHeaderClass(index) {
      return index % 2 === 0 ? 'bg-primary' : 'bg-success';
    },
  },
  watch: {
    '$route.hash': function () {
      this.scrollToWithOffet(100);
    }
  },
};
</script>

<style>

#rydPayApisCards .card {
  margin-bottom: 0px;
}

#rydPayApisCards .card-absolute {
  margin-top: 0px;
}

#rydPayApisUl .list-group-item {
  margin: 5px 0;
}

</style>
