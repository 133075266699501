
export const RPP_API_SERVER_URL_GOOGLE_CLOUD = 'https://europe-west3-ryd-partner-portal.cloudfunctions.net/rpp-users';
export const RPP_API_SERVER_URL_GOOGLE_CLOUD_2 = 'https://europe-west3-ryd-partner-portal.cloudfunctions.net/rpp-users-3';

export const RPP_API_SERVER_URL_LOCAL = 'http://localhost:8082';

export const RPP_API_SERVER_URL = RPP_API_SERVER_URL_GOOGLE_CLOUD_2;

export const PARTNER_ANALYTICS_SERVER_URL_GOOGLE_CLOUD = 'https://europe-west3-ryd-cloud-functions.cloudfunctions.net/partner-analytics';

export const PARTNER_ANALYTICS_SERVER_URL_GOOGLE_CLOUD_2 = 'https://europe-west3-ryd-cloud-functions.cloudfunctions.net/partner-analytics-2';
export const PARTNER_ANALYTICS_SERVER_URL_LOCAL = 'http://localhost:8081';

export const PARTNER_ANALYTICS_SERVER_URL = PARTNER_ANALYTICS_SERVER_URL_GOOGLE_CLOUD_2;


export const USER_EVENTS_PYTHON_API_SERVER_URL_GOOGLE_CLOUD = 'https://europe-west3-ryd-cloud-functions.cloudfunctions.net/customer-events-analytics';
export const USER_EVENTS_PYTHON_API_SERVER_LOCAL = 'http://localhost:8085';

export const USER_EVENTS_API_SERVER_URL = USER_EVENTS_PYTHON_API_SERVER_URL_GOOGLE_CLOUD;


export const PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL_GOOGLE_CLOUD = 'https://europe-west3-ryd-cloud-functions.cloudfunctions.net/partner-performance-guardian';
export const PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_LOCAL = 'http://localhost:8091';

export const PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL = PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL_GOOGLE_CLOUD;

export const PARTNER_ANALYTICS_API_KEY = 'p5osWJmPIwdD9MMwZ6cU3Cb40O3ibcBkh2G1V9ZYjV47QtOaRaVdgYMmhHzJ08G5';

export const NA_EVENTS_API_SERVER_KAY = 'qxfbBOo5cEuwJxNTNpCnOq3LCIyK0O5mGFdkR5qskPUxGaGYTxjZObrfnPLFrrjk';

export const PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY = 'J7xXywHsGbtu6970Qfo6L8ZnJ8M3sZB67AoD712z5BIzQZEQsk';


export const USER_GROUP_RYD = "ryd";

export const RPP_USERS_FIRESTORE_COLLECTION_NAME = "rpp-users";

export const ROLE_DESCRIPTIONS = {
    userManagementAdmin: 'This role grants the user comprehensive user management capabilities, including the ability to invite new users and view a complete list of all users associated with their company on ryd partner portal..',
    userManagementUser: 'This role is assigned to users with restricted access. Users with this role are unable to invite new users or view the list of users within their company on ryd partner portal, ensuring limited user management privileges.',
    reportingAdmin: 'This role allows the user to access and manage the reporting sections, providing insights and analytics relevant to their company\'s activities at Ryd.',
    partnerAdmin: 'This role empowers the user with full administration privileges over partners on the ryd partner portal. It includes the permission to add new partners/user groups.',
    accountingAdmin: 'This role empoweres the user to access and manage the accounting and tax sections used by the ryd accounting team.'
};


export const EUROPEAN_COUNTRIES = [
    { text: 'Austria', value: 'AT' },
    { text: 'Belgium', value: 'BE' },
    { text: 'Bulgaria', value: 'BG' },
    { text: 'Croatia', value: 'HR' },
    { text: 'Cyprus', value: 'CY' },
    { text: 'Czech Republic', value: 'CZ' },
    { text: 'Denmark', value: 'DK' },
    { text: 'Estonia', value: 'EE' },
    { text: 'Finland', value: 'FI' },
    { text: 'France', value: 'FR' },
    { text: 'Germany', value: 'DE' },
    { text: 'Greece', value: 'GR' },
    { text: 'Hungary', value: 'HU' },
    { text: 'Ireland', value: 'IE' },
    { text: 'Italy', value: 'IT' },
    { text: 'Latvia', value: 'LV' },
    { text: 'Lithuania', value: 'LT' },
    { text: 'Luxembourg', value: 'LU' },
    { text: 'Malta', value: 'MT' },
    { text: 'Netherlands', value: 'NL' },
    { text: 'Poland', value: 'PL' },
    { text: 'Portugal', value: 'PT' },
    { text: 'Romania', value: 'RO' },
    { text: 'Slovakia', value: 'SK' },
    { text: 'Slovenia', value: 'SI' },
    { text: 'Spain', value: 'ES' },
    { text: 'Sweden', value: 'SE' }
];

export const SUPPORTED_EMAIL_LANGUAGES = [
    { text: 'English', value: 'EN' },
    { text: 'German', value: 'DE' },
    { text: 'Portuguese', value: 'PT' },
];