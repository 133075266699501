<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Create Cashier System Configuration"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="m-t-20 mb-0 text-center f-w-900">Loading ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main form content -->
        <div class="col-sm-12" v-if="!isLoading">
          <div class="card">
            <div class="card-header bg-primary">
              <h5>Create Cashier System Configuration</h5>
            </div>
            <div class="card-body">
              <div class="form theme-form">
                <form @submit.prevent="createConfiguration">

                  <div class="row fw-bolder f-w-900">
                    <p v-if="error" class="error-message m-b-25">{{ error }}</p>
                  </div>

                  <!-- Cashier System Name Input -->
                  <div class="row border-bottom m-b-20">
                    <div class="col">
                      <div class="mb-3">
                        <label for="cashierSystemName" class="fw-bold">Cashier System Name *</label>
                        <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"></i>
                          <p>Please ensure that there is only one configuration for a cashier system and country. To edit an existing configuration, go to the <a href="/guardian-system-and-alerts/cashier-system-configuration/list">Overview List</a> and select the cashier system first.</p>
                          <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <div v-if="isLoadingCashierSystemNames">
                          <h6 class="sub-title mb-0 text-center">Loading All Available Cashier System Names...</h6>
                          <div class="loader-box">
                            <div class="loader-19"></div>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="!isLoadingCashierSystemNames">
                        <select class="form-control border border-primary min-width-max-content"
                                :class="{ 'is-invalid': cashierSystemNameError }"
                                id="cashierSystemName"
                                v-model="newConfiguration.cashierSystemName"
                                ref="cashierSystemNameSelect"
                                @change="validateCashierSystemName">
                          <option value="">Select a Cashier System</option>
                          <option v-for="cashierSystemName in cashierSystemNames" :key="cashierSystemName" :value="cashierSystemName">
                            {{ cashierSystemName }}
                          </option>
                        </select>
                        </div>
                        <div class="invalid-feedback" v-if="cashierSystemNameError">
                          Please select a cashier system.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- country -->
                  <div class="row border-bottom m-b-20">
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="country" class="fw-bold">Country</label>
                        <select v-model="newConfiguration.country"
                                class="form-control border border-primary"
                                :class="{ 'is-invalid': countryError }"
                                id="country"
                                ref="countrySelect"
                                @change="validateCountry">
                          <option value="">Select a country</option>
                          <option v-for="country in europeanCountries"
                                  :key="country.value"
                                  :value="country.value">
                            {{ country.text }}
                          </option>
                        </select>
                        <div class="invalid-feedback" v-if="countryError">
                          Please select a country.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Email Configurations Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Email Configurations</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addEmailConfiguration">Add Email</button>

                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Department</th>
                            <th>Note</th>
                            <th>Alert Type</th>
                            <th>Active</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(email, index) in newConfiguration.emailConfigurations" :key="index">
                            <td><input type="text" v-model="email.email" class="form-control" placeholder="Email"></td>
                            <td><input type="text" v-model="email.firstName" class="form-control" placeholder="First Name"></td>
                            <td><input type="text" v-model="email.lastName" class="form-control" placeholder="Last Name"></td>
                            <td><input type="text" v-model="email.department" class="form-control" placeholder="Department (optional)"></td>
                            <td><textarea v-model="email.note" class="form-control" placeholder="Note"></textarea></td>
                            <td>
                              <select v-model="email.alertType" class="form-control">
                                <option value="EscalationLevel1">Escalation Level 1</option>
                                <option value="EscalationLevel2">Escalation Level 2</option>
                                <option value="EscalationLevel3">Escalation Level 3</option>
                              </select>
                            </td>
                            <td>
                              <label :for="'active-checkbox-' + index">Active</label>
                              <br/>
                              <input type="checkbox" v-model="email.isActive" :id="'active-checkbox-' + index">
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeEmailConfiguration(index)">Remove</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>

                  <!-- Links Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Links</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addLink">Add Link</button>

                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Description</th>
                            <th>Link Type</th>
                            <th>Link URL</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(link, index) in newConfiguration.links" :key="index">
                            <td>
                              <input type="text" v-model="link.description" class="form-control" placeholder="Description">
                            </td>
                            <td>
                              <select class="form-control border border-primary" id="linkType"
                                      v-model="link.linkType" required>
                                <option value="">Select a link type...</option>
                                <option value="STATUS_PAGE">STATUS_PAGE</option>
                                <option value="HOMEPAGE">HOMEPAGE</option>
                                <option value="OTHERS">OTHERS</option>
                              </select>
                            </td>
                            <td>
                              <input type="text" v-model="link.link" class="form-control" placeholder="Link URL">
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeLink(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>

                  <!-- Downtimes Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Downtimes</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addDowntime">Add Downtime</button>

                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>End Date</th>
                            <th>End Time</th>
                            <th>Note</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(downtime, index) in newConfiguration.downtimes" :key="index">
                            <td>
                              <label for="startDate-{{index}}" class="form-label">Start Date Time</label>
                              <input type="datetime-local" id="startDate-{{index}}" v-model="downtime.startDate" class="form-control">
                            </td>
                            <td>
                              <label for="endDate-{{index}}" class="form-label">End Date Time</label>
                              <input type="datetime-local" id="endDate-{{index}}" v-model="downtime.endDate" class="form-control">
                            </td>
                            <td>
                              <label for="note-{{index}}" class="form-label">Note</label>
                              <textarea id="note-{{index}}" v-model="downtime.note" class="form-control" placeholder="Note"></textarea>
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeDowntime(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>


                  <!-- Alert Disabled Time Ranges -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Alert Disabled Time Ranges</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addAlertDisabledTimeRanges">Add Alert Disabled Time Ranges</button>

                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>End Date</th>
                            <th>End Time</th>
                            <th>Note</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(alertDisabledTimeRange, index) in newConfiguration.alertDisabledTimeRanges" :key="index">
                            <td>
                              <label for="startDate-{{index}}" class="form-label">Start Date Time</label>
                              <input type="datetime-local" id="startDate-{{index}}" v-model="alertDisabledTimeRange.startDate" class="form-control">
                            </td>
                            <td>
                              <label for="endDate-{{index}}" class="form-label">End Date Time</label>
                              <input type="datetime-local" id="endDate-{{index}}" v-model="alertDisabledTimeRange.endDate" class="form-control">
                            </td>
                            <td>
                              <label for="note-{{index}}" class="form-label">Note</label>
                              <textarea id="note-{{index}}" v-model="alertDisabledTimeRange.note" class="form-control" placeholder="Note"></textarea>
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeAlertDisabledTimeRange(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>

                  <!-- Email Language -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col-md-3">
                      <div class="mb-3">
                        <label for="emailLanguage" class="fw-bold">Email Language</label>
                        <select class="form-control border border-primary"
                                :class="{ 'is-invalid': emailLanguageError }"
                                id="emailLanguage"
                                v-model="newConfiguration.emailLanguage"
                                ref="emailLanguageSelect"
                                @change="validateEmailLanguage"
                                >
                          <option value="">Select a language...</option>
                          <option value="EN">English</option>
                          <option value="DE">German</option>
                          <option value="PT">Portuguese</option>
                        </select>
                        <div class="invalid-feedback" v-if="emailLanguageError">
                          Please select an email language.
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Submit Button -->
                  <div class="row">
                    <div class="col">
                      <button class="btn btn-pill btn-success btn-air-success active btn-lg" type="submit"
                              title="Create configuration">
                        <i class="fa fa-send m-r-15"></i>Create
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  PARTNER_ANALYTICS_API_KEY,
  PARTNER_ANALYTICS_SERVER_URL,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL,
  RPP_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import { EUROPEAN_COUNTRIES } from '@/constants/constants';
import {sharedConstantsMixin} from "@/mixins/sharedConstantsMixin";

export default {
  name: 'CreateCashierSystemConfiguration',
  keywords: ['Create Alert Configuration', 'New Cashier System Alert Config', 'Add Alert Settings', 'Setup Alert Configuration', 'Create New Alert Config', 'Configure System Alerts', 'Alert Configuration Setup'],
  data() {
    return {
      newConfiguration: {
        cashierSystemName: '',
        country: '',
        emailConfigurations: [],
        links: [],
        downtimes: [],
        alertDisabledUntil: '',
        alertDisabledTimeRanges: [],
        emailLanguage: '',
        additionalConfigurations: ''
      },
      isLoading: false,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      cashierSystemNames: [],
      europeanCountries: EUROPEAN_COUNTRIES,
      formIsValid: true,
      countryError: false,
      emailLanguageError: false,
      cashierSystemNameError: false,
      isLoadingCashierSystemNames: false
    };
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
      this.loadCashierSystemNames(this.loggedInUserId);
    } else {
      this.partnerMetricsFor = userGroup;
    }
  },
  methods: {
    validateCountry() {
      this.countryError = !this.newConfiguration.country;
    },
    validateEmailLanguage() {
      this.emailLanguageError = !this.newConfiguration.emailLanguage;
    },
    validateCashierSystemName() {
      this.cashierSystemNameError = !this.newConfiguration.cashierSystemName;
    },
    createConfiguration() {

      // Perform validations
      this.validateCashierSystemName();
      this.validateCountry();
      this.validateEmailLanguage();

      // Check if there are any errors
      const errors = [];
      if (this.cashierSystemNameError) errors.push('cashierSystemName');
      if (this.countryError) errors.push('country');
      if (this.emailLanguageError) errors.push('emailLanguage');

      // If there are errors, scroll into view and focus on the first error
      if (errors.length > 0) {
        this.$nextTick(() => {
          const firstError = errors[0];
          const refName = `${firstError}Select`;
          this.$refs[refName].scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.$refs[refName].focus();
        });
        return;
      }


      this.isLoading = true;
      this.error = null;

      axios.post(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations`, this.newConfiguration, {
        headers: {
          'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
          'uid': this.loggedInUserId
        }
      }).then(() => {
            this.$router.push('/guardian-system-and-alerts/cashier-system-configuration/list'); //
          })
          .catch(error => {
            this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to create configuration';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    async loadCashierSystemNames() {
      try {
        this.isLoadingCashierSystemNames = true;
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/constants/cashier-systems?filterOutConfiguredCashierSystems=false`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        this.cashierSystemNames =  response.data.data;
      } catch (error) {
        console.log("error", error);
      }finally {
        this.isLoadingCashierSystemNames = false;
      }
    },
    addEmailConfiguration() {
      this.newConfiguration.emailConfigurations.push({
        email: '',
        firstName: '',
        lastName: '',
        department: '',
        note: '',
        alertType: 'EscalationLevel1', // Default to Level 1
        isActive: false
      });
    },
    removeEmailConfiguration(index) {
      this.newConfiguration.emailConfigurations.splice(index, 1);
    },
    addLink() {
      this.newConfiguration.links.push({
        description: '',
        linkType: '',
        link: ''
      });
    },
    removeLink(index) {
      this.newConfiguration.links.splice(index, 1);
    },
    addDowntime() {
      this.newConfiguration.downtimes.push({
        startDate: '',
        endDate: '',
        note: ''
      });
    },
    removeDowntime(index) {
      this.newConfiguration.downtimes.splice(index, 1);
    },
    addAlertDisabledTimeRanges() {
      this.newConfiguration.alertDisabledTimeRanges.push({
        startDate: '',
        endDate: '',
        note: ''
      });
    },
    removeAlertDisabledTimeRange(index) {
      this.newConfiguration.alertDisabledTimeRanges.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>
<style scoped>
@import "@/assets/css/productlist.css";
</style>
<style scoped>
.sortable {
  cursor: pointer;
}

.sortable.active {
  color: #0d6efd; /* Bootstrap primary color */
}
</style>