<template>
  <Breadcrumbs main="Guides: EV Charging" title="EV Charging API Journey"/>


  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card" id="introduction">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Introduction</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>General Information</h5>


              <li class="list-group-item">
                Welcome to the EV Charging API Journey. This documentation is designed to provide a comprehensive
                overview of the integration process with the Rydpay API Gateway for electric vehicle (EV) charging.
              </li>
              <li class="list-group-item">
                Whether you are a developer, product manager, or technical integrator, this guide will help you
                understand the <b>step-by-step interactions</b>, <b>API calls</b>, and <b>best practices</b> necessary
                for a successful integration.
              </li>
              <li class="list-group-item">
                By following this journey, you will be able to seamlessly integrate EV charging capabilities into your
                applications, providing a smooth and efficient charging experience for your users.
              </li>
            </ul>

            <br/>
            <ul class="list-group">

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>HINTS</h5>

              <ul class="list-group">
                <li class="list-group-item">
                  <p><strong>HINT 1:</strong> The following API Journey illustrates the happy path. Error cases are comprehensively detailed in the Postman collection, which is available at <a href="/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-test-scenarios">Test Scenarios with Postman collection</a>.</p>

                </li>
                <li class="list-group-item">
                  <p><strong>HINT 2:</strong> EV Charging differs significantly from Fuelling. An EV Charging session can last up to several hours. Therefore, polling the charge/order state every 5 seconds is not optimal. Consider using the provided <a href="/integration-guide/rydpay-apis/webhooks">webhook</a> capability by Ryd.</p>

                </li>
                <li class="list-group-item">
                  <p><strong>HINT 3:</strong> Ryd will receive the transactional/invoice data from the respective <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a> after the completion of an EV charging session, which can take from a few seconds to a few days. Consequently, it is not always possible to obtain this invoice data immediately after the session's completion.</p>

                </li>
              </ul>

            </ul>



            <br/>
          </div>
        </div>

        <div class="card" id="sequence-diagram">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Sequence Diagram</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <ul class="list-group">
              <li class="list-group-item">
                Below is a sequence diagram illustrating how the Rydpay APIs should be used to provide a seamless EV
                charging experience.
                This diagram details the interactions between the user, Ryd Partner, and the Rydpay API Gateway,
                highlighting the key API calls and their flow.
              </li>
              <li class="list-group-item">
                Please click on the picture to view a larger version of the sequence diagram.
              </li>
            </ul>

            <br/>

            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials-1" itemscope="" data-pswp-uid="1">
                    <figure class="col-md-3 col-6 img-hover hover-15" @click="showImg(3)" itemprop="associatedMedia"
                            itemscope="">
                      <div>
                        <img :src="require('../../../../assets/images/ryd/ev-charging-api-journey.png')"
                             itemprop="thumbnail1" alt="Image description" class="img-fluid">
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <ul class="list-group p-t-20">
              <li class="list-group-item">
                In the subsequent section, you will find a detailed textual explanation of each step in the sequence
                diagram, helping you understand the process and how to implement it effectively.
              </li>
            </ul>


            <br/>
          </div>
        </div>

        <div class="card" id="explanation">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Detailed Explanation of the Sequence Diagram</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <ul class="list-group p-t-20">
              <li class="list-group-item">
                Below is the sequence of API calls for a successful EV charging session, including how the response of
                one API call is used in subsequent requests.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>1. Find Stations & Products</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user surfs through the map or gets suggested charging locations based on
                geolocation.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/pois?showEvPois=true</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns POIs/charging info (chargers, plug types, powers, prices, availability,
                openNow, etc.)
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user selects a POI/charging station.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/pois/:poiId</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns detailed information about the selected POI (chargers, plug types, powers,
                prices, availability, openNow, etc.)
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user selects a charger/evesId/plugId and a payment method.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>2. Authorize Money</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user executes the payment by clicking Confirm payable.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>POST /v4/orders/</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Creates a new order with state <code>CREATED</code>
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user waits for the order state to change to <code>PAYMENT_PENDING</code>.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Checks the order state, repeated until <code>PAYMENT_PENDING</code> or timeout after 5
                minutes
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Alternative Flow</b>: If the order state is still not <code>PAYMENT_PENDING</code> after 5 minutes,
                the order is rejected.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns the order state <code>REJECTED</code>
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user confirms the payment authorization.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>POST /v4/orders/:id/authorize</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Changes the order state to <code>PAYMENT_AUTHORIZED</code>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>3. Acquire Product & Charging</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user requests to acquire the product.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>PUT /v4/orders/:id/acquire</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Initiates acquisition with state <code>PRODUCT_ACQUISITION_STARTED</code>
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user connects the plug to start charging.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Checks the order state <code>PRODUCT_PENDING</code>, waiting for the user to connect
                the plug
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user plugs in the car, and the charging session starts.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns the order state <code>PRODUCT_SUPPLYING</code>, indicating the charger is
                unlocked and the session has started
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>4. Stop Charging Session</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user stops the charging session by clicking Stop charging session.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>PUT /v4/orders/:id/stopSession</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Stops the session with response status code 202, changing the order state to <code>PRODUCT_ACQUIRED</code>
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user unplugs the car.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns the order state <code>PRODUCT_ACQUIRED</code>, indicating the user can unplug
                the car and drive away, and the invoice will come soon
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>5. Get Invoice and Complete Order</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user retrieves the order to get the invoice data.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns the order state <code>READY_FOR_INVOICE</code>, with transaction details
                (quantity, total amount, etc.)
              </li>
            </ul>
            <br/>
            <ul class="list-group">
              <li class="list-group-item">
                <b>User Activity</b>: The user checks the order to ensure it is completed.
              </li>
              <li class="list-group-item">
                <b>Request</b>: <code>GET /v4/orders/:id</code>
              </li>
              <li class="list-group-item">
                <b>Response</b>: Returns the final state <code>COMPLETED</code>, indicating the order is complete
              </li>
            </ul>
            <br/>

            <br/>


            <br/>
          </div>
        </div>

        <div class="card" id="api-requests-and-responses">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>API Journey Requests and Responses</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <ul class="list-group">

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>1. GET POI by ID</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>GET /v4/pois/:poiId</code>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Detailed information about the selected POI (chargers, plug types, powers, prices, availability, openNow, etc.)
                </li>
                <li class="list-group-item">
                  The response provides technical product IDs and information on EVSE and connector types, as well as pricing information.
                  Click on the image to see which fields in the response are required for the POST /v4/order API request.
                </li>
                <br/>

                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-md-8"> <!-- Increase the column width -->
                      <div class="row my-gallery gallery" id="aniimated-thumbnials-1" itemscope="" data-pswp-uid="1">
                        <figure class="col-md-6 col-12 img-hover hover-15" @click="showImg(5)" itemprop="associatedMedia" itemscope="">
                          <div>
                            <img
                                :src="require('../../../../assets/images/ryd/get-poi-by-id-response-02.png')"
                                itemprop="thumbnail1"
                                alt="Image description"
                                class="img-fluid"
                                style="width: 400px; height: 400px;"
                            >
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>


                <li class="list-group-item">
                  <b>Response Body</b>:
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal1">
                    Click to see the example response of GET /v4/pois/:poiId
                  </button>
                </li>

              </ul>
              <br/>


              <JsonModal
                  modalId="modal1"
                  modalTitle="pois-by-id Response for b989ce59-d976-411d-ba30-0d2e1f90baac"
                  :jsonData="json1"
              />


              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>2. Create Order</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>POST /v4/order</code>
                  <pre><code class="font-success">
{
   "poiId":"{{chargingStationId1}}",
   "products":[
      {
        "fuelTypeId": "CHADEMO:175:DC:EV_CHARGE",
         "productId": "b7bdcbc3-c9c2-512a-b0eb-7da69923b541"
      }
   ]
}
    </code></pre>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: New order created with state <code>CREATED</code>
                </li>
                <li class="list-group-item">
                  <b>Note</b>: both <code>productId</code> and <code>fuelTypeId</code> are mandatory in EV Charging to create an order.
                  <br/>
                  <b>productId</b>: it is <code>products[index1]._id</code> from the previous response.
                  <br/>
                  <b>fuelTypeId</b>: it is <code>products[index1].attributes.availableItems[index2].itemCode</code> from the previous response.
                </li>

                <li class="list-group-item">
                  <b>Response Body</b>:
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal2">
                    Click to see the example of POST /v4/order
                  </button>
                </li>
              </ul>
              <br/>

              <JsonModal
                  modalId="modal2"
                  modalTitle="POST /v4/order API Response"
                  :jsonData="json2"
              />

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>3. Authorize Order</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>POST /v4/order/:orderId/authorize</code>
                  <pre><code class="font-success">
{
  "authorizePrice": {
    "amount": "120.00",
    "precision": 2,
    "currency": "EUR"
  }
}
                  </code></pre>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Order state <code>PAYMENT_AUTHORIZED</code>
                </li>

                <li class="list-group-item">
                  <b>Response Body</b>:
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal3">
                    Click to see the example respone of POST /v4/order/:orderId/authorize
                  </button>
                </li>

              </ul>
              <br/>

              <JsonModal
                  modalId="modal3"
                  modalTitle="POST /v4/order/:orderId/authorize API Response"
                  :jsonData="json3"
              />

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>4. Get Order (until PAYMENT_PENDING)</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>GET /v4/orders/:orderId</code>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Order state, checked repeatedly until <code>PAYMENT_PENDING</code> or timeout after 5 minutes
                </li>

                <li class="list-group-item">
                  <b>Response Body</b>:
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal4">
                    Click to see the example respone of GET /v4/order/:orderId
                  </button>
                </li>

              </ul>
              <br/>

              <JsonModal
                  modalId="modal4"
                  modalTitle="GET /v4/order/:orderId API Response"
                  :jsonData="json4"
              />

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>5. Acquire Order</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>PUT /v4/orders/:orderId/acquire</code>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Acquisition initiated, state <code>PRODUCT_ACQUISITION_STARTED</code>
                </li>
                <li class="list-group-item">
                  <b>Response Body</b>:
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal5">
                    Click to see the example respone of PUT /v4/orders/:orderId/acquire
                  </button>
                </li>
              </ul>
              <br/>

              <JsonModal
                  modalId="modal5"
                  modalTitle="PUT /v4/orders/:orderId/acquire API Response"
                  :jsonData="json5"
              />

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>6. Get Order (Until PRODUCT_PENDING)</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>GET /v4/orders/:orderId</code>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Order state <code>PRODUCT_PENDING</code>, waiting for user to connect the plug
                </li>

              </ul>
              <br/>


              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>7. Stop Session</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>PUT /v4/orders/:orderId/stopSession</code>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Session stopped, state <code>PRODUCT_ACQUIRED</code>
                </li>
              </ul>
              <br/>

              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>8. Get Order (Ready for Invoice)</h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Request</b>: <code>GET /v4/orders/:orderId</code>
                </li>
                <li class="list-group-item">
                  <b>Response</b>: Order state <code>READY_FOR_INVOICE</code>, with transaction details
                </li>
                <li class="list-group-item">
                  <b>Response Body</b>:
                  <button type="button" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#modal6">
                    Click to see the example respone of GET /v4/orders/:orderId
                  </button>
                </li>
              </ul>
              <br/>

              <JsonModal
                  modalId="modal6"
                  modalTitle="GET /v4/orders/:orderId API Response"
                  :jsonData="json6"
              />



            </ul>
            <br/>
          </div>
        </div>


      </div>
    </div>
  </div>

</template>

<script>
import 'sweetalert2/dist/sweetalert2.min.css';
import {scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";
import json1 from "@/pages/integrationguide/rydpayapis/data/ev-charging-api-journey/01-get-pois-by-id-response.json";
import json2 from "@/pages/integrationguide/rydpayapis/data/ev-charging-api-journey/02-order-creation-response.json";
import json3 from "@/pages/integrationguide/rydpayapis/data/ev-charging-api-journey/03-order-authorization-response.json";
import json4 from "@/pages/integrationguide/rydpayapis/data/ev-charging-api-journey/04-get-order-by-id-response.json";
import json5 from "@/pages/integrationguide/rydpayapis/data/ev-charging-api-journey/05-acquire-response.json";
import json6 from "@/pages/integrationguide/rydpayapis/data/ev-charging-api-journey/06-get-order-by-id-response.json";

import JsonModal from "@/pages/integrationguide/rydpayapis/components/JsonModal.vue";

export default {
  keywords: ['Happy Path', 'CPO', 'Postman'],
  components: {JsonModal},
  data() {
    return {
      lightBoxImages: [],
      index: 0, // Added missing index data property
      gallery1: [
        {
          image: 'prepay-sequence-diagram-full.png'
        },
        {
          image: 'postpay-sequence-diagram-full.png'
        },
        {
          image: 'ryd-order-state-simple-0415.png'
        },
        {
          image: 'ev-charging-api-journey.png'
        },
        {
          image: 'get-poi-by-id-response-01.png'
        },
        {
          image: 'get-poi-by-id-response-02.png'
        }
      ],
      json1: JSON.stringify(json1, null, 2),
      json2: JSON.stringify(json2, null, 2),
      json3: JSON.stringify(json3, null, 2),
      json4: JSON.stringify(json4, null, 2),
      json5: JSON.stringify(json5, null, 2),
      json6: JSON.stringify(json6, null, 2),
    }
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
  methods: {
    scrollToRootHash,
    scrollToTop,
    showImg(index) {
      this.index = index;
      this.visible = true;
      const imageUrl = this.lightBoxImages[this.index];

      this.$swal({
        imageUrl,
        imageAlt: 'Image',
        showCloseButton: true,
        showConfirmButton: false,
        customClass: 'swal-wide'
      });

    },
  },
  mounted() {
    this.scrollToRootHash();
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/swal_custom.scss";
</style>