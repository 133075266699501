<template>
  <Breadcrumbs main="Guides: EV Charging" title="EV Pricing"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <p>
              On this page, you will find general information about EV Pricing. The topics covered include:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('general-information')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> EV Pricing General Information</a>
                Understand the complexity of EV pricing due to various CPOs and the adherence to <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#ocpi">OCPI</a> standards.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('key-concepts')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Key Concepts</a>
                Learn about the fundamental concepts of EV pricing including price components and <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#ocpi">OCPI</a> standards.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('price-components')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Price Components</a>
                Explore the different types of price components used in EV pricing such as energy, time, flat fees, and
                parking time pricing.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('examples-and-code')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Real-World Examples and Code</a>
                Review real-world examples and their corresponding code to better understand the application of EV
                pricing models.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('available-price-component-combinations')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Available Price Component Combinations</a>
                Discover the actual combinations of price components available, categorized by country or in a combined
                view.
              </li>
            </ul>

          </div>
        </div>


        <div class="card" id="general-information">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>EV Pricing General Information</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>General Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                EV pricing is a complex topic due to the diverse range of <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operators (CPOs)</a> in
                the market, each offering different tariffs and pricing models.
              </li>
              <li class="list-group-item">
                Ryd adheres to the
                <a href="https://github.com/ocpi/ocpi/blob/master/mod_tariffs.asciidoc#mod_tariffs_pricecomponent_class"
                   target="_blank" rel="noopener noreferrer">OCPI Tariffs</a> standard, which defines prices and costs
                across four different dimensions. These dimensions are explained in the subsequent sub-sections.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Use in rydpay APIs</h5>
            <ul class="list-group">
              <li class="list-group-item">
                The price components, along with their various restrictions and units, are utilized by the <code>GET
                /v4/pois/:poiId</code> API to display the pricing information of a charging port to customers.
                Additionally, the <code>GET /v4/orders/:orderId</code> API uses these components to show the charging
                fees in the same dimensions.
              </li>

            </ul>
            <br/>


          </div>
        </div>

        <div class="card" id="key-concepts">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Key Concepts</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Price Components</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Each price component represents a distinct part of the overall pricing structure, such as the cost per
                unit of energy, the cost per unit of time, or the cost for a session.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i><a
                href="/integration-guide/rydpay-apis/terms-and-definitions#ocpi">OCPI</a> Standards</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd follows the <a href="/integration-guide/rydpay-apis/terms-and-definitions#ocpi">OCPI</a> standards
                to ensure consistency and compatibility with various charging networks.
                For more details, refer to the <a
                  href="https://github.com/ocpi/ocpi/blob/master/mod_tariffs.asciidoc#mod_tariffs_pricecomponent_class">
                OCPI Tariffs Documentation</a>.
              </li>
            </ul>
            <br/>

          </div>
        </div>


        <div class="card" id="price-components">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Price Components</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Energy Pricing</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Cost based on the amount of energy consumed (e.g., €0.59 per kWh).
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Time-Based Pricing</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Cost based on the duration of the charging session (e.g., €0.05 per minute).
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Flat Fees</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Fixed cost for initiating a charging session (e.g., €1.00 per session).
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Parking Time Pricing</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Cost based on the duration the vehicle is parked at the charging station (e.g., €0.10 per minute).
              </li>
            </ul>
            <br/>

          </div>
        </div>

        <div class="card" id="examples-and-code">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Real-World Examples and Code</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Example 1: Energy-Based Pricing</h5>
            <ul class="list-group">

              <li class="list-group-item">
                This example represents a simple energy-based pricing model where the cost is based on the amount of
                energy consumed.
              </li>

              <li class="list-group-item">
                      <pre><code class="font-success">
{
  "priceElements": [
    {
      "priceComponents": [
        {
          "type": "ENERGY",
          "unit": "KWH",
          "price": "0.59",
          "stepSize": 0.01
        }
      ]
    }
  ]
}

                      </code></pre>
              </li>
              <br/>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Type: ENERGY</b> indicates that the pricing is based on the amount of energy consumed.
                </li>
                <li class="list-group-item">
                  <b>Unit: KWH</b> specifies that the unit of measurement is kilowatt-hours.
                </li>
                <li class="list-group-item">
                  <b>Price: 0.59</b> indicates that the cost is €0.59 per kWh
                </li>
                <li class="list-group-item">
                  <b>StepSize: 0.01</b> means the billing is calculated in increments of 0.01 kWh.
                </li>

              </ul>


            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Example 2: Time-Based Pricing</h5>
            <ul class="list-group">

              <li class="list-group-item">
                This example represents a time-based pricing model where the cost is based on the duration of the
                charging session.
              </li>

              <li class="list-group-item">
                      <pre><code class="font-success">
{
  "priceElements": [
    {
      "priceComponents": [
        {
          "type": "TIME",
          "unit": "MINUTE",
          "price": "0.05",
          "stepSize": 1
        }
      ]
    }
  ]
}

                      </code></pre>
              </li>
              <br/>


              <ul class="list-group">
                <li class="list-group-item">
                  <b>Type: TIME</b> indicates that the pricing is based on the duration of the charging session.
                </li>
                <li class="list-group-item">
                  <b>Unit: MINUTE</b> specifies that the unit of measurement is minutes
                </li>
                <li class="list-group-item">
                  <b>Price: 0.05</b> indicates that the cost is €0.05 per minute
                </li>
                <li class="list-group-item">
                  <b>StepSize: 1</b> 1 means the billing is calculated in increments of 1 minute.
                </li>
              </ul>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Example 3: Combined Energy and Time-Based
              Pricing</h5>
            <ul class="list-group">

              <li class="list-group-item">
                This example combines both energy-based and time-based pricing.
              </li>

              <li class="list-group-item">
                      <pre><code class="font-success">
{
  "priceElements": [
    {
      "priceComponents": [
        {
          "type": "ENERGY",
          "unit": "KWH",
          "price": "0.30",
          "stepSize": 0.01
        },
        {
          "type": "TIME",
          "unit": "MINUTE",
          "price": "0.05",
          "stepSize": 1
        }
      ]
    }
  ]
}
                      </code></pre>
              </li>
              <br/>

            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Example 4: Flat Fee Pricing</h5>
            <ul class="list-group">

              <li class="list-group-item">
                This example represents a flat fee pricing model where a fixed cost is applied for each session.
              </li>

              <li class="list-group-item">
                      <pre><code class="font-success">
{
  "priceElements": [
    {
      "priceComponents": [
        {
          "type": "FLAT",
          "unit": "COUNT",
          "price": "1.00",
          "stepSize": 1
        }
      ]
    }
  ]
}
                      </code></pre>
              </li>
              <br/>


              <ul class="list-group">
                <li class="list-group-item">
                  <b>Type: FLAT</b> indicates a fixed cost per session.
                </li>
                <li class="list-group-item">
                  <b>Unit: COUNT</b> specifies that the cost applies to each charging session.
                </li>
                <li class="list-group-item">
                  <b>Price: 1.00</b> indicates that the cost is €1.00 per session.
                </li>
                <li class="list-group-item">
                  <b>StepSize: 1</b> means the billing is calculated per session.
                </li>
              </ul>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Example 5: Parking Time Pricing</h5>
            <ul class="list-group">

              <li class="list-group-item">
                This example represents a parking time pricing model where the cost is based on the duration the vehicle
                is parked.
              </li>

              <li class="list-group-item">
                      <pre><code class="font-success">
{
  "priceElements": [
    {
      "priceComponents": [
        {
          "type": "PARKING_TIME",
          "unit": "MINUTE",
          "price": "0.10",
          "stepSize": 1
        }
      ]
    }
  ]
}
                      </code></pre>
              </li>
              <br/>


              <ul class="list-group">
                <li class="list-group-item">
                  <b>Type: PARKING_TIME</b> indicates that the pricing is based on the parking duration.
                </li>
                <li class="list-group-item">
                  <b>Unit: MINUTE</b> specifies that the unit of measurement is minutes.
                </li>
                <li class="list-group-item">
                  <b>Price: 0.10</b> indicates that the cost is €0.10 per minute
                </li>
                <li class="list-group-item">
                  <b>StepSize: 1</b> 1 means the billing is calculated in increments of 1 minute.
                </li>
              </ul>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Example 6: Time-Based Pricing with
              Restrictions</h5>
            <ul class="list-group">

              <li class="list-group-item">
                This example demonstrates a pricing model that includes both an energy-based tariff and a time-based
                tariff. The time-based tariff applies between 14400 seconds (4 hours) and 28800 seconds (8 hours) after
                the start of the EV charging session.
              </li>

              <li class="list-group-item">
                      <pre><code class="font-success">
{
  "priceElements": [
    {
      "priceComponents": [
        {
          "type": "ENERGY",
          "price": 0.59,
          "step_size": 1
        }
      ]
    },
    {
      "priceComponents": [
        {
          "type": "TIME",
          "price": 6,
          "step_size": 60
        }
      ],
      "restrictions": {
        "min_duration": 14400,
        "max_duration": 28800
      }
    }
  ]
}
                      </code></pre>
              </li>
              <br/>

            </ul>


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>HINT</h5>
            <div class="alert alert-light dark alert-dismissible fade show d-flex" role="alert"><i
                data-feather="bell">
              <vue-feather type="bell">
              </vue-feather>
            </i>
              <p class="mx-2">
                The examples above illustrate some typical pricing models, but there are many other combinations with
                various restrictions. In the next section, you will find the actual combinations of price components
                available, organized by country or presented in a combined view.
              </p>
              <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>


          </div>
        </div>


        <div class="card" id="available-price-component-combinations">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Available Price Component Combinations</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="table-responsive product-table">
              <div class="mb-3">
                Based on the charging stations and their real prices as of July 12, 2024, you can select a country below
                or choose a cross-country view to explore all available combinations of price components and
                restrictions.

                <h5 class="m-t-20">Select Country</h5>
                <select id="country-select" class="form-select w-50 border border-primary" v-model="selectedCountry"
                        @change="handleCountryChange">
                  <option value="germany">Germany</option>
                  <option value="belgium">Belgium</option>
                  <option value="countries">Cross-Country</option>
                </select>
              </div>
              <div class="mb-3 d-flex align-items-center" v-if="showHint">
                <p class="mb-0 me-2 fw-bold">All the variations for the selected country/cross-country in the table can
                  be downloaded in a JSON file.</p>
                <button class="btn btn-primary" @click="downloadJson">Download JSON</button>
              </div>
              <div class="mb-3">
                <p>Total Variations Available: {{ totalVariations }}</p>
              </div>
              <div class="table-wrapper">
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark" style="width: 10%;">Type</th>
                    <th class="font-primary f-w-800 border border-dark" style="width: 10%;">Restriction Keys</th>
                    <th class="font-primary f-w-800 border border-dark" style="width: 10%;">
                      Count
                      <span class="ms-2" data-bs-toggle="tooltip" data-bs-placement="bottom"
                            title="This represents the number of EVSEs that have used this price pattern.">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </th>
                    <th class="font-primary f-w-800 border border-dark" style="width: 10%;">Example Value</th>
                    <th class="font-primary f-w-800 border border-dark" style="width: 10%;">Country</th>
                    <th class="font-primary f-w-800 border border-dark" style="width: 35%;">Code Example</th>
                    <th class="font-primary f-w-800 border border-dark" style="width: 15%;">External Location Id
                      <span class="ms-2" data-bs-toggle="tooltip" data-bs-placement="bottom"
                            title="This is the location id at plugsurfing. The corresponding poi id at ryd will be added soon.">
                        <i class="fa fa-info-circle"></i>
                      </span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in filteredData" :key="item._id.$oid">
                    <td class="border border-dark" style="width: 10%;">{{ item.types.join(", ") }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ item.restrictionKeys.join(", ") }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ item.count }}</td>
                    <td class="border border-dark" style="width: 10%;">
                      {{ item.example.element.price_components.map(pc => pc.type + ": " + pc.price).join(", ") }}
                    </td>
                    <td class="border border-dark" style="width: 10%;">{{ item.example.country }}</td>
                    <td class="border border-dark" style="width: 35%;">
                      <pre><code class="font-success">{{ prettyPrintJson(item.example.element) }}</code></pre>
                    </td>
                    <td class="border border-dark" style="width: 15%;">
                      <button class="btn btn-secondary btn-sm" @click="copyToClipboard(item.example.documentId)">Copy
                        External Location Id
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash as utilScrollToRootHash, scrollToTop} from "@/utils/anchorUtils";
import germanyData from '@/data/json/application/rydapis/charging-station-prod.price_patterns_deu.json';
import belgiumData from '@/data/json/application/rydapis/charging-station-prod.price_patterns_bel.json';
import countriesData from '@/data/json/application/rydapis/charging-station-prod.price_patterns.json';
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js';

export default {
  name: "EvPricing",
  keywords: [
    'EV Pricing', 'Price Categories', 'OCPI Standards', 'Charging Rates', 'Pricing Documentation',
    'Price Implementation', 'EV Charging Prices', 'Cost Structures', 'Tariff Models', 'Dynamic Pricing',
    'Static Pricing', 'Time-based Pricing', 'Energy-based Pricing', 'Session-based Pricing',
    'Subscription Pricing', 'Billing', 'Payment Methods', 'Pricing Schemes', 'Cost Calculation',
    'OCPI Integration', 'Technical Implementation', 'Pricing API', 'EV Tariffs', 'Rate Plans',
    'Charge Point Pricing', 'Pricing Guide', 'Charging Fees', 'Cost Per kWh', 'Flat Rate Pricing',
    'Variable Pricing', 'Price Components', 'Rate Management', 'EV Charging Billing', 'OCPI Protocol',
    'Standardized Pricing', 'Charging Session Costs', 'Price Configuration', 'Rate Adjustments',
    'EVSE Pricing', 'Pricing Models', 'Pricing Strategies', 'EV Charging Network', 'Price Transparency',
    'Charging Infrastructure', 'OCPI Documentation', 'Technical Guide', 'Price Documentation',
    'OCPI Price Categories', 'Pricing System', 'EV Charging Costs', 'Price Calculation',
    'Tariff Structure', 'Rate Implementation', 'Pricing Standards', 'Charging Pricing Mechanism',
    'EV Billing System', 'Cost Management', 'Payment Integration', 'OCPI Tariffs', 'Pricing Mechanism',
    'EV Cost Models', 'Pricing Details', 'Rate Types', 'Charging Fees Calculation', 'Price Setup',
    'Rate Configuration', 'Charge Pricing', 'EVSE Tariffs', 'OCPI Price Implementation', 'Pricing Framework', 'EV Charging Restrictions', 'Pricing Exploration'
  ],
  data() {
    return {
      loggedInUserGroup: '',
      selectedCountry: 'germany',
      showHint: true, // Initialize to true since deu is the default
      allData: {
        germany: germanyData,
        belgium: belgiumData,
        countries: countriesData,
      },
      filteredData: [],
    }
  },
  mounted() {
    this.filterData();

    this.$nextTick(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
      });
    });

    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.$nextTick(() => {
      this.scrollToRootHashWithDelay();
    });

  },
  computed: {
    totalVariations() {
      return this.filteredData.length;
    },
  },
  methods: {
    scrollToCard,
    scrollToRootHashWithDelay() {
      setTimeout(() => {
        utilScrollToRootHash.call(this, 75);
      }, 100);
    },
    scrollToTop,
    filterData() {
      this.filteredData = this.allData[this.selectedCountry];
    },
    prettyPrintJson(json) {
      return JSON.stringify(json, null, 2);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.show('Copied to clipboard!', {
          theme: 'outline',
          position: 'bottom',
          type: 'success',
          duration: 5000
        });
      }).catch(err => {
        this.$toast.show('Failed to copy!', {theme: 'outline', position: 'bottom', type: 'danger', duration: 8000});
      });
    },
    handleCountryChange() {
      this.filterData();
      this.showHint = true;
    },
    downloadJson() {
      const dataStr = JSON.stringify(this.filteredData, null, 2);
      const blob = new Blob([dataStr], {type: 'application/json'});
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.selectedCountry}_price_patterns.json`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      this.$toast.show('The Price Patterns JSON file has been successfully downloaded!', {
        theme: 'outline',
        position: 'bottom',
        type: 'success',
        duration: 5000
      });

    }
  },
  watch: {
    '$route.hash': function (newHash, oldHash) {
      if (newHash !== oldHash) {
        this.$nextTick(() => {
          this.scrollToRootHashWithDelay();
        });
      }
    }
  },
}
</script>


<style>

</style>