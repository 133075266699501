<template>
  <Breadcrumbs main="Guides: EV Charging" title="EV Insights & Statistics"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <div class="mb-3">

              Select a country to view its latest insights and statistics on EV charging stations, updated as of <span
                class="f-w-900 font-primary">July 31, 2024</span>.

              <h5 class="m-t-20">Select Country</h5>
              <select id="country-select" class="form-select w-50 border border-primary" v-model="selectedCountry"
                      @change="handleCountryChange">
                <option value="Germany">Germany</option>
                <option value="Belgium">Belgium</option>
              </select>
            </div>

            <p>
              On this page, you will find detailed information and statistics about EV charging infrastructure,
              including:
            </p>


            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('terminology-and-components')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> EV Charging Terminology and Components</a>
                Get familiar with the essential terminology and components related to EV charging.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('key-statistics')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Key Statistics</a>
                Explore key statistics about the EV charging infrastructure in the selected country.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('cpo-statistics')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> CPO Statistics</a>
                Analyze statistics for Charge Point Operators (CPOs) in the selected country, including total CPOs,
                station counts, and more.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('price-patterns-statistics')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Price Pattern Statistics</a>
                Analyze statistics for price patterns in the selected country, including total EVSE counts, top CPOs, top cities, and top city and CPO combinations.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('city-statistics')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Top Cities by EVSE Count</a>
                View the top 30 cities by EVSE count in the selected country, including general statistics, power type
                statistics, top CPOs, and price patterns.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('power-types')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Power Type Statistics</a>
                Review statistics for different power types available in the selected country's EV charging
                infrastructure.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('connector-types')"
                   class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Connector Type Statistic</a>
                Examine statistics for various connector types used in the EV charging infrastructure of the selected
                country.
              </li>
            </ul>


          </div>
        </div>
        <!-- CARD 1 START -->

        <div class="card" id="terminology-and-components">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Understanding EV Charging Terminology and Components</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-md-6">
                <div class="ev-charging-explanation">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials-1" itemscope="" data-pswp-uid="1">
                    <figure class="img-hover hover-15" @click="showImg(0)" itemprop="associatedMedia" itemscope="">
                      <div>
                        <img :src="require('../../../../assets/images/ryd/ev-terms-v2.png')" itemprop="thumbnail1"
                             alt="EV Charging Terms" class="img-fluid mx-auto d-block">
                      </div>
                    </figure>
                  </div>
                </div>
              </div>

              <h5 class="m-t-20"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>EV Charging Terminology:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">
                  <i class="fa fa-arrow-circle-right font-primary"></i>
                  <strong>Charging Pool:</strong> A collection of multiple charging stations located in a single area,
                  serving several vehicles simultaneously.
                  <br><em>Comparison:</em> Similar to a gas station forecourt with multiple islands of pumps.
                </li>
                <li class="list-group-item">
                  <i class="fa fa-arrow-circle-right font-primary"></i>
                  <strong>Charging Station (POI):</strong> The unit that houses one or more EVSEs, each associated with
                  a
                  specific owner/CPO (Charge Point Operator).
                  <br><em>Comparison:</em> Comparable to an island of pumps at a gas station, where each pump might
                  belong to a different brand (Shell, BP, etc.).
                </li>
                <li class="list-group-item">
                  <i class="fa fa-arrow-circle-right font-primary"></i>
                  <strong>Charging Point (EVSE):</strong> The individual unit where the vehicle plugs in for charging,
                  equipped with multiple connectors.
                  <br><em>Comparison:</em> Equivalent to the individual nozzles on a gas pump, where there might be
                  multiple nozzles for different fuel types (regular, premium, diesel).
                </li>
                <li class="list-group-item">
                  <i class="fa fa-arrow-circle-right font-primary"></i>
                  <strong>Outlet (Connector):</strong> The specific plugs or sockets on the EVSE that connect to the
                  vehicle, coming in different types to fit various vehicle models.
                  <br><em>Comparison:</em> Similar to the nozzle of a gas pump, with different connectors fitting
                  different vehicle models much like how diesel and gasoline nozzles fit different fuel tanks.
                </li>
              </ul>

            </div>
          </div>
        </div>

        <div class="card" id="key-statistics">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Key Statistics for {{ selectedCountry }}</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5 class="m-t-20"><i class="icofont icofont-hand-right font-success">&nbsp;</i>Statistics for
              {{ selectedCountry }}:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Total Charging Stations (POIs):</strong>
                <span
                    class="font-success fw-bold m-l-10">{{
                    keyStatisticsData[selectedCountry].locationsWithCountry
                  }}</span>
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Number of Charging Stations (POIs) with Remote Start/Stop Capabilities:</strong>
                <span
                    class="font-success fw-bold m-l-10">{{
                    keyStatisticsData[selectedCountry].locationsWithRemoteStartStopCapable
                  }}</span>
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Total Charging Points (EVSEs):</strong>
                <span
                    class="font-success fw-bold m-l-10">{{
                    keyStatisticsData[selectedCountry].totalEVSEsInCountry
                  }}</span>
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Number of Charging Points (EVSEs) with Remote Start/Stop Capabilities:</strong>
                <span
                    class="font-success fw-bold m-l-10">{{
                    keyStatisticsData[selectedCountry].totalEVSEsWithRemoteStartStopCapableInCountry
                  }}</span>
              </li>

              <li class="list-group-item" v-if="isUserGroupRyd">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Number of Outlets (Connectors) with Remote Start/Stop Capabilities and No Price
                  Information:</strong>
                <span
                    class="font-success fw-bold m-l-10">{{
                    keyStatisticsData[selectedCountry].connectorsWithRemoteStartStopCapableNoPriceInCountry
                  }}</span>
              </li>

            </ul>

            <h5 class="m-t-20"><i class="icofont icofont-hand-right font-success">&nbsp;</i>Explanation:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Total Charging Stations:</strong> Indicates how many unique POIs or physical addresses have
                charging equipment.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Charging Locations with Remote Start/Stop Capabilities:</strong>
                Indicates how many of these locations can manage charging remotely, allowing greater flexibility and
                control. This means that the charging station can be used by the ryd payment system.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Total Charging Points (EVSEs):</strong> Shows the number of individual charging units available
                across all locations.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Charging Points (EVSEs) with Remote Start/Stop Capabilities:</strong> Highlights how many of
                these individual units can be remotely controlled.
              </li>
              <li class="list-group-item" v-if="isUserGroupRyd">
                <i class="fa fa-arrow-circle-right font-success"></i>
                <strong>Outlets (Connectors) with Remote Start/Stop Capabilities and No Price Information:</strong>
                Identifies connectors that, while advanced in functionality, lack transparent pricing.
              </li>
            </ul>

            <br/>
          </div>
        </div>

        <div class="card" id="cpo-statistics">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>CPO Statistics for {{ selectedCountry }}</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="container mt-4">
              <h5>Total CPOs in {{ selectedCountry }}: {{ totalCpos }}</h5>
              <div class="table-responsive">
                <div class="d-flex align-items-center mb-3">
                  <input
                      type="text"
                      v-model="searchQuery"
                      placeholder="Search CPOs by name or id"
                      class="form-control me-2 border border-primary"
                      style="width: 30%;"
                  />
                  <div class="total-results fw-bold">
                    <p>{{ filteredCpoNumberOfCount }} CPOs found.</p>
                  </div>
                </div>
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('operator_name')">CPO Name
                    </th>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('_id')">CPO ID</th>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('locationsWithRemote')">
                      Stations Count
                    </th>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('totalEVSEsWithRemote')">EVSEs
                      Count
                    </th>
                    <th class="font-primary f-w-800 border border-dark">Top Cities</th>
                    <th class="font-primary f-w-800 border border-dark">Connector and Power Types</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="cpo in filteredAndSortedCpos" :key="cpo.operator_id">
                    <td class="border border-dark" style="width: 20%;">{{ cpo.operator_name }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ cpo._id }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ cpo.locationsWithRemote }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ cpo.totalEVSEsWithRemote }}</td>
                    <td class="border border-dark" style="width: 20%;">
                      <button type="button"
                              @click="showTopCities(cpo)"
                              class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modalTopCities">
                        Top Cities
                      </button>
                    </td>
                    <td class="border border-dark" style="width: 20%;">
                      <button type="button"
                              @click="showConnectorAndPowerTypes(cpo)"
                              class="btn btn-outline-warning" data-bs-toggle="modal"
                              data-bs-target="#modalConnectorAndPowerTypes">
                        Connector And Power Types
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                      <button class="page-link" @click.prevent="prevPage">Previous</button>
                    </li>
                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                      <button class="page-link" @click.prevent="nextPage">&nbsp;&nbsp;Next&nbsp;&nbsp;</button>
                    </li>
                  </ul>
                </nav>

                <div class="m-t-10">
                  Only EVSEs that support remote start and stop are included in the statistics.
                </div>

              </div>
            </div>
          </div>
        </div>


        <div class="card" id="price-patterns-statistics">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Price Pattern Statistics for {{ selectedCountry }}</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="container mt-4">
              <h5>Out of <span class="font-success">{{ pricePatternTotalEvseCount }}</span> EVSEs, there are a total of
                <span class="font-success">{{ totalPricePatterns }}</span> Price Patterns in {{ selectedCountry }}</h5>
              <div class="table-responsive">
                <div class="d-flex align-items-center mb-3">
                  <input
                      type="text"
                      v-model="searchQueryPricePattern"
                      placeholder="Search Price Pattern by name"
                      class="form-control me-2 border border-success"
                      style="width: 30%;"
                  />
                  <div class="total-results fw-bold">
                    <p>{{ filteredPricePatternNumberOfCount }} Price Patterns found.</p>
                  </div>
                </div>
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-success f-w-800 border border-dark"
                        @click="sortTablePricePattern('pricePatternId')">Price Pattern Id
                    </th>
                    <th class="font-success f-w-800 border border-dark" @click="sortTablePricePattern('evseCount')">EVSE
                      Count
                    </th>
                    <th class="font-success f-w-800 border border-dark">
                      Percentage of Total EVSEs
                    </th>

                    <th class="font-success f-w-800 border border-dark">Top Cities</th>
                    <th class="font-success f-w-800 border border-dark">Top CPOs</th>

                    <th class="font-success f-w-800 border border-dark">Top City&CPOs</th>

                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="pricePatternItem in filteredAndSortedPricePatterns" :key="pricePatternItem.id">
                    <td class="border border-dark" style="width: 20%;">
                      {{ pricePatternItem.pricePatternKey }}
                      <br/>
                      <button type="button"
                              @click="showPricePattern(pricePatternItem.pricePatternKey)"
                              class="btn btn-outline-success" data-bs-toggle="modal" data-bs-target="#modalPricePattern">
                        Price Pattern Detail
                      </button>
                    </td>
                    <td class="border border-dark" style="width: 10%;">{{ pricePatternItem.evseCount }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ pricePatternItem.percentage }} %</td>
                    <td class="border border-dark" style="width: 20%;">
                      <button type="button"
                              @click="showTopCitiesPricePattern(pricePatternItem)"
                              class="btn btn-outline-success" data-bs-toggle="modal"
                              data-bs-target="#modalTopCitiesPricePattern">
                        Top Cities
                      </button>
                    </td>
                    <td class="border border-dark" style="width: 20%;">
                      <button type="button"
                              @click="clickTopCposPricePattern(pricePatternItem)"
                              class="btn btn-outline-warning" data-bs-toggle="modal"
                              data-bs-target="#modelTopCposPricePattern">
                        TOP CPOs
                      </button>
                    </td>
                    <td class="border border-dark" style="width: 20%;">
                      <button type="button"
                              @click="clickTopCityAndCposPricePattern(pricePatternItem)"
                              class="btn btn-outline-info" data-bs-toggle="modal"
                              data-bs-target="#modelTopCityAndCposPricePattern">
                        Top City&CPOs
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <nav>
                  <ul class="pagination">
                    <li class="page-item" :class="{ disabled: currentPagePricePattern === 1 }">
                      <button class="page-link" @click.prevent="prevPagePricePattern">Previous</button>
                    </li>
                    <li class="page-item" :class="{ disabled: currentPagePricePattern === totalPagesPricePatterns }">
                      <button class="page-link" @click.prevent="nextPagePricePattern">&nbsp;&nbsp;Next&nbsp;&nbsp;
                      </button>
                    </li>
                  </ul>
                </nav>


              </div>
            </div>
          </div>
        </div>

        <!-- modal modalTopCitiesPricePattern start -->
        <div class="modal fade" id="modalTopCitiesPricePattern" tabindex="-1" role="dialog"
             ref="modalTopCitiesPricePattern"
             aria-labelledby="modalTopCitiesPricePatternLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Top Cities with the selected Price Pattern
                </h5>
              </div>
              <div class="modal-body">
                <div v-if="selectedPricePatternForShowTopCities">
                  <h6>Price Pattern Id</h6>
                  <div class="font-success fw-bold m-b-20">{{
                      selectedPricePatternForShowTopCities.pricePatternKey
                    }}
                  </div>

                  <div class="m-b-30">
                    Below you will find the top <span class="font-success">20</span> cities with the selected price
                    pattern.
                  </div>


                  <table class="table table-bordered table-striped border border-dark">
                    <thead class="text-black">
                    <tr>
                      <th class="font-success f-w-800 border border-dark">City Name</th>
                      <th class="font-success f-w-800 border border-dark">EVSE Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="topCitiesItem in selectedPricePatternForShowTopCities.topCities"
                        :key="topCitiesItem.city">
                      <td class="border border-dark" style="width: 20%;">{{ topCitiesItem.city }}</td>
                      <td class="border border-dark" style="width: 10%;">{{ topCitiesItem.count }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-success" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal modalTopCitiesPricePattern end -->

        <!-- modal modalTopCposPricePattern start -->
        <div class="modal fade" id="modelTopCposPricePattern" tabindex="-1" role="dialog" ref="modelTopCposPricePattern"
             aria-labelledby="modelTopCposPricePatternLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Top CPOs with the selected Price Pattern
                </h5>
              </div>
              <div class="modal-body">
                <div v-if="selectedPricePatternForShowTopCities">

                  <h6>Price Pattern Id</h6>
                  <div class="font-success fw-bold m-b-20">{{
                      selectedPricePatternForShowTopCities.pricePatternKey
                    }}
                  </div>

                  <div class="m-b-30">
                    Below you will find the top <span class="font-success">20</span> CPOs with the selected price
                    pattern.
                  </div>


                  <table class="table table-bordered table-striped border border-dark">
                    <thead class="text-black">
                    <tr>
                      <th class="font-success f-w-800 border border-dark">CPO Name</th>
                      <th class="font-success f-w-800 border border-dark">CPO ID</th>
                      <th class="font-success f-w-800 border border-dark">EVSE Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="topCposItem in selectedPricePatternForShowTopCities.topCPOs" :key="topCposItem.city">
                      <td class="border border-dark" style="width: 20%;">{{
                          findCpoNameByCpoId(topCposItem.operatorId)
                        }}
                      </td>
                      <td class="border border-dark" style="width: 10%;">{{ topCposItem.operatorId }}</td>
                      <td class="border border-dark" style="width: 10%;">{{ topCposItem.count }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-success" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal modalTopCposPricePattern end -->

        <!-- modal modalTopCityAndCposPricePattern start -->
        <div class="modal fade" id="modelTopCityAndCposPricePattern" tabindex="-1" role="dialog"
             ref="modelTopCityAndCposPricePattern"
             aria-labelledby="modelTopCityAndCposPricePatternLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Top City&CPOs with the selected Price Pattern
                </h5>
              </div>
              <div class="modal-body">
                <div v-if="selectedPricePatternForShowTopCities">

                  <h6>Price Pattern Id</h6>
                  <p class="font-success fw-bold m-b-20">{{ selectedPricePatternForShowTopCities.pricePatternKey }}</p>

                  <p class="m-b-30">
                    Below you will find the top 20 city&CPOs with the selected price pattern.
                  </p>

                  <table class="table table-bordered table-striped border border-dark">
                    <thead class="text-black">
                    <tr>
                      <th class="font-success f-w-800 border border-dark">City Name</th>
                      <th class="font-success f-w-800 border border-dark">CPO Name</th>
                      <th class="font-success f-w-800 border border-dark">CPO ID</th>
                      <th class="font-success f-w-800 border border-dark">EVSE Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="topCityAndCpoItem in selectedPricePatternForShowTopCities.topCityAndCpo"
                        :key="topCityAndCpoItem.city">
                      <td class="border border-dark" style="width: 10%;">{{ topCityAndCpoItem.city }}</td>
                      <td class="border border-dark" style="width: 10%;">{{findCpoNameByCpoId(topCityAndCpoItem.operatorId)}}</td>
                      <td class="border border-dark" style="width: 10%;">{{ topCityAndCpoItem.operatorId }}</td>
                      <td class="border border-dark" style="width: 10%;">{{ topCityAndCpoItem.count }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-success" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal modalTopCityAndCposPricePattern end -->


        <!-- CARD START -->
        <div class="card" id="city-statistics">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Top 30 Cities by EVSE Count in {{ selectedCountry }}</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="container mt-4">
              <div class="total-results fw-bold">
                Please choose a city to view its details.
                <br/>
                <p>The cities are listed in order of the number of EVSEs they have, with the numbers in parentheses
                  indicating the count of EVSEs for each city.</p>
              </div>
              <div class="table-responsive">
                <div class="d-flex align-items-center mb-3">
                  <select
                      type="text"
                      v-model="selectedCityId"
                      class="form-control me-2 border border-primary"
                      style="width: 50%;"
                      @change="selectCityForStatistics"
                  >
                    <option disabled value="">select a city</option>
                    <option v-for="cityName in filteredCityStatisticsData.topCities" :value="cityName.city">
                      {{ cityName.city }} ({{ cityName.evseCount }})
                    </option>
                  </select>

                </div>

                <!-- PART 1 -->
                <h6 class="m-t-20 font-primary"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>General
                  Statistics</h6>
                <div v-if="selectedCityData">
                  City Name: <span class="fw-bold">{{ selectedCityId }}</span>
                  <br/>
                  Number of EVSES: <span class="fw-bold">{{ selectedCityData.evseCount }}</span>
                </div>


                <!-- PART 2 -->
                <h6 class="m-t-20 font-primary"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Power Type
                  Statistics</h6>
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark">Power Type</th>
                    <th class="font-primary f-w-800 border border-dark">Number of EVSEs</th>
                    <th class="font-primary f-w-800 border border-dark">Percentage</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="selectedCityData" v-for="selectedCityDataItem in selectedCityData.powerTypes"
                      :key="selectedCityDataItem.power_type">
                    <td class="border border-dark" style="width: 10%;">{{ selectedCityDataItem.power_type }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ selectedCityDataItem.count }}</td>
                    <td class="border border-dark" style="width: 10%;">
                      {{ roundToThreeDecimalPlaces(selectedCityDataItem.percentage) }}%
                    </td>
                  </tr>
                  </tbody>
                </table>

                <!-- PART 3 -->
                <h6 class="m-t-20 font-primary"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Top 20 CPOs
                </h6>
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark">CPO Name</th>
                    <th class="font-primary f-w-800 border border-dark">CPO Id</th>
                    <th class="font-primary f-w-800 border border-dark">Number of EVSEs</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="selectedCityData" v-for="topCpoDataItem in selectedCityData.topCPOs"
                      :key="topCpoDataItem.name">
                    <td class="border border-dark" style="width: 15%;">{{ topCpoDataItem.name }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ topCpoDataItem.cpo }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ topCpoDataItem.count }}</td>
                  </tr>
                  </tbody>
                </table>


                <!-- PART 4 -->
                <h6 class="m-t-20 font-primary"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Price
                  Patterns</h6>
                <div class="total-results fw-bold">
                  Please note that about 10 % of the Evses have no price.
                </div>
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark">CPO Name</th>
                    <th class="font-primary f-w-800 border border-dark">Pattern</th>
                    <th class="font-primary f-w-800 border border-dark">Number Of EVSES with Pattern</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="selectedCityData" v-for="pricePatternsDataItem in selectedCityData.pricePatterns"
                      :key="pricePatternsDataItem.name">
                    <td class="border border-dark" style="width: 15%;">{{ pricePatternsDataItem.name }}</td>
                    <td class="border border-dark fw-bold" style="width: 10%;">
                      {{ pricePatternsDataItem.pattern }}
                      <br/>
                      <button type="button"
                              @click="showPricePattern(pricePatternsDataItem.pattern)"
                              class="btn btn-outline-primary" data-bs-toggle="modal" data-bs-target="#modalPricePattern">
                        Price Pattern Detail
                      </button>
                      <br/>

                      <button type="button"
                              @click="showLocationPricePattern(pricePatternsDataItem.name, pricePatternsDataItem.cpo, pricePatternsDataItem.pattern)"
                              class="btn btn-outline-warning m-t-10" data-bs-toggle="modal"
                              data-bs-target="#modalLocationPricePatternSearch">
                        Find Charging Stations
                      </button>
                    </td>
                    <td class="border border-dark" style="width: 10%;">{{ pricePatternsDataItem.count }}</td>
                  </tr>
                  </tbody>
                </table>


              </div>
            </div>
          </div>
        </div>
        <!-- CARD END -->


        <!-- CARD START -->
        <div class="card" id="power-types">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Power Type Statistics for {{ selectedCountry }}</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="container mt-4">
              <h5>Power Type Statistics for {{ selectedCountry }}</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-success f-w-800 border border-dark" @click="sortTable('operator_name')">Power Type
                    </th>
                    <th class="font-success f-w-800 border border-dark" @click="sortTable('_id')">Number of EVSEs</th>
                    <th class="font-success f-w-800 border border-dark" @click="sortTable('_id')">Percentage</th>
                    <th class="font-success f-w-800 border border-dark" @click="sortTable('_id')">Top 5 CPOs</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="powerTypeItem in filteredConnectorAndPowerTypesCountryStatisticsData.powerTypes"
                      :key="powerTypeItem.power_type">
                    <td class="border border-dark" style="width: 20%;">{{ powerTypeItem.power_type }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ powerTypeItem.count }}</td>
                    <td class="border border-dark" style="width: 10%;">
                      {{ roundToThreeDecimalPlaces(powerTypeItem.percentage) }}%
                    </td>
                    <td class="border border-dark" style="width: 30%;">
                      <ul class="" v-for="topCpo in powerTypeItem.topCPOs" :key="topCpo.cpo">
                        <li class="border border-info">
                          &nbsp; {{ topCpo.name }} &nbsp; ({{ topCpo.cpo }}): {{ topCpo.count }}
                        </li>

                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="m-t-10">
                  Only EVSEs that support remote start and stop are included in the statistics.
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card" id="connector-types">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Connector Type Statistics for {{ selectedCountry }}</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="container mt-4">
              <h5>Connector Type Statistics for {{ selectedCountry }}</h5>
              <div class="table-responsive">
                <table class="table table-bordered table-striped border border-dark">
                  <thead class="text-black">
                  <tr>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('operator_name')">Connector
                      Type
                    </th>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('_id')">Number of EVSEs</th>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('_id')">Percentage</th>
                    <th class="font-primary f-w-800 border border-dark" @click="sortTable('_id')">Top 5 CPOs</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="connectorItem in filteredConnectorAndPowerTypesCountryStatisticsData.connectorStandards"
                      :key="connectorItem.standard">
                    <td class="border border-dark" style="width: 20%;">{{ connectorItem.standard }}</td>
                    <td class="border border-dark" style="width: 10%;">{{ connectorItem.count }}</td>
                    <td class="border border-dark" style="width: 10%;">
                      {{ roundToThreeDecimalPlaces(connectorItem.percentage) }}%
                    </td>
                    <td class="border border-dark" style="width: 30%;">
                      <ul class="" v-for="topCpo in connectorItem.topCPOs" :key="topCpo.cpo">
                        <li class="border border-info">
                          &nbsp; {{ topCpo.name }} &nbsp; ({{ topCpo.cpo }}): {{ topCpo.count }}
                        </li>

                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="m-t-10">
                  Only EVSEs that support remote start and stop are included in the statistics.
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- CARD END -->


        <!-- modal start -->
        <div class="modal fade" id="modalTopCities" tabindex="-1" role="dialog" ref="modalTopCities"
             aria-labelledby="modalTopCitiesLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Cities with the Most Charging Stations from CPO
                  <span
                      class="font-primary">{{
                      selectedCpoForShowTopCities ? selectedCpoForShowTopCities.operator_name : ''
                    }}</span>
                </h5>
              </div>
              <div class="modal-body">
                <div>
                  <ul v-if="selectedCpoForShowTopCities">

                    <div>
                      The CPO
                      <span class="font-primary">{{ selectedCpoForShowTopCities.operator_name }}</span>
                      is operational in
                      <span class="fw-bold">{{ selectedCpoForShowTopCities.cities.length }} </span>
                      cities.
                    </div>
                    <div class="m-b-20">
                      Below are the top 20 cities with the most charging stations.
                    </div>


                    <table class="table table-bordered table-striped border border-dark">
                      <thead class="text-black">
                      <tr>
                        <th class="font-primary f-w-800 border border-dark">City Name</th>
                        <th class="font-primary f-w-800 border border-dark">NR of Charging Stations</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="city in selectedCpoForShowTopCities.topCities" :key="city._id">
                        <td class="border border-dark" style="width: 20%;">{{ city._id }}</td>
                        <td class="border border-dark" style="width: 10%;">{{ city.count }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </ul>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal end -->

        <!-- modal start -->
        <div class="modal fade" id="modalConnectorAndPowerTypes" tabindex="-1" role="dialog"
             ref="modalConnectorAndPowerTypes" aria-labelledby="modalConnectorAndPowerTypesLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Connector Types and Power Types of CPO
                  <span
                      class="font-primary">{{
                      selectedCpoConnectorAndPowerTypesData ? selectedCpoConnectorAndPowerTypesData.operator_name : ''
                    }}</span>
                </h5>
              </div>
              <div class="modal-body">
                <div>
                  <div v-if="selectedCpoConnectorAndPowerTypesData">

                    <h6>Connector Types</h6>
                    <!-- Table 1 START -->
                    <table class="table table-bordered table-striped border border-dark">
                      <thead class="text-black">
                      <tr>
                        <th class="font-primary f-w-800 border border-dark">Connector Type</th>
                        <th class="font-primary f-w-800 border border-dark">Number of Charge Points</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="connectorTypeData in selectedCpoConnectorAndPowerTypesData.connectorStandards"
                          :key="connectorTypeData.standard">
                        <td class="border border-dark" style="width: 20%;">{{ connectorTypeData.standard }}</td>
                        <td class="border border-dark" style="width: 10%;">{{ connectorTypeData.count }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <!-- Table 1 END -->


                    <h6 class="m-t-30">Power Types</h6>
                    <!-- Table 1 START -->
                    <table class="table table-bordered table-striped border border-dark">
                      <thead class="text-black">
                      <tr>
                        <th class="font-primary f-w-800 border border-dark">Power Type</th>
                        <th class="font-primary f-w-800 border border-dark">Number of Charge Points</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="powerTypesData in selectedCpoConnectorAndPowerTypesData.powerTypes"
                          :key="powerTypesData.power_type">
                        <td class="border border-dark" style="width: 20%;">{{ powerTypesData.power_type }}</td>
                        <td class="border border-dark" style="width: 10%;">{{ powerTypesData.count }}</td>
                      </tr>
                      </tbody>
                    </table>
                    <!-- Table 1 END -->

                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal end -->

        <!-- modal start -->
        <div class="modal fade" id="modalPricePattern" tabindex="-1" role="dialog" ref="modalPricePattern"
             aria-labelledby="modalPricePatternLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Price Pattern Details
                  <span class="font-primary"></span>
                </h5>
              </div>
              <div class="modal-body">
                <div>
                  <div v-if="selectedPricePatternData">

                    <h6>Price Pattern Id</h6>
                    <div>{{ selectedPricePatternData.pricePatternKey }}</div>

                    <h6 class="m-t-20">Type</h6>
                    <div>{{ selectedPricePatternData.types }}</div>


                    <h6 class="m-t-20">restrictionKeys</h6>
                    <div>{{ selectedPricePatternData.restrictionKeys }}</div>

                    <h6 class="m-t-20">example</h6>
                    <div v-if="selectedPricePatternData && selectedPricePatternData.example">
                      <pre><code class="font-success" v-html="prettyPrintJson(selectedPricePatternData.example)"></code></pre>
                    </div>


                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal end -->

        <!-- modal LocationPricePatternSearch start -->
        <div class="modal fade" id="modalLocationPricePatternSearch" tabindex="-1" role="dialog"
             ref="modalLocationPricePatternSearch" aria-labelledby="modalLocationPricePatternSearchLabel">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Find Charging Station By Price Pattern, CPO and Zip Code
                  <span class="font-primary"></span>
                </h5>
              </div>
              <div class="modal-body">
                <div v-if="selectedLocationPricePatternsData">
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <h6>CPO Name</h6>
                      <div>{{ selectedLocationPricePatternsData.cpoName }}</div>
                    </div>
                    <div class="col-md-6">
                      <h6>Price Pattern Id</h6>
                      <div>{{ selectedLocationPricePatternsData.pricePatternKey }}</div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-md-6">
                      <h6>Zip Code</h6>
                      <input
                          type="text"
                          v-model="selectedLocationPricePatternsData.zipCode"
                          placeholder="Enter zipcode"
                          class="form-control border border-primary"
                          @input="findLocationsByPricePatternIdAndCpoId"
                          autocomplete="new-password"
                      />
                    </div>
                    <div class="col-md-6">
                      <h6>Street Name</h6>
                      <input
                          type="text"
                          v-model="selectedLocationPricePatternsData.streetName"
                          placeholder="Enter part of the street, not case sensitive"
                          class="form-control border border-primary"
                          @input="findLocationsByPricePatternIdAndCpoId"
                          autocomplete="new-password"
                      />
                    </div>
                  </div>
                  <h6 class="m-t-10">Found Locations</h6>
                  <div class="total-results fw-bold">
                    Only 100 results will be displayed. To refine your search, enter a zip code and/or part of the
                    street name (case insensitive).
                  </div>

                  <div
                      v-if="selectedLocationPricePatternsData.foundLocations && selectedLocationPricePatternsData.foundLocations.length">
                    <table class="table table-bordered table-striped border border-dark">
                      <thead class="text-black">
                      <tr>
                        <th class="font-primary f-w-800 border border-dark">ZipCode</th>
                        <th class="font-primary f-w-800 border border-dark">Street</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="foundLocation in selectedLocationPricePatternsData.foundLocations"
                          :key="foundLocation.locationPostalCode + foundLocation.locationAddress">
                        <td class="border border-dark" style="width: 15%;">{{ foundLocation.locationPostalCode }}</td>
                        <td class="border border-dark" style="width: 10%;">{{ foundLocation.locationAddress }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="font-danger"
                       v-if="selectedLocationPricePatternsData.foundLocations && selectedLocationPricePatternsData.foundLocations.length === 0">
                    No Location has been found.
                  </div>


                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <!-- modal LocationPricePatternSearch end -->

      </div>
    </div>
  </div>
</template>

<script>
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash as utilScrollToRootHash, scrollToTop} from "@/utils/anchorUtils";
import keyStatisticsData from '@/data/json/application/rydapis/ev/key-statistics/key-statistics.json';
import pricePatternsFromJson from '@/data/json/application/rydapis/ev/price-patterns/price-patterns.json';
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min.js';
import {Modal} from "bootstrap";

export default {
  name: "EvInsightsAndStatistics",
  keywords: [],
  data() {
    return {
      loggedInUserGroup: '',
      isUserGroupRyd: false,
      selectedCountry: 'Germany',
      showHint: true, // Initialize to true since deu is the default

      keyStatisticsData: keyStatisticsData,

      cpoStatistics: {},
      filteredCpoStatisticsData: [],
      filteredCpoNumberOfCount: 0,
      modelForTopCities: null,
      selectedCpoForShowTopCities: null,

      searchQuery: '',
      sortKey: 'locationsWithRemote',
      sortAsc: true,
      currentPage: 1,
      itemsPerPage: 10,

      pricePatternStatistics: {},
      filteredPricePatternStatisticsData: [],
      filteredPricePatternNumberOfCount: 0,
      modelForTopCitiesPricePattern: null,
      selectedPricePatternForShowTopCities: null,
      pricePatternTotalEvseCount: 0,

      searchQueryPricePattern: '',
      sortKeyPricePattern: 'evseCount',
      sortAscPricePattern: true,
      currentPagePricePattern: 1,
      itemsPerPagePricePattern: 10,

      connectorAndPowerTypesCpoStatistics: {},
      filteredConnectorAndPowerTypesCpoStatisticsData: [],
      selectedCpoConnectorAndPowerTypesData: null,

      connectorAndPowerTypesCountryStatistics: {},
      filteredConnectorAndPowerTypesCountryStatisticsData: [],
      selectedCountryConnectorAndPowerTypesData: null,

      cityStatistics: {},
      filteredCityStatisticsData: [],
      selectedCityData: null,
      selectedCityId: '',

      pricePatterns: pricePatternsFromJson,
      selectedPricePatternId: null,
      selectedPricePatternData: null,

      locationPricePatters: {},
      filteredLocationPricePattersData: [],
      selectedLocationPricePatternsData: {
        cpoName: '',
        cpoId: '',
        pricePatternKey: '',
        zipCode: '',
        streetName: '',
        foundLocations: []
      },

      lightBoxImages: [],
      index: 0, // Added missing index data property
      gallery1: [
        {
          image: 'ev-terms-v2.png'
        }
      ],
    }
  },
  mounted() {
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });

    this.modelForTopCities = new Modal('#modalTopCities');

    //this.filteredCpoStatistics();
    this.loadCountryData(this.selectedCountry);
    this.sortTable('locationsWithRemote'); // Sort the data initially
    this.sortTablePricePattern('evseCount'); // Sort the data initially


    this.$nextTick(() => {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
      });
    });

    const {userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.isUserGroupRyd = isUserGroupRyd;
    this.$nextTick(() => {
      this.scrollToRootHashWithDelay();
    });
  },
  computed: {
    totalVariations() {
      return this.filteredData.length;
    },
    totalCpos() {
      return this.filteredCpoStatisticsData.length;
    },
    filteredAndSortedCpos() {
      let result = this.filteredCpoStatisticsData || [];

      // Filter
      if (this.searchQuery) {
        const searchQueryLower = this.searchQuery.toLowerCase();
        result = result.filter((cpo) =>
            cpo.operator_name.toLowerCase().includes(searchQueryLower) ||
            cpo._id.toLowerCase().includes(searchQueryLower)
        );
      }

      this.filteredCpoNumberOfCount = result.length;

      // Sort
      if (this.sortKey) {
        result.sort((a, b) => {
          let compareA = a[this.sortKey];
          let compareB = b[this.sortKey];
          if (typeof compareA === 'string') {
            compareA = compareA.toLowerCase();
            compareB = compareB.toLowerCase();
          }
          if (this.sortAsc) {
            return compareA > compareB ? 1 : compareA < compareB ? -1 : 0;
          } else {
            return compareA < compareB ? 1 : compareA > compareB ? -1 : 0;
          }
        });
      }

      // Pagination
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return result.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredCpoStatisticsData.length / this.itemsPerPage);
    },
    filteredCpoCount() {
      return this.filteredCpoStatisticsData.length;
    },
    totalPricePatterns() {
      return this.filteredPricePatternStatisticsData.length;
    },
    totalPagesPricePatterns() {
      return Math.ceil(this.filteredPricePatternStatisticsData.length / this.itemsPerPagePricePattern);
    },
    filteredPricePatternsCount() {
      return this.filteredPricePatternStatisticsData.length;
    },
    filteredAndSortedPricePatterns() {
      let result = this.filteredPricePatternStatisticsData || [];

      // Filter
      if (this.searchQueryPricePattern) {
        const searchQueryLower = this.searchQueryPricePattern.toLowerCase();
        result = result.filter((pricePatternItem) =>
            pricePatternItem.pricePatternKey.toLowerCase().includes(searchQueryLower)
        );
      }

      this.filteredPricePatternNumberOfCount = result.length;

      // Sort
      if (this.sortKeyPricePattern) {
        result.sort((a, b) => {
          let compareA = a[this.sortKeyPricePattern];
          let compareB = b[this.sortKeyPricePattern];
          if (typeof compareA === 'string') {
            compareA = compareA.toLowerCase();
            compareB = compareB.toLowerCase();
          }
          if (this.sortAscPricePattern) {
            return compareA > compareB ? 1 : compareA < compareB ? -1 : 0;
          } else {
            return compareA < compareB ? 1 : compareA > compareB ? -1 : 0;
          }
        });
      }

      // Pagination
      const start = (this.currentPagePricePattern - 1) * this.itemsPerPagePricePattern;
      const end = start + this.itemsPerPagePricePattern;
      return result.slice(start, end);
    },


  },
  methods: {
    scrollToCard,
    scrollToRootHashWithDelay() {
      setTimeout(() => {
        utilScrollToRootHash.call(this, 75);
      }, 100);
    },
    scrollToTop,
    showImg(index) {
      this.index = index;
      this.visible = true;
      const imageUrl = this.lightBoxImages[this.index];

      this.$swal({
        imageUrl,
        imageAlt: 'Image',
        showCloseButton: true,
        showConfirmButton: false,
        customClass: 'swal-wide'
      });
    },
    async updateCountryRelatedData() {
      this.filteredCpoStatisticsData = this.cpoStatistics[this.selectedCountry];

      this.filteredPricePatternStatisticsData = this.pricePatternStatistics[this.selectedCountry];
      this.calculatePercentagesForPricePatterns();

      this.filteredConnectorAndPowerTypesCpoStatisticsData = this.connectorAndPowerTypesCpoStatistics[this.selectedCountry];
      this.filteredConnectorAndPowerTypesCountryStatisticsData = this.connectorAndPowerTypesCountryStatistics[this.selectedCountry];
      this.filteredCityStatisticsData = this.cityStatistics[this.selectedCountry];
      this.filteredLocationPricePattersData = this.locationPricePatters[this.selectedCountry] || [];
      this.currentPage = 1; // Reset to the first page when the country changes
      this.currentPagePricePattern = 1; // Reset to the first page when the country changes

      // Reset selected city data
      this.selectedCityId = '';
      this.selectedCityData = {
        cityName: '',
        evseCount: 0,
        topCPOs: [],
        powerTypes: [],
        pricePatterns: []
      };

      // Reset selected location price patterns data
      this.selectedLocationPricePatternsData = {
        cpoName: '',
        cpoId: '',
        pricePatternKey: '',
        zipCode: '',
        streetName: '',
        foundLocations: []
      };

    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.show('Copied to clipboard!', {
          theme: 'outline',
          position: 'bottom',
          type: 'success',
          duration: 5000
        });
      }).catch(err => {
        this.$toast.show('Failed to copy!', {theme: 'outline', position: 'bottom', type: 'danger', duration: 8000});
      });
    },
    handleCountryChange() {
      this.updateCountryRelatedData();
      this.showHint = true;

      const message = `Country has been changed to ${this.selectedCountry}. Scroll down to see the EV market insights and statistics in ${this.selectedCountry}.`;
      this.$toast.show(message, {
        theme: 'outline',
        position: 'top',
        type: 'success',
        duration: 5000
      });
    },
    sortTable(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    sortTablePricePattern(key) {
      if (this.sortKeyPricePattern === key) {
        this.sortAscPricePattern = !this.sortAscPricePattern;
      } else {
        this.sortKeyPricePattern = key;
        this.sortAscPricePattern = true;
      }
    },
    prevPagePricePattern() {
      if (this.currentPagePricePattern > 1) {
        this.currentPagePricePattern--;
      }
    },
    nextPagePricePattern() {
      if (this.currentPagePricePattern < this.totalPagesPricePatterns) {
        this.currentPagePricePattern++;
      }
    },
    showTopCities(cpo) {
      this.selectedCpoForShowTopCities = cpo;
    },
    showTopCitiesPricePattern(pricePattern) {
      this.selectedPricePatternForShowTopCities = pricePattern;
    },
    clickTopCposPricePattern(pricePattern) {
      this.selectedPricePatternForShowTopCities = pricePattern;
    },
    clickTopCityAndCposPricePattern(pricePattern) {
      this.selectedPricePatternForShowTopCities = pricePattern;
    },
    selectCityForStatistics() {
      this.selectedCityData = this.filteredCityStatisticsData.topCities.find(city => city.city === this.selectedCityId);

      if (this.selectedCityData && this.selectedCityData.pricePatterns) {
        this.selectedCityData.pricePatterns.sort((a, b) => b.count - a.count);
      }
    },
    showPricePattern(pricePatternKey) {
      this.selectedPricePatternData = this.pricePatterns.find(pattern => pattern.pricePatternKey === pricePatternKey);
    },
    findPatternNameById(pricePatternKey) {
      return this.pricePatterns.find(pattern => pattern.pricePatternKey === pricePatternKey).name;
    },
    showLocationPricePattern(cpoName, cpoId, pricePatternKey) {
      this.selectedLocationPricePatternsData.cpoId = cpoId;
      this.selectedLocationPricePatternsData.cpoName = cpoName;
      this.selectedLocationPricePatternsData.pricePatternKey = pricePatternKey;
      this.selectedLocationPricePatternsData.zipCode = '';
      this.selectedLocationPricePatternsData.streetName = '';
      this.selectedLocationPricePatternsData.foundLocations = [];
      this.findLocationsByPricePatternIdAndCpoId();
    },
    findLocationsByPricePatternIdAndCpoId() {
      const uniqueLocations = new Set();
      const results = [];
      const limit = 100;

      for (const locationData of this.filteredLocationPricePattersData) {
        if (results.length >= limit) break;

        const matchCity = locationData.city === this.selectedCityId;
        const matchesCpoId = locationData.operatorId === this.selectedLocationPricePatternsData.cpoId;
        const matchesPricePatternId = locationData.pricePatternKey === this.selectedLocationPricePatternsData.pricePatternKey;
        const matchesPostalCode = !this.selectedLocationPricePatternsData.zipCode ||
            locationData.locationPostalCode.includes(this.selectedLocationPricePatternsData.zipCode);
        const matchesStreetName = !this.selectedLocationPricePatternsData.streetName ||
            locationData.locationAddress.toLowerCase().includes(this.selectedLocationPricePatternsData.streetName.toLowerCase());

        if (matchCity && matchesCpoId && matchesPricePatternId && matchesPostalCode && matchesStreetName) {
          const uniqueKey = `${locationData.locationPostalCode}_${locationData.locationAddress.toLowerCase()}`;
          if (!uniqueLocations.has(uniqueKey)) {
            uniqueLocations.add(uniqueKey);
            results.push(locationData);
          }
        }
      }

      // Directly assign the results to the foundLocations property
      this.selectedLocationPricePatternsData.foundLocations = results;
    },
    showConnectorAndPowerTypes(cpo) {
      this.selectedCpoConnectorAndPowerTypesData = {operator_name: cpo.operator_name};

      let expectedInputData = this.filteredConnectorAndPowerTypesCpoStatisticsData;

      // Check if expectedInputData and cpoStats are valid
      if (!expectedInputData || !expectedInputData.cpoStats) {
        this.selectedCpoConnectorAndPowerTypesData = {};
        return;
      }

      // Check if the desired CPO exists in the cpoStats
      let desiredResult = expectedInputData.cpoStats[cpo.operator_external_id];
      if (!desiredResult) {
        this.selectedCpoConnectorAndPowerTypesData = {};
        return;
      }


      // Assign the desired result to selectedCpoConnectorAndPowerTypesData
      this.selectedCpoConnectorAndPowerTypesData = {
        ...this.selectedCpoConnectorAndPowerTypesData,
        ...desiredResult
      };
    },
    async loadCountryData(countryCode) {
      try {
        const [
          germanyStatsCpo, belgiumStatsCpo,
          germanyConnectorPowerTypeCpoStats, belgiumConnectorPowerTypeCpoStats,
          germanyConnectorPowerTypeCountryStats, belgiumConnectorPowerTypeCountryStats,
          germanyStatsCity, belgiumStatsCity,
          germanyLocationPricePatters, belgiumLocationPricePatters,
          germanyStatsPricePattern, belgiumStatsPricePattern,
        ] = await Promise.all([
          import(`@/data/json/application/rydapis/ev/cpo/deu/cpo-statistics.json`),
          import(`@/data/json/application/rydapis/ev/cpo/bel/cpo-statistics.json`),
          import(`@/data/json/application/rydapis/ev/connector-power-types/deu/cpo-stats.json`),
          import(`@/data/json/application/rydapis/ev/connector-power-types/bel/cpo-stats.json`),
          import(`@/data/json/application/rydapis/ev/connector-power-types/deu/country-stats.json`),
          import(`@/data/json/application/rydapis/ev/connector-power-types/bel/country-stats.json`),
          import(`@/data/json/application/rydapis/ev/city/deu/city-stats.json`),
          import(`@/data/json/application/rydapis/ev/city/bel/city-stats.json`),
          import(`@/data/json/application/rydapis/ev/location-price-pattern/deu/location-price-patterns.json`),
          import(`@/data/json/application/rydapis/ev/location-price-pattern/bel/location-price-patterns.json`),
          import(`@/data/json/application/rydapis/ev/price-patterns/country/deu/price-patterns-per-country.json`),
          import(`@/data/json/application/rydapis/ev/price-patterns/country/bel/price-patterns-per-country.json`),
        ]);

        this.cpoStatistics = {
          Germany: germanyStatsCpo.default,
          Belgium: belgiumStatsCpo.default
        };
        this.filteredCpoStatisticsData = this.cpoStatistics[countryCode];

        this.pricePatternStatistics = {
          Germany: germanyStatsPricePattern.default,
          Belgium: belgiumStatsPricePattern.default
        };
        this.filteredPricePatternStatisticsData = this.pricePatternStatistics[countryCode];
        this.calculatePercentagesForPricePatterns();

        this.connectorAndPowerTypesCpoStatistics = {
          Germany: germanyConnectorPowerTypeCpoStats.default,
          Belgium: belgiumConnectorPowerTypeCpoStats.default
        }
        this.filteredConnectorAndPowerTypesCpoStatisticsData = this.connectorAndPowerTypesCpoStatistics[countryCode];

        this.connectorAndPowerTypesCountryStatistics = {
          Germany: germanyConnectorPowerTypeCountryStats.default,
          Belgium: belgiumConnectorPowerTypeCountryStats.default
        }
        this.filteredConnectorAndPowerTypesCountryStatisticsData = this.connectorAndPowerTypesCountryStatistics[countryCode];

        this.cityStatistics = {
          Germany: germanyStatsCity.default,
          Belgium: belgiumStatsCity.default
        };
        this.filteredCityStatisticsData = this.cityStatistics[countryCode];

        this.locationPricePatters = {
          Germany: germanyLocationPricePatters.default,
          Belgium: belgiumLocationPricePatters.default
        };
        this.filteredLocationPricePattersData = this.locationPricePatters[countryCode];

      } catch (error) {
        console.error(`Error loading data for country: ${countryCode}`, error);
      }
    },
    roundToThreeDecimalPlaces(value) {
      return value.toFixed(3);
    },
    prettyPrintJson(jsonObject) {
      return `<pre>${JSON.stringify(jsonObject, null, 2)}</pre>`;
    },
    calculatePercentagesForPricePatterns() {
      this.pricePatternTotalEvseCount = this.filteredPricePatternStatisticsData.reduce((total, item) => {
        return total + (item.evseCount || 0);
      }, 0);

      // Calculate percentage for each item
      this.filteredPricePatternStatisticsData = this.filteredPricePatternStatisticsData.map(item => {
        const percentage = this.pricePatternTotalEvseCount > 0 ? ((item.evseCount || 0) / this.pricePatternTotalEvseCount) * 100 : 0;
        return {
          ...item,
          percentage: percentage.toFixed(2) // Add percentage field
        };
      });
    },
    findCpoNameByCpoId(cpoId) {
      // example cpoId: DE*ETT
      return this.filteredCpoStatisticsData.find(cpoStatisticItem => cpoStatisticItem._id === cpoId)?.operator_name;
    }
  },
  watch: {
    searchQuery: function () {
      this.currentPage = 1; // Reset to the first page when search query changes
    },
    '$route.hash': function (newHash, oldHash) {
      if (newHash !== oldHash) {
        this.$nextTick(() => {
          this.scrollToRootHashWithDelay();
        });
      }
    },
    'selectedLocationPricePatternsData.zipCode': 'findLocationsByPricePatternIdAndCpoId',
    'selectedLocationPricePatternsData.streetName': 'findLocationsByPricePatternIdAndCpoId'
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/swal_custom.scss";
</style>

<style>
.table th {
  cursor: pointer;
}
</style>
