
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '@/assets/scss/app.scss';
import VueFeather from 'vue-feather';
import store from './store';
import Breadcrumbs from './components/bread_crumbs';
import VueApexCharts from 'vue3-apexcharts';
import 'bootstrap';
import settingPage from './components/settingPage.vue';
import DropZone from 'dropzone-vue';
import 'bootstrap/dist/js/bootstrap.bundle';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import PerfectScrollbar from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css';
import Toaster from '@meforma/vue-toaster';
import Vue3Tour from 'vue3-tour';
import 'vue3-tour/dist/vue3-tour.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import bcard from './components/b-card.vue';
import Vue3Toasity from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import VueAnimateOnScroll from 'vue3-animate-onscroll';
import { quillEditor } from 'vue3-quill';
import Lightbox from 'vue-easy-lightbox';
import { VueMasonryPlugin } from 'vue-masonry';
import vue3StarRatings from 'vue3-star-ratings';
import Datepicker from 'vue3-datepicker';
import VueNumber from 'vue-number-animation';
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import { createI18n } from 'vue-i18n';
import English from './locales/en.json';
import Français from './locales/fr.json';
import Português from './locales/pt.json';
import Español from './locales/es.json';
import Deutsch from './locales/de.json';
import 简体中文 from './locales/cn.json';
import لعربية from './locales/ae.json';
import { defaultLocale, localeOptions } from './constants/config';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueGoogleMaps from '@fawmi/vue-google-maps';

const locale = (localStorage.getItem('currentLanguage') &&
    localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0)
    ? localStorage.getItem('currentLanguage')
    : defaultLocale;

const i18n = createI18n({
    locale: locale,
    messages: {
        English: English,
        Español: Español,
        Deutsch: Deutsch,
        Français: Français,
        Português: Português,
        简体中文: 简体中文,
        لعربية: لعربية,
    },
});

const app = createApp(App);

app.use(Toast);
app.use(i18n);
app.use(SimpleTypeahead);
app.use(VueMasonryPlugin);
app.use(VueNumber);
app.component('vue3-star-ratings', vue3StarRatings);
app.use(quillEditor);
app.use(Lightbox);
app.use(store);
app.use(VueSweetalert2);
app.use(VueAnimateOnScroll);
app.use(Vue3Toasity);
app.use(Vue3Tour);
app.use(Toaster);
app.use(router);
app.component('settingPage', settingPage);
app.component('b-card', bcard);
app.use(PerfectScrollbar);
app.use(DropZone);
app.component('multiselect', Multiselect);
app.component(VueFeather.name, VueFeather);
app.component('Breadcrumbs', Breadcrumbs);
app.component('apexchart', VueApexCharts);
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(require('vue-chartist'));
app.component('Datepicker', Datepicker);
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyCMG1KGtL6MYvT1ypk8X29GygIOJpNZApw',
    },
});
app.mount('#app');
