<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Guardian Checks"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="row">
          <!-- Loading Indicator START -->
          <div v-if="isOfflineStationsLoading" class="loading-indicator">
            <h6 class="sub-title mb-0 text-center">Loading Guradian Checks...</h6>
            <div class="loader-box">
              <div class="loader-19"></div>
            </div>
          </div>
          <!-- Loading Indicator END -->
        </div>

        <div class="row">
          <div class="card">
            <div class="card-header bg-primary">
              <h5> All guardian checks at a glance </h5>
            </div>
            <div class="card-body">
              <div class="theme-form">
                <div class="row">
                  <div class="col-12">
                    <div class="input-group">

                      <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                        <label class="me-2" for="guardianCheckId">Guardian Check Id:</label>
                        <input v-model="guardianCheckId" type="search" class="form-control"
                               id="guardianCheckId" placeholder="Check Id">
                      </div>

                      <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                        <label class="me-2" for="cashierSystem">Cashier System:</label>
                        <input v-model="cashierSystem" type="search" class="form-control"
                               id="cashierSystem" placeholder="Cashier System">
                      </div>


                      <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                        <label class="me-2" for="partnerName">MOC Partner:</label>
                        <input v-model="partnerName" type="search" class="form-control"
                               id="partnerName" placeholder="MOC Partner">
                      </div>

                      <button @click="fetchOfflineStationsAndReset"
                              class="btn btn-pill btn-success btn-air-success active input-group-text">
                        <i class="fa fa-search m-r-15"></i>Search
                      </button>
                      <button @click="resetSearch"
                              class="btn btn-pill btn-secondary btn-air-secondary input-group-text m-l-20">
                        <i class="fa fa-times m-r-15"></i>Reset Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- User List and Pagination -->
        <div v-if="!isOfflineStationsLoading && offlineStations.length > 0">
          <!-- second card: found users -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive product-table">
                    <table class="display table-striped" id="basic-1">
                      <thead class="bg-primary text-white">
                      <tr>
                        <th @click="sortOfflineStations('GUARDIAN_CHECK_ID')" :class="getSortClass('guardian_check_id')" aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Guardian Check Id
                          <i v-if="sortColumn === 'GUARDIAN_CHECK_ID'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('POI_ID')" :class="getSortClass('poi_id')" aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">POI ID
                          <i v-if="sortColumn === 'POI_ID'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('CASHIER_SYSTEM')" :class="getSortClass('cashier_system')" aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 10%;">Cashier System
                          <i v-if="sortColumn === 'CASHIER_SYSTEM'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('BRAND')" :class="getSortClass('brand')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Brand
                          <i v-if="sortColumn === 'BRAND'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('PARTNER_NAME')" :class="getSortClass('partner_name')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">MOC Partner
                          <i v-if="sortColumn === 'PARTNER_NAME'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('COUNTRY_CODE')" :class="getSortClass('country_code')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Country
                          <i v-if="sortColumn === 'COUNTRY_CODE'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('CASHIER_SYSTEM_COUNT')" :class="getSortClass('cashier_system_count')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Stations Of Cashier System
                          <i v-if="sortColumn === 'CASHIER_SYSTEM_COUNT'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('CASHIER_SYSTEM_COUNT_COUNTRY')" :class="getSortClass('cashier_system_count_country')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Stations Of Cashier System At Country
                          <i v-if="sortColumn === 'CASHIER_SYSTEM_COUNT_COUNTRY'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('PARTNER_NAME_COUNT')" :class="getSortClass('partner_name_count')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Stations Of MOC Partner
                          <i v-if="sortColumn === 'PARTNER_NAME_COUNT'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('PARTNER_NAME_COUNT_COUNTRY')" :class="getSortClass('partner_name_count_country')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Stations Of MOC Partner At Country
                          <i v-if="sortColumn === 'PARTNER_NAME_COUNT_COUNTRY'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                        <th @click="sortOfflineStations('LAST_UPDATED_AT')" :class="getSortClass('last_updated_at')" aria-controls="basic-1" rowspan="1"
                            colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 16%;">Last Checked At
                          <i v-if="sortColumn === 'LAST_UPDATED_AT'"
                             :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="offlineStationItem in offlineStations" :key="offlineStationItem">
                        <td>
                          {{ offlineStationItem.guardian_check_id }}
                          <br/>
                          <a
                              target="_blank"
                              class="cursor-pointer m-b-10 m-t-10"
                              title="find out the aggregated offline status dashboards"
                              :href="`/guardian-system-and-alerts/station-offline-status-dashboard?guardianCheckId=${offlineStationItem.guardian_check_id}`">
                            Dashboard
                          </a>

                          <br/>

                          <a
                              target="_blank"
                              class="cursor-pointer"
                              title="find out the aggregated offline status dashboards"
                              :href="`/guardian-system-and-alerts/guardian-check-map-view?guardianCheckId=${offlineStationItem.guardian_check_id}`">
                            Map View
                          </a>

                        </td>
                        <td>
                          {{ offlineStationItem.poi_id }}
                          <br/>
                          <a data-bs-toggle="modal"
                             @click="openSelectedPoiModal(offlineStationItem.poi_id)"
                             class="cursor-pointer m-b-10  m-t-10"
                             data-bs-target="#poiDetailModalCenter">
                             Station Info</a>

                          <br/>

                          <a target="_blank"
                             class="cursor-pointer"
                             :href="offlineHistoryLink(offlineStationItem.poi_id)">
                            Offline History</a>

                        </td>
                        <td>{{ offlineStationItem.cashier_system }}</td>
                        <td>{{ offlineStationItem.brand }}</td>
                        <td>{{ offlineStationItem.partner_name }}</td>
                        <td>{{ offlineStationItem.country_code }}</td>
                        <td>{{ offlineStationItem.cashier_system_count }}</td>
                        <td>{{ offlineStationItem.cashier_system_count_country }}</td>
                        <td>{{ offlineStationItem.partner_name_count }}</td>
                        <td>{{ offlineStationItem.partner_name_count_country }}</td>
                        <td>{{ offlineStationItem.last_updated_at }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

              <!-- Pagination Controls -->
              <nav aria-label="User list pagination" v-if="totalPages > 0" class="m-t-15">
                <ul class="pagination">
                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="prevPage">Previous</button>
                  </li>

                  <!-- Example: Simple pagination buttons -->
                  <li class="page-item" v-for="n in totalPages" :key="n" :class="{ active: n === currentPage }">
                    <button class="page-link" @click="goToPage(n)">{{ n }}</button>
                  </li>

                  <li class="page-item" :class="{ disabled: currentPage * pageSize >= totalOfflineStations }">
                    <button class="page-link" @click="nextPage">Next</button>
                  </li>
                </ul>

              </nav>

              <!-- Total Results Display -->
              <div v-if="totalPages > 0" class="total-results m-t-15 fw-bold">
                <p>{{ totalOfflineStations }} Row<span v-if="totalOfflineStations !== 1">s</span> found.</p>
              </div>

              <br/><br/>
            </div>
          </div>
        </div>

        <div v-if="!isOfflineStationsLoading && offlineStations.length === 0">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="alert alert-danger dark" role="alert"><p>No offline station was found. Please refine your search
                  criteria. </p></div>
              </div>
            </div>
          </div>
        </div>


        <!-- poiDetailModal -->
        <div class="modal fade" id="poiDetailModalCenter" tabindex="-1" role="dialog" ref="poiDetailModal"
             aria-labelledby="poiDetailModalCenter">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">POI Detail</h5>
              </div>
              <div class="modal-body">
                <div class="row border-bottom m-b-20 loading-indicator" v-if="isSelectedPoiModalDataLoading">
                    <h6 class="sub-title mb-0 text-center">Loading gas station detail ...</h6>
                    <div class="loader-box">
                      <div class="loader-19"></div>
                    </div>
                </div>

                <div class="row border-bottom m-b-20" v-if="!isSelectedPoiModalDataLoading">
                  <div class="col">
                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="poi_id">POI ID at ryd</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiId }}</p>
                      </div>
                    </div>
                    <hr class="my-2">


                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_country_code">Country</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_country_code }}</p>
                      </div>
                    </div>
                    <hr class="my-2">


                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_zip">Zip</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_zip }}</p>
                      </div>
                    </div>
                    <hr class="my-2">

                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_city">City</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_city }}</p>
                      </div>
                    </div>
                    <hr class="my-2">

                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_street">Street</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_street }}</p>
                      </div>
                    </div>
                    <hr class="my-2">

                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_house_number">House Number</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_house_number }}</p>
                      </div>
                    </div>
                    <hr class="my-2">

                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_latitude">Latitude</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_latitude }}</p>
                      </div>
                    </div>
                    <hr class="my-2">

                    <div class="row">
                      <div class="col-md-4 fw-bold text-primary">
                        <label for="address_longitude">Longitude</label>
                      </div>
                      <div class="col-md-8">
                        <p>{{ selectedPoiData.address_longitude }}</p>
                      </div>
                    </div>
                    <hr class="my-2">
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {auth} from '@/firebase/firebaseInit';
import {
  PARTNER_ANALYTICS_API_KEY,
  PARTNER_ANALYTICS_SERVER_URL, PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import getParamsFromHash from "@/utils/urlUtils";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";


export default {
  name: 'guardianChecks',
  keywords: ['Guardian Checks', 'Station Offline Status', 'Offline Stations Overview', 'Aggregated Station Information', 'Cashier System Station Status', 'MOC Partner Station Status', 'Station Availability', 'Offline Station Metrics', 'Station Status Summary', 'Station Offline Aggregation', 'View Offline Stations', 'Station Status by Cashier System', 'Station Status by MOC Partner', 'Station Availability Report', 'Station Offline Dashboard', 'Station Operational Status'],
  data() {
    return {
      users: [],
      currentUserUid: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      isModalVisible: false,
      theModal: null,
      isLoading: false,
      isOfflineStationsLoading: false,
      currentPage: 1,
      pageSize: 20,
      totalUsers: 0,
      searchKey: '',
      sortColumn: 'FIRSTNAME',
      sortOrder: 'asc', // or 'desc'
      offlineStations: [],
      totalOfflineStations: 0,
      guardianCheckId: null,
      partnerName: null,
      cashierSystem: null,
      selectedPoiId: null,
      selectedPoiModalActive: false,
      isSelectedPoiModalDataLoading: false,
      selectedPoiData: {
        poi_id: null,
        address_street: null,
        address_house_number: null,
        address_zip: null,
        address_city: null,
        address_country_code: null,
        address_latitude: null,
        address_longitude: null,
      }
    };
  },
  created() {
    //this.fetchUsers();
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    this.initializeParameters();
    this.fetchOfflineStations();
  },
  computed: {
    totalPages() {
      if (!this.totalOfflineStations || !this.pageSize) {
        return 0; // Default to 0 if totalOfflineStations or pageSize is not set or is 0
      }
      return Math.ceil(this.totalOfflineStations / this.pageSize);
    }
  },
  methods: {
    offlineHistoryLink(poiId) {
      return `/guardian-system-and-alerts/station-offline-history?poiId=${poiId}`;
    },
    goToOfflineHistory(poiId) {
        this.$router.push({
          path: '/guardian-system-and-alerts/station-offline-history',
          query: { poiId: this.poiId }
        });
    },
    async fetchOfflineStationsAndReset() {
      this.currentPage = 1;
      this.fetchOfflineStations();
    },
    resetSearch() {
      this.searchKey = ''; // Clear the search key
      this.currentPage = 1;
      this.sortColumn = "guardian_check_id";
      this.sortOrder = "desc";
      this.guardianCheckId = null;
      this.cashierSystem = null;
      this.partnerName = null;
      this.fetchOfflineStations();   // Fetch users without search filter
    },
    async fetchOfflineStations() {

      this.isOfflineStationsLoading = true;

      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/offline-gas-stations`, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            searchKey: this.searchKey,
            sortBy: this.sortColumn,
            order: this.sortOrder,
            guardianCheckId: this.guardianCheckId,
            partnerName: this.partnerName,
            cashierSystem: this.cashierSystem
          },
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId
          }
        });

        this.offlineStations = response.data.data;
        this.totalOfflineStations = response.data.total;
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error
      } finally {
        this.isOfflineStationsLoading = false;
      }
    },
    goToPage(page) {
      this.currentPage = page;
      this.fetchOfflineStations();
    },

    nextPage() {
      if (this.currentPage * this.pageSize < this.totalUsers) {
        this.currentPage++;
        this.fetchOfflineStations();
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchOfflineStations();
      }
    },
    sortOfflineStations(column) {
      if (this.sortColumn === column) {
        // Toggle sort order
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column.toLowerCase();
        this.sortOrder = 'asc';
      }
      this.fetchOfflineStations();
    },

    getSortClass(column) {
      if (this.sortColumn !== column) {
        return '';
      }
      return this.sortOrder === 'asc' ? 'sorting_asc' : 'sorting_desc';
    },
    initializeParameters() {
      let paramsFromHash = getParamsFromHash(window.location.href);
      this.guardianCheckId = paramsFromHash.guardianCheckId || '';
      this.partnerName = paramsFromHash.partnerName || '';
      this.cashierSystem = paramsFromHash.cashierSystem || '';
    },
    async openSelectedPoiModal(poiId) {
      this.selectedPoiModalActive = true;
      this.isSelectedPoiModalDataLoading = true;
      this.selectedPoiId = poiId;
      this.selectedPoiData = {};
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/gas-stations/${poiId}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid':  this.loggedInUserId,
          }
        });

        this.selectedPoiData = response.data.data;
      } catch (error) {
        console.log("error", error);
      }finally {
        this.isSelectedPoiModalDataLoading = false;
      }
    },
  }

}


</script>


<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>
<style scoped>
@import "@/assets/css/productlist.css";
</style>
<style scoped>
.sortable {
  cursor: pointer;
}

.sortable.active {
  color: #0d6efd; /* Bootstrap primary color */
}
</style>