<template>
  <Breadcrumbs main="Guardian System and Alerts" title="View And Edit an Alert"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="m-t-20 mb-0 text-center f-w-900">Loading An Alert and its Details ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main  content -->
        <div class="col-sm-12" v-if="!isLoading && alert">
          <div class="card">
            <div class="card-header bg-primary">
            </div>
            <div class="card-body">
              <div class="form theme-form">

                <!-- Alert Disabled Until -->
                <div class="mb-3" v-if="alert.cashierSystemName">
                  <label class="fw-bold font-primary f-16">Cashier System</label>
                  <p>{{ alert.cashierSystemName }}</p>
                </div>

                <div class="mb-3" v-if="alert.mocPartnerName">
                  <label class="fw-bold font-primary f-16">MOC Partner</label>
                  <p>{{ alert.mocPartnerName }}</p>
                </div>

                <div class="mb-3" v-if="alert.country">
                  <label class="fw-bold font-primary f-16">Country</label>
                  <p>{{ alert.country }}</p>
                </div>

                <div class="mb-5">
                  <label class="fw-bold font-primary f-16">Last Updated At</label>
                  <p>{{ alert.lastModifiedAt }}</p>
                </div>


                <button class="btn btn-success m-t-10" type="button" data-bs-toggle="modal"
                        @click="openResponseStatusModal()"
                        data-bs-target="#responseStatusModalCenter">Update Response Status
                </button>
                <div class="mb-3" v-if="alert.responseStatus">
                  <label class="fw-bold font-primary f-16">has been responded by partner?</label>
                  <p>{{ alert.responseStatus.hasResponded }}</p>
                </div>

                <div class="mb-3" v-if="alert.responseStatus">
                  <label class="fw-bold font-primary f-16">Last responded by partner Date Time</label>
                  <p>{{ alert.responseStatus.lastRespondedTimestamp }}</p>
                </div>

                <div class="mb-5" v-if="alert.responseStatus">
                  <label class="fw-bold font-primary f-16">Last responded by partner Note</label>
                  <p>{{ alert.responseStatus.lastRespondedNote }}</p>
                </div>

                <button class="btn btn-success m-t-10" type="button" data-bs-toggle="modal"
                        @click="openNoteModal()"
                        data-bs-target="#noteModalCenter">Add a Note
                </button>
                <div class="mb-3">
                  <label class="fw-bold font-primary f-16">General Note To Alert</label>
                  <div v-for="(note, index) in alert.notes" :key="index">
                    <p>{{ note }}</p>
                  </div>
                </div>


                <!-- Email Configurations -->
                <div class="mb-3 m-t-30" v-if="alert.alertHistory">
                  <label class="fw-bold font-primary f-16">Alert History</label>
                  <table class="table table-striped">
                    <thead class="">
                    <tr>
                      <th>Timestamp</th>
                      <th>nrOfOfflineStations</th>
                      <th>nrOfTotalStations</th>
                      <th>offlineRate</th>
                      <th>alertType</th>
                      <th>communicatedAt</th>
                      <th>alreadyCommunicated</th>
                      <th>communicationNeeded</th>
                      <th>guardianCheckId</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(alertHistoryItem, index) in alert.alertHistory" :key="index">
                      <td>{{ alertHistoryItem.timestamp }}</td>
                      <td>{{ alertHistoryItem.nrOfOfflineStations }}</td>
                      <td>{{ alertHistoryItem.nrOfTotalStations }}</td>
                      <td>{{ alertHistoryItem.offlineRate }}</td>
                      <td>{{ alertHistoryItem.alertType }}</td>
                      <td>{{ alertHistoryItem.communicatedAt }}</td>
                      <td>{{ alertHistoryItem.alreadyCommunicated }}</td>
                      <td>{{ alertHistoryItem.communicatedAt }}</td>
                      <td>{{ alertHistoryItem.guardianCheckId }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>


              </div>
            </div>
          </div>
        </div>


        <!-- responseStatusModal -->
        <div class="modal fade" id="responseStatusModalCenter" tabindex="-1" role="dialog" ref="responseStatusModal"
             aria-labelledby="responseStatusModalCenter">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Update Response Status</h5>
              </div>
              <div class="modal-body">
                <p>Update the response status when ryd has received any response from a cashier system or a MOC
                  partner.</p>
                <br/>
                <div class="row border-bottom m-b-20">
                  <div class="col">

                    <div class="mb-3">
                      <label for="hasResponded" class="fw-bold">has been responded by partner?</label>
                      <select class="form-control border border-primary" id="hasResponded"
                              v-model="responseStatusData.hasResponded" required @change="convertToBoolean">
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>

                    <div class="mb-3">
                      <label for="hasResponded" class="fw-bold">Last responded by partner Date Time</label>
                      <input type="datetime-local" v-model="responseStatusData.lastRespondedTimestamp"
                             class="form-control">
                    </div>

                    <div class="mb-3">
                      <label for="hasResponded" class="fw-bold">Last responded by partner Note</label>
                      <textarea v-model="responseStatusData.lastRespondedNote" class="form-control"></textarea>
                    </div>


                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-secondary" type="button" @click="confirmUpdateResponseStatus">Confirm</button>
              </div>
            </div>
          </div>
        </div>


        <!-- noteModal -->
        <div class="modal fade" id="noteModalCenter" tabindex="-1" role="dialog" ref="noteStatusModal"
             aria-labelledby="noteModalCenter">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Add additional Alert Note</h5>
              </div>
              <div class="modal-body">
                <p>Add a note to the selected alert.</p>
                <br/>
                <div class="row border-bottom m-b-20">
                  <div class="col">

                    <div class="mb-3">
                      <textarea v-model="noteData.note" class="form-control"></textarea>
                    </div>


                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-secondary" type="button" @click="confirmAddingNoteStatus">Confirm</button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {Modal} from "bootstrap";

export default {
  name: 'PartnerGuardianAlertsEdit',
  keywords: ['View and Edit Alert', 'Edit Alert Details', 'Add Alert Responses', 'Add Alert Comments', 'Customer Support Alert Management', 'Edit Alert Information', 'Alert Response Management', 'Update Alert', 'Alert Commenting', 'Support Alert Handling', 'Alert Response Entry', 'Alert Comment Entry', 'Support Edit Alert', 'Manage Alert Responses', 'Alert Editing and Comments'],
  data() {
    return {
      isLoading: false,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      originFromPage: null,
      alertId: null,
      alert: null,
      responseStatusModal: null,
      isResponseStatusModalVisible: false,
      responseStatusData: {
        hasResponded: false,
        lastRespondedTimestamp: null,
        lastRespondedNote: null
      },
      noteModal: null,
      isNoteModalVisible: false,
      noteData: {
        note: null
      }
    };
  },
  mounted() {
    this.responseStatusModal = new Modal('#responseStatusModalCenter');
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
    } else {
      this.partnerMetricsFor = userGroup;
    }
    this.alertId = this.$route.query.alertId;
    if (!this.alertId) {
      // Redirect to the list page if the ID is not provided
      this.$router.push('/guardian-system-and-alerts/alerts/list?originFromPage=edit');
    } else {
      this.loadAlerts();
    }

  },
  methods: {
    convertToBoolean() {
      this.responseStatusData.hasResponded = this.responseStatusData.hasResponded === 'true';
    },
    async loadAlerts() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/alerts/${this.alertId}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        this.alert = response.data.data;
        if (response.data.data && response.data.data.responseStatus) {
          this.responseStatusData = response.data.data.responseStatus;
        }
      } catch (error) {
        console.log("error", error);
      }finally {
        this.isLoading = false;
      }
    },
    async confirmUpdateResponseStatus() {

      axios.patch(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/alerts/${this.alertId}/response_status`, this.responseStatusData, {
        headers: {
          'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
          'uid': this.loggedInUserId
        }
      }).then(() => {
        window.location.href = `/guardian-system-and-alerts/alerts/edit?alertId=${this.alertId}`;
      })
          .catch(error => {
            console.log("error", error);
            this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to create configuration';
          })
    },
    openNoteModal() {
      this.isNoteModalVisible = true;
    },
    async confirmAddingNoteStatus() {

      axios.patch(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/alerts/${this.alertId}/note`, this.noteData, {
        headers: {
          'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
          'uid': this.loggedInUserId
        }
      }).then(() => {
        window.location.href = `/guardian-system-and-alerts/alerts/edit?alertId=${this.alertId}`;
      })
          .catch(error => {
            console.log("error", error);
            this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to create configuration';
          })
    },
    openResponseStatusModal() {
      this.isResponseStatusModalVisible = true;
    },

  }
};
</script>

<style scoped>
/* Add any specific styles for your component here */
</style>