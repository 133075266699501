<template>
  <Breadcrumbs main="rydpay APIs" title="Best Practices" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="ribbon-wrapper card">
          <div class="card-body">
            <div class="ribbon ribbon-clip ribbon-secondary">Index of Content</div>

            <p>
              On this page, you will find best practice tips for integrating with Rydpay APIs to ensure a reliable and extensible client implementation. These practices are applicable to all the supported products: fuelling, car wash, and EV charging.
              The topics covered include:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('api-timeouts')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Handling API Request Timeouts</a>
                Learn how to manage API request timeouts effectively to ensure seamless communication with the Rydpay API.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('order-completion-and-customer-departure')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Order Completion and Customer Departure
                </a>
                Understand the criteria for order completion and the best practices for managing customer departures from petrol stations, car wash stations, and EV charging stations.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('retry-logic')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Retry Logic
                </a>
                Implement retry strategies to handle transient errors gracefully. Learn when to retry and when to check the order state to determine the next steps.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('monitoring-and-logging')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Monitoring and Logging
                </a>
                Set up comprehensive monitoring and logging for your API interactions. Understand the importance of tracking key metrics and utilizing Ryd’s provided insights.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('order-creation')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Creating an Order with POST /v4/order
                </a>
                Discover the recommended approach for creating orders using the <code>POST /v4/order</code> endpoint, ensuring accuracy and consistency in your transactions.
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('enum-expansion')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Handling Enum Expansion and Validation
                </a>
                Get tips on handling enum expansion and validation to maintain robustness and flexibility in your API integration.
              </li>

            </ul>

          </div>
        </div>

        <div class="card" id="api-timeouts">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Handling API Request Timeouts</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Important Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                On the Ryd API server side, the client request timeout is set to <b>60 seconds</b> for all APIs, unless a different timeout is specified in the API documentation.
              </li>
              <li class="list-group-item">
                As a Ryd partner, it is recommended to use timeouts that are equal to or shorter than those configured on the Ryd API gateway in your client implementation.
                This ensures consistency and minimizes the likelihood of request failures due to timeout mismatches.
              </li>
              <li class="list-group-item">
                <code>POST /v4/order/{orderId}/authorize</code>: External systems, such as Payment Service Providers (PSPs), may cause delays. If you are using your own PSP, expect quick responses. Otherwise, set a timeout of at least 30 seconds.
              </li>
              <li class="list-group-item">
                <code>PUT /v4/order/{orderId}/acquire</code>: Depending on external systems and onsite equipment, such as the cashier system of a petrol station, this call might be slower. Implement a minimum timeout of 60 seconds.
              </li>
            </ul>
            <br/>
          </div>
        </div>



        <div class="card" id="order-completion-and-customer-departure">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Order Completion and Customer Departure</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> General Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                An order is considered successfully completed when it reaches the <code>PRODUCT_ACQUIRED</code> state.
                At this point, customers are cleared to leave the petrol station, car wash station, or EV charging station.
                This ensures that the transaction has been finalized and all necessary processes have been completed.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Technical Verificaiton
            </h5>
            <ul class="list-group">
              <li class="list-group-item">
                To verify that the order has reached the <code>PRODUCT_ACQUIRED</code> state, use the <code>stateHistory</code> object in the response of the <code>GET /v4/orders/{orderId}</code> API call. Check that <code>PRODUCT_ACQUIRED</code> is listed as an item in the <code>stateHistory</code>.
              </li>
              <li class="list-group-item">
                Example of <code>stateHistory</code>:
              </li>
              <li class="list-group-item">
                      <pre><code class="font-success">
{
   "stateHistory":[
      {
         "state":"CREATED",
         "message":"Order was created.",
         "createdAt":"2024-06-28T06:28:26.609Z",
         "updatedAt":"2024-06-28T06:28:26.609Z"
      },
      {
         "state":"PAYMENT_PENDING",
         "message":"Payment started notification",
         "createdAt":"2024-06-28T06:28:44.078Z",
         "updatedAt":"2024-06-28T06:28:44.078Z"
      },
      {
         "state":"PAYMENT_AUTHORIZATION_STARTED",
         "message":"Payment authorization started.",
         "createdAt":"2024-06-28T06:28:47.223Z",
         "updatedAt":"2024-06-28T06:28:47.223Z"
      },
      {
         "state":"PAYMENT_AUTHORIZED",
         "message":"Payment authorized in AUTOMATIC flow",
         "createdAt":"2024-06-28T06:28:47.396Z",
         "updatedAt":"2024-06-28T06:28:47.396Z"
      },
      {
         "state":"PRODUCT_ACQUISITION_STARTED",
         "message":"Product acquisition started.",
         "createdAt":"2024-06-28T06:30:15.349Z",
         "updatedAt":"2024-06-28T06:30:15.349Z"
      },
      {
         "state":"PRODUCT_PENDING",
         "message":"Product pending, i.e. product outlet (=pump) is unlocked",
         "createdAt":"2024-06-28T06:30:17.547Z",
         "updatedAt":"2024-06-28T06:30:17.547Z"
      },
      {
         "state":"PRODUCT_SUPPLYING",
         "message":"Product is being supplied.",
         "createdAt":"2024-06-28T06:30:19.452Z",
         "updatedAt":"2024-06-28T06:30:19.452Z"
      },
      {
         "state":"PRODUCT_ACQUIRED",
         "message":"Product acquired successfully - customer unplugged the charger",
         "createdAt":"2024-06-28T06:35:22.109Z",
         "updatedAt":"2024-06-28T06:35:22.109Z"
      },
      {
         "state":"READY_FOR_INVOICE",
         "message":"Vouchers updated",
         "createdAt":"2024-06-28T06:35:28.964Z",
         "updatedAt":"2024-06-28T06:35:28.964Z"
      },
      {
         "state":"COMPLETED",
         "message":"Transaction completed",
         "createdAt":"2024-06-28T06:35:29.137Z",
         "updatedAt":"2024-06-28T06:35:29.137Z"
      }
   ]
}
                      </code></pre>
              </li>
            </ul>

            <br/>


          </div>
        </div>

        <div class="card" id="retry-logic">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Retry Logic</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Important Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Implementing retry strategies is essential for handling transient errors gracefully and ensuring a robust integration with Rydpay APIs. Below are detailed guidelines for implementing retry logic effectively.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Understand Idempotency</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Before implementing retry logic, it is crucial to understand the idempotency of Rydpay APIs.
                Please read the section about the <a href="/integration-guide/rydpay-apis/api-fundamentals#idempotency">idempotency of the provided Rydpay APIs</a>. This will help you design your retry strategies in a way that avoids unintended side effects.              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>General Retry Strategy</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>5xx Errors</b>: If one of the APIs fails with a 5xx status code, it indicates a server error.
                Implementing a retry with exponential backoff can help in these situations. Exponential backoff means that the retry intervals increase progressively (e.g., 1 second, 2 seconds, 4 seconds, etc.), which reduces the load on the server and increases the chances of a successful retry.
              </li>
              <li class="list-group-item">
                <b>4xx Errors or Timeouts for Specific APIs</b>:
                If the <code>POST /v4/order/{orderId}/authorize</code> or <code>PUT /v4/order/{orderId}/acquire</code> API calls fail with a 4xx status code or timeout, a direct retry may not be effective. Instead, use the <code>GET /v4/orders/:id</code> endpoint to check the current order state and determine the appropriate next steps.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Business-Specific Retry Considerations</h5>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Fuelling</b>: If an error occurs during the order creation or acquisition process and the cashier system of the petrol station indicates that the pump reservation could not be completed, retrying the creation of a new order at the same pump is often not helpful.
              </li>
              <li class="list-group-item">
                <b>EV Charging</b>:
                If an EV charging session cannot be started successfully in the first order at the <a href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>, the likelihood of a successful retry with the same charging port is very low. Therefore, creating a new order for the same charging port is generally not recommended.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Server-Side Retries and Upcoming Enhancements</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd has implemented retries in certain situations within the server-side logic.
              </li>
              <li class="list-group-item">
                Additionally, Ryd is working on adding more detailed error codes and action codes to the API responses. These enhancements will provide better guidance on whether a business retry (e.g., creating a new order) or an API retry is likely to be effective.              </li>
            </ul>
            <br/>

          </div>
        </div>

        <div class="card" id="monitoring-and-logging">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Monitoring and Logging</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>General Information</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Implementing monitoring and logging for your API interactions is crucial for maintaining the health of your integration and quickly diagnosing issues.              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Your Monitoring and Logging</h5>
            <ul class="list-group">
              <li class="list-group-item">
                As a Ryd partner, you should implement detailed logging of all API requests and responses, ensuring that sensitive information such as API keys is masked. It is also important to log detailed error information, including the endpoints called, parameters sent, and error messages. Monitoring key metrics like API call success rates, latency, and rate limit usage will help you identify and address issues proactively. Setting up alerts for spikes in error rates and high latency is essential for maintaining a robust integration.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Ryd's Monitoring and Logging</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Ryd implements its own monitoring, logging, and alert systems. Some metrics and logs are available to partners on the Ryd Partner Portal, providing additional insights into API interactions and integration health.
              </li>
            </ul>
            <br/>


          </div>
        </div>



        <div class="card" id="order-creation">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Creating an Order with POST /v4/order</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <ul class="list-group">
              <li class="list-group-item">
                To create new orders via the API, you'll need to use the <code>POST /v4/order</code> endpoint. This endpoint requires a well-defined JSON body with specific attributes that identify the Point of Interest (POI, could be a petrol station, car wash or EV station) and the products involved in the order.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i> 1. Request Body Structure
            </h5>
            <div>The body of the POST request should be structured as follows:</div>
            <pre>
                  <code>
{
   "poiId": "{{poiIdAtRyd}}",
   "products": [
      {
         "productId": "{{PRODUCT_ID}}",
         "fuelTypeId": "{{FUEL_TYPE_ID}}"
      }
   ]
}
                  </code>
                </pre>
            <br/>
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>2. Attribute Overview:</h5>
            <h6><i class="icofont icofont-hand-right font-primary">&nbsp;</i> poiId (string)</h6>
            <ul class="list-group  mb-2">
              <li class="list-group-item">
                <b>Description</b>: The unique identifier for the <a href="https://partner-portal.rydcloud.de/integration-guide/rydpay-apis/terms-and-definitions#poi">POI</a>. This could be a petrol station, a car wash, or an EV charging station within the ryd network.
              </li>
              <li class="list-group-item">
                <b>Details</b>: Corresponds to the <code>_id</code> attribute of a POI obtained from the <code>GET /v4/pois</code> API response.
              </li>
            </ul>

            <h6><i class="icofont icofont-hand-right font-primary">&nbsp;</i> products (array)</h6>
            <ul class="list-group mb-2">
              <li class="list-group-item">
                This array contains one or more objects, each representing a product (a pump with or without fuel type, a car wash product, an EV charging point) to be ordered at the specified POI.
              </li>
              <li class="list-group-item">
                Currently only one product per order is supported.
              </li>
            </ul>

            <h6><i class="icofont icofont-hand-right font-primary">&nbsp;</i> productId (string)</h6>
            <ul class="list-group mb-2">
              <li class="list-group-item">
                <b>Description</b>: The technical identifier of a specific pump (or wash product, or charging point) at the POI.
              </li>
              <li class="list-group-item">
                <b>Details</b>: Corresponds to the <code>_id</code> of an entry within the <code>products</code> array in the <code>GET /v4/pois/:poiId</code> API response. It is important to distinguish this id from the pump’s name (e.g., "1", "2", "3"), which is displayed to customers for selection purposes. The productId is used for backend interactions between ryd and the petrol station’s (or wash station's or EV station's) cashier system.
              </li>
            </ul>

            <h6><i class="icofont icofont-hand-right font-primary">&nbsp;</i> fuelTypeId (string)</h6>
            <ul class="list-group mb-2">
              <li class="list-group-item">
                <b>Description</b>: The identifier for the specific type of fuel requested.
              </li>
              <li class="list-group-item">
                <b>Conditionally Mandatory</b>:
                Including the <code>fuelTypeId</code> in the order is necessary if the response from <code>GET /v4/pois/:poiId</code> includes available fuel types, listed either under <code>automaticPumpFuelTypes</code> (deprecated) or <code>availableItems</code> (new replacement). The <code>fuelTypeId</code> should match the <code>itemCode</code> in the <code>availableItems</code> array or the <code>_id</code> in the <code>automaticPumpFuelTypes</code> array.
              </li>
              <li class="list-group-item">
                <b>Purpose</b>: This ID is essential for the petrol station’s cashier system to unlock a specific nozzle at the pump, facilitating the correct dispensing of fuel.
              </li>
              <li class="list-group-item">
                <b>Examples</b>: The below examples show how fuel types are represented within the API responses, clarifying the source of <code>fuelTypeId</code>:

                <div>automaticPumpFuelTypes (Deprecated)</div>
                <pre>
                  <code>
[
   {
       "_id": "1337",
       "marketingName": "Super95",
       "internalName": "SUPER E5"
   },
   ...
]
                  </code>
                </pre>
                <br/>

                <div>availableItems (New Replacement)</div>
                <pre>
                  <code>
[
    {
        "itemCode": "1337",
        "marketingName": "Super95",
        "internalName": "SUPER E5",
        "unit": "LTR",
        ...
    },
    ...
]
                  </code>
                </pre>
              </li>
            </ul>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>3. Station-Specific Order Examples:</h5>
            <h6><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Example 1: Weat PrePay.MIX (No fuelTypeId Required)</h6>
            <ul class="list-group  mb-2">
              <li class="list-group-item">
                <b>Description</b>: For the <code>Weat PrePay.MIX</code> station on the staging system, which does not require specifying fuel types. Orders at this station only need to specify the <code>productId</code> because the system does not utilize specific fuel types for unlocking the pumps.
              </li>
              <li class="list-group-item">
                <b>Station Name</b>: Weat PrePay.MIX
              </li>
              <li class="list-group-item">
                <b>poiId</b>: <code>3a7d9b6b-1598-4884-8f7e-e1386265228b</code>
              </li>
              <li class="list-group-item">
                <b>Details</b>: Since the GET <code>/v4/pois/3a7d9b6b-1598-4884-8f7e-e1386265228b</code> API for this station does not return a fuel type (non-empty <code>automaticPumpFuelTypes</code> (deprecated) or <code>availableItems</code> (new replacement)), the request does not include a <code>fuelTypeId</code>.
              </li>
              <li class="list-group-item">
                <pre>
                  <code>
{
   "poiId":"3a7d9b6b-1598-4884-8f7e-e1386265228b",
   "products":[
      {
         "productId": "6f1cb48d-50ab-54f9-a088-768573925c6d"
      }
   ]
}
                  </code>
                </pre>
              </li>

            </ul>


            <h6><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Example 2: DE Automatic Noop (fuelTypeId Required)</h6>
            <ul class="list-group  mb-2">
              <li class="list-group-item">
                <b>Description</b>: For the <code>DE Automatic Noop</code> station, which requires specifying fuel types. Orders at this station need to specify the <code>productId</code> and the <code>fuelTypeId</code>. This fuelTypeId is essential for the petrol station’s cashier system to unlock a specific nozzle at the selected pump.
              </li>
              <li class="list-group-item">
                <b>Station Name</b>: DE Automatic Noop
              </li>
              <li class="list-group-item">
                <b>poiId</b>: <code>60c9bfe0f2d000d67561f172</code>
              </li>
              <li class="list-group-item">
                <b>Details</b>: The GET <code>/v4/pois/60c9bfe0f2d000d67561f172</code> API for this station returns specific fuel types, which must be included in the order request.
              </li>
              <li class="list-group-item">
                <pre>
                  <code>
{
   "poiId": "60c9bfe0f2d000d67561f172",
   "products": [
      {
         "productId": "c6fdbf7a-ce56-4877-b11e-e86b58f28ab3",
         "fuelTypeId": "PremiumDiesel"
      }
   ]
}
                  </code>
                </pre>
              </li>

            </ul>


          </div>
        </div>

        <div class="card" id="enum-expansion">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Handling Enum Expansion and Validation</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Context:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                As an API provider, we regularly update our enums, such as the order <code>State</code> enumeration, to enhance
                functionality without disrupting existing integrations.
              </li>
              <li class="list-group-item">
                The recent addition of
                <code>PAYMENT_AUTHORIZATION_STARTED</code> and <code>PRODUCT_ACQUISITION_STARTED</code> to the order <code>State</code> enum is a typical
                example of such non-breaking changes. For API clients, adapting to these changes is crucial for seamless
                integration.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Best Practices:</h5>
            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-success">&nbsp;</i>Expect Enum Expansion:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Enums, like order <code>State</code> , will evolve over time as the API grows. New states provide more detailed insights into the order process.
              </li>
              <li class="list-group-item">
                Design your application to gracefully handle known (e.g. <code>CREATED</code>, <code>PAYMENT_PENDING</code>, <code>PAYMENT_AUTHORIZED</code>) and new (<code>PAYMENT_AUTHORIZATION_STARTED</code>, <code>PRODUCT_ACQUISITION_STARTED</code>) enum values.
              </li>
            </ul>



            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-success">&nbsp;</i>Flexible Validation Over Strict Checks:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Implement flexible validation for enum values. Avoid hardcoding checks for each specific enum value.
              </li>
              <li class="list-group-item">
                Use logic that accommodates unforeseen enum values, ensuring that new additions don’t disrupt your application's functionality.
              </li>
            </ul>


            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-success">&nbsp;</i>Backward Compatibility:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Ensure your application logic remains backward compatible. Introducing new enum values, like <code>PAYMENT_AUTHORIZATION_STARTED</code>, should not break your application.
              </li>
              <li class="list-group-item">
                Handle unknown enum values with default or generic logic, maintaining application stability and user experience.
              </li>
            </ul>

            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-success">&nbsp;</i>Testing for Enum Changes:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Include scenarios in your testing environment that simulate both known and unknown (<code>PAYMENT_AUTHORIZATION_STARTED</code>, <code>PRODUCT_ACQUISITION_STARTED</code>) enum values.
              </li>
              <li class="list-group-item">
                Consider automated tests to detect changes in enum values and alert you to potential compatibility issues.
              </li>
            </ul>


          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";

export default {
  name: "RydpayApisApiFundamentals",
  keywords: ['Timeout', 'Retry', 'Monitoring', 'Logging', 'Enum', 'Enumeration', 'Customer Departure', 'Create An Order', 'Best Practices', 'Backward Compatibility'],
  data() {
    return {
      loggedInUserGroup: '',
    }
  },
  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.scrollToRootHash();
  },
  methods: {
    scrollToCard,
    scrollToRootHash,
    scrollToTop
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
}
</script>