<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Alerts"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div v-if="originFromPage === 'edit'">
          <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"></i>
            <p>Please select an alert from the list below to edit it.</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>
        <div v-if="originFromPage === 'view'">
          <div class="alert alert-dark inverse alert-dismissible fade show" role="alert"><i class="icon-info-alt"></i>
            <p>Please select an alert from the list below to view it.</p>
            <button class="btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>
        </div>

        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="m-t-20 mb-0 text-center f-w-900">Loading Alerts for Cashier Systems and MOC Partners ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main form content -->
        <div v-if="!isLoading && alertsResponse.results && alertsResponse.results.length > 0">
          <!-- second card: found users -->
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive product-table">
                    <table class="display table-striped" id="basic-1">
                      <thead class="bg-primary text-white">
                      <tr>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Cashier System
                        </th>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">MOC Partner
                        </th>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Country
                        </th>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Last Updated At
                        </th>
                        <th aria-controls="basic-1"
                            rowspan="1" colspan="1"
                            aria-label=""
                            class="bg-primary sortable"
                            style="width: 9%;">Was Responded?
                        </th>
                        <th class="bg-primary" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                            aria-label="" style="width: 25%;">
                          Actions
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="alert in alertsResponse.results" :key="alert">
                        <td>
                          {{ alert.cashierSystemName }}
                        </td>
                        <td>
                          {{ alert.mocPartnerName }}
                        </td>
                        <td>
                          {{ alert.country }}
                        </td>
                        <td>
                          {{ alert.lastModifiedAt }}
                        </td>
                        <td>
                          {{ alert.responseStatus.hasResponded }}
                        </td>


                        <td>
                          <button class="btn btn-success me-3 m-t-10" type="button" title="Edit user's data"
                                  @click="editAlertConfiguration(alert.id )">
                            View & Edit
                          </button>


                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>

              <br/>

              <nav aria-label="Alerts pagination" v-if="totalPages > 0" class="mt-3">
                <ul class="pagination">
                  <li class="page-item" :class="{ disabled: currentPage === 1 }">
                    <button class="page-link" @click="prevPage">Previous</button>
                  </li>
                  <li class="page-item" v-for="n in totalPages" :key="n" :class="{ active: n === currentPage }">
                    <button class="page-link" @click="goToPage(n)">{{ n }}</button>
                  </li>
                  <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                    <button class="page-link" @click="nextPage">Next</button>
                  </li>
                </ul>
              </nav>

              <div class="total-results m-t-15 fw-bold">
                <p>Total results: {{ totalCount }}</p>
              </div>


            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL,
  RPP_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  name: 'PartnerGuardianAlertsList',
  keywords: ['Alert History List', 'View Alert Histories', 'Cashier System Alert History', 'MOC Partner Alert History', 'Alert History Records', 'Historical Alerts', 'Alert Log', 'Alert Event History', 'System Alert History', 'Partner Alert History', 'Alert History Overview', 'Alert History Management', 'Review Alert History', 'Alert Incident History'],
  data() {
    return {
      newConfiguration: {
        cashierSystemName: '',
        additionalConfigurations: ''
      },
      isLoading: false,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      alertsResponse: {},
      originFromPage: null,
      totalCount: 0,
      totalPages: 0,
      pageSize: 20,       // Number of items per page
      currentPage: 1,     // Current page number
      nextPageToken: '',  // Token for fetching the next page
      prevPageTokens: []  // Stack to keep track of previous page tokens
    };
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
      this.loadAlerts();
    } else {
      this.partnerMetricsFor = userGroup;
    }
    this.originFromPage = this.$route.query.originFromPage;

  },
  methods: {
    editAlertConfiguration(alertId) {
      this.$router.push({
        path: '/guardian-system-and-alerts/alerts/edit',
        query: {
          alertId: alertId
        }
      });
    },
    async loadAlerts() {
      try {
        this.isLoading = true;
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/alerts?page=${this.currentPage}&pageSize=${this.pageSize}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        this.alertsResponse =  response.data;
        this.totalCount = response.data.totalCount;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.log("error", error);
      }finally {
        this.isLoading = false;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.loadAlerts();
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadAlerts();
      }
    },

    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.loadAlerts();
      }
    }
  }
};
</script>

<style scoped>
/* Add any specific styles for your component here */
</style>