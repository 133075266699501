<template>
  <Breadcrumbs main="rydpay APIs" title="Integration Pathways"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card">
          <div class="card-header bg-primary">
            <h5>General Information</h5>
          </div>
          <div class="card-body">

            <ul class="list-group">
              <li class="list-group-item">
                Rydpay APIs use a unified set of API Fundamentals, including versioning, authentication, and
                idempotency. They share common APIs and order state management across all supported products/services:
                <b>Fuelling</b>, <b>Car Wash</b>, and <b>EV Charging</b>.
              </li>
              <li class="list-group-item">
                Each product has unique requirements, workflows, and business differences. As a result, there are
                specific product APIs and distinct fields within the shared APIs.
              </li>
              <li class="list-group-item">
                The following sections provide comprehensive guides for each product: <b>Fuelling</b>, <b>Car Wash</b>,
                and <b>EV Charging</b>. These guides detail the recommended sections to read and the order in which to
                follow them.

                <ul class="list-group list-group-flush p-t-20">
                  <li class="list-group-item">
                    <a @click.prevent="scrollToCard('fuelling')" class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Integration Pathway: Fuelling</a>
                  </li>
                  <li class="list-group-item">
                    <a @click.prevent="scrollToCard('ev-charging')" class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Integration Pathway: EV Charging
                    </a>
                  </li>
                  <li class="list-group-item">
                    <a @click.prevent="scrollToCard('car-wash')" class="text-primary text-decoration-none cursor-pointer">
                      <i class="icofont icofont-link font-primary">&nbsp;</i> Integration Pathway: Car Wash
                    </a>
                  </li>
                </ul>

              </li>
            </ul>




            <br/>
          </div>
        </div>


        <div class="card" id="fuelling">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Integration Pathway: Fuelling </h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="p-b-20">
              The following list provides a comprehensive guide for integrating Fuelling, presented in the
              recommended order of reading:
            </div>


            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-success f-w-900 border border-dark text-center">Section</th>
                  <th class="font-success f-w-900 border border-dark text-center">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark text-center">
                    <a href="/integration-guide/rydpay-apis/getting-started#introduction" class="text-success">
                      <i class="icofont icofont-link font-success">&nbsp;</i>Introduction to Rydpay APIs</a>
                  </td>
                  <td class="border border-dark">Get an overview of the Rydpay APIs and understand the fundamental
                    concepts.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center text-success"><a
                      href="/integration-guide/rydpay-apis/getting-started#features" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Comprehensive Features and Capabilities</a></td>
                  <td class="border border-dark">Explore the full range of features and capabilities offered by the
                    Rydpay APIs.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/getting-started#onboarding" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    New Partner Onboarding Guide</a></td>
                  <td class="border border-dark">Follow the onboarding process designed to help new partners get started
                    quickly and efficiently.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/api-fundamentals"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    API Fundamentals</a></td>
                  <td class="border border-dark">Learn about the core principles of the APIs, including versioning,
                    authentication, idempotency, and error handling.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/order-state-management" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Order State Management</a></td>
                  <td class="border border-dark">Discover how to manage order states across different stages of the
                    transaction lifecycle.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/best-practices"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Best Practices</a></td>
                  <td class="border border-dark">Adopt best practices for integrating and using the APIs effectively and
                    securely.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/webhooks"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Webhooks</a></td>
                  <td class="border border-dark">Set up and utilize webhooks for real-time notifications and updates.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/guides-fuelling/prepay-vs-postpay" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Prepay VS Postpay</a></td>
                  <td class="border border-dark">Understand the differences between prepay and postpay models in the
                    context of fuelling.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/testing-and-simulation/fuelling-test-scenarios"
                      class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Fuelling Test Scenarios</a></td>
                  <td class="border border-dark">Explore test scenarios to understand different happy paths and error
                    cases, and validate your Fuelling integration.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/testing-and-simulation/fuelling-simulated-stations"
                      class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Fuelling Simulated Stations</a></td>
                  <td class="border border-dark">Simulate different Fuelling situations to ensure your integration
                    handles all behaviours, such as different currencies, taxes.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/api-reference#/production" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Ryd.Pay API Reference</a></td>
                  <td class="border border-dark">Access the comprehensive reference documentation for all Rydpay API
                    endpoints. This documentation is available in Swagger format, providing a detailed and interactive
                    interface for exploring the APIs. Additionally, you can download the JSON definition in OpenAPI
                    format for use in your development environment.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/terms-and-definitions" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Terms & Definitions</a></td>
                  <td class="border border-dark">Understand the terminology used throughout the documentation and
                    APIs.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/faqs"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    FAQs</a></td>
                  <td class="border border-dark">Read frequently asked questions to gain additional insights and
                    answers.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/troubleshooting-and-support" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Troubleshooting & Support</a></td>
                  <td class="border border-dark">Find solutions to common issues and get support for any problems you
                    encounter.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/release-notes-and-updates" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Release Notes & Updates</a></td>
                  <td class="border border-dark">Stay informed about the latest updates and changes to the Rydpay
                    APIs.
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>

        <div class="card" id="ev-charging">
          <div class="card-header bg-primary d-flex justify-content-between align-items-center">
            <h5>Integration Pathway: EV Charging</h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">


            <div class="p-b-20">
              The following list provides a comprehensive guide for integrating EV Charging, presented in the
              recommended order of reading:
            </div>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-primary f-w-900 border border-dark text-center">Section</th>
                  <th class="font-primary f-w-900 border border-dark text-center">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/getting-started#introduction">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Introduction to Rydpay APIs</a></td>
                  <td class="border border-dark">Get an overview of the Rydpay APIs and understand the fundamental
                    concepts.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/getting-started#features">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>Comprehensive Features and Capabilities</a>
                  </td>
                  <td class="border border-dark">Explore the full range of features and capabilities offered by the
                    Rydpay APIs.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/getting-started#onboarding">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    New Partner Onboarding Guide</a></td>
                  <td class="border border-dark">Follow the onboarding process designed to help new partners get started
                    quickly and efficiently.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/api-fundamentals">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    API Fundamentals</a></td>
                  <td class="border border-dark">Learn about the core principles of the APIs, including versioning,
                    authentication, idempotency, and error handling.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/order-state-management">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Order State Management</a></td>
                  <td class="border border-dark">Discover how to manage order states across different stages of the
                    transaction lifecycle.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/best-practices">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Best Practices</a></td>
                  <td class="border border-dark">Adopt best practices for integrating and using the APIs effectively and
                    securely.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/webhooks"><i
                      class="icofont icofont-link font-primary">&nbsp;</i>
                    Webhooks</a></td>
                  <td class="border border-dark">Set up and utilize webhooks for real-time notifications and updates.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center">
                    <a href="/integration-guide/rydpay-apis/guides-ev-charging/ev-insights-and-statistics">
                      <i class="icofont icofont-link font-primary">&nbsp;</i>
                      EV Insights & Statistics
                    </a>
                  </td>
                  <td class="border border-dark">
                    Find detailed information and statistics about EV charging infrastructure, including key metrics such as total charging stations, EVSE counts, remote start/stop capabilities, and comprehensive data on power and connector types. This page also covers top cities by EVSE count and key CPO statistics.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/guides-ev-charging/ev-pricing">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    EV Pricing</a></td>
                  <td class="border border-dark">Gain insight into the complex pricing models and structures unique to
                    EV Charging, and learn how they are implemented in the code.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/guides-ev-charging/api-journey">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    API Journey</a></td>
                  <td class="border border-dark">Get detailed information on the flow of API requests and responses for
                    EV Charging.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-test-scenarios">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    EV Charging Test Scenarios</a></td>
                  <td class="border border-dark">Explore test scenarios to understand different happy paths and error
                    cases, and validate your EV charging integration.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/testing-and-simulation/ev-charging-simulated-stations">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    EV Charging Simulated Stations</a></td>
                  <td class="border border-dark">Simulate different EV charging situations to ensure your integration
                    handles all behaviours, such as different currencies.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/api-reference#/production">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Ryd.Pay API Reference</a></td>
                  <td class="border border-dark">Access the comprehensive reference documentation for all Rydpay API
                    endpoints. This documentation is available in Swagger format, providing a detailed and interactive
                    interface for exploring the APIs. Additionally, you can download the JSON definition in OpenAPI
                    format for use in your development environment.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/terms-and-definitions">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Terms & Definitions</a></td>
                  <td class="border border-dark">Understand the terminology used throughout the documentation and
                    APIs.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/faqs">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    FAQs</a></td>
                  <td class="border border-dark">Read frequently asked questions to gain additional insights and
                    answers.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/troubleshooting-and-support">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Troubleshooting & Support</a></td>
                  <td class="border border-dark">Find solutions to common issues and get support for any problems you
                    encounter.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/release-notes-and-updates">
                    <i class="icofont icofont-link font-primary">&nbsp;</i>
                    Release Notes & Updates</a></td>
                  <td class="border border-dark">Stay informed about the latest updates and changes to the Rydpay
                    APIs.
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>

        <div class="card" id="car-wash">
          <div class="card-header bg-success d-flex justify-content-between align-items-center">
            <h5>Integration Pathway: Car Wash </h5>
            <button @click="scrollToTop" class="btn btn-light btn-sm text-white">
              <i class="icofont icofont-arrow-up"></i> Back to Top
            </button>
          </div>
          <div class="card-body">
            <div class="p-b-20">
              The following list provides a comprehensive guide for integrating Car Wash, presented in the
              recommended order of reading:
            </div>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-success f-w-900 border border-dark text-center">Section</th>
                  <th class="font-success f-w-900 border border-dark text-center">Description</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark text-center">
                    <a href="/integration-guide/rydpay-apis/getting-started#introduction" class="text-success">
                      <i class="icofont icofont-link font-success">&nbsp;</i>Introduction to Rydpay APIs</a>
                  </td>
                  <td class="border border-dark">Get an overview of the Rydpay APIs and understand the fundamental
                    concepts.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center text-success"><a
                      href="/integration-guide/rydpay-apis/getting-started#features" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Comprehensive Features and Capabilities</a></td>
                  <td class="border border-dark">Explore the full range of features and capabilities offered by the
                    Rydpay APIs.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/getting-started#onboarding" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    New Partner Onboarding Guide</a></td>
                  <td class="border border-dark">Follow the onboarding process designed to help new partners get started
                    quickly and efficiently.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/api-fundamentals"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    API Fundamentals</a></td>
                  <td class="border border-dark">Learn about the core principles of the APIs, including versioning,
                    authentication, idempotency, and error handling.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/order-state-management" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Order State Management</a></td>
                  <td class="border border-dark">Discover how to manage order states across different stages of the
                    transaction lifecycle.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/best-practices"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Best Practices</a></td>
                  <td class="border border-dark">Adopt best practices for integrating and using the APIs effectively and
                    securely.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/webhooks"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Webhooks</a></td>
                  <td class="border border-dark">Set up and utilize webhooks for real-time notifications and updates.
                  </td>
                </tr>

                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/testing-and-simulation/car-wash-test-scenarios"
                      class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Car Wash Test Scenarios</a></td>
                  <td class="border border-dark">Explore test scenarios to understand different happy paths and error
                    cases, and validate your car wash integration.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/testing-and-simulation/car-wash-simulated-stations"
                      class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Car Wash Simulated Stations</a></td>
                  <td class="border border-dark">Simulate different car wash situations to ensure your integration
                    handles all behaviours, such as different currencies, taxes.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/api-reference#/production" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Ryd.Pay API Reference</a></td>
                  <td class="border border-dark">Access the comprehensive reference documentation for all Rydpay API
                    endpoints. This documentation is available in Swagger format, providing a detailed and interactive
                    interface for exploring the APIs. Additionally, you can download the JSON definition in OpenAPI
                    format for use in your development environment.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/terms-and-definitions" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Terms & Definitions</a></td>
                  <td class="border border-dark">Understand the terminology used throughout the documentation and
                    APIs.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a href="/integration-guide/rydpay-apis/faqs"
                                                                class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    FAQs</a></td>
                  <td class="border border-dark">Read frequently asked questions to gain additional insights and
                    answers.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/troubleshooting-and-support" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Troubleshooting & Support</a></td>
                  <td class="border border-dark">Find solutions to common issues and get support for any problems you
                    encounter.
                  </td>
                </tr>
                <tr>
                  <td class="border border-dark text-center"><a
                      href="/integration-guide/rydpay-apis/release-notes-and-updates" class="text-success">
                    <i class="icofont icofont-link font-success">&nbsp;</i>
                    Release Notes & Updates</a></td>
                  <td class="border border-dark">Stay informed about the latest updates and changes to the Rydpay
                    APIs.
                  </td>
                </tr>
                </tbody>
              </table>
            </div>


          </div>
        </div>



      </div>
    </div>
  </div>
</template>


<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {
  scrollToCard,
  scrollToRootHash as utilScrollToRootHash,
  scrollToRootHash,
  scrollToTop
} from "@/utils/anchorUtils";

export default {
  name: "IntegrationPathways",
  keywords: ['API Fundamentals', 'Versioning', 'Authentication', 'Idempotency', 'Order State Management', 'Fuelling Integration', 'Car Wash Integration', 'EV Charging Integration'],
  data() {
    return {
      loggedInUserGroup: '',
    }
  },
  mounted() {
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
    this.$nextTick(() => {
      this.scrollToRootHashWithDelay();
    });
  },
  methods: {
    scrollToCard,
    scrollToRootHashWithDelay() {
      setTimeout(() => {
        scrollToRootHash.call(this, 75);
      }, 100);
    },
    scrollToTop
  },
  watch: {
    '$route.hash': function (newHash, oldHash) {
      if (newHash !== oldHash) {
        this.$nextTick(() => {
          this.scrollToRootHashWithDelay();
        });
      }
    }
  },
}
</script>