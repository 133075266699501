<template>
  <Breadcrumbs main="Guides: EV Charging" title="EV Charging Test Scenarios"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Overview</h5>
          </div>
          <div class="card-body">
            <p>
              To streamline your testing process, we offer a Postman collection that includes all the EV Charging test
              scenarios detailed below. Simply download the collection and import it into your Postman application to
              begin testing right away.
            </p>
            <p>
              <b>Download Link:</b>
              <button type="button" class="m-l-20 btn btn-primary" @click="downloadJsonEvCharging">Download Ryd APIs for EV Charging Postman Collection
              </button>
            </p>

            <div v-if="isLoadingPartnerConfigs" class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Hang tight! We're whipping up your Postman collection!</h6>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>
          </div>
        </div>

        <br/>


        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">How to Use the Postman Collection</h5>
          </div>
          <div class="card-body m-t-20">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Importing the Collection:</h5>
            <ul class="list-group">
              <li class="list-group-item">After downloading, open Postman and import the collection by
                selecting 'Import' and choosing the downloaded file.
              </li>
            </ul>
            <br/>

            <div v-if="!tt4ApiTokensData.tt4ApiTokenStaging">
              <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Setting Up Collection Variables:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">
                  Set the <code>xTxnAuthToken</code> collection variable with your API key for the staging environment.
                  The Postman collection includes a temporary auth token; please replace it with your own.
                </li>
                <li class="list-group-item">
                  Additionally, update the <code>xTxnInitiatedByPartner</code> collection variable with your partner ID at
                  Ryd, which is <code>{{ loggedInUserGroup }}</code>.
                </li>
              </ul>
              <br/>
            </div>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Executing Test Scenarios: </h5>
            <ul class="list-group">
              <li class="list-group-item">Select a test case from the collection and hit 'Send' to execute the
                API requests. Observe the responses and validate them against the expected behavior outlined
                on this page.
              </li>
              <li class="list-group-item">The entire collection can be executed in Postman, which includes
                specific test validations for each request and response.
              </li>
            </ul>
            <br/>

          </div>
        </div>


        <div class="card card-absolute">
          <div class="card-header bg-info">
            <h5>Test Scenario Index</h5>
          </div>
          <div class="card-body">
            <div class="alert alert-info outline" role="alert">
              <p>Select a test scenario to directly access its specific details.</p>
            </div>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('ts401')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Scenario 401: Successful Charging</a>
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('ts402')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Scenario 402: Charging Session Overshoot
                </a>
              </li>
              <li class="list-group-item">
                <a @click.prevent="scrollToCard('ts403')" class="text-primary text-decoration-none cursor-pointer">
                  <i class="icofont icofont-link font-primary">&nbsp;</i> Scenario 403: Charging Session Creation
                  Failure
                </a>
              </li>
            </ul>
          </div>
        </div>


        <!-- TC 401 -->
        <div class="card" id="ts401">
          <div class="card-header bg-primary">
            <h5>Scenario 401: Successful Charging</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
            <ul class="list-group">
              <li class="list-group-item">This test case validates the system's response when a customer successfully
                initiates and completes an EV charging session at a specified charging station.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
            <ul class="list-group">

              <li class="list-group-item font-primary">1. Select Charging Station:
                <ol class="list-group">
                  <li class="list-group-item">The customer selects the charging station with the poiId <code>b989ce59-d976-411d-ba30-0d2e1f90baac</code>
                    and selects the charging port <code>NLLMSE11700000*1</code></li>
                </ol>
              </li>

              <li class="list-group-item font-primary">2. Authorize Payment Amount:
                <ol class="list-group">
                  <li class="list-group-item">
                    Optionally, the customer can select a maximum amount to be authorized for the charging session.
                  </li>
                  <li class="list-group-item">
                    Alternatively, the Ryd partner may always authorize a fixed amount without giving the customer an
                    option to choose an amount.
                  </li>
                  <li class="list-group-item">
                    In this test scenario, the amount is 40 EURO.
                  </li>
                  <li class="list-group-item">
                    Important: <a href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot">Overshooting</a>
                    often occurs in EV charging because charging stations cannot stop at a reserved amount like fueling
                    pumps.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-primary">3. Authorize Payment:
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer authorizes the payment.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-success">4. Start Charging
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer connects their car to the charger, and the charging process begins.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-primary">5. Stop Charging Session:
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer can stop the charging session using Ryd's provided methods, or by stopping it in their
                    car, at the charging port, or through a third-party app, if available
                  </li>
                  <li class="list-group-item">
                    If the car is fully charged, the session will stop automatically In this case, stopping the session
                    via Ryd is not necessary, but it can be triggered and will be handled properly by ryd.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-primary">6. Receive Payment/Invoice Data:
                <ol class="list-group">
                  <li class="list-group-item">
                    In most cases, Ryd receives the payment/invoice data within a few minutes after the session stops,
                    and this information is available in the "Get Order by ID" API response.
                  </li>
                  <li class="list-group-item">
                    In this test scenario, the total cost is 35 EURO.
                  </li>
                  <li class="list-group-item">
                    However, it is also common in EV charging for Ryd to receive this data from the respective <a
                      href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>
                    a few hours to a few days after the session has been completed.
                  </li>
                </ol>
              </li>

            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
            <ul class="list-group">
              <li class="list-group-item">1. The ryd partner's system processes the payment authorization correctly
              </li>
              <li class="list-group-item">2. The charging session is initiated and completed successfully
              </li>
              <li class="list-group-item">3. Appropriate status updates and messages are displayed to the customer.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Verification Steps:
            </h5>
            <ol class="list-group" type="1">
              <li class="list-group-item">
                1. The <code>GET /v4/pois/{chargingStationId1}</code> API call returns HTTP 200, confirming the station
                details
              </li>
              <li class="list-group-item">
                2. The <code>POST /v4/order</code> API call creates an order and returns HTTP 201, confirming the order
                creation.
              </li>
              <li class="list-group-item">
                3. The <code>POST /v4/order/{orderId}/authorize</code> API call returns HTTP 200, confirming the payment
                authorization
              </li>
              <li class="list-group-item">
                4. The <code>PUT /v4/order/{orderId}/acquire</code> API call returns HTTP 200, confirming that an EV
                session has been successfully created with the respective <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>.
              </li>
              <li class="list-group-item">
                5. The <code>PUT /v4/order/{orderId}/acquire/stop</code> API call returns HTTP 200, confirming that the
                created EV session has been successfully stopped.
              </li>
              <li class="list-group-item">
                6. The <code>GET /v4/order/{orderId}</code> API call
                <ul class="list-group">
                  <li class="list-group-item">returns an HTTP 200 status AND
                  </li>
                  <li class="list-group-item">includes the order state <code>PRODUCT_ACQUIRED</code> in the state
                    history, along with the invoice data if available
                  </li>
                  <li class="list-group-item">DOESN'T include the order state <code>PROBLEM</code> in the state history
                  </li>
                  <li class="list-group-item">Here is a sample response from the <code>GET /v4/order/{orderId}</code>
                    API.
                  </li>
                </ul>
              </li>
            </ol>
            <br/>
          </div>
        </div>

        <!-- TC 402 -->
        <div class="card" id="ts402">
          <div class="card-header bg-success">
            <h5>Scenario 402: Charging Session Overshoot</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
            <ul class="list-group">
              <li class="list-group-item">
                In the context of <b>EV charging</b>, an overshoot occurs when the amount of energy delivered exceeds
                the initially authorized amount.
                Unlike fueling, EV charging stations do not have a mechanism to automatically stop charging at a
                pre-authorized monetary value.
                This results in the total costs beiging heigher than initially authorized.
              </li>
              <li class="list-group-item">
                In such cases, the overshoot should not affect the customer, and they must be allowed to leave the
                charging station without any issues.
                The respective order will be transferred into the manual resolution state, labeled as
                <code>PROBLEM</code>, and will need to be manually addressed by Ryd's payment operations services
                personnel.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Steps:</h5>
            <ul class="list-group">

              <li class="list-group-item font-success">1. Select Charging Station:
                <ol class="list-group">
                  <li class="list-group-item">The customer selects the charging station with the poiId <code>b989ce59-d976-411d-ba30-0d2e1f90baac</code>
                    and selects the charging port <code>NLLMSE11700000*2</code></li>
                </ol>
              </li>

              <li class="list-group-item font-success">2. Authorize Payment Amount:
                <ol class="list-group">
                  <li class="list-group-item">
                    Optionally, the customer can select a maximum amount to be authorized for the charging session.
                  </li>
                  <li class="list-group-item">
                    Alternatively, the Ryd partner may always authorize a fixed amount without giving the customer an
                    option to choose an amount.
                  </li>
                  <li class="list-group-item">
                    In this test scenario, the amount is 40 EURO.
                  </li>
                  <li class="list-group-item">
                    Important: <a href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot">Overshooting</a>
                    often occurs in EV charging because charging stations cannot stop at a reserved amount like fueling
                    pumps.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-success">3. Authorize Payment:
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer authorizes the payment.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-success">4. Start Charging
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer connects their car to the charger, and the charging process begins.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-success">5. Stop Charging Session:
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer can stop the charging session using Ryd's provided methods, or by stopping it in their
                    car, at the charging port, or through a third-party app, if available
                  </li>
                  <li class="list-group-item">
                    If the car is fully charged, the session will stop automatically In this case, stopping the session
                    via Ryd is not necessary, but it can be triggered and will be handled properly by ryd.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-success">6. Receive Payment/Invoice Data:
                <ol class="list-group">
                  <li class="list-group-item">
                    In most cases, Ryd receives the payment/invoice data within a few minutes after the session stops,
                    and this information is available in the "Get Order by ID" API response.
                  </li>
                  <li class="list-group-item">
                    In this test scenario, the total cost is 250 EURO that is higher than the pre-authorized amount of
                    40 EURO. It is an overshoot case.
                  </li>
                  <li class="list-group-item">
                    It is up to the ryd partner to decide how to deal with this overshoot situation.
                    In the case of Ryd's B2C business, Ryd tells the B2C end customer that the order is successful and
                    they are allowed to drive away, but such an order has to be processed manually by Ryd's payment
                    service personnel, sometimes Ryd's payment service personnel has to record the money or the
                    different amount manually, depending on the payment method used.
                  </li>
                  <li class="list-group-item">
                    However, it is also common in EV charging for Ryd to receive this data from the respective <a
                      href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>
                    a few hours to a few days after the session has been completed.
                  </li>
                </ol>
              </li>

            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Expected Behavior:</h5>
            <ul class="list-group">
              <li class="list-group-item">1. The ryd partner's system processes the payment authorization correctly
              </li>
              <li class="list-group-item">2. The charging session is initiated and completed successfully
              </li>
              <li class="list-group-item">3. Appropriate status updates and messages are displayed to the customer.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Technical Verification Steps:
            </h5>
            <ol class="list-group" type="1">
              <li class="list-group-item">
                1. The <code>GET /v4/pois/{chargingStationId1}</code> API call returns HTTP 200, confirming the station
                details
              </li>
              <li class="list-group-item">
                2. The <code>POST /v4/order</code> API call creates an order and returns HTTP 201, confirming the order
                creation.
              </li>
              <li class="list-group-item">
                3. The <code>POST /v4/order/{orderId}/authorize</code> API call returns HTTP 200, confirming the payment
                authorization
              </li>
              <li class="list-group-item">
                4. The <code>PUT /v4/order/{orderId}/acquire</code> API call returns HTTP 200, confirming that an EV
                session has been successfully created with the respective <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>.
              </li>
              <li class="list-group-item">
                5. The <code>PUT /v4/order/{orderId}/acquire/stop</code> API call returns HTTP 200, confirming that the
                created EV session has been successfully stopped.
              </li>
              <li class="list-group-item">
                6. The <code>GET /v4/order/{orderId}</code> API call
                <ul class="list-group">
                  <li class="list-group-item">returns an HTTP 200 status AND
                  </li>
                  <li class="list-group-item">includes the order state <code>PRODUCT_ACQUIRED</code> in the state
                    history, along with the invoice data if available
                  </li>
                  <li class="list-group-item">includes the order state <code>PROBLEM</code> in the state history
                  </li>
                  <li class="list-group-item">Here is a sample response from the <code>GET /v4/order/{orderId}</code>
                    API.
                  </li>
                </ul>
              </li>
            </ol>
            <br/>
          </div>
        </div>


        <!-- TC 403 -->
        <div class="card" id="ts403">
          <div class="card-header bg-primary">
            <h5>Scenario 403: Charging Session Creation Failure</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
            <ul class="list-group">
              <li class="list-group-item">
                This scenario tests the behavior when an EV charging session cannot be created due to issues with the <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>.
                It examines how the system handles failures in initiating a charging session caused by external factors
                such as CPO connectivity problems, authorization failures, or other CPO-related issues.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
            <ul class="list-group">

              <li class="list-group-item font-primary">1. Select Charging Station:
                <ol class="list-group">
                  <li class="list-group-item">The customer selects the charging station with the poiId <code>f1157103-63b5-4412-b7b0-4737dac45470</code>
                    and selects the charging port <code>NLLMSE11711111*1</code></li>
                </ol>
              </li>

              <li class="list-group-item font-primary">2. Authorize Payment Amount:
                <ol class="list-group">
                  <li class="list-group-item">
                    Optionally, the customer can select a maximum amount to be authorized for the charging session.
                  </li>
                  <li class="list-group-item">
                    Alternatively, the Ryd partner may always authorize a fixed amount without giving the customer an
                    option to choose an amount.
                  </li>
                  <li class="list-group-item">
                    In this test scenario, the amount is 120 EURO.
                  </li>
                  <li class="list-group-item">
                    Important: <a href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot">Overshooting</a>
                    often occurs in EV charging because charging stations cannot stop at a reserved amount like fueling
                    pumps.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-primary">3. Authorize Payment:
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer authorizes the payment.
                  </li>
                </ol>
              </li>

              <li class="list-group-item font-primary">4. Start Charging:
                <ol class="list-group">
                  <li class="list-group-item">
                    The customer can not start charging because there is an issue with the respective <a
                      href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>.
                  </li>
                </ol>
              </li>


            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
            <ul class="list-group">
              <li class="list-group-item">1. The ryd partner's system processes the payment authorization correctly
              </li>
              <li class="list-group-item">2. No charging session can not be created.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Verification Steps:
            </h5>
            <ol class="list-group" type="1">
              <li class="list-group-item">
                1. The <code>GET /v4/pois/{chargingStationId1}</code> API call returns HTTP 200, confirming the station
                details
              </li>
              <li class="list-group-item">
                2. The <code>POST /v4/order</code> API call creates an order and returns HTTP 201, confirming the order
                creation.
              </li>
              <li class="list-group-item">
                3. The <code>POST /v4/order/{orderId}/authorize</code> API call returns HTTP 200, confirming the payment
                authorization
              </li>
              <li class="list-group-item">
                4. The <code>PUT /v4/order/{orderId}/acquire</code> API call returns HTTP <code>400</code>, confirming
                that no EV Charging session can be created with the respective <a
                  href="/integration-guide/rydpay-apis/terms-and-definitions#cpo">Charging Point Operator (CPO)</a>.
              </li>
            </ol>
            <br/>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {scrollToCard, scrollToRootHash} from "@/utils/anchorUtils";
import axios from 'axios';
import {RPP_API_SERVER_URL} from "@/constants/constants";
import PostmanCollectionMixin from '@/mixins/PostmanCollectionMixin';

export default {
  name: "EvChargingTestScenarios",
  mixins: [PostmanCollectionMixin],
  keywords: [
    'EV', 'EV Charging', 'Testing Scenarios', 'Postman Collection', 'Happy Paths', 'Error Cases',
    'API Testing', 'Test Cases', 'API Scenarios', 'Test Documentation',
    'API Validation', 'API Testing Guide', 'API Test Examples', 'API Test Setup',
    'API Error Handling', 'Postman Tests', 'API Debugging', 'API QA',
    'Test Automation', 'Manual Testing', 'Integration Testing', 'Functional Testing',
    'Performance Testing', 'Test Scripts', 'Test Suite', 'API Workflow Testing',
    'Error Scenarios', 'Positive Test Cases', 'Negative Test Cases', 'Boundary Testing',
    'Edge Case Testing', 'API Test Plan', 'Testing Framework', 'Test Data',
    'API Response Validation', 'API Request Testing', 'Mock Server', 'Mock API',
    'API Simulation', 'API Mocking', 'Postman Setup', 'Postman Documentation',
    'Postman Collection Guide', 'API Scenario Examples', 'Testing Best Practices',
    'API Test Cases', 'Error Handling Scenarios', 'API Coverage', 'API Test Report',
    'Test Results', 'Test Execution', 'API Test Automation', 'API Regression Testing',
    'Postman Scripts', 'API Debugging Guide', 'API Test Checklist', 'End-to-End Testing',
    'Test Environment', 'Testing Tools', 'Postman Environment', 'Test Strategy',
    'Test Plan', 'API Validation Scenarios', 'Request Testing', 'Response Testing',
    'Test Documentation Guide', 'Test Scenarios Overview', 'API Testing Examples'
  ],
  data() {
    return {
      loggedInUserGroup: '',
      tt4ApiTokensData: {},
      isLoadingPartnerConfigs: false,
      userGroup: ''
    }
  },
  created() {
    this.fetchPartnerConfigs();
  },
  methods: {
    scrollToCard,
    scrollToRootHash,
    downloadJsonEvCharging() {
      this.downloadJson('rydpay-api-ev-charging.postman_collection.json', 'ev-charging');
    },
  },
  mounted() {
    this.scrollToRootHash();
    const {userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserGroup = userGroup;
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  }
}
</script>


