<template>
  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-12">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Date Range:
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">From:</label>
                    <input v-model="fromTimestamp" type="datetime-local" class="form-control"
                           id="fromTimestamp" placeholder="From Timestamp">
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">To:</label>
                    <input v-model="toTimestamp" type="datetime-local" class="form-control" placeholder="To Timestamp">
                  </div>
                  <div class="col d-flex align-items-center" v-if="loggedInUserIsRyd">
                    <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="partnerMetricsFor">For Partner:</label>
                    <select class="form-control border border-primary min-width-max-content" id="partnerMetricsFor"
                            v-model="partnerMetricsFor"
                            required>
                      <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                        {{ partnerName }}
                      </option>
                    </select>
                  </div>

                  <div class="col">
                    <button @click="refreshOrderDetailFailure"
                            class="btn btn-pill btn-success btn-air-success active input-group-text">
                      <i class="fa fa-send m-r-10"></i> Refresh Order Failure Details
                    </button>
                  </div>
                </div>

                <div class="col">
                <button @click="copyShareableLink" class="btn btn-info rounded-pill m-l-5"
                        data-bs-toggle="popover"
                        data-bs-placement="left"
                        data-bs-title="Information"
                        title="Click this button to generate a full URL, including your input fields, making it easy to share with others."
                        data-bs-content="Click this button to generate a full URL, including your input fields, making it easy to share with others.">
                  <i class="fa fa-share"></i> Share
                </button>
                </div>

              </div>
              <!-- INPUT GROUP 1 END -->
            </div>
            <div class="d-flex align-items-center m-t-20 m-l-35">
              <i class="icofont icofont-hand-right font-primary">&nbsp;</i>
              <div><b>NOTE:</b> For optimal performance, please choose a date range not exceeding 24 hours.</div>
            </div>
          </div>
        </div>
        <!-- CARD 1 END -->

        <div class="card" v-if="showAlertTimestamps">
          <div id="alertDiv2" class="alert alert-secondary outline" role="alert">
            <p>Please Enter a valid date range: Both 'From' and 'To' dates are required and cannot be left
              empty. </p>
            <p>Additionally, the time span between the 'From' and 'To' dates must not exceed 24 hours for optimal
              performance. </p>
          </div>
        </div>


        <div class="col-sm-12">
          <div class="card card-absolute">
            <div class="card-header bg-primary">
              <h6>Chart: Order Failures Across Brands</h6>
            </div>
            <div class="card-body m-t-20"
                 v-if="!isLoadingOrderFailureDetails && orderFailureDetailsResponse.barChartData">
            <span class="m-b-5">
              <b>Comparative Insights on Order Failures and Volume by Gas Station Brand, Only the top 10 with the most failures will be included.</b>
            </span>
              <div id="basic-bar">
                <apexchart type="bar" height="350" ref="chart" :options="chartOptions" :series="seriesData"></apexchart>
              </div>
            </div>
            <div class="card-body m-t-20"
                 v-if="!isLoadingOrderFailureDetails && !orderFailureDetailsResponse.barChartData">
              <h6>There are no recorded order failures within the specified date range.</h6>
            </div>
            <div class="card-body" v-if="isLoadingOrderFailureDetails">
              <div class="loading-indicator">
                <div class="d-flex align-items-center justify-content-center f-w-900">
                  <i class="icofont icofont-speed-boat font-primary fa-2x me-2"></i>
                  <span>Hang tight! We're fetching your Order Failure Details at warp speed!</span>
                </div>
                <div class="loader-box">
                  <div class="loader-4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- CARD 2 START -->
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h6>Table: Order Failures Details</h6>
          </div>
          <div class="card-body m-t-20"
               v-if="!isLoadingOrderFailureDetails && !showAlertTimestamps && orderFailureDetailsResponse.detailedData">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive product-table">
                  <table class="display table-striped" id="basic-1">
                    <thead class="bg-light-success">
                    <tr>
                      <th aria-controls="basic-1"
                          rowspan="1" colspan="1"
                          aria-label=""
                          class="bg-light-success text-black"
                          style="width: 8%;">Gas Station Brand

                      </th>
                      <th aria-controls="basic-1"
                          rowspan="1" colspan="1"
                          aria-label=""
                          class="bg-light-success text-black"
                          style="width: 8%;" v-if="loggedInUserIsRyd">Cashier System
                      </th>
                      <th aria-controls="basic-1" rowspan="1"
                          colspan="1"
                          aria-label=""
                          class="bg-light-success text-black"
                          style="width: 8%;">Order State
                      </th>
                      <th aria-controls="basic-1"
                          rowspan="1" colspan="1"
                          aria-label=""
                          class="bg-light-success text-black"
                          style="width: 20%;">State Message
                      </th>
                      <th tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                          aria-label=""
                          class="bg-light-success text-black"
                          style="width: 5%;">
                        Failure Count
                      </th>
                      <th class="bg-light-success text-black" tabindex="0" aria-controls="basic-1" rowspan="1"
                          colspan="1"
                          aria-label="" style="width: 5%;">
                        Total Orders for Brand
                      </th>
                      <th class="bg-light-success text-black" tabindex="0" aria-controls="basic-1" rowspan="1"
                          colspan="1"
                          aria-label="" style="width: 5%;">
                        Failure Rate
                      </th>
                      <th class="bg-light-success text-black" tabindex="0" aria-controls="basic-1" rowspan="1"
                          colspan="1"
                          aria-label="" style="width: 5%;" v-if="loggedInUserIsRyd">
                        Affected POIs
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="failureItem in orderFailureDetailsResponse.detailedData" :key="failureItem">
                      <td>{{ failureItem.brand }}</td>
                      <td v-if="loggedInUserIsRyd">
                        {{ failureItem.cashierSystem }}
                      </td>
                      <td>{{ failureItem.eventType }}</td>
                      <td>
                        {{ failureItem.eventLastStateMessage }}
                      </td>
                      <td>{{ failureItem.numberOfFailures }}</td>
                      <td>{{ failureItem.nrOfCreatedOrdersForGivenBrand }}</td>
                      <td>{{ (failureItem.failureRate * 100).toFixed(2) }}%</td>
                      <td v-if="loggedInUserIsRyd"><a class="font-success" href="#"
                             @click.prevent="showPoiOccurrences(failureItem.poiOccurrences)">View POIs
                        ({{ failureItem.poiOccurrences ? Object.keys(failureItem.poiOccurrences).length : 0 }})</a></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
          <div class="card-body" v-if="isLoadingOrderFailureDetails">
            <div class="loading-indicator">
              <div class="d-flex align-items-center justify-content-center f-w-900">
                <i class="icofont icofont-speed-boat font-success fa-2x me-2"></i>
                <span>Hang tight! We're fetching your Order Failure Details at warp speed!</span>
              </div>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- CARD 2 END -->

        <div class="card card-absolute">
          <div class="card-body"
               v-if="!isLoadingOrderFailureDetails && !showAlertTimestamps && !orderFailureDetailsResponse.detailedData">
            <h6>There are no recorded order failures within the specified date range.</h6>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import getParamsFromHash from "@/utils/urlUtils";

export default {
  props: {
    partnerNames: []
  },
  data() {
    return {
      showAlertTimestamps: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      loggedInUserIsRyd: false,
      loggedInUserGroup: null,
      partnerMetricsFor: '',
      isLoadingOrderFailureDetails: false,
      orderFailureDetailsResponse: {
        detailedData: [],
        barChartData: {}
      }
    }
  },
  computed: {
    failureRateDataFormatted() {
      return this.orderFailureDetailsResponse?.barChartData?.failureRates?.map(item => (item * 100).toFixed(2)) || [];
    },
    seriesData() {
      return [{
        name: 'Number of Failures',
        type: 'bar',
        data: this.orderFailureDetailsResponse?.barChartData?.numberOfFailures || []
      }, {
        name: 'Total Orders for Brand',
        type: 'bar',
        data: this.orderFailureDetailsResponse?.barChartData?.nrOfCreatedOrders || []
      }, {
        name: 'Failure Rate',
        type: 'bar',
        data: this.failureRateDataFormatted || []
      }];
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            }
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (val, opts) {
              if (opts.seriesIndex === 2) { //
                return val.toFixed(2) + '%';
              }
              return val.toFixed(0);
            }
          }
        },
        xaxis: {
          categories: this.orderFailureDetailsResponse?.barChartData?.titles || []
        },
        colors: ["#fc4438", "#7366ff", "#a927f9"]
      };
    }
  },
  mounted() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.partnerMetricsFor = userGroup;

    this.initializeParameters();
    this.checkAndTriggerSearch();
    this.fetchOrderFailureDetails(this.fromTimestamp, this.toTimestamp);
  },
  methods: {
    async refreshOrderDetailFailure() {
      if (!this.validateTimestamps()) {
        this.showAlertTimestamps = true;
        return;
      }

      this.showAlertTimestamps = false;
      this.isLoadingOrderFailureDetails = true;
      this.$forceUpdate();
      await this.$nextTick(() => {
        this.fetchOrderFailureDetails(this.fromTimestamp, this.toTimestamp);
      });

    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const maxHours = 24 * 60 * 60 * 1000; // Approximately 4 hours in milliseconds
      if (to - from > maxHours) {
        this.showAlertTimestamps = true;
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    getDefaultFromDate() {
      let now = new Date();
      now.setHours(now.getHours() - 6);

      // Format to YYYY-MM-DDTHH:mm
      let year = now.getFullYear();
      let month = ('0' + (now.getMonth() + 1)).slice(-2);
      let day = ('0' + now.getDate()).slice(-2);
      let hours = ('0' + now.getHours()).slice(-2);
      let minutes = ('0' + now.getMinutes()).slice(-2);

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    getDefaultToDate() {
      let now = new Date();

      // Format to YYYY-MM-DDTHH:mm
      let year = now.getFullYear();
      let month = ('0' + (now.getMonth() + 1)).slice(-2);
      let day = ('0' + now.getDate()).slice(-2);
      let hours = ('0' + now.getHours()).slice(-2);
      let minutes = ('0' + now.getMinutes()).slice(-2);

      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    initializeParameters() {
      //const queryParams = this.$route.query;
      let paramsFromHash = getParamsFromHash(window.location.hash);
      this.fromTimestamp = paramsFromHash.from || this.getDefaultFromDate();
      this.toTimestamp = paramsFromHash.to || this.getDefaultToDate();
      this.partnerMetricsFor = paramsFromHash.partnerMetricsFor || this.loggedInUserGroup;
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to && queryParams.partnerMetricsFor) {
        this.refreshOrderDetailFailure();
      }
    },
    async fetchOrderFailureDetails(fromTimestamp, toTimestamp) {
      this.isLoadingOrderFailureDetails = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/order-states/failures`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.convertDateToISOString(toTimestamp),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.orderFailureDetailsResponse = null;
        } else {
          this.orderFailureDetailsResponse = response.data;
        }

        //console.log("orderFailureDetailsResponse", this.orderFailureDetailsResponse);

      } catch (error) {
        this.orderFailureDetailsResponse = null;
        this.isLoadingOrderFailureDetails = false;
        console.error('Error termsHistogramOrderCreationOrderStateResponse:', error);
      } finally {
        this.isLoadingOrderFailureDetails = false;
      }
    },
    showPoiOccurrences(poiOccurrences) {

      const tableRows = Object.entries(poiOccurrences)
          .map(([poi, count]) => `<tr><td>${poi}</td><td>${count}</td></tr>`)
          .join('');

      const tableHtml = `
      <table class="table table-striped">
        <thead>
          <tr>
            <th>POI</th>
            <th>Occurrences</th>
          </tr>
        </thead>
        <tbody>
          ${tableRows}
        </tbody>
      </table>
    `;

      this.$swal({
        title: 'Affected POIs',
        html: tableHtml,
        customClass: {
          content: 'swal-wide' // Custom class to adjust width if necessary
        },
        confirmButtonText: 'Close'
      });

    },

    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname + window.location.hash;
      const queryParams = new URLSearchParams({
        from: this.fromTimestamp || '',
        to: this.toTimestamp || '',
        partnerMetricsFor: this.partnerMetricsFor?.trim() || 'ryd'
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('It has been successfully copied to the clipboard as a complete link and can now be components.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },
  }
}

</script>

<style>
.swal-wide .table {
  width: 100%;
  max-width: 800px;
  margin-bottom: 1rem;
  color: #212529;
}

.swal2-modal {
  width: 800px;
}

.swal-wide .table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}


</style>

