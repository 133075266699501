<template>
  <div class="row">

    <!-- card start-->
    <div class="card">
      <div class="card-body">

        <div class="row">
          <div class="col-md-8">
            This tab shows the HTTP requests sent from your account to the ryd API gateway https://tt4.thinxcloud.de in the last 2 hours and 48 hours.
          </div>
          <div class="col-md-2">
            <button @click="copyShareableLink" class="btn btn-info rounded-pill m-l-5"
                    data-bs-toggle="popover"
                    data-bs-placement="left"
                    data-bs-title="Information"
                    title="Click this button to generate a full URL, including your input fields, making it easy to share with others."
                    data-bs-content="Click this button to generate a full URL, including your input fields, making it easy to share with others.">
              <i class="fa fa-share"></i> Share
            </button>
          </div>
        </div>

        <div v-if="this.loggedInUserGroup === 'ryd' ">
          <div class="row">
            <div class="col-md-4 pe-1">
              <span>Select the partner whose metrics you want to view: </span>
            </div>
            <div class="col-md-3">
              <select class="form-control border border-primary min-width-max-content" id="partnerMetricsFor" v-model="partnerMetricsFor"
                      required>
                <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                  {{ partnerName }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="this.loggedInUserGroup === 'ryd' ">
          <i class="icofont icofont-hand-right font-success">&nbsp;</i>Selecting <b>ryd</b> includes HTTP requests by
          all partners and clients.
        </div>
        <div v-if="this.loggedInUserGroup === 'parkopedia' ">
          <i class="icofont icofont-hand-right font-success">&nbsp;</i>Selecting <b>parkopedia</b> includes HTTP
          requests by parkopedia_bmw and parkopedia_skoda.
        </div>

      </div>
    </div>
    <!-- card end-->

    <!-- Loading Indicator START -->
    <div v-if="isLoadingHttpRequestsStatisticsLast2Hours" class="loading-indicator">
      <h6 class="sub-title mb-0 text-center">Loading ... Recent 2-Hour HTTP Requests Snapshot</h6>
      <div class="loader-box">
        <div class="loader-4"></div>
      </div>
    </div>
    <!-- Loading Indicator END -->

    <!--  CARD START -->
    <div class="col-sm-12 col-md-6" v-if="!isLoadingHttpRequestsStatisticsLast2Hours">
      <div class="card card-absolute">
        <div class="card-header bg-primary">
          <h5 class="text-white">Recent 2-Hour HTTP Requests Snapshot</h5>
        </div>
        <div class="card-body">
          <div
              v-if="httpRequestsStatisticsLast2Hours">
            <table class="table table-bordernone table-striped display" id="basic-1">
              <tbody>
              <tr>
                <th class="border-right-column" scope="row">From</th>
                <td>{{ httpRequestsStatisticsLast2Hours.from }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">To</th>
                <td>{{ httpRequestsStatisticsLast2Hours.to }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Total Number of HTTP Requests
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="Number of total HTTP requests made from your account to ryd API gateway within the chosen time range.">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Hours.totalRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Number of Failed HTTP Requests
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content=" Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range, including errors made by your account, API misuse, logic errors on the ryd side, and server errors on the ryd side.">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Hours.totalFailedRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Number of Successful HTTP Requests
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="Number of successful HTTP requests from your account to ryd API gateway within the chosen time range."></i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Hours.totalSuccessRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Number of HTTP Requests due to ryd Server Failure
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range due to server errors on ryd's side, indicating that the issue lies with ryd and requires ryd's attention for resolution.">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Hours.totalServerFailureRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Error Rate
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="It is a percentage that represents the number of unsuccessful HTTP requests divided by the total number of HTTP requests, rounded to two decimal places">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Hours.errorRate }}%</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
    </div>
    <!--  CARD END -->

    <!-- Loading Indicator START -->
    <div v-if="isLoadingHttpRequestsStatisticsLast2Days" class="loading-indicator">
      <h6 class="sub-title mb-0 text-center">Loading ... Last 48-Hour HTTP Requests Summary</h6>
      <div class="loader-box">
        <div class="loader-17"></div>
      </div>
    </div>
    <!-- Loading Indicator END -->
    <!--  CARD START -->
    <div class="col-sm-12 col-md-6" v-if="!isLoadingHttpRequestsStatisticsLast2Days">
      <div class="card card-absolute">
        <div class="card-header bg-success">
          <h5 class="text-white">Last 48-Hour HTTP Requests Summary</h5>
        </div>
        <div class="card-body">
          <div
              v-if="httpRequestsStatisticsLast2Days">
            <table class="table table-bordernone table-striped display" id="basic-1">
              <tbody>
              <tr>
                <th class="border-right-column" scope="row">From</th>
                <td>{{ httpRequestsStatisticsLast2Days.from }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">To</th>
                <td>{{ httpRequestsStatisticsLast2Days.to }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">
                  Total Number of HTTP Requests
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="Number of total HTTP requests made from your account to ryd API gateway within the chosen time range.">&nbsp;</i>
                </th>

                <td>{{ httpRequestsStatisticsLast2Days.totalRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Number of Failed HTTP Requests
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content=" Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range, including errors made by your account, API misuse, logic errors on the ryd side, and server errors on the ryd side.">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Days.totalFailedRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Number of Successful HTTP Requests
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="Number of successful HTTP requests from your account to ryd API gateway within the chosen time range."></i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Days.totalSuccessRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Number of HTTP Requests due to ryd Server Failure
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range due to server errors on ryd's side, indicating that the issue lies with ryd and requires ryd's attention for resolution.">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Days.totalServerFailureRequestsCount }}</td>
              </tr>
              <tr>
                <th class="border-right-column" scope="row">Error Rate
                  <i class="fa fa-info-circle fa-lg m-l-20"
                     data-bs-toggle="popover"
                     data-bs-placement="top"
                     data-bs-title="What does it mean?"
                     data-bs-content="It is a percentage that represents the number of unsuccessful HTTP requests divided by the total number of HTTP requests, rounded to two decimal places">&nbsp;</i>
                </th>
                <td>{{ httpRequestsStatisticsLast2Days.errorRate }}%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--  CARD END -->

    <!-- card 2 start-->
    <div class="card card-absolute m-t-15">
      <div class="card-header bg-info">
        <h5 class="text-white">Important Notes</h5>
      </div>

      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i> It includes
            comprehensive data on http requests directly
            associated with the account you are currently logged in with.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i> The HTTP requests
            mentioned are requests that your account sent to the ryd API gateway https://tt4.thinxcloud.de.
          </li>
        </ul>
      </div>
    </div>
    <!-- card 2 end-->

  </div>
</template>
<script>
import axios from "axios";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import getParamsFromHash from "@/utils/urlUtils";
import {Popover} from 'bootstrap/dist/js/bootstrap.esm.min';
import {hideAllPopovers, initializePopoversInChildComponent} from "@/utils/popoverUtils";


export default {
  name: 'DashboardRequestStatsTabRequests',
  props: {
    partnerNames: []
  },
  data() {
    return {
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      httpRequestsStatisticsLast2Hours: {},
      httpRequestsStatisticsLast2Days: {},
      isLoadingHttpRequestsStatisticsLast2Hours: false,
      isLoadingHttpRequestsStatisticsLast2Days: false,
    }
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;

    initializePopoversInChildComponent(this.$el);

    let paramsFromHash = getParamsFromHash(window.location.hash);
    this.partnerMetricsFor = paramsFromHash.partnerMetricsFor || this.loggedInUserGroup;

    this.initDataFetch();


  },
  watch: {
    partnerMetricsFor(newVal, oldVal) {
      if (newVal !== oldVal) {

        this.fetchHttpRequestsStatisticsLast2Hours();
        this.fetchHttpRequestsStatisticsLast2Days();

      }
    },
  },
  methods: {
    async fetchHttpRequestsStatisticsLast2Hours(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingHttpRequestsStatisticsLast2Hours = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/metrics-nr-of-http-requests`, {
          params: {
            to: new Date().toISOString(),
            from: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId
          }
        });

        this.httpRequestsStatisticsLast2Hours = response.data;
        //console.log(response.data);

      } catch (error) {
        console.error('Error fetching API Gateway HTTP Requests:', error);
        // Handle error appropriately
      } finally {
        await this.$nextTick(() => {
          initializePopoversInChildComponent(this.$el);
        });
        this.isLoadingHttpRequestsStatisticsLast2Hours = false;
      }
    },
    async fetchHttpRequestsStatisticsLast2Days(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingHttpRequestsStatisticsLast2Days = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/metrics-nr-of-http-requests`, {
          params: {
            to: new Date().toISOString(),
            from: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).toISOString(),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId
          }
        });

        this.httpRequestsStatisticsLast2Days = response.data;
        //console.log(response.data);

      } catch (error) {
        console.error('Error fetching API Gateway HTTP Requests:', error);
        // Handle error appropriately
      } finally {
        await this.$nextTick(() => {
          initializePopoversInChildComponent(this.$el);
        });
        this.isLoadingHttpRequestsStatisticsLast2Days = false;
      }
    },
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    async initDataFetch() {

      try {
        await Promise.all([
          this.fetchHttpRequestsStatisticsLast2Hours(),
          this.fetchHttpRequestsStatisticsLast2Days()
        ]);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // Handle the error appropriately
      }

    },

    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname + window.location.hash;
      const queryParams = new URLSearchParams({
        partnerMetricsFor: this.partnerMetricsFor?.trim() || 'ryd',
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('It has been successfully copied to the clipboard as a complete link and can now be components.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },

  }
}
</script>
<style scoped>
.border-right-column {
  border-right: 1px solid #dee2e6; /* Adjust color as needed */
}
</style>