<template>
  <Breadcrumbs main="Guides: Fuelling" title="Prepay vs Postpay" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- General Information Card -->
        <div class="card">
          <div class="card-header bg-primary">
            <h5>General Information about Prepay and Postpay</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description:</h5>
            <ul class="list-group">
              <li class="list-group-item">An overview of the prepay and postpay functionalities, explaining
                how they work, their differences, and their applications.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Purpose</h5>
            <ul class="list-group">
              <li class="list-group-item">To provide partners with a clear understanding of these two key
                features of the Ryd API.
              </li>
            </ul>
            <br />
          </div>
        </div>

        <!-- Prepaid Transactions Card -->
        <div class="card">
          <div class="card-header bg-success">
            <h5>Prepaid Transactions (Automatic Flow)</h5>
          </div>
          <div class="card-body">
            <p>Prepaid transactions, also known as the "automatic flow," are designed for convenience and
              efficiency in product acquisition, such as fueling at a pump. Here's how it works:</p>
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Upfront Payment Reservation:</h5>
            <ul class="list-group">
              <li class="list-group-item">In this flow, payments are reserved upfront. This reservation is
                crucial as it unlocks the product outlet (for example, a fuel pump at a gas station).
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Product Serving Limit:</h5>
            <ul class="list-group">
              <li class="list-group-item">The product outlet will dispense the product - be it fuel or any
                other item - up to the amount that has been pre-authorized. This ensures that the dispensing
                is controlled and within the expected limit.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Pricing and Acquisition:</h5>
            <ul class="list-group">
              <li class="list-group-item">At the initial stage, only the maximum possible price is known. The
                final price and the total amount of the product acquired are determined only after the user
                has completed the acquisition process, such as finishing fueling.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Order State Transition:</h5>
            <ul class="list-group">
              <li class="list-group-item">The order status in the system automatically changes from
                <code>PRODUCT_PENDING</code> to <code>PRODUCT_ACQUIRED</code>. This transition occurs as soon as the
                user takes the product from the outlet, like when fueling is completed.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Sequence Diagram:</h5>
            <ul class="list-group">
              <li class="list-group-item">For a more detailed understanding, a full sequence diagram for the
                automatic flow is available below:
              </li>
            </ul>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials-1" itemscope="" data-pswp-uid="1">
                    <figure class="col-md-3 col-6 img-hover hover-15" @click="showImg(0)" itemprop="associatedMedia" itemscope="">
                      <div>
                        <img :src="require('../../../../assets/images/ryd/prepay-sequence-diagram-full.png')" itemprop="thumbnail1" alt="Image description" class="img-fluid">
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>

        <!-- Postpaid Transactions Card -->
        <div class="card">
          <div class="card-header bg-primary">
            <h5>Postpaid Transactions (Manual Flow)</h5>
          </div>
          <div class="card-body">
            <p>Postpaid transactions, or the "manual flow," offer a more traditional approach to purchasing.
              This method is characterized by the following steps:</p>
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Product Acquisition Before Payment:</h5>
            <ul class="list-group">
              <li class="list-group-item">Unlike the prepaid method, the manual flow allows users to first
                take the product, such as performing a fuel top-up, and then authorize the payment afterward.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Known Price and Amount:</h5>
            <ul class="list-group">
              <li class="list-group-item">In this flow, both the final price and the exact amount of the
                product are known at the time the user decides to acquire it. This provides clarity and
                certainty in terms of cost and quantity.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>User-Initiated Order Completion:</h5>
            <ul class="list-group">
              <li class="list-group-item">The order status changes from PRODUCT_PENDING to PRODUCT_ACQUIRED
                manually. This change is user-initiated, typically done by clicking a 'purchase now' button
                or completing a similar action.
              </li>
            </ul>
            <br />
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Sequence Diagram:</h5>
            <ul class="list-group">
              <li class="list-group-item">To visualize this process, a full sequence diagram for the manual
                flow is available below:
              </li>
            </ul>
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials-2" itemscope="" data-pswp-uid="1">
                    <figure class="col-md-3 col-6 img-hover hover-15" @click="showImg(1)" itemprop="associatedMedia" itemscope="">
                      <div>
                        <img :src="require('../../../../assets/images/ryd/postpay-sequence-diagram-full.png')" itemprop="thumbnail2" alt="Image description" class="img-fluid">
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import {scrollToCard, scrollToRootHash, scrollToTop} from "@/utils/anchorUtils";

export default {
  keywords: [
    'Prepay', 'Prepaid', 'Postpay', 'Postpaid', 'Fuelling Flows', 'Payment Flows', 'Integration Guide',
    'Sequence Diagram', 'Client Integration', 'Payment Processes', 'Fuel Payment', 'API Flows',
    'Prepay Integration', 'Postpay Integration', 'Payment Workflow', 'Transaction Flow',
    'Payment Sequence', 'API Integration', 'Fuel Prepay', 'Fuel Postpay', 'Flow Documentation',
    'Payment Sequence Diagram', 'Prepay Process', 'Postpay Process', 'Integration Steps',
    'Fuelling API', 'Prepay Workflow', 'Postpay Workflow', 'Fuel Payment Integration',
    'Payment API', 'Flow Implementation', 'Transaction Management', 'Client Payment Flow',
    'Prepay API', 'Postpay API', 'Payment Flowchart', 'Integration Example', 'Payment Method',
    'Fuel Purchase', 'Prepaid Fueling', 'Postpaid Fueling', 'Fuel Transaction', 'API Guide',
    'Client Payment Integration', 'Payment Handling', 'Integration Diagram', 'Prepay Sequence',
    'Postpay Sequence', 'Process Flow', 'Sequence Chart', 'API Usage', 'Payment Integration Guide',
    'Prepay Flowchart', 'Postpay Flowchart', 'API Workflow', 'Fueling API Integration',
    'Fuel Station Payment', 'Prepaid Integration', 'Postpaid Integration', 'Sequence Guide',
    'Client Setup', 'Prepay Steps', 'Postpay Steps', 'Fuel Payment System', 'Payment Sequence Chart'
  ],
  data() {
    return {
      lightBoxImages: [],
      index: 0, // Added missing index data property
      gallery1: [
        {
          image: 'prepay-sequence-diagram-full.png'
        },
        {
          image: 'postpay-sequence-diagram-full.png'
        },
        {
          image: 'ryd-order-state-simple-0415.png'
        }
      ],
    }
  },
  watch: {
    '$route.hash': function () {
      this.scrollToRootHash();
    }
  },
  methods: {
    scrollToRootHash,
    scrollToTop,
    showImg(index) {
      this.index = index;
      this.visible = true;
      const imageUrl = this.lightBoxImages[this.index];

      this.$swal({
        imageUrl,
        imageAlt: 'Image',
        showCloseButton: true,
        showConfirmButton: false,
        customClass: 'swal-wide'
      });

    },
  },
  mounted() {
    this.scrollToRootHash();
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/components/swal_custom.scss";
</style>
