<template>
  <Breadcrumbs main="Partner Administration" title="Partner List"/>

  <div class="container-fluid">


    <div v-if="isLoading">
      <h6 class="sub-title mb-0 text-center">Loading added partners ...</h6>
      <div class="loader-box">
        <div class="loader-19"></div>
      </div>
    </div>


    <div class="col-sm-12" v-if="!isLoading">
      <div class="card">
        <div class="card-body">

          <p>
            Below you will find all registered partners on the ryd partner portal:
          </p>
          <br/>

          <div class="input-group row mb-3">
            <div class="col-6">
              <input type="text" class="form-control border-primary border border-3" v-model="searchQuery" placeholder="Search partners by entering a part of the name, case insensitive.."/>
            </div>
          </div>


          <div class="table-responsive product-table">
            <div class="row">
              <div class="col-6">
                <table class="table table-bordered table-striped border border-dark">
                  <tbody>
                  <tr v-for="partnerName in filteredPartnerNames" :key="partnerName" :value="partnerName">
                    <td class="border border-dark">{{ partnerName }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  name: "partnerAdministrationList",
  keywords: ['Partner List', 'All Partners', 'Registered Partners', 'Partner Directory', 'List of Partners'],
  data() {
    return {
      partnerNames: [],
      isLoading: false,
      loggedInUserId: '',
      searchQuery: '',
    };
  },
  mounted() {
    const {uid} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loadPartnerNames();
  },
  methods: {
    async loadPartnerNames() {
      try {
        this.isLoading = true;
        this.partnerNames = await fetchPartnerNames(this.loggedInUserId);
        this.isLoading = false;
      } catch (error) {
        console.log("error", error);
        this.isLoading = false;
      }
    }
  },
  computed: {
    filteredPartnerNames() {
      return this.partnerNames.filter(partnerName =>
          partnerName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }
}
</script>