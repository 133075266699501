<template>
  <Breadcrumbs main="Troubleshooting" title="Account Activities"/>

  <div class="container-fluid search-page">
    <div class="row">

      <!-- CARD 1 START -->
      <div class="card">
        <div class="card-body">
          <div class="theme-form">

            <!-- INPUT GROUP 1 START -->
            <div class="input-group m-b-10">
              <div class="row align-items-center">
                <div class="col text-center f-w-700">
                  Search Date Range:
                </div>
                <div class="col">
                  <input v-model="fromTimestamp" type="date" class="form-control"
                         placeholder="From Timestamp">
                </div>
                <div class="col-auto text-center"> <!-- Center the icon in a narrower column -->
                  <i class="fa fa-long-arrow-right"></i>
                </div>
                <div class="col">
                  <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                </div>
              </div>
            </div>
            <!-- INPUT GROUP 1 END -->

            <!-- INPUT GROUP 2 START -->
            <div class="input-group m-0 col-sm-12">
              <div class="row align-items-center m-r-30">

                <div class="col text-center f-w-700">
                  Account ID:
                </div>
              </div>

              <input v-model="accountId" class="form-control form-control-plaintext ryd-search-input"
                     type="search" id="account-details-search"
                     placeholder="Enter an account id from staging or production environment ..">

              <button @click="updateTabs"
                      class="btn btn-pill btn-success btn-air-success active input-group-text m-r-20">
                <i class="fa fa-search m-r-10"></i>Search
              </button>

              <button @click="copyShareableLink" class="btn btn-info" id="copy-url-button"
                      data-bs-toggle="popover"
                      data-bs-placement="left"
                      data-bs-title="Information"
                      data-bs-content="Click this button to generate a full URL, including your input fields (account ID and search date range), making it easy to share with others.">
                <i class="fa fa-share"></i> Share This Search
              </button>


            </div>
          </div>
          <!-- INPUT GROUP 2 END -->
        </div>
      </div>
      <!-- CARD 1 END -->

      <!-- CARD 2 START -->
      <div class="card">
        <div class="card-body">

          <div id="alertDiv1" v-if="showAlertAccountId" class="alert alert-secondary outline" role="alert">
            <p>Please enter a valid order id with at least 10 characters. </p>
          </div>
          <div id="alertDiv2" v-if="showAlertTimestamps" class="alert alert-secondary outline" role="alert">
            <p>Please Enter a valid search date range: Both 'From' and 'To' dates are required and cannot be left
              empty. Additionally, the time span between the 'From' and 'To' dates must not exceed 7 days. </p>
          </div>

          <div class="text-center" id="account-details-search-result-tabs">
            <ul class="nav nav-tabs search-list" id="top-tab" role="tablist">
              <li class="nav-item" v-on:click="changetab('accountActivities')">
                <a class="nav-link " :class="{'show active': showtab === 'accountActivities' }" id="account-activities"
                   data-bs-toggle="popover"
                   data-bs-placement="bottom"
                   data-bs-title="What is provided in this tab?"
                   data-bs-content="Provides insights into customer activities within the ryd Android or iOS apps, including app launches, screen views, clicks, and updates to ryd Android or iOS app versions."
                   role="tab" aria-controls="accountActivities" aria-selected="false"><i class="icon-agenda"></i>1.
                  Account Activities</a>
              </li>
              <li class="nav-item" v-on:click="changetab('accountMetadata')">
                <a class="nav-link" :class="{'show active': showtab === 'accountMetadata' }" id="account-metadata"
                   data-bs-toggle="popover"
                   data-bs-placement="bottom"
                   data-bs-title="What is provided in this tab?"
                   data-bs-content="Displays information about the operating system and device used by the specified account within the selected date range."
                   role="tab" aria-controls="accountMetadata" aria-selected="false"><i class="icon-info-alt"></i>2.
                  Account Metadata </a>
              </li>
              <li class="nav-item" v-on:click="changetab('availableOrders')">
                <a class="nav-link" :class="{'show active': showtab === 'availableOrders' }" id="available-orders"
                   data-bs-toggle="popover"
                   data-bs-placement="bottom"
                   data-bs-title="What is provided in this tab?"
                   data-bs-content="Lists the orders created by the specified account within the selected date range."
                   role="tab" aria-controls="availableOrders" aria-selected="false"><i
                    class="icon-shopping-cart-full"></i>3.
                  Available Orders </a>
              </li>
            </ul>
          </div>

          <div class="tab-content" id="top-tabContent">

            <!-- TAB 1 Content BEGIN -->
            <div class="search-links tab-pane fade" :class="{'show active': showtab === 'accountActivities' }"
                 id="account-activities"
                 role="tabpanel" aria-labelledby="accountNaActivities">

              <!-- If not loading and apiResponseAccountNaActivities is truthy -->
              <div class="order-history table-responsive" v-show="showPlot">
                <div ref="plotContainer" style="width: 100%;"></div>
              </div>

              <div
                  v-if="!isLoadingApiAccountNaActivities && apiResponseAccountNaActivities && apiResponseAccountNaActivities.errorMessage">
                <div class="text-center fw-bold fs-6 font-danger">
                  {{this.apiResponseAccountNaActivities.errorMessage}}</div>
              </div>

              <!-- Loading Indicator -->
              <div v-if="isLoadingApiAccountNaActivities" class="loading-indicator">
                <h6 class="sub-title mb-0 text-center">Loading 1... Account Activities</h6>
                <div class="loader-box">
                  <div class="loader-17"></div>
                </div>
              </div>
            </div>
            <!-- TAB 1 Content END -->

            <!-- TAB 2 Content BEGIN -->
            <TabAccountMetadata :api-response-api-account-metadata="apiResponseApiAccountMetadata"
                                :is-loading-api-account-metadata="isLoadingApiAccountMetadata" :showtab="showtab"/>
            <!-- TAB 2 Content END -->

            <!-- TAB 3 Content BEGIN -->
            <TabAvailableOrders :api-response-api-available-order-ids="apiResponseApiAvailableOrderIds"
                                :is-loading-api-available-order-ids="isLoadingApiAvailableOrderIds" :showtab="showtab"/>

            <!-- TAB 3 Content END -->
          </div>


        </div>
      </div>
    </div>
    <!-- CARD 2 END -->


    <!-- card 3 start-->
    <div class="card card-absolute m-t-15">
      <div class="card-header bg-info">
        <h5 class="text-white">Important Notes</h5>
      </div>

      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">Ensure you specify a valid search date range where
            the interval between the 'From' and 'To' dates <b>does not exceed 7 days</b>, to maintain optimal
            search
            performance.
          </li>
          <li class="list-group-item">At the moment, only the events / log messages of the ryd Android app or the ryd iOS app will be searched.
          </li>
        </ul>
      </div>
    </div>
    <!-- card 3 end-->
  </div>
</template>


<script>
import Plotly from 'plotly.js-dist-min';
import axios from 'axios';

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {parseDateStringYyyyMmDd, parseDateStringYyyyMmDdNoBinder} from "@/utils/dateUtils";
import {NA_EVENTS_API_SERVER_KAY, USER_EVENTS_API_SERVER_URL} from "@/constants/constants";
import TabAccountMetadata from "@/pages/troubleshooting/accountDetails/TabAccountMetadata.vue";
import TabAvailableOrders from "@/pages/troubleshooting/accountDetails/TabAvailableOrders.vue";
import {Popover} from 'bootstrap/dist/js/bootstrap.esm.min';


export default {
  keywords: ['Account Details', 'Account Activity', 'Account Activities', 'Account Info'],
  components: {
    TabAvailableOrders,
    TabAccountMetadata,

  },
  data() {
    return {
      showtab: 'accountActivities',
      accountId: null,
      loggedInUserId: '',
      loggedInUserIsRyd: false,
      showAlertAccountId: false,
      showAlertTimestamps: false,
      isLoadingApiAccountNaActivities: false,
      isLoadingApiAccountMetadata: false,
      isLoadingApiAvailableOrderIds: false,
      apiResponseAccountNaActivities: {
        data: null,
        layout: null,
        errorMessage: null
      },
      apiResponseApiAccountMetadata: null,
      apiResponseApiAvailableOrderIds: null,
      showPlot: false,
    };
  },
  created() {
    const {uid, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.initializeParameters();
    this.checkAndTriggerSearch();
  },
  mounted() {

    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');

    const popoverList = [...popoverTriggerList].map(popoverTriggerEl =>
        new Popover(popoverTriggerEl, {
          trigger: 'focus hover'
        })
    );

  },

  methods: {
    async fetchAccountNaActivities(accountId, fromTimestamp, toTimestamp) {
      this.isLoadingApiAccountNaActivities = true;
      this.showPlot = false;

      const payload = {
        time_range: {
          from: parseDateStringYyyyMmDdNoBinder(fromTimestamp),
          to: parseDateStringYyyyMmDdNoBinder(toTimestamp),
        },
        account_id: accountId
      };

      const headers = {
        'Content-Type': 'application/json',
        'x-txn-auth-token': NA_EVENTS_API_SERVER_KAY,
        'uid': this.loggedInUserId,
      };

      // Make the POST request to the API
      axios.post(USER_EVENTS_API_SERVER_URL, payload, {headers})
          .then(response => {

            this.apiResponseAccountNaActivities.data = response.data.data;
            this.apiResponseAccountNaActivities.layout = response.data.layout;
            this.apiResponseAccountNaActivities.errorMessage = null;

            //console.log("apiResponseAccountNaActivities", this.apiResponseAccountNaActivities);
            // Only show the plot if there is data
            if (this.apiResponseAccountNaActivities.data && this.apiResponseAccountNaActivities.data.length > 0) {
              this.showPlot = true;  // Show the plot
              this.$nextTick(() => {
                Plotly.newPlot(this.$refs.plotContainer, this.apiResponseAccountNaActivities.data, this.apiResponseAccountNaActivities.layout);
                Plotly.Plots.resize(this.$refs.plotContainer);
              });
            } else {
              this.apiResponseAccountNaActivities.errorMessage = "No activity was found.";
            }

            this.isLoadingApiAccountNaActivities = false;
          })
          .catch(error => {

            console.error('Error fetching plot data:', error);

            this.apiResponseAccountNaActivities.data = null;
            this.apiResponseAccountNaActivities.layout = null;
            this.apiResponseAccountNaActivities.errorMessage = "A technical error occurred.";
            this.isLoadingApiAccountNaActivities = false;
            this.showPlot = false;
          });
    },
    changetab(tab) {
      this.showtab = tab;
    },
    async updateTabs() {
      this.showPlot = false;
      if (!this.accountId || this.accountId.trim().length < 10) {
        this.showAlertAccountId = true;
        this.showAlertTimestamps = false;
        return; // Early return if orderUuid is invalid
      }

      // Check for valid timestamps
      if (!this.validateTimestamps()) {
        this.showAlertAccountId = false;
        this.showAlertTimestamps = true;
        return; // Early return if timestamps are invalid
      }

      this.showtab = 'accountActivities';
      this.showAlertAccountId = false;
      this.showAlertTimestamps = false;
      this.isLoadingApiAccountNaActivities = true;
      this.isLoadingApiAvailableOrderIds = true;
      this.isLoadingApiAccountMetadata = true;

      try {
        await Promise.all([
          this.fetchAccountNaActivities(this.accountId.trim(), this.fromTimestamp, this.toTimestamp),
          this.fetchAccountMetadata(this.accountId.trim(), this.fromTimestamp, this.toTimestamp),
          this.fetchAvailableOrderIds(this.accountId.trim(), this.fromTimestamp, this.toTimestamp)
        ]);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // Handle the error appropriately
      }


    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sevenDays = 7 * 24 * 60 * 60 * 1000;
      if (to - from > sevenDays) {
        this.showAlertTimestamps = true;
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 5);
      return parseDateStringYyyyMmDd(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate());
      return parseDateStringYyyyMmDd(now.toISOString());
    },
    initializeParameters() {
      const queryParams = this.$route.query;
      this.fromTimestamp = queryParams.from || this.getDefaultFromDate();
      this.toTimestamp = queryParams.to || this.getDefaultToDate();
      this.accountId = queryParams.accountId || '';

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? parseDateStringYyyyMmDd(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? parseDateStringYyyyMmDd(this.toTimestamp) : '';
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to && queryParams.accountId) {
        this.updateTabs();
      }
    },
    async fetchAccountMetadata(accountId, fromTimestamp, toTimestamp) {
      this.isLoadingApiAccountMetadata = true;
      try {
        const response = await axios.get(`${USER_EVENTS_API_SERVER_URL}/device-info-by-account-id`, {
          params: {
            from: parseDateStringYyyyMmDdNoBinder(fromTimestamp),
            to: parseDateStringYyyyMmDdNoBinder(toTimestamp),
            account_id: accountId,
          },
          headers: {
            'Content-Type': 'application/json',
            'x-txn-auth-token': NA_EVENTS_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        // example response:   [{"mobile_platform_name": "Android", "moblile_platform_version": "Android 13", "mobile_brand_name": "Xiaomi", "mobile_model_name": "2201122C", "mobile_marketing_name": "12 Pro", "is_limited_ad_tracking": "No", "ryd_native_app_version": "5.5.5"}]
        this.apiResponseApiAccountMetadata = response.data;

        if (this.apiResponseApiAccountMetadata.length === 0) {
          this.apiResponseApiAccountMetadata.errorMessage = "No account metadata is available.";
        }
        //console.log("apiResponseApiAccountMetadata", this.apiResponseApiAccountMetadata);
      } catch (error) {
        this.apiResponseApiAccountMetadata = {};
        this.apiResponseApiAccountMetadata.errorMessage = "A technical error occurred.";
        console.error('Error fetching account meta data:', error);
      } finally {
        this.isLoadingApiAccountMetadata = false;
      }
    },
    async fetchAvailableOrderIds(accountId, fromTimestamp, toTimestamp) {
      this.isLoadingApiAvailableOrderIds = true;
      try {
        const response = await axios.get(`${USER_EVENTS_API_SERVER_URL}/order-ids-by-account-id`, {
          params: {
            from: parseDateStringYyyyMmDdNoBinder(fromTimestamp),
            to: parseDateStringYyyyMmDdNoBinder(toTimestamp),
            account_id: accountId,
          },
          headers: {
            'Content-Type': 'application/json',
            'x-txn-auth-token': NA_EVENTS_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        // example response:
        this.apiResponseApiAvailableOrderIds = response.data;
        //console.log("apiResponseApiAvailableOrderIds", this.apiResponseApiAvailableOrderIds);

        if (this.apiResponseApiAvailableOrderIds.length === 0) {
          this.apiResponseApiAvailableOrderIds.errorMessage = "No order is available.";
        }
      } catch (error) {
        this.apiResponseApiAvailableOrderIds = {};
        this.apiResponseApiAvailableOrderIds.errorMessage = "A technical error occurred.";
        console.error('Error fetching account meta data:', error);
      } finally {
        this.isLoadingApiAvailableOrderIds = false;
      }
    },
    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname;
      const queryParams = new URLSearchParams({
        from: this.fromTimestamp || '',
        to: this.toTimestamp || '',
        accountId: this.accountId?.trim() || ''
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('The Account ID and Date Range have been successfully copied to the clipboard as a complete link.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },
  }
}
</script>

<style scoped>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

#account-details-search.ryd-search-input {
  border-radius: 0.375rem;;
}

#account-details-search-result-tabs .nav-item {
  border-right: 1px solid var(--theme-deafult);
}

#account-details-search-result-tabs .nav-item:last-child {
  border-right: none;
}


</style>