<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Station Offline History"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group">
                <div class="row align-items-center w-100">
                  <div class="col-md-1 text-center fw-bold label-margin-adjust">
                    POI Id
                  </div>
                  <div class="col-md-4">
                    <input v-model="poiId"
                           type="text"
                           class="form-control"
                           placeholder="Enter a ryd poi id"
                           ref="poiId"
                           :class="{ 'is-invalid': poiIdError }"
                    >
                  </div>
                  <div class="col-md-3">
                    <button
                        @click="triggerSearch"
                        class="btn btn-pill btn-success btn-air-success active w-100">
                      <i class="fa fa-send m-r-10"></i> Find Offline History
                    </button>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 END -->

            </div>
          </div>
        </div>
        <!-- CARD 1 END -->

        <!-- CARD 2 START -->
        <div class="card">
          <div class="card-body">


            <h5 class="card-title font-primary">
              <i class="icofont icofont-dashboard-web"></i>
              Station Online and Offline History</h5>
            <p class="card-text m-b-30">
              This chart displays the online and offline history for a specific station. The status of the station is determined at various checkpoints, with each checkpoint indicating whether the station was online or offline at that particular moment.
            </p>

            <!-- Loading Indicator START -->
            <div v-if="isLoading" class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Loading... offline history for the provided station identified by the poi id <strong>{{this.poiId}}</strong> </h6>
              <div class="loader-box">
                <div class="loader-17"></div>
              </div>
            </div>

            <!-- Chart START -->
            <div v-if="!isLoading && findOfflineHistoryResponse && findOfflineHistoryResponse.allCheckpoints && findOfflineHistoryResponse.allCheckpoints.length > 0">
              <apexchart type="scatter" height="250" :options="chartOptions" :series="series"></apexchart>
            </div>
            <!-- Chart END -->

          </div>
        </div>
        <!-- CARD 2 END -->

        <!-- CARD 3 START -->
        <div class="card">
          <div class="card-body">

            <h5 class="card-title font-success"><i class="icofont icofont-dashboard-web"></i>&nbsp; Station Information</h5>

            <!-- Loading Indicator START -->
            <div v-if="isLoadingPoiData" class="loading-indicator">
              <h6 class="sub-title mb-0 text-center">Loading information of the provided station identified by the poi id <strong>{{this.poiId}}</strong> </h6>
              <div class="loader-box">
                <div class="loader-19"></div>
              </div>
            </div>

            <!-- Chart START -->
            <div v-if="!isLoadingPoiData && selectedPoiData && selectedPoiData.address_city">

              <div class="row">
                <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-border table-striped">
                  <thead class="bg-primary text-white">
                  <tr>
                    <th class="border border-dark bg-primary" style="width: 20%;" scope="col">Field</th>
                    <th class="border border-dark bg-primary" style="width: 50%;" scope="col">Value</th>
                    <th class="bg-primary" scope="col" style="width: 30%;">Copy</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="border border-dark text-success fw-bold">POI Id</td>
                    <td class="border border-dark">{{ selectedPoiData.poi_id }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.poi_id)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Country</td>
                    <td class="border border-dark">{{ selectedPoiData.address_country_code }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_country_code)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Zip</td>
                    <td class="border border-dark">{{ selectedPoiData.address_zip }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_zip)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">City</td>
                    <td class="border border-dark">{{ selectedPoiData.address_city }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_city)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Street</td>
                    <td class="border border-dark">{{ selectedPoiData.address_street }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_street)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">House Number</td>
                    <td class="border border-dark">{{ selectedPoiData.address_house_number }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_house_number)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Latitude</td>
                    <td class="border border-dark">{{ selectedPoiData.address_latitude }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_latitude)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Longitude</td>
                    <td class="border border-dark">{{ selectedPoiData.address_longitude }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_longitude)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Cashier System</td>
                    <td class="border border-dark">{{ selectedPoiData.cashier_system }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.cashier_system)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Brand</td>
                    <td class="border border-dark">{{ selectedPoiData.brand }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.brand)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">MOC Partner</td>
                    <td class="border border-dark">{{ selectedPoiData.partner_name }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.partner_name)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Cashier System Level 2</td>
                    <td class="border border-dark">{{ selectedPoiData.cashier_system_level_2 }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.cashier_system_level_2)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td class="border border-dark text-success fw-bold">Has Order Created</td>
                    <td class="border border-dark">{{ selectedPoiData.has_order_created }}</td>
                    <td class="border border-dark">
                      <button class="m-l-50 btn btn-success" @click="copyToClipboard(selectedPoiData.address_country_code)"><i class="fa fa-copy"></i>&nbsp;Copy
                      </button>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>
                </div>
              </div>

            </div>
            <!-- Chart END -->

          </div>
        </div>
        <!-- CARD 3 END -->

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VueApexCharts from 'vue3-apexcharts';
import {
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL
} from "@/constants/constants";
import { getUserAddDataFromLocalStorage } from "@/utils/userUtils";

export default {
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      isLoading: false,
      isLoadingPoiData: false,
      loggedInUserId: '',
      poiId: '',
      poiIdError: false,
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'scatter',
          animations: {
            enabled: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#00E396', '#FF4560'], // Green for online, Red for offline
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function(value) {
              return new Date(value).toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
              }).replace(',', '');
            }
          }
        },
        yaxis: {
          min: 0,
          max: 2,
          tickAmount: 3,
          labels: {
            formatter: function(value) {
              if (value === 1) return 'Online';
              if (value === 2) return 'Offline';
              return '';
            }
          }
        },
        markers: {
          size: 6,
          strokeWidth: 0,
        },
        tooltip: {
          custom: function({ seriesIndex, dataPointIndex, w }) {
            const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            const status = seriesIndex === 0 ? 'Online' : 'Offline';
            const time = new Date(data[0]).toLocaleString('en-US', {
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }).replace(',', '');
            return `
                <div class="apexcharts-tooltip-box">
                  <span style="color: ${status === 'Online' ? '#00E396' : '#FF4560'}">
                    ${status}
                  </span>
                  <br>
                  <span>${time}</span>
                </div>
              `;
          }
        },
      },
      findOfflineHistoryResponse: {
        offline: [],
        allCheckpoints: [],
        errorMessage: null,
        infoMessage: null
      },
      selectedPoiData: {
        poi_id: null,
        address_street: null,
        address_house_number: null,
        address_zip: null,
        address_city: null,
        address_country_code: null,
        address_latitude: null,
        address_longitude: null,
      }
    };
  },
  methods: {
    initializeParameters() {
      const queryParams = this.$route.query;
      this.poiId = queryParams.poiId || '';
    },
    validatePoiId() {
      this.poiIdError = !this.poiId || (this.poiId && this.poiId.length < 5);
    },
    async triggerSearch() {
      this.validatePoiId();

      if (this.poiIdError) {
        this.$nextTick(() => {
          this.$refs.poiId.scrollIntoView({ behavior: 'smooth', block: 'center' });
          this.$refs.poiId.focus();
        });
        return;
      }

      await this.findOfflineHistory();
      await this.getPoiData();
    },
    async findOfflineHistory() {
      this.isLoading = true;
      this.poiIdError = false;

      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/gas-stations/${this.poiId}/offline-history`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.findOfflineHistoryResponse = {};
          this.findOfflineHistoryResponse.errorMessage = "A technical error occurred.";
        } else {
          this.findOfflineHistoryResponse = response.data;

          const { offline, allCheckpoints } = this.findOfflineHistoryResponse;

          const onlinePoints = [];
          const offlinePoints = [];

          allCheckpoints.forEach(checkpoint => {
            const date = this.convertToDate(checkpoint);
            const timestamp = date.getTime();

            if (offline.includes(checkpoint)) {
              offlinePoints.push([timestamp, 2]); // x, y (2 for offline)
            } else {
              onlinePoints.push([timestamp, 1]); // x, y (1 for online)
            }
          });

          this.series = [
            {
              name: 'Online',
              data: onlinePoints
            },
            {
              name: 'Offline',
              data: offlinePoints
            }
          ];
        }
      } catch (error) {
        this.findOfflineHistoryResponse.errorMessage = "A technical error occurred.";
        console.error('Error findOfflineHistory:', error);
      } finally {
        this.isLoading = false;
      }
    },
    convertToDate(timestamp) {
      const year = timestamp.slice(0, 4);
      const month = timestamp.slice(4, 6);
      const day = timestamp.slice(6, 8);
      const hour = timestamp.slice(8, 10);
      const minute = timestamp.slice(10, 12);
      return new Date(`${year}-${month}-${day}T${hour}:${minute}:00`);
    },
    async getPoiData() {
      this.isLoadingPoiData = true;
      this.selectedPoiData = {};
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/gas-stations/${this.poiId}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid':  this.loggedInUserId,
          }
        });

        this.selectedPoiData = response.data.data;
      } catch (error) {
        console.log("error", error);
      }finally {
        this.isLoadingPoiData = false;
      }
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        this.$toast.show('Copied to clipboard!', {
          theme: 'outline',
          position: 'bottom',
          type: 'success',
          duration: 5000
        });
      }).catch(err => {
        this.$toast.show('Failed to copy!', {
          theme: 'outline',
          position: 'bottom',
          type: 'danger',
          duration: 8000
        });
      });
    }
  },
  mounted() {
    const { uid } = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.initializeParameters();
    if (this.poiId) {
      this.triggerSearch();
      this.getPoiData();
    }
  },
};
</script>

<style scoped>
.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
