<template>
  <Breadcrumbs main="Guides: Fuelling" title="Fuelling Simulated Stations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Purpose</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">Ryd provides virtual EV Charging stations in a STAGING environment,
                specifically designed for testing purposes.
              </li>
              <li class="list-group-item">These simulated EV charging stations are intended to replicate various
                scenarios for API integration and testing.
              </li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->


        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-success">
            <h5 class="text-white">Automatic Station Details</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Automatic Noop Stations</b>: Available in several countries (DE, BE, CH,
                AT, NL, LU, PT, ES) with respective currencies and tax rates. All pumps are automatic.
              </li>
            </ul>

            <br/>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-success f-w-800 border border-dark">POI ID</th>
                  <th class="font-success f-w-800 border border-dark">POI Brand</th>
                  <th class="font-success f-w-800 border border-dark">Country</th>
                  <th class="font-success f-w-800 border border-dark">Currency</th>
                  <th class="font-success f-w-800 border border-dark">Tax Rate</th>
                  <th class="font-success f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark">5f7c5d5d09838cf9cbfd3ecf</td>
                  <td class="border border-dark">NoOp</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f178</td>
                  <td class="border border-dark">BE Automatic Noop</td>
                  <td class="border border-dark">BE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">21%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe0f2d000d67561f172</td>
                  <td class="border border-dark">DE Automatic Noop</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">This station displays MTSK fuel prices</td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f174</td>
                  <td class="border border-dark">CH Automatic</td>
                  <td class="border border-dark">CH</td>
                  <td class="border border-dark">CHF</td>
                  <td class="border border-dark">8%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f176</td>
                  <td class="border border-dark">AT Automatic Noop</td>
                  <td class="border border-dark">AT</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">20%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f17a</td>
                  <td class="border border-dark">NL Automatic Noop</td>
                  <td class="border border-dark">NL</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">21%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f17c</td>
                  <td class="border border-dark">LU Automatic Noop</td>
                  <td class="border border-dark">LU</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">17%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f17e</td>
                  <td class="border border-dark">PT Automatic Noop</td>
                  <td class="border border-dark">PT</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">23%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f180</td>
                  <td class="border border-dark">ES Automatic Noop</td>
                  <td class="border border-dark">ES</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">21%</td>
                  <td class="border border-dark"></td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="text-white">Manual Station Details</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Manual Noop Stations</b>: Also available in multiple countries, including a
                special 'Manual-never-ready' station in DE. All pumps are manual.
              </li>
            </ul>

            <br/>

            <div class="table-responsive">
              <table class="table table-bordered table-striped border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-primary f-w-800 border border-dark">POI ID</th>
                  <th class="font-primary f-w-800 border border-dark">POI Brand</th>
                  <th class="font-primary f-w-800 border border-dark">Country</th>
                  <th class="font-primary f-w-800 border border-dark">Currency</th>
                  <th class="font-primary f-w-800 border border-dark">Tax Rate</th>
                  <th class="font-primary f-w-800 border border-dark">Comments</th>
                </tr>
                </thead>
                <tbody>

                <tr>
                  <td class="border border-dark">5fa92c325bce72222dcde699</td>
                  <td class="border border-dark">DE Manual Noop</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark"></td>
                </tr>

                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f181</td>
                  <td class="border border-dark">DE Manual-never-ready Noop</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark">Gas station that will never have a transaction ready state</td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f179</td>
                  <td class="border border-dark">BE Manual Noop</td>
                  <td class="border border-dark">BE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">21%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe0f2d000d67561f173</td>
                  <td class="border border-dark">DE Manual Noop</td>
                  <td class="border border-dark">DE</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">19%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f175</td>
                  <td class="border border-dark">CH Manual Noop</td>
                  <td class="border border-dark">CH</td>
                  <td class="border border-dark">CHF</td>
                  <td class="border border-dark">8%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f177</td>
                  <td class="border border-dark">AT Manual Noop</td>
                  <td class="border border-dark">AT</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">20%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f17b</td>
                  <td class="border border-dark">NL Manual Noop</td>
                  <td class="border border-dark">NL</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">21%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f17d</td>
                  <td class="border border-dark">LU Manual Noop</td>
                  <td class="border border-dark">LU</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">17%</td>
                  <td class="border border-dark"></td>
                </tr>
                <tr>
                  <td class="border border-dark">60c9bfe1f2d000d67561f17f</td>
                  <td class="border border-dark">PT Manual Noop</td>
                  <td class="border border-dark">PT</td>
                  <td class="border border-dark">EUR</td>
                  <td class="border border-dark">23%</td>
                  <td class="border border-dark"></td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card">
          <div class="card-header bg-success">
            <h5 class="text-white">Pump Configurations</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Pump 1 to 11</b>: Each pump is uniquely configured to test different
                scenarios like displaying fuel types with/without prices, transaction cancellations, response failures,
                and specific transaction states.
              </li>
            </ul>

            <br/>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped  border border-dark">
                <thead class="text-black">
                <tr>
                  <th class="font-success f-w-800 border border-dark">Pump ID</th>
                  <th class="font-success f-w-800 border border-dark">Description</th>
                  <th class="font-success f-w-800 border border-dark">Fuel Types</th>
                  <th class="font-success f-w-800 border border-dark">Prices Displayed</th>
                  <th class="font-success f-w-800 border border-dark">Transaction Behavior</th>
                  <th class="font-success f-w-800 border border-dark">Additional Info</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="border border-dark">Pump_1</td>
                  <td class="border border-dark">Offers two different fuel types</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">Yes</td>
                  <td class="border border-dark">Normal</td>
                  <td class="border border-dark">-</td>
                </tr>
                <tr>
                  <td class="border border-dark">Pump_2</td>
                  <td class="border border-dark">Offers two different fuel types</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">No</td>
                  <td class="border border-dark">Normal</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Pump 3 -->
                <tr>
                  <td class="border border-dark">Pump_3</td>
                  <td class="border border-dark">Cancels all transactions</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Zero fueling test</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Pump 4 -->
                <tr>
                  <td class="border border-dark">Pump_4</td>
                  <td class="border border-dark">Rejects all transactions</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Acquire call fails</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Pump 5 -->
                <tr>
                  <td class="border border-dark">Pump_5</td>
                  <td class="border border-dark">Disabled (UNAVAILABLE)</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Does not accept transactions</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Pump 6 -->
                <tr>
                  <td class="border border-dark">Pump_6</td>
                  <td class="border border-dark">Offers two different fuel types with prices</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">Yes</td>
                  <td class="border border-dark">Returns 'ACQUIRE_SUCCESS'</td>
                  <td class="border border-dark">-</td>
                </tr>
                <!-- Pump 7 -->
                <tr>
                  <td class="border border-dark">Pump_7</td>
                  <td class="border border-dark">Rejects all transactions</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Returns 'ACQUIRE_REJECTED'</td>
                  <td class="border border-dark">Shows reason from the client</td>
                </tr>
                <!-- Pump 8 -->
                <tr>
                  <td class="border border-dark">Pump_8</td>
                  <td class="border border-dark">Cancels transactions</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Returns 'PRODUCT_NOT_AVAILABLE'</td>
                  <td class="border border-dark">Invalid pump status</td>
                </tr>
                <!-- Pump 9 -->
                <tr>
                  <td class="border border-dark">Pump_9</td>
                  <td class="border border-dark">Cancels transactions</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Returns 'ACQUIRE_NOT_ANSWERED'</td>
                  <td class="border border-dark">Long client timeout response</td>
                </tr>
                <!-- Pump 10 -->
                <tr>
                  <td class="border border-dark">Pump_10</td>
                  <td class="border border-dark">Order in PROBLEM state</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">-</td>
                  <td class="border border-dark">Overshoot payment limit 250</td>
                  <td class="border border-dark">UNAVAILABLE for Manual noop stations</td>
                </tr>
                <!-- Pump 11 -->
                <tr>
                  <td class="border border-dark">Pump_11</td>
                  <td class="border border-dark">Offers two different fuel types with prices</td>
                  <td class="border border-dark">Super95, DieselVariant1</td>
                  <td class="border border-dark">Yes</td>
                  <td class="border border-dark">Returns 'ACQUIRE_SUCCESS' after 30s</td>
                  <td class="border border-dark">Waits before changing status</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->


      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FuellingSimulatedStations",
  keywords: [
    'Simulated Petrol Stations', 'Simulated Pumps', 'Pump Behavior Testing', 'Petrol Station Simulation',
    'Pump Simulation', 'API Testing', 'Test Scenarios', 'Fuel Pump Testing', 'Virtual Petrol Stations',
    'Mock Petrol Stations', 'Pump Performance', 'Pump Error Scenarios', 'API Simulation', 'Test Environment',
    'Test Stations', 'Testing Tools', 'API Debugging', 'Simulation Setup', 'Simulated Environments',
    'Fuel Pump Scenarios', 'Test Fuel Stations', 'Pump Functionality', 'Simulated Fuel Dispensers',
    'Behavior Testing', 'Test Cases', 'API Validation', 'Station Configuration', 'Pump Configuration',
    'API Testing Guide', 'Test Data', 'Test Setup', 'Mock API', 'Simulated Stations', 'Pump Error Handling',
    'Pump Testing Examples', 'API Test Scripts', 'Fuel Simulation', 'Pump Interaction', 'Station Simulation',
    'Test Pumps', 'Test Simulation', 'API Integration Testing', 'Pump Testing Tools', 'Scenario Testing',
    'API Test Environment', 'Petrol Simulation', 'Testing Scenarios', 'API Simulation Guide', 'Virtual Pump Setup',
    'Fuel Pump API', 'Simulated Station Guide', 'Testing Best Practices', 'API Test Scenarios', 'Simulation Tools',
    'API Test Data', 'API Mocking', 'Fuel Dispensing Scenarios', 'Simulated Test Environment', 'Station and Pump Testing',
    'Test Execution', 'Pump Test Examples', 'API Test Cases', 'Testing Framework', 'API Mock Setup'
  ]
}
</script>