<template>
  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-12">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Histogram Parameters:
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">From:</label>
                    <input v-model="fromTimestamp" type="date" class="form-control"
                           id="fromTimestamp" placeholder="From Timestamp">
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">To:</label>
                    <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                  </div>
                  <div class="col d-flex align-items-center" v-if="loggedInUserIsRyd">
                    <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">For Partner:</label>
                    <select class="form-control border border-primary min-width-max-content" id="partnerMetricsFor"
                            v-model="partnerMetricsFor"
                            required>
                      <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                        {{ partnerName }}
                      </option>
                    </select>
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">Histogram Interval:</label>
                    <select class="form-control border border-primary min-width-max-content" id="histogramInterval"
                            v-model="histogramInterval"
                            required>
                      <option value="day">day</option>
                      <option value="hour">hour</option>
                    </select>
                  </div>

                  <div class="col">
                    <button @click="refreshHistogram"
                            class="btn btn-pill btn-success btn-air-success active input-group-text">
                      <i class="fa fa-send m-r-10"></i> Refresh Histogram
                    </button>
                  </div>

                  <div class="col">
                    <button @click="copyShareableLink" class="btn btn-info rounded-pill m-l-5"
                            data-bs-toggle="popover"
                            data-bs-placement="left"
                            data-bs-title="Information"
                            title="Click this button to generate a full URL, including your input fields, making it easy to share with others."
                            data-bs-content="Click this button to generate a full URL, including your input fields, making it easy to share with others.">
                      <i class="fa fa-share"></i> Share
                    </button>
                  </div>

                </div>
              </div>
              <!-- INPUT GROUP 1 END -->
            </div>
          </div>
        </div>
        <!-- CARD 1 END -->


        <!-- Loading Indicator START -->
        <div v-if="isLoadingHistogramOrderCreation" class="loading-indicator">
          <h6 class="sub-title mb-0 text-center">Loading ... Histogram of Created Orders</h6>
          <div class="loader-box">
            <div class="loader-17"></div>
          </div>
        </div>
        <!-- Loading Indicator END -->

        <!-- CARD 2 START -->
        <div class="card"
             v-if="!isLoadingHistogramOrderCreation && termsHistogramNrOfCreatedOrders && termsHistogramNrOfCreatedOrders.labels && termsHistogramNrOfCreatedOrders.labels.length > 0">
          <div class="card-body">
            <div id="basic-apex">
              <apexchart type="area" height="350" ref="chart" :options="chartOptions" :series="series"></apexchart>
            </div>


            <!-- BUTTON START-->
            <br/>
            <div class="row" v-if="loggedInUserIsRyd">
              <div class="col">
                <div
                    v-if="termsHistogramNrOfCreatedOrders && termsHistogramNrOfCreatedOrders.graylogQueryUrl">
                  <a :href="termsHistogramNrOfCreatedOrders.graylogQueryUrl" target="_blank"
                     class="btn btn-pill btn-primary btn-lg" title="Go To Graylog Query">
                    Go To Graylog Query to See More Details
                  </a>
                </div>
              </div>
            </div>
            <!-- BUTTON END-->

          </div>
        </div>
        <!-- CARD 2 END -->

        <!-- CARD 3 START -->
        <div class="card"
             v-if="!isLoadingHistogramOrderCreation && termsHistogramNrOfCreatedOrders && termsHistogramNrOfCreatedOrders.labels && termsHistogramNrOfCreatedOrders.labels.length === 0">
          <div class="card-body">
            <h6 class="chart-title">Order Creation Trends</h6>
            <span>Comprehensive Overview of Order Volumes Over Time</span>
            <br/><br/>
            <div class="error-message">No data is available.</div>
          </div>
        </div>
        <!-- CARD 3 END -->


      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import getParamsFromHash from "@/utils/urlUtils";

let primary = localStorage.getItem('primary_color') || '#24695c';
let secondary = localStorage.getItem('secondary_color') || '#ba895d';

export default {
  props: {
    partnerNames: []
  },
  data() {
    return {
      showAlertTimestamps: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      loggedInUserIsRyd: false,
      loggedInUserGroup: null,
      partnerMetricsFor: '',
      isLoadingHistogramApiGatewayHttpRequests: false,
      isLoadingHistogramOrderCreation: false,
      histogramInterval: 'hour',
      termsHistogramNrOfCreatedOrders: {
        totalRequests: [],
        failedRequests: [],
        errorRates: [],
        labels: [],
      }
    }
  },
  computed: {
    series() {
      if (this.termsHistogramNrOfCreatedOrders) {
        return [{
          name: "Number of Created Orders",
          data: this.termsHistogramNrOfCreatedOrders.totalCreatedOrders,
        }];
      }
      return [];
    },

    chartOptions() {
      const isHourInterval = this.histogramInterval === "hour";
      return {
        chart: {
          height: 350,
          type: 'area',
          zoom: {enabled: false},
          toolbar: {show: false},
        },
        dataLabels: {enabled: false},
        stroke: {curve: 'straight'},
        title: {
          text: 'Order Creation Trends',
          align: 'left',
        },
        subtitle: {
          text: 'Comprehensive Overview of Order Volumes Over Time',
          align: 'left',
        },
        labels: this.termsHistogramNrOfCreatedOrders ? this.termsHistogramNrOfCreatedOrders.labels : [],
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function(value, timestamp) {
              // Convert timestamp to Date object and format it
              const date = new Date(timestamp);
              const formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2);
              return formattedDate;
            }
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: (value) => `${value}`
          }
        },
        tooltip: {
          enabled: true,
          marker: {show: true},
          x: {
            formatter: (value) => {
              const date = new Date(value);
              let formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2);

              if (isHourInterval) {
                // Format as 'yyyy-MM-dd HH:00' for hourly interval
                let hours = ('0' + date.getHours()).slice(-2);
                return `${formattedDate} ${hours}:00`;
              } else {
                // Format as 'yyyy-MM-dd' for daily interval
                return formattedDate;
              }
            }
          }
        },
        colors: [primary] // Ensure 'primary' is defined in data or as a computed property
      };
    }
  },
  mounted() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.partnerMetricsFor = userGroup;
    this.initializeParameters();
    this.checkAndTriggerSearch();
    this.fetchHistogramOrderCreation(this.fromTimestamp, this.toTimestamp);
  },
  methods: {
    async refreshHistogram() {
      if (!this.validateTimestamps()) {
        this.showAlertTimestamps = true;
        return;
      }

      this.showAlertTimestamps = false;
      await this.fetchHistogramOrderCreation(this.fromTimestamp, this.toTimestamp);
    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sixMonths = 2 * 30 * 24 * 60 * 60 * 1000; // Approximately 2 months in milliseconds
      if (to - from > sixMonths) {
        this.showAlertTimestamps = true;
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    addXDayAndConvertDateToISOString(date, days) {
      if (date) {
        let dateInputValue = new Date(date);
        dateInputValue.setDate(dateInputValue.getDate() + days);
        return dateInputValue.toISOString();
      }
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 2);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate());
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    initializeParameters() {
      let paramsFromHash = getParamsFromHash(window.location.hash);

      this.fromTimestamp = paramsFromHash.from || this.getDefaultFromDate();
      this.toTimestamp = paramsFromHash.to || this.getDefaultToDate();

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';

      this.partnerMetricsFor = paramsFromHash.partnerMetricsFor || this.loggedInUserGroup;

      this.histogramInterval = paramsFromHash.histogramInterval || 'hour';

    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to) {
        this.refreshHistogram();
      }
    },
    async fetchHistogramOrderCreation(fromTimestamp, toTimestamp) {
      this.isLoadingHistogramOrderCreation = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/terms-histogram/created-orders`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.addXDayAndConvertDateToISOString(toTimestamp, 1),
            histogramInterval: this.histogramInterval,
            size: 100,
            termsHistogramField: 'eventLastState',
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        //console.log("fetchHistogramOrderCreation response", response.data);
        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.termsHistogramNrOfCreatedOrders = null;
        } else {
          this.termsHistogramNrOfCreatedOrders = response.data;
        }

        //console.log("termsHistogramNrOfCreatedOrders", this.termsHistogramNrOfCreatedOrders);

      } catch (error) {
        this.termsHistogramNrOfCreatedOrders = null;
        console.error('Error termsHistogramNrOfCreatedOrders:', error);
      } finally {
        this.isLoadingHistogramOrderCreation = false;
      }
    },

    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname + window.location.hash;
      const queryParams = new URLSearchParams({
        from: this.fromTimestamp || '',
        to: this.toTimestamp || '',
        partnerMetricsFor: this.partnerMetricsFor?.trim() || 'ryd',
        histogramInterval: this.histogramInterval
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('It has been successfully copied to the clipboard as a complete link and can now be components.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },

  }
}

</script>
